import React from 'react';

const CloseSearchInChat = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.70889 11.994C3.70822 11.9165 3.7376 11.8417 3.79081 11.7854L7.2214 8.35479L7.57495 8.00124L7.2214 7.64768L3.78705 4.21334C3.78704 4.21332 3.78702 4.2133 3.787 4.21328C3.73079 4.15703 3.69922 4.08076 3.69922 4.00124C3.69922 3.92174 3.73077 3.84549 3.78695 3.78924C3.78697 3.78923 3.78698 3.78921 3.787 3.78919C3.78702 3.78917 3.78704 3.78915 3.78705 3.78914C3.8433 3.73296 3.91955 3.70141 3.99905 3.70141C4.07857 3.70141 4.15484 3.73298 4.21109 3.78919C4.21111 3.78921 4.21113 3.78923 4.21115 3.78924L7.64549 7.22359L7.99905 7.57714L8.3526 7.22359L11.787 3.78919L11.7871 3.78924L11.7931 3.78299C11.8208 3.75434 11.8539 3.73148 11.8905 3.71576C11.9271 3.70004 11.9664 3.69176 12.0063 3.69142C12.0461 3.69107 12.0856 3.69866 12.1225 3.71375C12.1593 3.72883 12.1928 3.75111 12.221 3.77927C12.2492 3.80744 12.2715 3.84094 12.2865 3.8778C12.3016 3.91468 12.3092 3.95418 12.3089 3.99401C12.3085 4.03385 12.3002 4.07321 12.2845 4.10982C12.2688 4.14641 12.2459 4.17952 12.2173 4.20719L12.2172 4.20714L12.2111 4.21328L8.77669 7.64768L8.42314 8.00124L8.77669 8.35479L12.2073 11.7854C12.2605 11.8417 12.2899 11.9165 12.2892 11.994C12.2885 12.0727 12.257 12.1479 12.2013 12.2035C12.1457 12.2592 12.0705 12.2907 11.9918 12.2914C11.9143 12.2921 11.8395 12.2627 11.7832 12.2095L8.3526 8.77888L7.99905 8.42533L7.64549 8.77888L4.21491 12.2095C4.15859 12.2627 4.08383 12.2921 4.00627 12.2914C3.92761 12.2907 3.85237 12.2592 3.79675 12.2035C3.74112 12.1479 3.70957 12.0727 3.70889 11.994Z"
        fill="#3F3F46"
        stroke="#60A5FA"
      />
    </svg>
  );
};

export default CloseSearchInChat;
