import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { translateRequisitesPath } from '../constants';

import { RequestBalanceModalProps } from './request-balance-modal.type';

import { Modal } from '@/components/ui';
import { User } from '@/types/user.type';
import { useAppSelector } from '@/hooks/use-app-selector';
import { userSelector } from '@/store/selectors/auth.selectors';

const RequestBalanceModal: FC<RequestBalanceModalProps> = ({ isShow, setShow, submit }) => {
  const { t } = useTranslation();

  const user: User | null = useAppSelector(userSelector);

  function handleSubmit() {
    submit?.();
  }

  return (
    <Modal
      isShow={isShow}
      setShow={setShow}
      label={t(`${translateRequisitesPath}.request-balance-modal.label`)}
      type="dialog"
      submitStyle="bg-primary_400 text-white"
      submitText={
        (!user?.referral_contract_url && !user?.counterparty_link) ||
        (!user?.referral_contract_url && user?.counterparty_link)
          ? t(`${translateRequisitesPath}.request-balance-modal.no-contract.submit-text`)
          : t(`${translateRequisitesPath}.request-balance-modal.contract-is-sign.submit-text`)
      }
      onSubmit={handleSubmit}
    >
      {(!user?.referral_contract_url && !user?.counterparty_link) ||
      (!user?.referral_contract_url && user?.counterparty_link) ? (
        <p className="text-gray_600 text-paragraph_m">
          {t(`${translateRequisitesPath}.request-balance-modal.no-contract.text`)}
        </p>
      ) : (
        <div>
          <p className="text-gray_600 text-paragraph_m mb-[12px]">
            {t(`${translateRequisitesPath}.request-balance-modal.contract-is-sign.text`)}
          </p>
          <p className="text-paragraph_m text-gray_500">
            {t(`${translateRequisitesPath}.request-balance-modal.contract-is-sign.balance`)}:
          </p>
          <p className="text-heading_h3 text-gray_600 font-bold">
            {user?.partner_balance}{' '}
            {t(`${translateRequisitesPath}.request-balance-modal.contract-is-sign.currency`)}
          </p>
        </div>
      )}
    </Modal>
  );
};

export default RequestBalanceModal;
