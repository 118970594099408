import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { apiBaseUrl } from '@/util/api';
import { ListResponse } from '@/types/responses.type';
import { User } from '@/types/user.type';
import { UpdateProfile } from '@/types/profile.type';

export const profileApi = createApi({
  reducerPath: 'ProfileQuery',
  tagTypes: ['Profile'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
  }),
  endpoints: (build) => ({
    // Редактировать профиль
    updateUser: build.mutation<ListResponse<User>, UpdateProfile>({
      query: ({ userId, body }) => ({
        url: `/users/${userId}`,
        method: 'POST',
        body,
      }),
    }),
    // Получить пользователя по id
    getUsers: build.query<ListResponse<User>, number>({
      query: (id) => ({
        url: `/users/${id}`,
      }),
    }),
    // Запрос вывода средств по партнерской программе
    requestWithdraw: build.mutation<ListResponse<string>, unknown>({
      query: () => ({
        url: `/users/withdraw`,
        method: 'POST',
      }),
    }),
  }),
});

export const { useUpdateUserMutation, useGetUsersQuery, useRequestWithdrawMutation } = profileApi;
