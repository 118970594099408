import React from 'react';

const Inbox = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M33.3337 21.666V9.99935C33.3337 9.11529 32.9825 8.26745 32.3573 7.64233C31.7322 7.0172 30.8844 6.66602 30.0003 6.66602H10.0003C9.11627 6.66602 8.26842 7.0172 7.6433 7.64233C7.01818 8.26745 6.66699 9.11529 6.66699 9.99935V21.666M33.3337 21.666V29.9993C33.3337 30.8834 32.9825 31.7312 32.3573 32.3564C31.7322 32.9815 30.8844 33.3327 30.0003 33.3327H10.0003C9.11627 33.3327 8.26842 32.9815 7.6433 32.3564C7.01818 31.7312 6.66699 30.8834 6.66699 29.9993V21.666M33.3337 21.666H29.0237C28.5817 21.6661 28.1578 21.8418 27.8453 22.1543L23.822 26.1777C23.5095 26.4903 23.0857 26.6659 22.6437 26.666H17.357C16.915 26.6659 16.4911 26.4903 16.1787 26.1777L12.1553 22.1543C11.8428 21.8418 11.419 21.6661 10.977 21.666H6.66699"
      ></path>
    </svg>
  );
};

export default Inbox;
