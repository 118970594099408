import clsx from 'clsx';

export const columnsClassName = clsx(
  'grid',
  'mx-[12px]',
  'px-[12px]',
  'grid-cols-[1.5fr_1fr_1fr_1.5fr_1fr_1fr]',
  'border-b-[1px]',
  'border-b-gray_200',
  'text-gray_400',
  'text-paragraph_m',
  'font-medium',
);

export const cellClassName = 'font-bold text-paragraph_m flex items-center pr-2 last:pr-0';
