import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CounterpartyListBlock from '../blocks/counterparty-list.block';

import { Button, Container, Input, Modal } from '@/components/ui';
import AddCounterparty from '@/components/add-counterparty';
import LegalForm from '@/components/counterparty-forms/legal-form';
import IndividualForm from '@/components/counterparty-forms/individual-form';
import { Counterparty } from '@/types/counterparty.type';
import { fetchCounterparties } from '@/store/slices/counterparty.slice';
import {
  counterpartySelector,
  totalCounterpartiesCountSelector,
} from '@/store/selectors/counterparty.selectors';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { useAppSelector } from '@/hooks/use-app-selector';
import ChatOpen from '@/features/chat/blocks/chatOpen/chatOpen';
// import { useLazyGetContractImportQuery } from '@/store/api/contract.api';
// import { useNotification } from '@/hooks/use-notifications';

type SearchField = {
  search: string;
};

const CounterpartiesPage = () => {
  const { t } = useTranslation();
  const { register, control } = useForm<SearchField>();
  const search = useWatch({
    control,
    name: 'search',
  });

  // const { createNotification } = useNotification();

  const pageLimit = 10;

  const dispatch = useAppDispatch();

  const counterparties: Counterparty[] = useAppSelector(counterpartySelector); // Все контрагенты
  const totalCounterpartiesCount: number = useAppSelector(totalCounterpartiesCountSelector); // Всего контрагентов

  const [openAddCounterparty, toggleAddCounterparty] = useState<boolean>(false);
  const [counterparty, setCounterparty] = useState<Counterparty | null>(null);
  const [isShownForm, setIsShownForm] = useState(false);
  const [searchText, setSearchText] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    const params = {
      search: searchText,
      page: currentPage,
      limit: pageLimit,
    };
    if (currentPage !== 0) {
      dispatch(fetchCounterparties(params));
    }
  }, [searchText, currentPage, dispatch]);

  // Живой поиск по контрагентам
  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      setSearchText(search);
      setCurrentPage(1);
    }, 500);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [search]);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleOnCancelCounterpartyForm = () => {
    setCounterparty(null);
    setIsShownForm(false);
    toggleAddCounterparty(false);
  };

  const openCounterpartyForEdit = (item: Counterparty) => {
    setCounterparty(item);
    setIsShownForm(true);
  };

  return (
    <Container>
      <div className="my-[20px]">
        <h1 className="text-heading_h2 font-bold text-gray_600">{t('counterparties.title')}</h1>
      </div>
      <div className="inline-flex items-center justify-between w-full mb-[24px]">
        <div className="w-full max-w-[673px] mr-[7px]">
          <Input
            defaultValue={search}
            beforeIcon="Search"
            {...register('search')}
            placeholder={t('counterparties.input.placeholder')}
            // onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="inline-flex items-center">
          <div className="text-paragraph_l font-medium text-gray_400">
            {t('counterparties.total')}
            <span className="text-gray-600"> {totalCounterpartiesCount}</span>
          </div>
          <div className="ml-[16px]">
            <Button
              label={t('counterparties.btn.add')}
              onClick={toggleAddCounterparty.bind(null, true)}
              space={15}
              icon="Plus"
              variant="filled"
            />
          </div>
        </div>
      </div>
      <CounterpartyListBlock
        counterparties={counterparties}
        onPageChange={onPageChange}
        pageLimit={pageLimit}
        openCounterpartyForEdit={openCounterpartyForEdit}
      />
      <AddCounterparty
        openAddCounterparty={openAddCounterparty}
        toggleAddCounterparty={toggleAddCounterparty}
      />
      {isShownForm && counterparty ? (
        <Modal
          type="dialog"
          withoutFooter={true}
          label={t('create_counterparty.eidt.title')}
          onCancel={handleOnCancelCounterpartyForm}
          isShow={isShownForm}
        >
          {counterparty?.counterparty_type === 'ФЛ' ? (
            <IndividualForm counterparty={counterparty} OnCancel={handleOnCancelCounterpartyForm} />
          ) : (
            <LegalForm counterparty={counterparty} OnCancel={handleOnCancelCounterpartyForm} />
          )}
        </Modal>
      ) : null}
      <ChatOpen />
    </Container>
  );
};

export default CounterpartiesPage;
