import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { apiBaseUrl } from '@/util/api';

export const egrnApi = createApi({
  reducerPath: 'EgrnQuery',
  tagTypes: ['Egrn'],
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
  endpoints: (build) => ({
    // Типы объекта недвижимости
    getEgrnRealtyTypes: build.query({
      query: () => ({
        url: '/egrn/realty_types',
      }),
    }),
    // Список заказов
    getEgrnOrders: build.query({
      query: (params) => ({
        url: '/egrn',
        params: params,
      }),
    }),
    // Создание заказа
    createEgrnOrder: build.mutation({
      query: (data) => ({
        url: '/egrn',
        method: 'POST',
        body: data,
      }),
    }),
    // Данные для заказа по кадастровому номеру
    getEgrnNewOrder: build.query({
      query: (params) => ({
        url: '/egrn/from_cadastr_number',
        params: params,
      }),
    }),
    // Данные для заказа из csv файла
    createEgrnNewOrder: build.mutation({
      query: (data) => ({
        url: '/egrn/fromfile',
        method: 'POST',
        body: data,
      }),
    }),
    // Скачивание заказа
    downloadEgrnOrder: build.query({
      query: (orderId) => ({
        url: `/egrn/${orderId}/download`,
      }),
    }),
    // Скачивание элемента заказа
    downloadEgrnOrderItem: build.query({
      query: (itemId) => ({
        url: `/egrn/item/${itemId}/download`,
      }),
    }),
    // Копирование
    copyEgrnOrder: build.mutation({
      query: ({ orderId, params }) => ({
        url: `/egrn/${orderId}/copy`,
        method: 'POST',
        params: params,
      }),
    }),
    // Оплата
    payEgrnOrder: build.mutation({
      query: (data) => ({
        url: '/payments/egrn',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetEgrnRealtyTypesQuery,
  useGetEgrnOrdersQuery,
  useLazyGetEgrnOrdersQuery,
  useCreateEgrnOrderMutation,
  useGetEgrnNewOrderQuery,
  useCreateEgrnNewOrderMutation,
  useLazyDownloadEgrnOrderQuery,
  useLazyDownloadEgrnOrderItemQuery,
  useCopyEgrnOrderMutation,
  usePayEgrnOrderMutation,
} = egrnApi;
