import React, { FC, ReactNode } from 'react';

import { Icon } from '@/components/ui';
import {
  NotificationProps,
  NotificationVariant,
} from '@/components/ui/notification/notification.types';

const Notification: FC<NotificationProps> = (props) => {
  const { notification, onRemove } = props;
  const { title, description, variant = 'success' } = notification;

  const variantsIconList: Record<NotificationVariant, ReactNode> = {
    success: <Icon name="Check" className="text-positive_500" />,
    fail: <Icon name="Alert" className="text-negative_500" />,
  };

  const renderDescription = description && (
    <div className="text-paragraph_m font-regular text-gray_500 mt-[4px]">{description}</div>
  );

  return (
    <div className="border border-gray_200 shadow-lg rounded bg-white w-[411px] flex items-center z-30 p-[16px] mt-[20px]">
      <div className="inline-flex w-full  text-paragraph_l font-medium text-gray_600  justify-between">
        <div className="inline-flex w-full">
          <div className="mr-[16px]">{variantsIconList[variant]}</div>
          <div>
            <div>{title}</div>
            {renderDescription}
          </div>
        </div>
        <div onClick={onRemove?.bind(null, notification.id)} role="button">
          <Icon
            name="Close"
            size={20}
            className="text-gray_400 transition opacity-80 hover:opacity-100"
          />
        </div>
      </div>
    </div>
  );
};

export default Notification;
