import { useTranslation } from 'react-i18next';

export function useValidation() {
  const { t } = useTranslation();

  return {
    company_name: {
      required: {
        value: true,
        message: String(t('common.input.company_name.error.required')),
      },
    },
    legal_inn: {
      required: {
        value: true,
        message: String(t('common.input.inn.error.required')),
      },
      pattern: {
        value: /^([0-9]{10})?$/,
        message: String(t('common.input.inn.error.wrong')),
      },
    },
    individual_inn: {
      required: {
        value: true,
        message: String(t('common.input.inn.error.required')),
      },
      pattern: {
        value: /^([0-9]{12})?$/,
        message: String(t('common.input.inn.error.wrong')),
      },
    },
    kpp: {
      required: {
        value: true,
        message: String(t('common.input.kpp.error.required')),
      },
      pattern: {
        value: /^([0-9]{9})?$/,
        message: String(t('common.input.kpp.error.wrong')),
      },
    },
    ogrn: {
      required: {
        value: true,
        message: String(t('common.input.ogrn.error.required')),
      },
      pattern: {
        value: /^(([0-9]{15}|([0-9]{13})))?$/,
        message: String(t('common.input.ogrn.error.wrong')),
      },
    },
    address: {
      required: {
        value: true,
        message: String(t('common.input.address.error.required')),
      },
    },
    manager_name: {
      required: {
        value: true,
        message: String(t('common.input.manager_name.error.required')),
      },
    },
    manager_position: {
      required: {
        value: true,
        message: String(t('common.input.manager_position.error.required')),
      },
    },
    bank_name: {
      required: {
        value: true,
        message: String(t('common.input.bank_name.error.required')),
      },
    },
    billing_account: {
      required: {
        value: true,
        message: String(t('common.input.billing_account.error.required')),
      },
      pattern: {
        value: /^([0-9]{20})?$/,
        message: String(t('common.input.billing_account.error.wrong')),
      },
    },
    bik: {
      required: {
        value: true,
        message: String(t('common.input.bik.error.required')),
      },
      pattern: {
        value: /^([0-9]{9})?$/,
        message: String(t('common.input.bik.error.wrong')),
      },
    },
    correspondent_account: {
      required: {
        value: true,
        message: String(t('common.input.correspondent_account.error.required')),
      },
      pattern: {
        value: /^([0-9]{20})?$/,
        message: String(t('common.input.correspondent_account.error.wrong')),
      },
    },
    email: {
      required: {
        value: true,
        message: String(t('common.input.email.error.required')),
      },
      pattern: {
        value: /^[\w.0-9_-]+@([\w-]+\.)+[\w-]{2,4}$/,
        message: String(t('common.input.email.error.wrong')),
      },
    },
    name: {
      required: {
        value: true,
        message: String(t('common.input.name.error.required')),
      },
    },
    password: {
      required: {
        value: true,
        message: String(t('common.input.password.error.required')),
      },
    },
    surname: {
      required: {
        value: true,
        message: String(t('common.input.password.error.required')),
      },
    },
    middle_name: {
      required: {
        value: true,
        message: String(t('common.input.password.error.required')),
      },
    },
    phone1: {
      required: {
        value: true,
        message: String(t('common.input.phone.error.required')),
      },
      minLength: {
        value: 18,
        message: String(t('common.input.phone.error.wrong')),
      },
    },
    phone2: {
      minLength: {
        value: 18,
        message: String(t('common.input.phone.error.wrong')),
      },
    },
    cadastral_number: {
      required: {
        value: true,
        message: String(t('common.input.cadastral_number.error.required')),
      },
      pattern: {
        value: /^\d{2}:\d{2}:\d{6,7}:\d+$/,
        message: String(t('common.input.cadastral_number.error.wrong')),
      },
    },
    passport_ser_num: {
      required: {
        value: true,
        message: String(t('common.input.passport_ser_num.error.required')),
      },
      pattern: {
        value: /^\d{4}\s{1}\d{6}$/,
        message: String(t('common.input.passport_ser_num.error.wrong')),
      },
    },
    passport_issued_by: {
      required: {
        value: true,
        message: String(t('common.input.passport_issued_by.error.required')),
      },
    },
    passport_date: {
      required: {
        value: true,
        message: String(t('common.input.passport_date.error.required')),
      },
    },
    passport_snils: {
      required: {
        value: true,
        message: String(t('common.input.passport_snils.error.required')),
      },
      pattern: {
        value: /^\d{3}-\d{3}-\d{3} \d{2}/,
        message: String(t('common.input.passport_snils.error.wrong')),
      },
    },
  };
}
