import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import MyClientsBlock from '../blocks/my-clients/my-clients.block';
import RequestBalanceModal from '../blocks/request-balance-modal/request-balance-modal.block';
import RequisitesAndContractBlock from '../blocks/requisites-and-contract/requisites-and-contract.block';
import { GenerateReportFields } from '../blocks/requisites-and-contract/types';

import { Button, Container, Icon, Menu } from '@/components/ui';
import { MenuItem } from '@/components/ui/menu/menu';
import { affiliateProgramQuery, route, tabQuery } from '@/util/route';
import { useQuery } from '@/hooks/use-query';
import HowToEarnBlock from '@/features/affiliate-program/blocks/how-to-earn/how-to-earn.block';
import { useNotification } from '@/hooks/use-notifications';
import { useAppSelector } from '@/hooks/use-app-selector';
import { userSelector } from '@/store/selectors/auth.selectors';
import DetailsOfAffiliatePaymentsBlock from '@/features/affiliate-program/blocks/details-of-affiliate-payments/details-of-affiliate-payments.block';
import { User } from '@/types/user.type';
import { useRequestWithdrawMutation } from '@/store/api/profile.api';
import { useGetReferralsCountQuery } from '@/store/api/affiliate-program.api';
import ChatOpen from '@/features/chat/blocks/chatOpen/chatOpen';

const translatePath = 'affiliate-program.menu';

const AffiliateProgramPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { createNotification } = useNotification();
  const query = useQuery();
  const form = useForm<GenerateReportFields>();

  const user: User | null = useAppSelector(userSelector);

  const active = query.get('tab') || affiliateProgramQuery.myClientsList;

  const menuList: Array<MenuItem> = [
    {
      tag: affiliateProgramQuery.myClientsList,
      title: t(`${translatePath}.${affiliateProgramQuery.myClientsList}`),
      link: `${route.affiliateProgram}${tabQuery}${affiliateProgramQuery.myClientsList}`,
    },
    {
      tag: affiliateProgramQuery.detailsOfAffiliatePayments,
      title: t(`${translatePath}.${affiliateProgramQuery.detailsOfAffiliatePayments}`),
      link: `${route.affiliateProgram}${tabQuery}${affiliateProgramQuery.detailsOfAffiliatePayments}`,
    },
    {
      tag: affiliateProgramQuery.requisitesAndContract,
      title: t(`${translatePath}.${affiliateProgramQuery.requisitesAndContract}`),
      link: `${route.affiliateProgram}${tabQuery}${affiliateProgramQuery.requisitesAndContract}`,
    },
    {
      tag: affiliateProgramQuery.howToEarnWith1kk,
      title: t(`${translatePath}.${affiliateProgramQuery.howToEarnWith1kk}`),
      link: `${route.affiliateProgram}${tabQuery}${affiliateProgramQuery.howToEarnWith1kk}`,
    },
  ];

  const [showBalanceModal, setShowBalanceModal] = useState(false);

  const [requestWithdraw] = useRequestWithdrawMutation();
  const { data: referralsCount } = useGetReferralsCountQuery({});

  function copyToClipboard(text: string) {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();

    createNotification?.({
      variant: 'success',
      title: t('affiliate-program.copy.title'),
      description: t('affiliate-program.copy.description'),
    });
  }

  function setVisibleModal() {
    setShowBalanceModal(true);
  }

  function requestWithoutBalance() {
    if (!user?.referral_contract_url) {
      navigate(
        `${route.affiliateProgram}${tabQuery}${affiliateProgramQuery.requisitesAndContract}`,
      );
      setShowBalanceModal(false);
    } else if (user?.referral_contract_url && user?.counterparty_link) {
      requestWithdraw({}).then((data: any) => {
        if (data.error) {
          createNotification?.({
            variant: 'fail',
            title: data.error.data.error,
          });
        } else {
          createNotification?.({
            variant: 'success',
            title: t('affiliate-program.current-balance.notification.title'),
            description: t('affiliate-program.current-balance.notification.description'),
          });
          setShowBalanceModal(false);
        }
      });
    }
  }

  function getContent() {
    switch (active) {
      case affiliateProgramQuery.howToEarnWith1kk:
        return <HowToEarnBlock />;
      case affiliateProgramQuery.requisitesAndContract:
        return <RequisitesAndContractBlock form={form} />;
      case affiliateProgramQuery.detailsOfAffiliatePayments:
        return <DetailsOfAffiliatePaymentsBlock />;
      default:
        return <MyClientsBlock />;
    }
  }

  return (
    <Container>
      <div className="mt-[24px] mb-[16px]">
        <h1 className="text-heading_h2 font-bold text-gray_600">
          {t('affiliate-program.heading')}
        </h1>
      </div>
      <div className="grid grid-cols-2 gap-x-[24px]">
        <div>
          <p className="text-gray_600 text-paragraph_m mb-[4px]">
            {t('affiliate-program.referral-link.label')}
          </p>
          <div className="flex items-center justify-between gap-[15px] bg-white px-[16px] py-[5px] border border-gray_300 hover:border-gray_400 rounded">
            <div className="flex gap-[15px] items-center w-full">
              <div className="text-primary_400">
                <Icon name="Globe" size={18} />
              </div>
              <span className="text-gray_600 truncate">{user?.referral_link}</span>
            </div>
            <Button
              onClick={() => copyToClipboard(user?.referral_link || '')}
              icon="Link"
              label={t('affiliate-program.referral-link.buttonText')}
              customStyles="text-primary_400 p-0 hover:opacity-80 py-[8px]"
              customIconStyles="mr-[4px] ml-[-6px]"
              iconSize={18}
              space={16}
            />
          </div>
        </div>
        <div>
          <p className="text-gray_600 text-paragraph_m mb-[4px]">
            {t('affiliate-program.current-balance.label')}
          </p>
          <div className="flex items-center justify-between gap-[15px] bg-white px-[16px] py-[5px] border border-gray_300 hover:border-gray_400 rounded">
            <div className="flex gap-[15px] items-center">
              <div className="text-primary_400">
                <Icon name="Database" size={18} />
              </div>
              <span className="text-gray_600 text-heading_h3 font-bold">
                {user?.partner_balance || 0} руб
              </span>
            </div>
            <Button
              label={t('affiliate-program.current-balance.buttonText')}
              space={16}
              disabled={user?.partner_balance === null || user?.partner_balance === 0}
              onClick={setVisibleModal}
            />
            <RequestBalanceModal
              isShow={showBalanceModal}
              setShow={setShowBalanceModal}
              submit={requestWithoutBalance}
            />
          </div>
        </div>
      </div>
      <div className="text-paragraph_m text-gray_600 mt-[4px]">
        {t('affiliate-program.referral-link.registered')}: {referralsCount?.data.count || 0}
      </div>
      <div className="inline-flex justify-between flex-col md:flex-row my-[24px] items-center">
        <Menu menuData={menuList} active={active} />
      </div>
      <div>{getContent()}</div>
      <ChatOpen />
    </Container>
  );
};
export default AffiliateProgramPage;
