import React, { FC, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@/components/ui';

type VideoInstructionProps = {
  isVisibleVideoInstruction: boolean;
  changeVideoInstructionVisible: (state: boolean) => void;
};

const VideoInstruction: FC<VideoInstructionProps> = (props) => {
  const descktopVideoRef = useRef<HTMLVideoElement>(null);
  const mobileVideoRef = useRef<HTMLVideoElement>(null);
  const { isVisibleVideoInstruction, changeVideoInstructionVisible } = props;
  const { t } = useTranslation();

  useEffect(() => {
    if (isVisibleVideoInstruction) {
      if (document.documentElement.clientWidth > 1000) {
        descktopVideoRef.current?.play();
      } else {
        mobileVideoRef.current?.play();
      }
    }
  }, [isVisibleVideoInstruction, mobileVideoRef, descktopVideoRef]);

  return (
    <Modal
      type="video"
      showClose
      withoutFooter={true}
      label={t('common.video_instruction')}
      onCancel={changeVideoInstructionVisible.bind(null, false)}
      isShow={isVisibleVideoInstruction}
    >
      <div className="flex justify-center 1/2 items-center  relative">
        <video
          className="hidden lg:block"
          ref={descktopVideoRef}
          src="/files/video/affiliate-presenation-1kadastr.mp4"
          preload="auto"
          controls={true}
        ></video>
        <video
          className="lg:hidden max-h-[800px]"
          ref={mobileVideoRef}
          src="/files/video/affiliate-presenation-1kadastr-vertical.mp4"
          preload="auto"
          controls={true}
        ></video>
      </div>
    </Modal>
  );
};

export default VideoInstruction;
