import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@/components/ui';
import VideoInstruction from '@/components/video-instruction';

const CompanyPromo = () => {
  const { t } = useTranslation();
  const itemListClassName = 'inline-flex text-gray_600 mb-[20px]';
  const itemTextListClassName = 'ml-[15px]';
  const [isVisibleVideoInstruction, changeVideoInstructionVisible] = useState<boolean>(false);
  return (
    <div className="w-[387px] p-[22px]">
      <div className="mb-[40px]">
        <Icon name="LogoV3" size={100} />
      </div>
      <ul className="flex flex-col">
        <li className={itemListClassName}>
          <Icon name={'Calendar'} className="text-primary_400" />
          <span className={itemTextListClassName}>{t('login.title_add_tasks_online')}</span>
        </li>
        <li className={itemListClassName}>
          <Icon name={'TrendingUp'} className="text-primary_400" />
          <span className={itemTextListClassName}>{t('login.track_status')}</span>
        </li>
        <li className={itemListClassName}>
          <Icon name={'Document'} className="text-primary_400" />
          <span className={itemTextListClassName}>{t('login.download_documents')}</span>
        </li>
        <li className={itemListClassName}>
          <Icon name={'ChatAlt'} className="text-primary_400" />
          <div className="flex flex-col">
            <span className={itemTextListClassName}>{t('login.ask_the_questions')}</span>
            <a
              href={`tel:${t('login.phone').replace(/[^+\d]/g, '')}`}
              className={itemTextListClassName}
            >
              {t('login.phone')}
            </a>
            <span className={itemTextListClassName}>{t('login.help_chat')}</span>
          </div>
        </li>
        <li
          className={`${itemListClassName} cursor-pointer`}
          onClick={changeVideoInstructionVisible.bind(null, true)}
        >
          <Icon name={'Video'} className="text-primary_400" />
          <span className={itemTextListClassName}>{t('login.video')}</span>
        </li>
      </ul>
      <VideoInstruction
        isVisibleVideoInstruction={isVisibleVideoInstruction}
        changeVideoInstructionVisible={changeVideoInstructionVisible}
      />
    </div>
  );
};

export default CompanyPromo;
