import React, { useState, forwardRef, useRef } from 'react';
import DatePicker, { ReactDatePicker, registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';

import { Icon } from '@/components/ui';
import { inputWrapperClassName } from '@/components/ui/input/input.styles';
import { DatepickerProps } from '@/components/ui/input/datepicker.types';

const Datepicker = forwardRef((props: DatepickerProps) => {
  const {
    selected,
    defaultValue,
    value,
    onChange,
    error,
    onRangeChange,
    placeholder,
    disabled = false,
    icon,
    onIconClick,
    resetIcon,
    onResetIconClick,
    isRange,
    startDate,
    endDate,
  } = props;

  const [isFocus] = useState(false);
  const ref = useRef<ReactDatePicker>(null);
  registerLocale('ru', ru);

  function handlerIconClick() {
    if (ref !== null) {
      ref?.current?.setOpen(true);
    }
    onIconClick?.();
  }

  function handlerResetIconClick() {
    onResetIconClick?.();
  }

  function handleOnChange(date: Date) {
    onChange?.(date);
  }

  function handleOnRangeChange(update: [Date | null, Date | null]) {
    onRangeChange?.(update);
  }

  const renderIcon = icon && (
    <div className="mx-[4px] cursor-pointer" onClick={handlerIconClick}>
      <Icon name={icon} size={24} className="opacity-80 text-primary_400 hover:opacity-100" />
    </div>
  );

  const renderResetIcon = resetIcon && (
    <div className="mx-[4px] cursor-pointer" onClick={handlerResetIconClick}>
      <Icon name={resetIcon} size={24} className="opacity-80 text-primary_400 hover:opacity-100" />
    </div>
  );

  const renderInput = isRange ? (
    <DatePicker
      selected={defaultValue}
      onChange={handleOnRangeChange}
      ref={ref}
      key="example9"
      placeholderText={placeholder}
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      dateFormat="mm/dd/yyyy"
      locale="ru"
    />
  ) : (
    <DatePicker
      selected={selected}
      onChange={handleOnChange}
      value={value}
      ref={ref}
      key="example9"
      placeholderText={placeholder}
      className="text-gray_600"
      locale="ru"
    />
  );

  return (
    <div className="w-full">
      <div className={inputWrapperClassName({ isFocus, disabled, error: !!error })}>
        {renderInput}
        {value && renderResetIcon}
        {renderIcon}
      </div>
    </div>
  );
});

Datepicker.displayName = 'Datepicker';

export default Datepicker;
