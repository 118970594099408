import React, { FC } from 'react';
import clsx from 'clsx';

import { Skeleton } from '@/components/ui';
import OrderCardHeader from '@/components/ui/order-card/order-card-header';

type OrderCardSkeletonProps = {
  count: number;
  ordering?: string;
};

const OrderCardSkeleton: FC<OrderCardSkeletonProps> = (props) => {
  const { count, ordering } = props;

  const renderSkeletonRows = Array(count)
    .fill('_')
    .map((_, index) => (
      <div key={index} className="px-[24px]" role="listitem">
        <div className={clsx(++index !== count && 'border-b-[1px] border-b-gray_200')}>
          <div className="grid grid-cols-[2fr_1fr_2fr_1fr] py-[16px]">
            <div>
              <div className="mb-[4px]">
                <Skeleton size={149} height={22} />
              </div>
              <div>
                <Skeleton />
              </div>
            </div>
            <div className="flex items-center">
              <Skeleton size={100} height={21} />
            </div>
            <div className="flex items-center">
              <Skeleton size={100} height={21} />
            </div>
            <div className={clsx('flex', 'justify-end', 'items-center')}>
              <Skeleton size={100} height={21} />
            </div>
          </div>
        </div>
      </div>
    ));

  return (
    <>
      <OrderCardHeader ordering={ordering} />
      {renderSkeletonRows}
    </>
  );
};

export default OrderCardSkeleton;
