import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { apiBaseUrl } from '@/util/api';
import { ListResponse } from '@/types/responses.type';
import { Contract, Progress, Statements, getContractParams } from '@/types/contract.type';

export const contractApi = createApi({
  reducerPath: 'ContractQuery',
  tagTypes: ['Contract'],
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
  endpoints: (build) => ({
    // Все договора
    getContractList: build.query<ListResponse<Contract[]>, getContractParams>({
      query: (params) => ({
        url: '/contract/',
        params,
      }),
    }),
    // Договор
    getContract: build.query<ListResponse<Contract>, number>({
      query: (pk) => ({
        url: `/contract/${pk}`,
      }),
    }),
    // Запросить доступ к предыдущим договорам
    getContractImport: build.query<ListResponse<string>, string>({
      query: (inn) => ({
        url: `/contract/import/${inn}`,
      }),
    }),
    // Поданные заявления в рамках услуги
    getListStatements: build.query<ListResponse<Statements[]>, number>({
      query: (pk) => ({
        url: `/contract/${pk}/statements`,
      }),
    }),
    // Прогресс выполнения услуги
    getListProgress: build.query<ListResponse<Progress[]>, number>({
      query: (pk) => ({
        url: `/contract/${pk}/progress`,
      }),
    }),
  }),
});

export const {
  useGetContractListQuery,
  useLazyGetContractImportQuery,
  useGetContractQuery,
  useGetListStatementsQuery,
  useGetListProgressQuery,
} = contractApi;
