import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { apiBaseUrl } from '@/util/api';
import { ApiFields, EstateType } from '@/types/types';
import { Fields, IsSelectedServiceTypeList, ServiceTypeList } from '@/types/order.type';

// Типы недвижимости
export const fetchServiceTypeList = createAsyncThunk(
  'orderSlice/fetchServiceTypeList',
  async ({ id, index = 0 }: { id: number | string; index?: number }, { dispatch }) => {
    const response = await fetch(`${apiBaseUrl}/service/service_type/?real_estate_type_id=${id}`);
    const data = await response.json();
    if (data.success) {
      dispatch(setServiceTypeList({ items: data.data.items, index }));
      dispatch(toggleIsDisabledServiceTypeList(false)); // открываем select с услугами
    } else {
      dispatch(setServiceTypeList({ items: [], index })); // обнуляем услуги
      dispatch(toggleIsDisabledServiceTypeList(true)); // закрываем select с услугами
    }
  },
);

// Типы объекта недвижимости
export const fetchEstateTypes = createAsyncThunk('orderSlice/fetchEstateTypes', async () => {
  const response = await fetch(`${apiBaseUrl}/service/real_estate_type/`);
  const data = await response.json();
  return data.data;
});

// Поля
export const fetchFields = createAsyncThunk(
  'orderSlice/fetchFields',
  async (
    { service_type_id, index }: { service_type_id: number | string; index: number },
    { dispatch },
  ) => {
    const response = await fetch(
      `${apiBaseUrl}/service/fields_type/?service_type_id=${service_type_id}`,
    );
    const data = await response.json();
    dispatch(setFields({ fields: data.data.fields, index }));
  },
);

const initialState = {
  estateTypes: [] as EstateType[], // Типы недвижимости
  serviceTypeList: { 0: [] } as ServiceTypeList, // Список типов услуг
  fields: { 0: [] } as Fields, // Поля
  isDisabledServiceTypeList: true,
  isSelectedServiceTypeList: { 0: false } as IsSelectedServiceTypeList,
  initialize: false,
  error: '',
};

type InitialStateType = typeof initialState;

const orderSlice = createSlice({
  name: 'orderSlice',
  initialState,
  reducers: {
    setServiceTypeList: (
      state: InitialStateType,
      action: PayloadAction<{ items: EstateType[]; index: number }>,
    ) => {
      state.serviceTypeList[action.payload.index] = action.payload.items;
    },
    setFields: (
      state: InitialStateType,
      action: PayloadAction<{ fields: ApiFields[]; index: number }>,
    ) => {
      state.fields[action.payload.index] = action.payload.fields;
    },
    resetFields: (state: InitialStateType) => {
      state.fields = {};
    },
    resetField: (state: InitialStateType, action: PayloadAction<Fields | []>) => {
      state.fields = { ...state.fields, ...action.payload };
    },
    toggleIsDisabledServiceTypeList: (state: InitialStateType, action: PayloadAction<boolean>) => {
      state.isDisabledServiceTypeList = action.payload;
    },
    setIsSelectedServiceTypeList: (
      state: InitialStateType,
      action: PayloadAction<{ index: number; selected: boolean }>,
    ) => {
      state.isSelectedServiceTypeList[action.payload.index] = action.payload.selected;
    },
    setError: (state: InitialStateType, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
  extraReducers: {
    [fetchEstateTypes.fulfilled as any]: (
      state: InitialStateType,
      action: PayloadAction<{ items: EstateType[] }>,
    ) => {
      state.estateTypes = action.payload.items;
    },
  },
});

export const {
  toggleIsDisabledServiceTypeList,
  setIsSelectedServiceTypeList,
  setError,
  setServiceTypeList,
  setFields,
  resetFields,
  resetField,
} = orderSlice.actions;
export default orderSlice.reducer;
