import React, { useState } from 'react';
import { ColumnsType } from 'antd/es/table';

import { t } from 'i18next';

import { Popover, Space } from 'antd';

import {
  OrderResultsFilesType,
  OrderFilesFoldersType,
  PropsOrderTableType,
} from '@/types/order.type';
import { Icon } from '@/components/ui';
import { useAppSelector } from '@/hooks/use-app-selector';
import { contractSelector } from '@/store/selectors/contract.selectors';
import { useSendOrderFilesMutation } from '@/store/api/files.api';
import { useNotification } from '@/hooks/use-notifications';

const SendFilesPopover = ({ files }: { files: OrderResultsFilesType[] }) => (
  <div>Отправить все файлы на почту ({files.length})</div>
);

export const useOrderTableHeaderData = (propsOrderTable: PropsOrderTableType) => {
  const { setOrderDirection, orderDirection } = propsOrderTable;
  const contract = useAppSelector(contractSelector);
  const [sendOrderFiles] = useSendOrderFilesMutation();
  const contractId = contract && contract?.id;
  const { createNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [mailIndex, setMailIndex] = useState<number>();

  const handleSendOrderFiles = async (folder_name: string, index: number) => {
    setIsLoading(true);
    setMailIndex(index);
    const status: any = await sendOrderFiles({
      contract_id: contractId,
      folder_name: folder_name,
    });

    if (status?.data?.success) {
      setIsLoading(false);
      createNotification?.({
        variant: 'success',
        title: status?.data?.data,
      });
    } else {
      setIsLoading(false);
      createNotification?.({
        variant: 'fail',
        title: 'Ошибка отправки данных на почту',
      });
    }
  };

  const handleSortName = () => {
    if (orderDirection === undefined || orderDirection === 'date' || orderDirection === '-date') {
      setOrderDirection('name');
    }

    if (orderDirection === 'name') {
      setOrderDirection('-name');
    }

    if (orderDirection === '-name') {
      setOrderDirection(undefined);
    }
  };

  const handleSortDate = () => {
    if (orderDirection === undefined || orderDirection === 'name' || orderDirection === '-name') {
      setOrderDirection('date');
    }

    if (orderDirection === 'date') {
      setOrderDirection('-date');
    }

    if (orderDirection === '-date') {
      setOrderDirection(undefined);
    }
  };

  const OrderTableHeaderData: ColumnsType<OrderFilesFoldersType> = [
    {
      title: () => (
        <>
          <button onClick={handleSortName} className="flex items-center cursor-pointer">
            <>
              {t('view_order.table.name')}
              <Icon
                name="ArrowNarrowDown"
                className={`${
                  (orderDirection === 'name' || orderDirection === '-name') && 'text-primary_400'
                } ${orderDirection === '-name' && 'rotate-180'}`}
              />
            </>
          </button>
        </>
      ),
      dataIndex: 'folder_name',
      key: 'folder_name',
      width: 294,
      render: (name) => <Space className="text-[16px] text-gray_600 h-full">{name}</Space>,
    },
    {
      title: `${t('view_order.table.file')}`,
      dataIndex: 'files',
      key: 'files',
      width: 391,
      render: (files) => {
        return (
          <ul className="flex flex-col gap-[16px]">
            {files.map((file: OrderResultsFilesType, i: number) => {
              return (
                <li key={i} className="flex gap-[8px]">
                  <Icon name="Document" color="primary_400" />
                  <a
                    href={file.link}
                    className="text-primary_400 hover:text-primary_500 hover:underline font-bold text-[16px]"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {file.filename}
                  </a>
                </li>
              );
            })}
          </ul>
        );
      },
    },
    {
      title: () => (
        <>
          <button onClick={handleSortDate} className="flex items-center cursor-pointer">
            <>
              {t('view_order.table.date')}
              <Icon
                name="ArrowNarrowDown"
                className={`${
                  (orderDirection === 'date' || orderDirection === '-date') && 'text-primary_400'
                } ${orderDirection === '-date' && 'rotate-180'}`}
              />
            </>
          </button>
        </>
      ),
      dataIndex: 'date',
      key: 'date',
      render: (date) => <Space className="text-[16px] text-gray_600 h-full">{date}</Space>,
    },
    {
      key: 'send',
      dataIndex: 'send',
      render: (_, record, index) => {
        if (record.files.length > 1) {
          return (
            <Popover
              placement="rightBottom"
              arrow={false}
              content={<SendFilesPopover files={record.files} />}
            >
              <button
                disabled={isLoading}
                onClick={() => handleSendOrderFiles(record.folder_name, index)}
              >
                {isLoading && index === mailIndex ? (
                  <Icon name="Load" color="primary_400" size={19} />
                ) : (
                  <Icon name="Mail" color="primary_400" />
                )}
              </button>
            </Popover>
          );
        }
      },
    },
  ];

  return OrderTableHeaderData;
};
