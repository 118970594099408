import React from 'react';

const Progress = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.833 5.834H17.5m0 0v6.667m0-6.667l-6.667 6.667L7.5 9.167l-5 5"
      ></path>
    </svg>
  );
};

export default Progress;
