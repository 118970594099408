import React from 'react';

const Video = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.555 9.03534L14.752 11.1673C14.889 11.2587 15.0013 11.3834 15.0789 11.5285C15.1566 11.6737 15.1972 11.8357 15.1972 12.0003C15.1972 12.165 15.1566 12.327 15.0789 12.4722C15.0013 12.6173 14.889 12.741 14.752 12.8323L11.555 14.9643C11.4044 15.0648 11.2293 15.1225 11.0485 15.1313C10.8677 15.1401 10.6879 15.0996 10.5282 15.0142C10.3686 14.9288 10.2352 14.8017 10.1421 14.6463C10.0491 14.491 9.99998 14.3134 10 14.1323V9.86934C9.99961 9.68813 10.0485 9.51021 10.1414 9.35462C10.2342 9.19902 10.3677 9.07158 10.5274 8.98594C10.687 8.90029 10.867 8.85964 11.048 8.86834C11.229 8.87704 11.4043 8.93477 11.555 9.03534Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Video;
