import React from 'react';

import { useAppSelector } from '@/hooks/use-app-selector';
import RegistrationFormBlock from '@/features/registration/blocks/registration-form.block';
import RegistrationSuccessBlock from '@/features/registration/blocks/registration-success.block';

const RegistrationPage = () => {
  const { isResendEmail } = useAppSelector((state) => state.commonStore);
  const renderCorrectForm = isResendEmail ? (
    <RegistrationSuccessBlock />
  ) : (
    <RegistrationFormBlock />
  );
  return (
    <div className="w-full h-[calc(100vh-90px)] flex items-center justify-center">
      {renderCorrectForm}
    </div>
  );
};

export default RegistrationPage;
