import clsx from 'clsx';

export const notificationClassName = clsx(
  'absolute',
  'top-[-1px]',
  'left-[50%]',
  'p-[2px]',
  'w-min',
  'min-w-[12px]',
  'h-[12px]',
  'bg-yellow_500',
  'text-white',
  'rounded-full',
  'text-paragraph_s',
  'font-regular',
  'flex',
  'items-center',
  'justify-center',
);

export const userProfileName = clsx(
  'text-paragraph_m',
  'hover:text-primary_400',
  'cursor-pointer',
  'hidden',
  'sm:block',
  'font-medium',
  'mr-[12px]',
  'ml-[16px]',
  'h-full',
  'mt-[5px]',
  'font-medium',
  'max-w-[130px]',
  'truncate',
);
