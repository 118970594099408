import React, { FC } from 'react';

type TitleLineProps = {
  title: string;
};
const TitleLine: FC<TitleLineProps> = (props) => {
  const { title } = props;
  return (
    <div className="w-full relative h-[30px] items-center justify-center inline-flex">
      <div className="h-[1px] bg-light w-full" />
      <div className="absolute bg-white px-[15px] text-paragraph_s text-gray_600">{title}</div>
    </div>
  );
};

export default TitleLine;
