import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { translateRequisitesPath } from '../constants';
import BankDetailsFormBlock from '../bank-details-form/bank-details-form.block';

import RequisitesAndContractWrapperBlock from './requisites-and-contract-wrapper.block';
import { BankDetailsFormProps } from './types';

import { affiliateProgramQuery, route, tabQuery } from '@/util/route';

const NoContractBlock: FC<BankDetailsFormProps> = ({ form, submit }) => {
  const { t } = useTranslation();

  return (
    <RequisitesAndContractWrapperBlock
      desctiption={
        <Trans
          defaults={t(translateRequisitesPath + '.no-contract.paragraph')}
          values={{
            'link-to-how-to-earn-with-1kk': t(
              translateRequisitesPath + '.no-contract.link-to-how-to-earn-with-1kk',
            ),
          }}
          components={[
            <b key={'link-to-how-to-earn-with-1kk'} />,
            <Link
              to={`${route.affiliateProgram}${tabQuery}${affiliateProgramQuery.howToEarnWith1kk}`}
              key="link-to-how-to-earn-with-1kk"
              className="text-primary_400"
            />,
          ]}
        />
      }
    >
      <BankDetailsFormBlock form={form} submit={submit} />
    </RequisitesAndContractWrapperBlock>
  );
};

export default NoContractBlock;
