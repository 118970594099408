import React from 'react';

const EmailBig = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 81 80">
      <g clipPath="url(#clip0_484_4997)">
        <path
          fill="currentColor"
          d="M78.875 34.444c-2.55-5.207-4.932-10.494-7.575-15.657a124.724 124.724 0 00-4.25-7.706c-1.144-1.9-2.5-4.775-4.744-5.568-1.256-.45-2.619-.575-3.944-.5-2.637.137-5.156 1.012-7.687 1.68-4.294 1.138-8.557 2.388-12.813 3.67-4.375 1.312-8.837 2.618-13.144 4.118-5.05 1.763-10.262 3.075-15.25 4.981-2.218.85-4.737 1.788-6.675 3.194-1.075.782-1.756 2.306-2.056 3.563-.244 1.062-.006 2.043.1 3.1.206 1.95.981 3.569 1.681 5.381 2.488 6.444 5.613 12.656 8.32 19.012 1.412 3.3 2.843 6.607 4.524 9.782.731 1.381 1.575 2.65 2.313 4.037.893 1.688 1.843 3.031 2.98 4.575 1.007 1.369 2.27 2.657 4.02 2.882 1.718.218 3.293-.47 4.83-1.132 4.182-1.787 7.863-4.325 11.663-6.775 3.025-1.95 6.138-3.75 9.244-5.575a272.716 272.716 0 0010.638-6.55c6.05-3.931 12.662-7.331 17.987-12.275 2.231-2.075 1.288-5.262-.162-8.237z"
        ></path>
        <path
          fill="#fff"
          d="M70.963 42.93c-1.988 1.526-4.063 2.92-6.194 4.245-8.206 5.08-16.437 9.95-25.075 14.275-3.731 1.868-8.119 4.768-12.394 5.037-3.856.238-5.837-2.637-7.45-5.756a196.592 196.592 0 01-6.956-15.006c-1.325-3.213-2.381-6.532-3.469-9.825-.287-.875-.68-1.844-1.03-2.832-.045-.412-.332-.925-.182-1.175.219-.356.962-.25 1.281-.218 1.038.118 2 .593 2.994.88 2.2.645 4.412 1.232 6.619 1.845 3.587.987 7.212 1.9 10.78 2.95 1.107.325 2.2.712 3.345.893.425.07.85.138 1.275.188.33.044.8.031 1.018-.269.257-.35.007-1.012-.087-1.369-.281-1.025-1.275-1.43-2.175-1.787-1.106-.431-2.181-.894-3.3-1.294-3.738-1.331-7.556-2.35-11.35-3.531-2.344-.731-4.744-1.431-7.025-2.331-.663-.257-1.963-.488-2.075-1.344-.056-.4.15-.675.45-.913.025-.025.094-.075.169-.13 2.443-1.445 5.093-2.382 7.75-3.37 6.325-2.343 12.875-4.068 19.28-6.187 2.982-.981 5.913-2.125 8.932-2.95 3.088-.85 6.213-1.556 9.288-2.45a14.864 14.864 0 011.925-.406c.087.018.175.03.25.062.543.256.606.994.6 1.513-.013.743-.025 1.493-.075 2.243-.107 1.6-.257 3.2-.32 4.8-.03.85-.13 1.688-.1 2.532.032.887-.143 1.762.082 2.637.188.731.95 1.644 1.806 1.513.994-.15 1.15-3.8 1.232-4.494.25-1.919.512-3.838.712-5.763.081-.775.156-1.55.213-2.325.025-.312-.044-1.075.393-1.143.157-.025.313.087.457.225.693.875 1.318 1.85 1.906 2.793 2.075 3.325 4.069 6.75 5.475 10.407 1.469 3.818 3.525 7.33 4.675 11.287.856 2.956-1.556 4.938-3.65 6.544z"
        ></path>
        <circle cx="48.5" cy="34" r="9" fill="currentColor"></circle>
      </g>
      <defs>
        <clipPath id="clip0_484_4997">
          <path fill="#fff" d="M0 0H80V80H0z" transform="translate(.5)"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmailBig;
