import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { skipToken } from '@reduxjs/toolkit/dist/query';

import { Button, Icon } from '@/components/ui';
import { useUpdateOfferAcceptanceMutation } from '@/store/api/offer.api';
import { Acceptance } from '@/types/offer.type';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { fetchOffer, fetchOfferList } from '@/store/slices/offer.slices';
import { useGetOfferFileQuery } from '@/store/api/files.api';

type OrderInfoProps = {
  id?: number | string | undefined;
  offer_id: number | string | null | undefined;
};

const OrderInfo: FC<OrderInfoProps> = (props) => {
  const { offer_id, id } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data: offerFile } = useGetOfferFileQuery(id ? id : skipToken);

  const [updateOfferAcceptance] = useUpdateOfferAcceptanceMutation();

  const updateRejectedOffer = async () => {
    const res: any = await updateOfferAcceptance({
      pk: offer_id,
      data: { acceptance: Acceptance.rejected },
    });
    if (res.data.success) {
      if (offer_id !== undefined && offer_id !== null) {
        dispatch(fetchOffer(offer_id)); // Согласование КП
      }
      dispatch(fetchOfferList());
    }
  };

  const updateAcceptedOffer = async () => {
    const res: any = await updateOfferAcceptance({
      pk: offer_id,
      data: { acceptance: Acceptance.accepted },
    });
    if (res.data.success) {
      if (offer_id !== undefined && offer_id !== null) {
        dispatch(fetchOffer(offer_id)); // Согласование КП
      }
      dispatch(fetchOfferList());
    }
  };

  return (
    <div className="border w-full border-positive_500 rounded bg-positive_50 inline-flex items-center py-[10px] px-[9px] justify-between">
      <div className="inline-flex items-center text-primary_400">
        <div className="mr-[8px] text-positive_500">
          <Icon name="Document" size={20} />
        </div>
        <a
          key={offerFile?.data?.filename}
          href={offerFile?.data?.link}
          className="hover:underline"
          target="_blank"
          rel="noreferrer"
        >
          {offerFile?.data?.filename}
        </a>
      </div>
      <div className="inline-flex">
        <div>
          <Button
            label={t('order_card.btn.cancel')}
            space={3}
            variant="outlined"
            size="small"
            onClick={updateRejectedOffer}
          />
        </div>
        <div className="ml-[10px]">
          <Button
            label={t('order_card.btn.ok')}
            variant="filled"
            size="small"
            space={14}
            customStyles="bg-positive_500 hover:opacity-90"
            onClick={updateAcceptedOffer}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(OrderInfo);
