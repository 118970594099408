import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  AnyAsyncThunk,
  RejectedWithValueActionFromAsyncThunk,
} from '@reduxjs/toolkit/dist/matchers';

import { apiBaseUrl } from '@/util/api';
import { Proposal } from '@/types/proposal.type';
import { OrderFilters } from '@/types/order.type';

// Заявка по id
export const fetchProposal = createAsyncThunk(
  'proposalSlice/fetchProposal',
  async (pk: string | number) => {
    const response = await fetch(`${apiBaseUrl}/proposal/${pk}`);
    return await response.json();
  },
);
// Все заявки
export const fetchProposals = createAsyncThunk(
  'proposalSlice/fetchProposals',
  async (
    params: OrderFilters,
    {
      rejectWithValue,
    }: {
      rejectWithValue: RejectedWithValueActionFromAsyncThunk<AnyAsyncThunk>;
    },
  ) => {
    let url = `${apiBaseUrl}/proposal/`;
    if (params) {
      url += '?';
      Object.entries(params).forEach(([key, value], index) => {
        if (value) {
          url += `${key}=${value}${index !== Object.entries(params).length - 1 ? '&' : ''}`;
        }
      });
    }
    const response = await fetch(url);
    const data = await response.json();
    if (data.success) {
      return data;
    } else {
      rejectWithValue(data.error);
    }
  },
);

const initialState = {
  proposal: {} as Proposal | null,
  proposals: [] as Proposal[],
  totalProposalsCount: 0,
  totalProposalsPrice: 0,
  page: 1,
  isLoading: false,
};

type InitialStateType = typeof initialState;

const proposalSlice = createSlice({
  name: 'proposalSlice',
  initialState,
  reducers: {
    setProposal: (state: InitialStateType) => {
      state.proposal = null;
    },
    resetProposals: (state: InitialStateType) => {
      state.proposals = [];
    },
  },
  extraReducers: {
    [fetchProposal.fulfilled as any]: (state, action) => {
      state.proposal = action.payload.data;
    },
    [fetchProposals.pending as any]: (state) => {
      state.isLoading = true;
    },
    [fetchProposals.fulfilled as any]: (state, action) => {
      state.isLoading = false;
      state.proposals = action.payload?.data.items;
      state.totalProposalsCount = action.payload?.data.total;
      state.totalProposalsPrice = action.payload?.data.total_price;
      state.page = action.payload?.data.page;
    },
    [fetchProposals.rejected as any]: (state) => {
      state.isLoading = false;
      state.proposals = [];
    },
  },
});

export const { setProposal, resetProposals } = proposalSlice.actions;
export default proposalSlice.reducer;
