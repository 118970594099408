import clsx from 'clsx';

export const tableColumnClassName = clsx(
  'grid',
  'text-gray_400',
  'text-paragraph_m',
  'font-medium',
  'border-b-[1px]',
  'border-b-gray_200',
);
