import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiBaseUrl } from '@/util/api';
import { Contract, Progress, Statements } from '@/types/contract.type';

// Все договора
export const fetchContractList = createAsyncThunk('contractSlice/fetchContractList', async () => {
  const response = await fetch(`${apiBaseUrl}/contract/`);
  const data = await response.json();
  if (data?.success) {
    return data.data.items;
  }
});

// Договор
export const fetchContract = createAsyncThunk(
  'contractSlice/fetchContract',
  async (pk: string | number) => {
    const response = await fetch(`${apiBaseUrl}/contract/${pk}`);
    const data = await response.json();

    return data;
  },
);
// Прогресс выполнения
export const fetchListProgress = createAsyncThunk(
  'contractSlice/fetchListProgress',
  async (pk: string | number) => {
    const response = await fetch(`${apiBaseUrl}/contract/${pk}/progress`);
    const data = await response.json();

    return data;
  },
);
// Поданные заявления в рамках
export const fetchListStatements = createAsyncThunk(
  'contractSlice/fetchListStatements',
  async (pk: string | number) => {
    const response = await fetch(`${apiBaseUrl}/contract/${pk}/statements`);
    const data = await response.json();
    return data;
  },
);
// Get Contract By Offer
export const fetchContractByOfferId = createAsyncThunk(
  'contractSlice/fetchContractByOfferId',
  async (offer_id: string | number) => {
    const response = await fetch(`${apiBaseUrl}/contract/${offer_id}/get_by_offer`);
    const data = await response.json();

    return data;
  },
);

const initialState = {
  contract_id: null as string | number | null, // ID Договора
  contract: null as null | Contract, // Договор
  contractList: [] as Contract[], // Все договора
  listStatements: null as null | Statements[], // Поданные заявления в рамках
  listProgress: null as null | Progress[], // Прогресс выполнения
};

type InitialStateType = typeof initialState;

const contractSlice = createSlice({
  name: 'contractSlice',
  initialState,
  reducers: {
    resetContractStore: (state: InitialStateType) => {
      state.listStatements = null;
      state.listProgress = null;
      state.contract_id = null;
      state.contract = null;
    },
  },
  extraReducers: {
    [fetchListProgress.fulfilled as any]: (state, action) => {
      state.listProgress = action.payload?.data?.items;
    },
    [fetchListStatements.fulfilled as any]: (state, action) => {
      state.listStatements = action.payload?.data;
    },
    [fetchContractByOfferId.fulfilled as any]: (state, action) => {
      state.contract = action.payload?.data || null;
      state.contract_id = action.payload?.data?.id;
    },
    [fetchContractList.fulfilled as any]: (state, action) => {
      state.contractList = action.payload;
    },
  },
});

export const { resetContractStore } = contractSlice.actions;
export default contractSlice.reducer;
