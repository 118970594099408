import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { idRefSelector } from '@/store/selectors/auth.selectors';
import { useAppSelector } from '@/hooks/use-app-selector';
import { route } from '@/util/route';
import { Box, Button, Input } from '@/components/ui';
import TitleLine from '@/components/ui/title-line';
import { useValidation } from '@/hooks/use-validation';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { changeResendEmail } from '@/store/slices/common.slice';
import { CompanyPromo } from '@/components';
import { useRegisterMutation } from '@/store/api/auth.api';
import { setIdRef, setRegisterData } from '@/store/slices/auth.slice';
import { LoginFields } from '@/types/auth.type';

const RegistrationFormBlock = () => {
  const { t } = useTranslation();
  const validation = useValidation();
  const dispatch = useAppDispatch();

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<LoginFields>();

  const [error, setError] = useState<string>('');

  const [registration] = useRegisterMutation();

  const idRef = useAppSelector(idRefSelector);

  async function handleFormSubmit(data: LoginFields) {
    data = { ...data, email: data.email.replace(/\s/gm, ''), referrer_id: idRef };
    const returned: any = await registration(data);
    if (returned?.data?.success) {
      setError('');
      dispatch(setIdRef(null));
      dispatch(setRegisterData(data));
      dispatch(
        changeResendEmail({
          email: data.email,
          pause: Date.now() + 60 * 60 * 2,
          isResendEmail: true,
        }),
      );
    } else if (returned?.error?.data?.error) {
      setError(returned?.error?.data?.error);
    }
  }
  return (
    <Box>
      <div className="inline-flex">
        <CompanyPromo />
        <div className="border-l-[1px] border-l-light w-[1px] my-[25px]" />
        <div className="w-[383px] p-[24px] pt-[20px]">
          <div>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <h1 className="text-gray-600 text-center text-heading_h2 mt-[2px] font-bold">
                {t('registration.title')}
              </h1>
              <div className="mt-[16px]">
                <Input
                  type="text"
                  {...register('surname', validation.surname)}
                  label={t('common.input.surname.label') + '*'}
                  error={errors.surname?.message}
                  placeholder={t('common.input.surname.placeholder')}
                />
              </div>
              <div className="mt-[12px]">
                <Input
                  type="text"
                  {...register('name', validation.name)}
                  error={errors.name?.message}
                  label={t('common.input.name.label') + '*'}
                  placeholder={t('common.input.name.placeholder')}
                />
              </div>
              <div className="mt-[12px]">
                <Input
                  type="text"
                  {...register('middle_name', validation.middle_name)}
                  error={errors.middle_name?.message}
                  label={t('common.input.middle_name.label') + '*'}
                  placeholder={t('common.input.middle_name.placeholder')}
                />
              </div>
              <div className="mt-[12px]">
                <Input
                  type="phone"
                  {...register('phone1', validation.phone1)}
                  error={errors.phone1?.message}
                  onChange={(event) => setValue('phone1', event.target.value.replace(/[_]/gm, ''))}
                  autoComplete="off"
                  mask={'+7 (999) 999-99-99'}
                  label={t('common.input.phone.label') + '*'}
                  placeholder={t('common.input.phone.placeholder')}
                />
              </div>
              <div className="mt-[12px] mb-[24px]">
                <Input
                  type="email"
                  {...register('email', validation.email)}
                  error={errors.email?.message}
                  label={t('common.input.email.label') + '*'}
                  placeholder={t('common.input.email.placeholder')}
                />
              </div>
              <div className="m-[5px]" style={{ color: 'red', fontSize: '14px' }}>
                {error && <span>{error}</span>}
              </div>
              <div>
                <Button type="submit" label={t('registration.btn.register')} isFull />
              </div>
              <div className="my-[6px]">
                <TitleLine title={t('registration.have_account')} />
              </div>
              <div>
                <Link to={route.login}>
                  <Button label={t('registration.btn.login')} variant="outlined" isFull />
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default RegistrationFormBlock;
