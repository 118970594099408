import React, { FC, ReactNode } from 'react';

const Container: FC<{ children: ReactNode; padding?: number }> = (props) => {
  const { children, padding = 0 } = props;
  return (
    <div className="w-full inline-flex justify-center" style={{ paddingBottom: `${padding}px` }}>
      <div className="w-full px-[10px] lg:px-0 max-w-[1170px]">{children}</div>
    </div>
  );
};

export default Container;
