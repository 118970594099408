import React from 'react';

const Link = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8278 8.17363C12.0777 7.42374 11.0605 7.00248 9.99984 7.00248C8.93918 7.00248 7.92195 7.42374 7.17184 8.17363L3.17184 12.1736C2.7898 12.5426 2.48507 12.984 2.27543 13.472C2.06579 13.96 1.95545 14.4849 1.95083 15.016C1.94622 15.5471 2.04743 16.0739 2.24855 16.5654C2.44967 17.057 2.74669 17.5036 3.12226 17.8792C3.49783 18.2548 3.94443 18.5518 4.43602 18.7529C4.92761 18.954 5.45432 19.0552 5.98544 19.0506C6.51656 19.046 7.04144 18.9357 7.52945 18.726C8.01747 18.5164 8.45885 18.2117 8.82784 17.8296L9.92984 16.7286M9.17184 11.8296C9.92195 12.5795 10.9392 13.0008 11.9998 13.0008C13.0605 13.0008 14.0777 12.5795 14.8278 11.8296L18.8278 7.82963C19.5565 7.07522 19.9596 6.06481 19.9505 5.01602C19.9414 3.96724 19.5207 2.96399 18.7791 2.22235C18.0375 1.48072 17.0342 1.06005 15.9854 1.05093C14.9367 1.04182 13.9262 1.445 13.1718 2.17363L12.0718 3.27363"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Link;
