import clsx from 'clsx';

import { ButtonVariant, SizeButton, StyleVariant } from '@/components/ui/button/button.types';

const buttonSizes: Record<ButtonVariant, Record<SizeButton, string>> = {
  filled: { small: 'py-[2px]', medium: 'py-[8px]', large: 'py-[15px]' },
  outlined: { small: 'py-[1px]', medium: 'py-[7px]', large: 'py-[14px]' },
};

export const buttonsVariant: Record<ButtonVariant, Record<StyleVariant, string>> = {
  filled: {
    bg: 'bg-primary_400 hover:bg-primary_500 disabled:bg-gray_100',
    txt: 'text-white disabled:disabled:text-gray_300',
    icon: 'mr-[4px] ml-[-6px]',
  },
  outlined: {
    bg: 'bg-white border-[1px] border-gray_300 hover:bg-primary_50 disabled:bg-gray_300',
    txt: 'text-gray_500 disabled:gray_300',
    icon: 'mr-[8px] ml-[-3px]',
  },
};

export const buttonClassName = ({
  customStyles,
  size,
  variant,
  isFull,
}: {
  customStyles: string | undefined;
  size: SizeButton;
  variant: ButtonVariant;
  isFull: boolean;
}) =>
  clsx(
    customStyles || buttonsVariant[variant].bg,
    customStyles || buttonsVariant[variant].txt,
    buttonSizes[variant][size],
    size === 'small' ? 'text-paragraph_m' : 'text-paragraph_l',
    'font-medium',
    'transition',
    'no-underline',
    'tracking-wide',
    'rounded',
    'inline-flex',
    'items-center',
    isFull && 'w-full justify-center',
  );
