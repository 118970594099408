import React, { FC, useEffect, useState } from 'react';

import {
  numberOfStepClassName,
  stepperItemClassName,
} from '@/components/ui/stepper/stepper.styles';
import { StepperProps } from '@/components/ui/stepper/stepper.types';
import { Icon } from '@/components/ui';

const Stepper: FC<StepperProps> = (props) => {
  const {
    data,
    // onActiveItem,
    step,
  } = props;
  const [currentStep, setCurrentStep] = useState<number>(0);

  const arrowSVG = (
    <svg width="12" height="46" viewBox="0 0 12 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.28564 45L11.2856 23L1.28564 1" stroke="currentColor" />
    </svg>
  );

  useEffect(() => {
    step && setCurrentStep(step);
  }, [step]);

  const renderSteps =
    data &&
    data.map((step, index) => {
      return (
        <li
          role="list"
          key={step.tag}
          className={stepperItemClassName({
            active: index === currentStep,
            success: index < currentStep,
          })}
        >
          <div className="inline-flex items-center">
            {index < currentStep ? (
              <div className="mr-[8px]">
                <Icon name="Check" size={20} className="text-primary_400" />
              </div>
            ) : (
              <div className={numberOfStepClassName({ active: index === currentStep })}>
                {++index}
              </div>
            )}
            {index < currentStep ? step.complete_title || step.title : step.title}
          </div>
          {index !== data.length && <span className="text-gray_200">{arrowSVG}</span>}
        </li>
      );
    });

  // function handleSetActiveItem(activeItem: number) {
  //   setCurrentStep(activeItem);
  //   onActiveItem?.(data[activeItem]);
  // }

  return (
    <ul className="inline-flex items-center w-full justify-between bg-white border border-gray_200">
      {renderSteps}
    </ul>
  );
};

export default Stepper;
