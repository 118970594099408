import clsx from 'clsx';

export const checkTheSameMessagesClassName = (is_user: boolean, isOnlyMessage: boolean) => {
  const commonClasses = ['flex', 'flex-col', 'max-w-[318px]', 'p-2', 'rounded-t-lg'];
  if (is_user && isOnlyMessage) {
    return clsx('bg-primary_100  rounded-bl-lg', ...commonClasses);
  } else if (!is_user && isOnlyMessage) {
    return clsx('bg-primary_400 rounded-br-lg', commonClasses);
  } else if (is_user && !isOnlyMessage) {
    return clsx('bg-primary_100 rounded-b-lg', ...commonClasses);
  } else if (!is_user && !isOnlyMessage) {
    return clsx('bg-primary_400 rounded-b-lg', ...commonClasses);
  }
};
