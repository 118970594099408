import React from 'react';

const TelegramCircleBlue = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="url(#paint0_linear_1855_8621)"
        stroke="#fff"
        d="M23.5 12c0 6.351-5.149 11.5-11.5 11.5S.5 18.351.5 12 5.649.5 12 .5 23.5 5.649 23.5 12z"
      ></path>
      <path
        fill="#fff"
        d="M5.491 11.738l11.57-4.46c.537-.195 1.006.13.832.942h.001l-1.97 9.28c-.146.658-.537.818-1.084.508l-3-2.21-1.447 1.393c-.16.16-.295.295-.605.295l.213-3.053 5.56-5.023c.242-.213-.054-.333-.373-.12l-6.871 4.325-2.962-.924c-.643-.204-.657-.643.136-.953z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_1855_8621"
          x1="19"
          x2="7"
          y1="2.5"
          y2="24"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#65BAF3"></stop>
          <stop offset="1" stopColor="#3F8FCC"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TelegramCircleBlue;
