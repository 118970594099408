import React, { ChangeEvent, FC } from 'react';

import { Documents, FileButton } from '@/components/ui';
import { DocumentBase64 } from '@/components/ui/documents/documents.types';
import { readAsDataURL } from '@/util/helpers';

type DocListProps = {
  label: string;
  onRemove?: (index: number) => void;
  onAddNew?: (document: Array<DocumentBase64>) => void;
  documents?: Array<DocumentBase64>;
  index: number;
};

const DocList: FC<DocListProps> = (props) => {
  const { label, onAddNew, documents, onRemove } = props;

  async function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
    // 'jpg' | 'png' | 'pdf' | 'doc' | 'docx' | 'xls' | 'xlsx';
    if (event.target.files) {
      const files = [...event.target.files];
      const currentFileList = await Promise.all(
        files.map((f) => {
          return readAsDataURL(f); // файл в base64
        }),
      );
      await onAddNew?.([...(documents || []), ...currentFileList] as DocumentBase64[]);
    }
  }

  const renderDocuments = documents
    ? documents?.map((document, index) => (
        <div key={index}>
          <Documents document={document} state="success" onRemove={onRemove?.bind(null, index)} />
        </div>
      ))
    : [];

  return (
    <div>
      <div className="block mb-1 text-paragraph_m font-bold truncate text-gray_600">{label}</div>
      <div className="mb-[13px] mt-[4px]">
        <FileButton label="Загрузить с ПК" onChange={handleOnChange} size="small" space={10} />
      </div>
      {!documents?.length && <div className="text-paragraph_s text-gray_100">Добавьте файл</div>}
      <div className="grid grid-cols-2">{renderDocuments}</div>
    </div>
  );
};

export default DocList;
