import React from 'react';

const Database = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5V15C1 17.21 4.582 19 9 19C13.418 19 17 17.21 17 15V5M1 5C1 7.21 4.582 9 9 9C13.418 9 17 7.21 17 5M1 5C1 2.79 4.582 1 9 1C13.418 1 17 2.79 17 5M17 10C17 12.21 13.418 14 9 14C4.582 14 1 12.21 1 10"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Database;
