import React, { FC } from 'react';
import clsx from 'clsx';

type SkeletonProps = {
  size?: number;
  height?: number;
};

const Skeleton: FC<SkeletonProps> = (props) => {
  const { size = 61, height = 18 } = props;
  return (
    <div
      className={clsx('bg-gray_100', 'animate-pulse', 'rounded-lg', 'w-full')}
      style={{ maxWidth: `${size}px`, height: `${height}px` }}
    />
  );
};

export default Skeleton;
