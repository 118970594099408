import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Table as TableAnt } from 'antd';

import moment from 'moment';
import { Tooltip } from 'antd';
import { v1 } from 'uuid';

import { textPkDescriptionClassName, textPkTitleClassName } from './kp.styles';
import ServicesTable from './services-table';
import ServicesOrderDetail from './services-order-detail';

import { SortOrderType, StageOrder } from '@/types/order.type';
import { Button, Heading, Icon, Input, Table } from '@/components/ui';
import OrderInfoKP from '@/components/ui/order-card/order-info-kp';
import { useTablesHeader } from '@/features/feature-hooks/use-tables-header';
import { useAppSelector } from '@/hooks/use-app-selector';
import {
  contractSelector,
  listProgressSelector,
  listStatementsSelector,
} from '@/store/selectors/contract.selectors';
import { resultFilesSelector } from '@/store/selectors/files.selectors';
import { offerSelector } from '@/store/selectors/offer.selectors';
import { Progress, Statements } from '@/types/contract.type';
import {
  useGetOrderFilesQuery,
  useSendOrderFilesMutation,
  useSendStatementsFileMutation,
} from '@/store/api/files.api';
import { useNotification } from '@/hooks/use-notifications';
import ArchieveResult from '@/components/archive-result';

import { userSelector } from '@/store/selectors/auth.selectors';
import Datepicker from '@/components/ui/input/datepicker';
import 'moment/locale/ru';
import Pagination from '@/components/ui/pagination/pagination';
import { useOrderTableHeaderData } from '@/features/orders/data/order-table-header-data';

type PropsType = {
  stage: StageOrder;
};

const ServiceProcessBlock: React.FC<PropsType> = ({ stage }) => {
  const { t } = useTranslation();
  const tableHead = useTablesHeader();
  const [sendOrderFiles] = useSendOrderFilesMutation();
  const [sendStatementsFile] = useSendStatementsFileMutation();
  const { createNotification } = useNotification();
  const user = useAppSelector(userSelector);
  const contract = useAppSelector(contractSelector); // Договор
  const listProgress: Progress[] | null = useAppSelector(listProgressSelector); // Прогресс выполнения
  const listStatements: Statements[] | null = useAppSelector(listStatementsSelector); // Поданные заявления в рамках услуги
  const resultFiles = useAppSelector(resultFilesSelector); // Результаты работ
  const offer = useAppSelector(offerSelector); // Согласование КП
  const [archiveButtonLoading, setArchieveButtonLoading] = useState<boolean>(false);
  const [sendStatementsButtonLoading, setSendStatementsButtonLoading] = useState<number | null>(
    null,
  );
  const [openArchieveResult, toggleArchieveResult] = useState<boolean>(false);
  const [inputSearchValue, setInputSearchValue] = useState<string>();
  const [inputSearchDate, setInputSearchDate] = useState<string>();
  const [currentPage, setCurrentPage] = useState(1);
  const [orderDirection, setOrderDirection] = useState<SortOrderType>(undefined);
  const contractId = contract && contract?.id;
  const {
    data: orderFilesData,
    isError: isErrorGetOrderFiles,
    isFetching: isOrderFilesDataLoading,
  } = useGetOrderFilesQuery(
    {
      contract_id: contractId,
      search_folder: inputSearchValue,
      date: inputSearchDate,
      page: currentPage,
      ordering: orderDirection,
    },
    {
      skip: contractId === null,
    },
  );
  const itemsPerPage = orderFilesData ? orderFilesData.data.limit : 0;
  const totalResults = orderFilesData ? orderFilesData.data.total : 0;
  const OrderTableHeaderData = useOrderTableHeaderData({
    setOrderDirection,
    orderDirection,
  });

  useEffect(() => {
    if (orderFilesData?.data.total === 0) {
      setOrderDirection(undefined);
    }
  }, [orderFilesData]);

  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalResults);
  const lastPage =
    orderFilesData && orderFilesData.data.total !== 0
      ? Math.ceil(orderFilesData.data.total / 10)
      : 1;

  const handleSendOrderFiles = async () => {
    setArchieveButtonLoading(true);
    const status: any = await sendOrderFiles({
      contract_id: contractId,
      search_folder: inputSearchValue,
      search_date: inputSearchDate,
    });

    if (status?.data?.success) {
      setArchieveButtonLoading(false);
      createNotification?.({
        variant: 'success',
        title: status?.data?.data,
      });
    } else {
      setArchieveButtonLoading(false);
      createNotification?.({
        variant: 'fail',
        title: 'Ошибка отправки данных на почту',
      });
    }
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearchValue(event.target.value);
    setCurrentPage(1);
  };
  const handleDatePickerChange = (event: Date) => {
    if (!isNaN(event.getTime())) {
      setInputSearchDate(moment(event).format('YYYY-MM-DD'));
      setCurrentPage(1);
    }
  };

  const handleClearFilters = () => {
    setInputSearchValue('');
    setInputSearchDate(undefined);
    setOrderDirection(undefined);
  };

  const handleClearInputSearchValue = () => {
    setInputSearchValue('');
  };
  const getArchieveStatementsSubmit = async (contractId: number, statementId: number) => {
    setSendStatementsButtonLoading(statementId);
    const result = await sendStatementsFile({
      contract_id: contractId,
      statement_id: statementId,
    }).unwrap();
    if (result?.success) {
      setSendStatementsButtonLoading(null);
      createNotification?.({
        variant: 'success',
        title: result?.data,
      });
    } else {
      setSendStatementsButtonLoading(null);
      createNotification?.({
        variant: 'fail',
        title: 'Неожиданная ошибка',
      });
    }
  };

  const listProgressData = listProgress?.map((progress: Progress, index) => ({
    ...progress,
    id: index,
    date: moment(progress.date).format('DD.MM.YYYY'),
  }));

  const listStatementsData = listStatements?.map((statement: Statements) => ({
    ...statement,
    answer_date: moment(statement.answer_date).format('DD.MM.YYYY'),
    date_send: moment(statement.date_send).format('DD.MM.YYYY'),
    request_files: statement?.request_files?.map((file, ind) => {
      return (
        <a
          key={`${ind}${file.filename}`}
          className="inline-flex items-center text-primary_400 hover:text-primary_500"
          href={file.link}
          target="_blank"
          rel="noreferrer"
        >
          <div>
            <Icon name="Document" />
          </div>
          <div className="font-medium hover:underline">{file.filename}</div>
        </a>
      );
    }),
    respond_files: statement?.respond_files?.map((file, ind) => {
      return (
        <div
          className="inline-flex items-center text-primary_400 truncate"
          key={`${ind}${file.filename}`}
        >
          <a
            className="inline-flex items-center hover:text-primary_500"
            href={file.link}
            target="_blank"
            rel="noreferrer"
          >
            <div>
              <Icon name="Document" />
            </div>
            <div className="font-medium hover:underline">{file.filename}</div>
          </a>
        </div>
      );
    }),
    send_files: statement?.request_files.length > 0 && statement?.respond_files.length > 0 && (
      <div
        onClick={() => getArchieveStatementsSubmit(statement?.contract_id, statement?.id)}
        className="cursor-pointer"
      >
        <Tooltip
          title={`${t('view_order.table.info')}(${
            statement?.request_files.length + statement?.respond_files.length
          })`}
          placement="topLeft"
          color="#fff"
          overlayInnerStyle={{ color: '#4B5563', width: '10rem', textAlign: 'center' }}
          className="text-primary_400 hover:text-primary_500"
        >
          {sendStatementsButtonLoading === statement?.id ? (
            <Icon name="Load" />
          ) : (
            <Icon name="Email" />
          )}
        </Tooltip>
      </div>
    ),
  }));

  if (contract !== undefined && resultFiles !== undefined && offer !== undefined) {
    return (
      <>
        {/*Детали заказа*/}
        {(stage === StageOrder.in_progress || stage === StageOrder.completed) && offer && (
          <>
            <div className="w-full">
              <Heading title={t('view_order.heading.order_detail')} icon="Dashboard" />
            </div>
            <ServicesOrderDetail contract={contract} offer={offer} />
            <div className="p-[16px]">
              <ServicesTable />
            </div>
          </>
        )}
        {/*Детали запроса*/}
        {stage === StageOrder.calculated && (
          <>
            <div className="w-full">
              <Heading title={t('view_order.heading.request_detail')} />
            </div>
            {contract && <ServicesOrderDetail contract={contract} />}
          </>
        )}
        {stage === StageOrder.approval && offer && (
          <div className="w-full">
            <Heading title={t('view_order.heading.agreement')} icon="Calculator" />
          </div>
        )}
        <div className="p-[16px] pb-[4px]">
          <OrderInfoKP status={offer?.status} acceptance={offer?.acceptance} stage={stage} />
        </div>
        {stage === StageOrder.approval && offer && (
          <>
            <ServicesOrderDetail contract={contract} offer={offer} />
            <div className="p-[16px]">
              <ServicesTable />
            </div>
          </>
        )}
        {stage === StageOrder.calculated && (
          <div className="p-[16px]">
            <ServicesTable />
          </div>
        )}
        {/* Промежуточные результаты работы / Результаты выполнения работы */}
        {(stage === StageOrder.allCompleted ||
          stage === StageOrder.completed ||
          stage === StageOrder.in_progress) && (
          <>
            <div className="w-full">
              <Heading
                title={
                  stage === StageOrder.allCompleted || stage === StageOrder.completed
                    ? t('view_order.heading.results')
                    : t('view_order.heading.middle_results')
                }
                icon="ClipboardCheck"
              />
            </div>
            {orderFilesData &&
            (orderFilesData.data.total !== 0 ||
              (orderFilesData.data.total === 0
                ? (inputSearchValue && inputSearchValue?.length > 0) ||
                  (inputSearchDate && inputSearchDate?.length > 0)
                : true)) &&
            !isErrorGetOrderFiles ? (
              <>
                <>
                  <div className="mb-[32px] p-[16px] flex flex-col gap-[16px]">
                    <div className="flex gap-[10px]">
                      <div className="w-2/3">
                        <Input
                          value={inputSearchValue}
                          beforeIcon="Search"
                          placeholder="Поиск по названию"
                          onChange={handleInputChange}
                          icon={inputSearchValue ? 'Close' : undefined}
                          onIconClick={handleClearInputSearchValue}
                        />
                      </div>
                      <div className="w-1/3">
                        <Datepicker
                          selected={inputSearchDate ? new Date(inputSearchDate) : undefined}
                          icon="Calendar"
                          value={inputSearchDate}
                          placeholder="Дата"
                          onChange={handleDatePickerChange}
                          resetIcon="Close"
                          onResetIconClick={() => {
                            setInputSearchDate(undefined);
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="text-[16px] text-gray_500">
                        Результатов: {orderFilesData && orderFilesData.data.total}, Файлов:{' '}
                        {orderFilesData && orderFilesData.data.total_files}
                      </div>
                      <div className="flex gap-[16px]">
                        <div>
                          <Button
                            icon="Close"
                            label={t('orders.btn.reset_filters')}
                            variant="outlined"
                            disabled={!inputSearchValue && !inputSearchDate && !orderDirection}
                            space={16}
                            onClick={handleClearFilters}
                          />
                        </div>
                        <div>
                          <button
                            disabled={
                              archiveButtonLoading ||
                              (orderFilesData && orderFilesData.data.total_files === 0)
                            }
                            onClick={handleSendOrderFiles}
                            className={`${
                              archiveButtonLoading ||
                              (orderFilesData && orderFilesData.data.total_files === 0)
                                ? 'bg-primary_100'
                                : 'bg-primary_400'
                            }  text-white font-medium flex flex-row gap-1 pl-2 pr-4 py-2 items-center justify-center rounded-[4px]`}
                          >
                            <span>
                              <Icon name="Mail" />
                            </span>
                            <span>
                              {t('view_order.btn.sendArchive')}
                              {` (${orderFilesData ? orderFilesData.data.total_files : 0})`}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <TableAnt
                        columns={OrderTableHeaderData}
                        loading={isOrderFilesDataLoading}
                        dataSource={orderFilesData && orderFilesData.data.items}
                        pagination={false}
                        rowKey={() => `${v1()}`}
                        showSorterTooltip={false}
                        locale={{
                          emptyText: `${t('view_order.table.empty_data')}`,
                        }}
                        components={{
                          header: {
                            cell: ({ ...props }) => {
                              return (
                                <th
                                  {...props}
                                  className="bg-white text-[14px] font-medium text-gray_400 z-0 before:content-none after:content-none py-[8px]"
                                >
                                  <div {...props} className="inline-flex">
                                    {props.children}
                                  </div>
                                </th>
                              );
                            },
                          },
                          body: {
                            row: ({ ...props }) => {
                              return (
                                <tr
                                  {...props}
                                  className={`${
                                    orderFilesData.data.total !== 0
                                      ? 'hover:bg-gray_50'
                                      : 'text-center text-gray_400 font-bold'
                                  }`}
                                >
                                  {props.children}
                                </tr>
                              );
                            },
                            cell: ({ ...props }) => {
                              return (
                                <td {...props} className="align-top">
                                  {props.children}
                                </td>
                              );
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="flex justify-between items-center">
                      {orderFilesData && orderFilesData.data.total !== 0 ? (
                        <div>
                          Показано {startItem}-{endItem} из{' '}
                          {orderFilesData && orderFilesData.data.total} результатов
                        </div>
                      ) : (
                        <div>Результатов нет</div>
                      )}
                      <div>
                        <Pagination
                          currentPage={orderFilesData.data.page}
                          maxLength={7}
                          lastPage={lastPage}
                          setCurrentPage={setCurrentPage}
                        />
                      </div>
                    </div>
                  </div>
                </>
              </>
            ) : (
              <>
                <div className="mb-[32px] p-[16px] flex flex-col">
                  <div className="inline-flex text-primary_400 text-paragraph_m items-center justify-center h-full">
                    <div className="flex flex-col items-center gap-[8px] text-[#9CA3AF]">
                      <Icon name="Box" size={20} />
                      {t('view_order.listProgress.description')}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {/* Контакты */}
        {!contract && offer?.employees && stage !== StageOrder.calculated ? (
          <>
            <div className="w-full">
              <Heading title={t('view_order.heading.contacts')} icon="Group" />
            </div>
            <div className="w-full inline-flex justify-between flex-wrap p-[16px] mb-[32px]">
              {offer?.employees && (
                <div className="inline-flex flex-col w-full max-w-[269px] ">
                  <h3 className={textPkTitleClassName}>Руководитель проекта:</h3>
                  <div className={textPkDescriptionClassName}>
                    <p>{offer?.employees?.fio}</p>
                    <div className="flex flex-row items-center gap-2">
                      <a href={`tel:${offer?.employees.phone.replace(/[^+\d]/g, '')}`}>
                        <p>{offer?.employees?.phone}</p>
                      </a>
                      <a>
                        <Icon color="black" size={25} name="WhatsupCircleGreen" />
                      </a>
                      <a>
                        <Icon color="black" size={25} name="TelegramCircleBlue" />
                      </a>
                    </div>
                    <a href={`mailto:${offer.employees.email}`}>
                      <p>{offer?.employees?.email}</p>
                    </a>
                  </div>
                </div>
              )}
              {offer?.docs_manager && (
                <div className="inline-flex flex-col w-full max-w-[269px] ">
                  <h3 className={textPkTitleClassName}>Вопросы документооборота:</h3>
                  <div className={textPkDescriptionClassName}>
                    <p>{offer?.docs_manager?.fio}</p>
                    <div className="flex flex-row items-center gap-2">
                      {offer?.docs_manager?.phone && (
                        <a href={`tel:${offer?.docs_manager?.phone.replace(/[^+\d]/g, '')}`}>
                          <p>{offer?.docs_manager?.phone}</p>
                        </a>
                      )}
                      {offer?.docs_manager?.wa_link && (
                        <a href={offer?.docs_manager?.wa_link}>
                          <Icon color="black" size={25} name="WhatsupCircleGreen" />
                        </a>
                      )}
                      {offer?.docs_manager?.tg_link && (
                        <a href={offer?.docs_manager?.tg_link}>
                          <Icon color="black" size={25} name="TelegramCircleBlue" />
                        </a>
                      )}
                    </div>
                    <a href={`mailto:${offer?.docs_manager?.email}`}>
                      <p>{offer?.docs_manager?.email}</p>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : contract ? (
          <>
            <div className="w-full">
              <Heading title={t('view_order.heading.contacts')} icon="Group" />
            </div>
            <div className="w-full inline-flex  justify-between flex-wrap p-[16px] mb-[32px] ">
              {contract?.project_manager && (
                <div className="inline-flex  flex-col w-full max-w-[269px] ">
                  <h3 className={textPkTitleClassName}>Руководитель проекта:</h3>
                  <div className={textPkDescriptionClassName}>
                    <p>{contract?.project_manager?.fio}</p>
                    <div className="flex flex-row items-center gap-2">
                      {contract?.project_manager?.phone && (
                        <a href={`tel:${contract.project_manager.phone.replace(/[^+\d]/g, '')}`}>
                          <p>{contract?.project_manager?.phone}</p>
                        </a>
                      )}
                      {contract?.project_manager?.wa_link && (
                        <a href={contract?.project_manager?.wa_link}>
                          <Icon color="black" size={25} name="WhatsupCircleGreen" />
                        </a>
                      )}
                      {contract?.project_manager?.tg_link && (
                        <a href={contract?.project_manager?.tg_link}>
                          <Icon color="black" size={25} name="TelegramCircleBlue" />
                        </a>
                      )}
                    </div>
                    <a href={`mailto:${contract.project_manager.email}`}>
                      <p>{contract?.project_manager?.email}</p>
                    </a>
                  </div>
                </div>
              )}
              {contract?.docs_manager && (
                <div className="inline-flex flex-col w-full  max-w-[269px] ">
                  <h3 className={textPkTitleClassName}>Вопросы документооборота:</h3>
                  <div className={textPkDescriptionClassName}>
                    <p>{contract?.docs_manager?.fio}</p>
                    <div className="flex flex-row items-center gap-2">
                      {contract?.docs_manager?.phone && (
                        <a href={`tel:${contract?.docs_manager?.phone.replace(/[^+\d]/g, '')}`}>
                          <p>{contract?.docs_manager?.phone}</p>
                        </a>
                      )}
                      {contract?.docs_manager?.wa_link && (
                        <a href={contract?.docs_manager?.wa_link}>
                          <Icon color="black" size={25} name="WhatsupCircleGreen" />
                        </a>
                      )}
                      {contract?.docs_manager?.tg_link && (
                        <a href={contract?.docs_manager?.tg_link}>
                          <Icon color="black" size={25} name="TelegramCircleBlue" />
                        </a>
                      )}
                    </div>
                    <a href={`mailto:${contract?.docs_manager?.email}`}>
                      <p>{contract?.docs_manager?.email}</p>
                    </a>
                  </div>
                </div>
              )}
              {contract?.engineer && (
                <div className="inline-flex flex-col w-full  max-w-[269px] ">
                  <div className="inline-flex  flex-col w-full max-w-[269px] ">
                    <h3 className={textPkTitleClassName}>Кадастровый инженер:</h3>
                    <div className={textPkDescriptionClassName}>
                      <p>{contract?.engineer?.fio}</p>
                      <div className="flex flex-row items-center gap-2">
                        {contract?.engineer?.phone && (
                          <a href={`tel:${contract?.engineer?.phone.replace(/[^+\d]/g, '')}`}>
                            <p>{contract?.engineer?.phone}</p>
                          </a>
                        )}
                        {contract?.engineer.wa_link && (
                          <a href={contract?.engineer?.wa_link}>
                            <Icon color="black" size={25} name="WhatsupCircleGreen" />
                          </a>
                        )}
                        {contract?.engineer?.tg_link && (
                          <a href={contract?.engineer?.tg_link}>
                            <Icon color="black" size={25} name="TelegramCircleBlue" />
                          </a>
                        )}
                      </div>
                      <a href={`mailto:${contract?.engineer?.email}`}>
                        <p>{contract?.engineer?.email}</p>
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        {/* Прогресс выполнения услуги */}
        {listProgress && listProgressData && (
          <>
            <div className="w-full">
              <Heading title={t('view_order.heading.service_process')} icon="Progress" />
            </div>
            <div className="mb-[32px] p-[16px]">
              <Table
                fractions="5fr 1fr"
                columns={tableHead('serviceProcessTableHeader')}
                dataSource={listProgressData}
              />
            </div>
          </>
        )}
        {!listProgress && contract && (
          <>
            <div className="w-full">
              <Heading title={t('view_order.heading.service_process')} icon="Progress" />
            </div>
            <div className="mb-[32px] p-[16px] flex flex-col">
              <div className="inline-flex text-primary_400 text-paragraph_m items-center justify-center h-full">
                <div className="flex flex-col items-center gap-[8px] text-[#9CA3AF]">
                  <Icon name="Box" size={20} />
                  {t('view_order.listProgress.description')}
                </div>
              </div>
            </div>
          </>
        )}
        {/* Поданные заявления в рамках услуги */}
        {listStatements && listStatementsData && (
          <>
            <div className="w-full">
              <Heading title={t('view_order.heading.service_orders')} icon="Application" />
            </div>
            <div className="mb-[32px] m-[16px] w-full max-w-[871px] overflow-x-scroll scrollbar-small">
              <div className="w-[1680px] pb-[16px]">
                <Table
                  fractions="2.2fr 1.4fr 2fr 2fr 0.5fr 2fr 4fr 1fr 2fr 1.5fr 1.5fr 2fr"
                  columns={tableHead('serviceOrdersTableHeader')}
                  dataSource={listStatementsData}
                  block={'true'}
                  rowHover={true}
                />
              </div>
            </div>
          </>
        )}
        {!listStatements && contract && (
          <>
            <div className="w-full">
              <Heading title={t('view_order.heading.service_orders')} icon="Application" />
            </div>
            <div className="mb-[32px] m-[16px] w-full max-w-[871px]">
              <div className="pb-[16px] flex flex-col">
                <div className="inline-flex text-primary_400 text-paragraph_m items-center justify-center h-full">
                  <div className="flex flex-col items-center gap-[8px] text-[#9CA3AF]">
                    <Icon name="Box" size={20} />
                    {t('view_order.listProgress.description')}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <ArchieveResult
          email={user?.email || ''}
          openArchieveResult={openArchieveResult}
          toggleArchieveResult={toggleArchieveResult}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default React.memo(ServiceProcessBlock);
