import React, { FC, useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox, Tooltip } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import { Input, Icon, Button } from '@/components/ui';
import { useValidation } from '@/hooks/use-validation';
import { useUpdateСounterpartyMutation } from '@/store/api/counterparty.api';
import { changeCounterpatyItem } from '@/store/slices/counterparty.slice';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { useNotification } from '@/hooks/use-notifications';
import { Counterparty } from '@/types/counterparty.type';

type CreateLegalCounterpartyFields = {
  inn: string;
  kpp: string;
  name: string;
  ogrn: string;
  address: string;
  manager_name: string;
  manager_position: string;
  bank_name: string;
  bik: string;
  billing_account: string;
  correspondent_account: string;
};

type LegalFormProps = {
  counterparty: Counterparty;
  OnCancel?: () => void;
};

const LegalForm: FC<LegalFormProps> = (props) => {
  const { counterparty, OnCancel } = props;
  const validationFields = useValidation();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { createNotification } = useNotification();

  const [updateCounterparty, { isLoading: updateLoading }] = useUpdateСounterpartyMutation();
  const [error, setError] = useState('');
  const [isReferral, setIsReferral] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm<CreateLegalCounterpartyFields>({
    defaultValues: {
      ...counterparty?.legal_data,
      ...counterparty?.account_data,
    },
  });

  useEffect(() => {
    setIsReferral(counterparty?.referral || false);
  }, [counterparty]);

  useEffect(() => {
    if (error) {
      createNotification?.({
        variant: 'fail',
        title: error,
      });
      setError('');
    }
  }, [error, createNotification]);

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    setIsReferral(e.target.checked);
  };

  // Редактирование контрагента
  async function submitForm(data: CreateLegalCounterpartyFields) {
    if (!error) {
      const {
        inn,
        kpp,
        name,
        ogrn,
        address,
        manager_name,
        manager_position,
        bank_name,
        bik,
        billing_account,
        correspondent_account,
      } = data;
      const params = {
        counterparty_type: counterparty.counterparty_type,
        referral: isReferral,
        legal_data: {
          inn,
          name,
          address,
          manager_name,
          manager_position,
          kpp,
          ogrn,
        },
        account_data: {
          bank_name,
          bik,
          billing_account,
          correspondent_account,
        },
      };
      const result: any = await updateCounterparty({ pk: counterparty?.id, body: params });
      if (result?.data?.success) {
        dispatch(changeCounterpatyItem(result.data.data));
        reset();
        OnCancel?.();
      } else if (result?.error?.data?.error) {
        setError(result?.error?.data?.error);
      }
    }
  }

  const handleOnCancel = () => {
    reset();
    OnCancel?.();
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <div className="flex mt-[16px]">
        <div className="w-full mr-1.5">
          <Controller
            name="inn"
            control={control}
            rules={
              counterparty.counterparty_type === 'ЮЛ'
                ? validationFields.legal_inn
                : validationFields.individual_inn
            }
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  label={t('common.input.inn.label') + '*'}
                  error={errors.inn?.message}
                  placeholder={t('common.input.inn.placeholder')}
                  mask={counterparty.counterparty_type === 'ЮЛ' ? '9999999999' : '999999999999'}
                  disabled={updateLoading}
                />
              );
            }}
          />
        </div>
        <div className="w-full ml-1.5">
          <Controller
            name={counterparty.counterparty_type === 'ЮЛ' ? 'kpp' : 'ogrn'}
            control={control}
            rules={
              counterparty.counterparty_type === 'ЮЛ' ? validationFields.kpp : validationFields.ogrn
            }
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  label={
                    (counterparty.counterparty_type === 'ЮЛ'
                      ? t('common.input.kpp.label')
                      : t('common.input.ogrn.label')) + '*'
                  }
                  error={
                    counterparty.counterparty_type === 'ЮЛ'
                      ? errors.kpp?.message
                      : errors.ogrn?.message
                  }
                  placeholder={
                    counterparty.counterparty_type === 'ЮЛ'
                      ? t('common.input.kpp.placeholder')
                      : t('common.input.ogrn.placeholder')
                  }
                  mask={counterparty.counterparty_type === 'ЮЛ' ? '999999999' : '999999999999999'}
                  disabled={updateLoading}
                />
              );
            }}
          />
        </div>
      </div>
      <div className="flex mt-[16px]">
        <div className={`w-full ${counterparty.counterparty_type === 'ЮЛ' ? 'mr-1.5' : ''}`}>
          <Input
            label={t('common.input.company_name.label') + '*'}
            {...register('name', validationFields.company_name)}
            error={errors.name?.message}
            placeholder={t('common.input.company_name.placeholder')}
            disabled={updateLoading}
          />
        </div>
        {counterparty.counterparty_type === 'ЮЛ' ? (
          <div className="w-full ml-1.5">
            <Controller
              name="ogrn"
              control={control}
              rules={validationFields.ogrn}
              render={({ field }) => {
                return (
                  <Input
                    {...field}
                    label={t('common.input.ogrn.label') + '*'}
                    error={errors.ogrn?.message}
                    placeholder={t('common.input.ogrn.placeholder')}
                    mask="9999999999999"
                    disabled={updateLoading}
                  />
                );
              }}
            />
          </div>
        ) : null}
      </div>
      <div className="mt-[16px]">
        <Input
          label={t('common.input.address.label') + '*'}
          {...register('address', validationFields.address)}
          error={errors.address?.message}
          placeholder={t('common.input.address.placeholder')}
          disabled={updateLoading}
        />
      </div>
      <div className="flex mt-[16px]">
        <div className="w-full mr-1.5">
          <Input
            label={t('common.input.manager_name.label') + '*'}
            {...register('manager_name', validationFields.manager_name)}
            error={errors.manager_name?.message}
            placeholder={t('common.input.manager_name.placeholder')}
            disabled={updateLoading}
          />
        </div>
        <div className="w-full ml-1.5">
          <Input
            {...register('manager_position', validationFields.manager_position)}
            label={t('common.input.manager_position.label') + '*'}
            error={errors.manager_position?.message}
            placeholder={t('common.input.manager_position.placeholder')}
            disabled={updateLoading}
          />
        </div>
      </div>
      <div className="flex mt-[16px]">
        <div className="w-full mr-1.5">
          <Input
            label={t('common.input.bank_name.label')}
            {...register('bank_name' /*, validationFields.bank_name*/)}
            error={errors.bank_name?.message}
            placeholder={t('common.input.bank_name.placeholder')}
            disabled={updateLoading}
          />
        </div>
        <div className="w-full ml-1.5">
          <Controller
            name="billing_account"
            control={control}
            // rules={validationFields.billing_account}
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  label={t('common.input.billing_account.label')}
                  error={errors.billing_account?.message}
                  placeholder={t('common.input.billing_account.placeholder')}
                  mask="99999999999999999999"
                  disabled={updateLoading}
                />
              );
            }}
          />
        </div>
      </div>
      <div className="flex mt-[16px]">
        <div className="w-full mr-1.5">
          <Controller
            name="bik"
            control={control}
            // rules={validationFields.bik}
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  label={t('common.input.bik.label')}
                  error={errors.bik?.message}
                  placeholder={t('common.input.bik.placeholder')}
                  mask="999999999"
                  disabled={updateLoading}
                />
              );
            }}
          />
        </div>
        <div className="w-full ml-1.5">
          <Controller
            name="correspondent_account"
            control={control}
            // rules={validationFields.correspondent_account}
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  label={t('common.input.correspondent_account.label')}
                  error={errors.correspondent_account?.message}
                  placeholder={t('common.input.correspondent_account.placeholder')}
                  mask="99999999999999999999"
                  disabled={updateLoading}
                />
              );
            }}
          />
        </div>
      </div>
      <div className="flex items-center mt-[16px]">
        <Checkbox
          className="text-gray_600 text-paragraph_l"
          checked={isReferral}
          onChange={onChangeCheckbox}
          disabled={updateLoading}
        >
          {t('create_counterparty.checkbox.label')}
        </Checkbox>
        <Tooltip
          title={t('create_counterparty.checkbox.info')}
          placement="topRight"
          color="#fff"
          overlayInnerStyle={{ color: '#4B5563' }}
        >
          <Icon name="Info" className="text-gray_300 ml-2 cursor-pointer" />
        </Tooltip>
      </div>
      <div className="w-full flex justify-end mt-[24px]">
        <div className="mr-[14px]">
          <Button
            label={t('create_counterparty.cancel_button')}
            onClick={handleOnCancel}
            variant="outlined"
            space={15}
            disabled={updateLoading}
          />
        </div>
        <div>
          <Button
            type="submit"
            label={t('create_counterparty.submit_button')}
            variant="filled"
            disabled={updateLoading}
            space={17}
          />
        </div>
      </div>
    </form>
  );
};

export default LegalForm;
