import React from 'react';

const YandexPay = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="red"
        fillRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.66961 13H10.2057V3H7.96961C5.72377 3 4.53766 4.30769 4.53766 6.23077C4.53766 7.76923 5.18905 8.68132 6.346 9.61539L4.3335 13H5.996L8.23211 9.20879L7.45433 8.61539C6.51127 7.9011 6.05433 7.34066 6.05433 6.13187C6.05433 5.06593 6.71544 4.35164 7.97933 4.35164H8.66961V13Z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default YandexPay;
