import clsx from 'clsx';

import { ModalType } from '@/components/ui/modal/modal.types';

const modalType: Record<ModalType, { width: string }> = {
  dialog: {
    width: 'max-w-[535px]',
  },
  content: {
    width: 'max-w-[419px]',
  },
  payment: {
    width: 'max-w-[436px]',
  },
  video: {
    width: 'max-w-[1000px]',
  },
  chat: {
    width: 'max-w-[824px]',
  },
};

// export const modalWrapperClassName = clsx(
//   'flex',
//   'items-center',
//   'justify-center',
//   'overflow-y-auto',
//   'overflow-x-hidden',
//   'fixed',
//   'top-0',
//   'right-0',
//   'left-0',
//   'z-50',
//   'w-full',
//   'md:inset-0',
//   'h-modal',
//   'md:h-full',
// );

export const modalWrapperClassName = ({ type }: { type: ModalType }) => {
  const commonClasses = [
    'flex',
    'items-center',
    'overflow-y-auto',
    'overflow-x-hidden',
    'fixed',
    'top-0',
    'right-0',
    'left-0',
    'z-50',
    'w-full',
    'md:inset-0',
    'h-modal',
    'md:h-full',
  ];
  if (type === 'chat') {
    return clsx('justify-end mt-4 ', ...commonClasses);
  } else {
    return clsx('justify-center', ...commonClasses);
  }
};

// export const modalContainerClassName = ({ type }: { type: ModalType }) =>
//   clsx(
//     modalType[type].width,
//     'relative',
//     'bg-white',
//     'rounded-lg',
//     'shadow',
//     'p-[24px]',
//     'pt-[26px]',
//     'max-w-[535px]',
//     'w-full',
//     'font-medium',
//   );
export const modalContainerClassName = ({ type }: { type: ModalType }) => {
  const commonClasses = [
    'relative',
    'bg-white',
    'rounded-lg',
    'shadow',
    'max-w-[535px]',
    'w-full',
    'font-medium',
    modalType[type].width,
  ];
  if (type === 'chat') {
    return clsx('p-0', 'mr-[30px]', ...commonClasses);
  } else {
    return clsx('p-[24px]', 'pt-[26px]', ...commonClasses);
  }
};
