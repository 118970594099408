import React from 'react';

const WhatsupCircleGreen = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <rect width="23" height="23" x="0.5" y="0.5" fill="#68BF5F" rx="11.5"></rect>
      <path
        fill="#fff"
        d="M17.096 6.894a7.102 7.102 0 00-5.069-2.093c-3.948 0-7.166 3.202-7.17 7.133 0 1.26.332 2.484.956 3.569L4.8 19.2l3.8-.992c1.052.57 2.23.868 3.427.869h.004c3.949 0 7.165-3.203 7.169-7.137a7.08 7.08 0 00-2.104-5.047zM12.027 17.87a5.964 5.964 0 01-3.034-.828l-.217-.13-2.255.589.602-2.19-.14-.226a5.884 5.884 0 01-.911-3.154c0-3.264 2.673-5.926 5.959-5.926a5.954 5.954 0 015.505 3.661c.3.72.453 1.492.45 2.27-.003 3.276-2.676 5.934-5.959 5.934zm3.268-4.44c-.178-.09-1.057-.521-1.223-.582-.164-.059-.285-.09-.402.089-.12.177-.464.581-.567.697-.103.12-.21.133-.389.045-.178-.09-.756-.277-1.44-.886-.532-.473-.89-1.058-.996-1.235-.103-.178-.01-.274.08-.363.078-.079.177-.209.267-.311.09-.103.12-.178.179-.297.059-.12.03-.223-.014-.312-.045-.09-.402-.969-.553-1.323-.145-.35-.292-.302-.402-.306-.103-.007-.224-.007-.344-.007a.662.662 0 00-.478.223c-.165.178-.625.609-.625 1.488 0 .88.642 1.725.733 1.844.088.12 1.26 1.92 3.058 2.693.425.185.76.293 1.02.376.43.137.818.116 1.127.072.344-.052 1.059-.432 1.21-.848.148-.418.148-.774.103-.85-.044-.075-.165-.119-.344-.208z"
      ></path>
      <rect width="23" height="23" x="0.5" y="0.5" stroke="#fff" rx="11.5"></rect>
    </svg>
  );
};

export default WhatsupCircleGreen;
