import React from 'react';
import clsx from 'clsx';

import { useTablesHeader } from '@/features/feature-hooks/use-tables-header';
import { columnsClassName } from '@/components/ui/order-card/order-card.styles';
import { Icon } from '@/components/ui';

type OrderCardHeaderProps = {
  setOrdering?: React.Dispatch<React.SetStateAction<string | undefined>>;
  ordering?: string;
};

const OrderCardHeader = ({ setOrdering, ordering }: OrderCardHeaderProps) => {
  const tableHeaders = useTablesHeader();

  const getSortIconName = () => {
    return ordering?.[0] === '-' ? 'SortDown' : 'SortUp';
  };

  const getSortField = () => {
    return ordering?.[0] === '-' ? ordering.slice(1) : ordering;
  };

  const handleClickColumn = (key: string) => {
    if (!ordering || getSortField() !== key) {
      setOrdering?.(key);
    } else if (ordering === key) {
      setOrdering?.(`-${key}`);
    } else {
      setOrdering?.(undefined);
    }
  };

  return (
    <div className={columnsClassName}>
      {tableHeaders('orderTableHeader').map(({ title, key, right = false }) => (
        <div
          key={key}
          className={clsx(
            'flex',
            right && 'flex-row-reverse',
            'py-[12px]',
            key !== 'stage' && 'cursor-pointer',
          )}
          onClick={key !== 'stage' ? () => handleClickColumn(key) : undefined}
        >
          {title}
          {ordering && key !== 'stage' && getSortField() === key ? (
            <Icon name={getSortIconName()} className="mx-4" />
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default OrderCardHeader;
