import React, { useEffect, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { v4 } from 'uuid';

import OnlinePayModalBlock from '@/features/egrn/blocks/online-pay/online-pay-modal.block';
import { Button, Container, Modal, Input, Skeleton, FileButton, Icon } from '@/components/ui';
import { RealEstateCard } from '@/components';
import { route } from '@/util/route';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useNotification } from '@/hooks/use-notifications';
import { useValidation } from '@/hooks/use-validation';
import {
  useGetEgrnNewOrderQuery,
  useCreateEgrnNewOrderMutation,
  useCreateEgrnOrderMutation,
} from '@/store/api/egrn.api';
import { setNewOrder, resetNewOrder } from '@/store/slices/egrn.slices';
import { EgrnNewOrder, EgrnNewOrderItem, EgrnOrder } from '@/types/egrn.type';
import { newEgrnOrderSelector } from '@/store/selectors/egrn.selectors';

type formType = {
  search: string;
};

const CreateEgrnPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const validationFields = useValidation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm<formType>();
  const search = useWatch({
    control,
    name: 'search',
  });

  const newOrder: EgrnNewOrder | undefined = useAppSelector(newEgrnOrderSelector);
  const [cadastralNumber, setCadastralNumber] = useState<string | undefined>();
  const [isCreateNewOrderError, setIsCreateNewOrderError] = useState<boolean>(false);
  const [fetchedObjects, setFetchedObjects] = useState<EgrnNewOrderItem[]>([]);
  const [fromFileObjects, setFromFileObjects] = useState<EgrnNewOrderItem[]>([]);
  const [showObjectList, toggleShowObjectList] = useState<boolean>(false);
  const [openCancel, toggleCancel] = useState<boolean>(false);
  const [openCsvModal, toggleCsvModal] = useState<boolean>(false);
  const [openPayModal, togglePayModal] = useState<boolean>(false);
  const [createdOrder, setCreatedOrder] = useState<EgrnOrder | undefined>();

  const radioStyledClassName =
    'radio-styled inline-block w-4 h-4 border border-gray_400 rounded-full';

  const { data: getNewOrderData, isFetching: isGetNewOrderLoading } = useGetEgrnNewOrderQuery({
    cadastral_number: cadastralNumber,
  });
  const [
    createNewOrderFromFile,
    {
      // data: createNewOrderData,
      isLoading: isCreateNewOrderLoading,
      // isError: isCreateNewOrderError,
      reset: resetFromFile,
    },
  ] = useCreateEgrnNewOrderMutation();
  const [createEgrnOrder] = useCreateEgrnOrderMutation();

  const { createNotification } = useNotification();

  useEffect(() => {
    return () => {
      dispatch(resetNewOrder());
    };
  }, [dispatch]);

  useEffect(() => {
    if (getNewOrderData?.data.items.length) {
      setFetchedObjects((fetchedObjects) => {
        const currentObjects = fetchedObjects.slice(0);
        currentObjects.push(...getNewOrderData.data.items);
        return currentObjects;
      });
    }
  }, [getNewOrderData]);

  async function handleOnChangeCsv(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      toggleShowObjectList(false);
      toggleCsvModal(true); // открываем модальное окно
      const formData = new FormData();
      formData.append('file', event.target.files[0]);
      await createNewOrderFromFile(formData)
        .unwrap()
        .then((payload) => {
          setFromFileObjects(payload.data.items);
        })
        .catch(() => {
          setIsCreateNewOrderError(true);
        });
    }
  }

  const SubmitForm = (data: formType) => {
    setCadastralNumber(data.search);
    toggleShowObjectList(true);
  };

  const addToNewOrder = (index: number) => {
    const cloneFetchedObjects = structuredClone(fetchedObjects);
    const objects = cloneFetchedObjects.splice(index, 1);
    objects[0].uuid = v4();
    const cloneNewOrder = structuredClone(newOrder);
    if (cloneNewOrder) {
      cloneNewOrder.items.push(...objects);
      dispatch(setNewOrder(cloneNewOrder));
    } else {
      const newOrder = {
        comment: '',
        items: objects,
      };
      dispatch(setNewOrder(newOrder));
    }
    setFetchedObjects(cloneFetchedObjects);
    if (!cloneFetchedObjects.length) toggleShowObjectList(false);
  };

  const deleteFromNewOrder = (uuid?: string) => {
    const cloneNewOrder = structuredClone(newOrder);
    const filteredItems = newOrder.items.filter((item: EgrnNewOrderItem) => item.uuid !== uuid);
    cloneNewOrder.items = filteredItems;
    dispatch(setNewOrder(cloneNewOrder));
  };

  const handleChangeItem = (
    field: keyof Pick<EgrnNewOrderItem, 'comment' | 'report_type'>,
    value: string | number,
    uuid?: string,
  ) => {
    const cloneNewOrder = structuredClone(newOrder);
    const index = cloneNewOrder.items.findIndex((item: EgrnNewOrderItem) => item.uuid === uuid);
    cloneNewOrder.items[index][field] = value;
    dispatch(setNewOrder(cloneNewOrder));
  };

  const changeNewOrderComment = (value: string) => {
    const cloneNewOrder = structuredClone(newOrder);
    cloneNewOrder.comment = value;
    dispatch(setNewOrder(cloneNewOrder));
  };

  const changeAllItemReportType = (value: number) => {
    const cloneNewOrder = structuredClone(newOrder);
    cloneNewOrder.items.forEach((item: EgrnNewOrderItem) => {
      item.report_type = value;
    });
    dispatch(setNewOrder(cloneNewOrder));
  };

  const cancelCsvModal = () => {
    toggleCsvModal(false);
    setFromFileObjects([]);
    resetFromFile();
  };

  const submitCsv = () => {
    if (fromFileObjects.length) {
      const cloneFromFileObjects = structuredClone(fromFileObjects);
      cloneFromFileObjects.forEach((item: EgrnNewOrderItem) => {
        item.uuid = v4();
      });
      dispatch(
        setNewOrder({
          comment: '',
          items: cloneFromFileObjects,
        }),
      );
      createNotification?.({
        variant: 'success',
        title: t('create_egrn.notification.add_objects.success.title'),
        description: `${fromFileObjects.length} ${t(
          'create_egrn.notification.add_objects.success.description',
        )}`,
      });
    }
    cancelCsvModal();
  };

  async function createOrder() {
    const cloneNewOrder = structuredClone(newOrder);
    cloneNewOrder?.items.forEach((item: EgrnNewOrderItem) => delete item.uuid);
    await createEgrnOrder(cloneNewOrder)
      .unwrap()
      .then((payload) => {
        if (payload.success) {
          setCreatedOrder(payload.data);
          togglePayModal(true);
        }
      })
      .catch(() => {
        createNotification?.({
          variant: 'fail',
          title: t('create_egrn.notification.create_order.fail.title'),
          description: t('create_egrn.notification.create_order.fail.description'),
        });
      })
      .finally(() => {
        dispatch(resetNewOrder());
        setFetchedObjects([]);
        setFromFileObjects([]);
        setCadastralNumber(undefined);
        toggleShowObjectList(false);
      });
  }

  const cancelPayModal = () => {
    togglePayModal(false);
    navigate(route.egrn);
    createNotification?.({
      variant: 'success',
      title: t('create_egrn.notification.create_order.success.title'),
      description: t('create_egrn.notification.create_order.success.description'),
    });
  };

  const renderObjectList = isGetNewOrderLoading ? (
    <div className="p-4">
      <Skeleton size={1120} />
    </div>
  ) : fetchedObjects.length ? (
    fetchedObjects.map(
      ({ cadastral_number, address, realty_type, area }: EgrnNewOrderItem, index: number) => (
        <div
          key={index}
          className="group hover:bg-gray_50 transition cursor-pointer py-2 mb-2 last:mb-0"
          onClick={() => addToNewOrder(index)}
        >
          <div className="flex items-center">
            <Icon name="Plus" className="text-primary_400 mr-2" />
            <span className="group-hover:text-primary_400">
              {cadastral_number}, {realty_type}, {area}, {address}
            </span>
          </div>
        </div>
      ),
    )
  ) : (
    <div>{t('create_egrn.empty.no_order_items')}</div>
  );

  const renderObjectSearch = (
    <div className="w-full">
      <Modal
        isShow={openCancel}
        label={t('create_egrn.modal.back.label')}
        type="dialog"
        icon="Alert"
        submitText={t('create_egrn.modal.back.cancel')}
        buttonText={t('create_egrn.modal.back.stay')}
        onCancel={() => {
          toggleCancel(false); // закрываем модальное окно
        }}
        onSubmit={() => {
          toggleCancel(false); // закрываем модальное окно
          navigate(route.egrn); // перенаправляем на страницу заказов егрн
        }}
      >
        {t('create_egrn.modal.back.children')}
      </Modal>
      <Modal
        isShow={openCsvModal}
        label={
          isCreateNewOrderLoading ? (
            <span className="flex">
              {t('create_egrn.modal.csv.label')} <Icon name="Load" className="ml-4" />
            </span>
          ) : isCreateNewOrderError ? (
            t('create_egrn.modal.csv.label_error')
          ) : (
            `Найдено ${fromFileObjects.length} кадастровых номеров`
          )
        }
        type="content"
        submitText={
          isCreateNewOrderError || !fromFileObjects.length
            ? t('create_egrn.modal.csv.close')
            : t('create_egrn.modal.csv.add_to_order')
        }
        isFileLoading={isCreateNewOrderLoading}
        isFileError={isCreateNewOrderError || !fromFileObjects.length}
        icon={isCreateNewOrderError ? 'Alert' : undefined}
        buttonText={t('create_egrn.modal.csv.cancel')}
        onCancel={cancelCsvModal}
        onSubmit={submitCsv}
      >
        {fromFileObjects.length
          ? fromFileObjects.map((item) => {
              return <p key={item.cadastral_number}>{item.cadastral_number}</p>;
            })
          : isCreateNewOrderError
          ? t('create_egrn.modal.csv.error')
          : ''}
      </Modal>
      <div className="w-full">
        <div className="flex mb-4 text-gray_600">
          <span>{t('create_egrn.input.cadaster.label')}</span>
          <FileButton
            label={t('create_egrn.btn.csv')}
            customStyles="bg-primary_400 hover:bg-primary_500 disabled:bg-gray_100 py-0 ml-2"
            accept=".csv"
            onChange={handleOnChangeCsv}
          />
        </div>
        <form className="flex w-full" onSubmit={handleSubmit(SubmitForm)}>
          <Input
            {...register('search', validationFields.cadastral_number)}
            beforeIcon="Search"
            icon={search ? 'Close' : undefined}
            onIconClick={() => setValue('search', '')}
            defaultValue={search}
            placeholder={t('create_egrn.input.cadaster.placeholder')}
            error={errors.search?.message}
          />
          {/* <div className="ml-4">
            <Button type="submit" space={32} label={t('create_egrn.btn.search_cadastral_number')} />
          </div> */}
        </form>
        {showObjectList ? (
          <div className="bg-white rounded shadow-md mt-2 px-3 py-2 text-gray_600">
            {renderObjectList}
          </div>
        ) : null}
      </div>
    </div>
  );

  return (
    <div className="pb-[80px]">
      <Container>
        <div className="mt-4 w-full mb-[24px]">
          <Button
            space={12}
            label={t('create_egrn.btn.back_to_orders')}
            variant="outlined"
            icon="ChevronLeft"
            onClick={() => {
              toggleCancel(true);
            }}
          />
        </div>
        <div className="mb-[20px]">
          <h1 className="mb-[12px] text-heading_h2 font-bold text-gray_600">
            {t('create_egrn.new_request')}
          </h1>
          <div>{renderObjectSearch}</div>
        </div>
        {newOrder?.items && newOrder?.items?.length > 1 ? (
          <div className="flex mb-4">
            <div className="w-[500px] mr-4">
              <Input
                label={t('create_egrn.input.comment_for_all.label')}
                placeholder={t('create_egrn.input.comment_for_all.placeholder')}
                onChange={(e) => changeNewOrderComment(e.target.value)}
              />
            </div>
            <div>
              <label className="font-bold text-paragraph_m text-gray_600">
                {t('create_egrn.radio.report_type.label')}
              </label>
              <label className="block cursor-pointer text-gray_600 mb-1">
                <input
                  type="radio"
                  name="report_type"
                  value={1}
                  className="radio hidden"
                  onChange={(e) => changeAllItemReportType(+e.target.value)}
                />
                <span className={radioStyledClassName}></span>
                {t('create_egrn.radio.report_type.1')}
              </label>
              <label className="block cursor-pointer text-gray_600">
                <input
                  type="radio"
                  name="report_type"
                  value={2}
                  className="radio hidden"
                  onChange={(e) => changeAllItemReportType(+e.target.value)}
                />
                <span className={radioStyledClassName}></span>
                {t('create_egrn.radio.report_type.2')}
              </label>
            </div>
          </div>
        ) : null}
        <div>
          {newOrder?.items.map((item, index) => {
            return (
              <div key={item.uuid} className="mb-4">
                <RealEstateCard
                  item={item}
                  index={index}
                  deleteItem={deleteFromNewOrder}
                  onChangeItem={handleChangeItem}
                />
              </div>
            );
          })}
        </div>
        <div className="fixed bottom-0 left-0 py-3 border-t-[1px] border-t-gray_200 bg-white w-full z-50">
          <Container>
            <div className="flex justify-between items-center">
              <div className="flex items-center text-gray_600">
                <div>
                  {t('create_egrn.new_order.items')}:{' '}
                  <span className="font-bold text-heading_h1 mr-4">
                    {newOrder?.items.length || 0}
                  </span>
                </div>
                <div>
                  {t('create_egrn.new_order.sum')}:{' '}
                  <span className="font-bold text-heading_h1">
                    {100 * (newOrder?.items.length || 0)} руб.
                  </span>
                </div>
              </div>
              <div>
                <Button
                  space={12}
                  label={t('create_egrn.btn.request_order')}
                  onClick={createOrder}
                  disabled={!newOrder?.items.length}
                />
              </div>
            </div>
          </Container>
        </div>
      </Container>
      <OnlinePayModalBlock
        isShow={openPayModal}
        paySum={(createdOrder?.price || 0) / 100}
        orderId={createdOrder?.id}
        onCancel={cancelPayModal}
      />
    </div>
  );
};

export default CreateEgrnPage;
