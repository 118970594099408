import React from 'react';
import moment from 'moment';

import UnreadMessage from '../unreadMessage/unreadMessage';

import { Icon } from '@/components/ui';
import { ChatType } from '@/types/chat.type';
import { useAppSelector } from '@/hooks/use-app-selector';
import { newMessagesCountSelector } from '@/store/selectors/chat.selector';

type ConversationPropsType = {
  title: string;
  offer: string;
  date: string;
  isReady: 'in_progress' | 'ready';
  unRead: number;
  id: number;
  currentChat: ChatType | null;
  type: 'contract' | 'request_proposal' | 'general_questions';
};
const Conversation = ({
  title,
  offer,
  date,
  isReady,
  id,
  currentChat,
  type,
}: ConversationPropsType) => {
  const newMessagesCount = useAppSelector(newMessagesCountSelector);
  const activeChatStyle =
    isReady === 'ready' && currentChat?.id === id
      ? 'bg-primary_100'
      : isReady === 'ready'
      ? 'bg-gray_50'
      : currentChat?.id === id
      ? 'bg-primary_100'
      : '';
  const formattedDate = moment(date).format('DD.MM.YYYY');

  return (
    <div className={`px-4 cursor-pointer ${activeChatStyle} `}>
      <div className="py-3 border-b border-gray_200">
        <div className="flex gap-x-2 items-center">
          {type === 'general_questions' && (
            <div>
              <Icon name="ChatCommenQuestions" size={20} />
            </div>
          )}
          <div className="flex w-[100%] justify-between ">
            <p
              className={`truncate text-paragraph_m  font-medium ${
                isReady === 'ready' ? 'text-gray_400' : 'text-gray_600'
              } `}
            >
              {title}
            </p>
            <UnreadMessage
              unreadMessagesCount={newMessagesCount.find((msg) => msg.chat_id === id)?.total ?? 0}
            />
          </div>
        </div>
        {offer && (
          <div className="flex  gap-x-2 mt-1  text-paragraph_s font-regular">
            <p className=" truncate text-gray_500 ">{offer}</p>
            {isReady === 'ready' && (
              <div className="  truncate text-positive_500">Работы выполнены</div>
            )}
          </div>
        )}

        {date && <p className="text-paragraph_s text-gray_500 font-regular">от {formattedDate}</p>}
      </div>
    </div>
  );
};

export default Conversation;
