import React from 'react';

const Download = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M3.33398 13.334V14.1673C3.33398 14.8304 3.59738 15.4662 4.06622 15.9351C4.53506 16.4039 5.17094 16.6673 5.83398 16.6673H14.1673C14.8304 16.6673 15.4662 16.4039 15.9351 15.9351C16.4039 15.4662 16.6673 14.8304 16.6673 14.1673V13.334M13.334 10.0007L10.0007 13.334M10.0007 13.334L6.66732 10.0007M10.0007 13.334V3.33398"
      ></path>
    </svg>
  );
};

export default Download;
