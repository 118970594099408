import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';

import { Box, Button, Container, FileButton, Icon, Info, Modal, Stepper } from '@/components/ui';
import { route } from '@/util/route';
import ServiceProcessBlock from '@/features/orders/blocks/view-order/service-process.block';
import { useDeleteFileMutation, useUploadFilesMutation } from '@/store/api/files.api';
import { DocumentBase64, FilesResponse } from '@/components/ui/documents/documents.types';
import { priceConvertor, readAsDataURL } from '@/util/helpers';
import { StageOrder } from '@/types/order.type';
import { Step } from '@/components/ui/stepper/stepper.types';
import { Bill, Contract, ContractStatus } from '@/types/contract.type';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { useAppSelector } from '@/hooks/use-app-selector';
import {
  contractFilesSelector,
  offerFilesSelector,
  uploadedFilesSelector,
} from '@/store/selectors/files.selectors';
import {
  fetchContractFiles,
  fetchFiles,
  fetchOffertFiles,
  fetchResultFiles,
  resetFiles,
  setUploadedFiles,
} from '@/store/slices/files.slices';
import { fetchProposal, setProposal } from '@/store/slices/proposal.slices';
import { proposalSelector } from '@/store/selectors/proposal.selectors';
import { Proposal } from '@/types/proposal.type';
import { fetchCounterparty, setCounterparty } from '@/store/slices/counterparty.slice';
import {
  fetchContractByOfferId,
  fetchListProgress,
  fetchListStatements,
  resetContractStore,
} from '@/store/slices/contract.slices';
import { File } from '@/types/file.type';
import { fetchServices } from '@/store/slices/service.slices';
import { contractIdSelector, contractSelector } from '@/store/selectors/contract.selectors';
import { fetchOffer, resetOffer } from '@/store/slices/offer.slices';
import { offerSelector } from '@/store/selectors/offer.selectors';
import Error404Route from '@/pages/error-404.route';

const ViewOrderPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const [deleteFile] = useDeleteFileMutation();
  const [uploadFiles] = useUploadFilesMutation();

  const uploadedFiles = useAppSelector(uploadedFilesSelector); // Загруженные документы
  const proposal: Proposal | null = useAppSelector(proposalSelector); // Заявка

  const contractFiles: File[] = useAppSelector(contractFilesSelector); // Договорные документы
  const offerFile: File | null = useAppSelector(offerFilesSelector); // Коммерческое предложение
  const contract: Contract | null = useAppSelector(contractSelector); // Договор
  const offer = useAppSelector(offerSelector); // Согласование КП

  const contract_id = useAppSelector(contractIdSelector); // id договора

  const [openDelete, toggleDelete] = useState<boolean>(false);
  const [file_name, setFile_name] = useState<string>('');
  const [step, setStep] = useState(0);
  const [stage, setStage] = useState(StageOrder.calculated);
  const [isNotFound, setIsNotFound] = useState(false);
  const [stageInitialize, setStageInitialize] = useState(false); // инициализация стадии

  useEffect(() => {
    if (id) {
      dispatch(fetchProposal(id)).then(({ payload }) => {
        if (!payload.success) {
          setIsNotFound(true);
          dispatch(setProposal());
        }
      }); // Заявка
      dispatch(fetchFiles(id)); // Загруженные документы
      dispatch(fetchServices(id)); // Детальное отображение заказа
      dispatch(fetchOffertFiles(id)); // Коммерческое предложение
    }
  }, [id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setCounterparty()); // обнуляем текущего контрагента при размонтировании
      dispatch(setProposal()); // обнуляем текущую заявку при размонтировании
      dispatch(resetOffer()); // Обнуляем текущее согласование КП
      dispatch(resetContractStore()); // Сбрасываем store договора
      dispatch(resetFiles()); // Сбрасываем файлы
    };
  }, [dispatch]);

  // синхронизация стадии
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (proposal?.stage === StageOrder.calculated) {
        setStage(StageOrder.calculated);
        setStep(Step.calculated);
        setStageInitialize(true);
      } else if (proposal?.stage === StageOrder.approval) {
        setStage(StageOrder.approval);
        setStep(Step.approval);
        setStageInitialize(true);
      }
      // Услуга в работе
      else if (
        // contract?.status === ContractStatus.WorkResumed || // Работа возобновлена
        // contract?.status === ContractStatus.WorkOnRequests || // Работа по заявкам
        // contract?.status === ContractStatus.WorkSuspended || // Работа приостановлена
        // contract?.status === ContractStatus.WorkDidNotStart || // Работы не начинались
        contract?.status === null
      ) {
        setStage(StageOrder.in_progress);
        setStep(Step.in_progress);
        setStageInitialize(true);
      }
      // В процессе выполнения
      else if (
        contract?.status !== ContractStatus.RefinementCompleted && // Доработка завершена
        contract?.status !== null &&
        contract?.status !== ContractStatus.JobCompleted // Работа завершена
      ) {
        setStage(StageOrder.completed);
        setStep(Step.completed);
        setStageInitialize(true);
      }
      // Выполнено
      else if (
        contract?.status === ContractStatus.JobCompleted || // Работа завершена
        contract?.status === ContractStatus.RefinementCompleted // Доработка завершена
      ) {
        setStage(StageOrder.completed);
        setStep(Step.allCompleted);
        setStageInitialize(true);
      }
    }, 1000);
    return () => {
      clearTimeout(timeout);
      setStageInitialize(false);
    };
  }, [proposal?.stage, contract?.status]);

  useEffect(() => {
    if (proposal?.counterparty_id !== undefined) {
      dispatch(fetchCounterparty(proposal.counterparty_id)); // Контрагент по id
    }
  }, [proposal?.counterparty_id, dispatch]);

  useEffect(() => {
    if (proposal && proposal.offer_id !== null && proposal.offer_id !== undefined) {
      dispatch(fetchContractByOfferId(proposal.offer_id)); // Договор
      dispatch(fetchOffer(proposal.offer_id)); // Согласование КП
    }
  }, [proposal, proposal?.offer_id, dispatch]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (contract_id) {
        dispatch(fetchResultFiles(contract_id)); // Результаты работ
        dispatch(fetchListStatements(contract_id)); // Поданные заявления в рамках услуги
        dispatch(fetchListProgress(contract_id)); // Прогресс выполнения
        dispatch(fetchContractFiles(contract_id)); // Договорные документы}
      }
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [contract_id, dispatch]);

  const stepperData = [
    {
      tag: 'callKp',
      title: t('view_order.stepper.call_KP'),
    },
    {
      tag: 'pk_agreement',
      title: t('view_order.stepper.PK_agreement'),
    },
    {
      tag: 'service_in_process',
      title: t('view_order.stepper.service_in_process'),
    },
    {
      tag: 'completed',
      title: t('view_order.stepper.completed'),
      complete_title: t('view_order.stepper.full_completed'),
    },
  ];

  const onToggleDelete = (filename: string) => {
    toggleDelete(true);
    setFile_name(filename);
  };

  const onDel = async () => {
    if (id) {
      const res = await deleteFile({ proposal_id: id, data: { file_name } }).unwrap();
      if (res.success) {
        dispatch(setUploadedFiles(res.data.files));
        toggleDelete(false);
      }
    }
  };

  async function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
    // 'jpg' | 'png' | 'pdf' | 'doc' | 'docx' | 'xls' | 'xlsx';
    if (event.target.files) {
      const files = [...event.target.files];
      const fileList = (await Promise.all(
        files.map((f) => {
          return readAsDataURL(f); // файл в base64
        }),
      )) as DocumentBase64[];
      if (id) {
        const res = await uploadFiles({ proposal_id: id, data: [...fileList] }).unwrap(); // Загрузка файлов
        if (res.success) {
          dispatch(setUploadedFiles(res.data.files));
        }
      }
    }
  }

  if (proposal) {
    return (
      <Container padding={180}>
        <div className="mt-[20px] mb-[24px]">
          <Link to={route.orders}>
            <Button label={t('view_order.btn.back')} variant="outlined" icon="ChevronLeft" />
          </Link>
        </div>
        <div className="flex flex-row gap-14">
          <h1 className="text-heading_h2 text-gray_700 font-bold">
            [{proposal?.number}] {proposal?.services && proposal?.services[0]?.address}
          </h1>
        </div>
        {offer?.number && (
          <h3 className="text-paragraph_l text-gray_700  font-bold">
            {' '}
            {t('order_card.table_head.offer')} {offer?.number}
          </h3>
        )}
        {contract?.number && (
          <h3 className="text-paragraph_l text-gray_700 font-bold ">
            {t('order_card.table_head.contract')} {contract?.number}
          </h3>
        )}
        <h4 className="text-gray_400 text-paragraph_s  block mt-[8px] mb-[16px]">
          {moment(proposal.proposal_date).format('DD.MM.YYYY')}
        </h4>
        <div className="flex w-full">
          <section className="w-full">
            {stageInitialize && (
              <Box>
                <div className="rounded-lg overflow-hidden">
                  <div>
                    <Stepper data={stepperData} step={step} />
                  </div>

                  <ServiceProcessBlock stage={stage} />
                </div>
              </Box>
            )}
          </section>
          <section className="w-[287px] ml-[20px]">
            <div className="mb-[19px]">
              <div className="w-full mb-[8px]">
                {/* Договор подписан/не подписан */}
                {contract?.is_sign && (
                  <div className="w-full mb-[8px]">
                    <Info
                      variant="success"
                      content={
                        <span>
                          <a
                            className="text-primary_400 hover:opacity-80 opacity-100"
                            href={contract.contract_file_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {contract.doc_type}
                          </a>{' '}
                          подписан
                        </span>
                      }
                    />
                  </div>
                )}
                {contract && !contract.is_sign && contract.doc_type && (
                  <div className="w-full mb-[8px]">
                    <Info
                      variant="danger"
                      content={
                        <div className="inline">
                          <a
                            className="text-primary_400 hover:opacity-80 opacity-100"
                            href={contract.contract_file_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {contract.doc_type}
                          </a>
                          <span>
                            {' '}
                            не подписан! Мы отправим к вам курьера для подписания{' '}
                            {contract.doc_type?.toLowerCase()}а
                          </span>
                        </div>
                      }
                    />
                  </div>
                )}
                {/* Счет на оплату */}
                {contract?.bills.map((bill: Bill, i: number) => {
                  if (Number(bill.bill_price) - Number(bill.bill_paid) === 0) {
                    return (
                      <div key={i} className="w-full mb-[8px]">
                        <Info
                          variant="success"
                          content={
                            <span>
                              <a
                                className="text-primary_400 hover:opacity-80 opacity-100"
                                href={bill.file_link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Счет {bill.number}
                              </a>{' '}
                              оплачен
                            </span>
                          }
                        />
                      </div>
                    );
                  }
                  return (
                    <div key={i} className="w-full mb-[8px]">
                      <Info
                        variant="danger"
                        content={
                          <span>
                            Оплата услуг по счету:{' '}
                            <span className="truncate break-all">
                              {priceConvertor(bill.bill_paid)} {t('order_card.currency')}
                            </span>{' '}
                            из{' '}
                            <span className="truncate break-all">
                              {priceConvertor(bill.bill_price)} {t('order_card.currency')}
                            </span>
                            <br />
                            <a
                              className="text-primary_400 hover:opacity-80 opacity-100"
                              href={bill.file_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {bill.filename}
                            </a>
                          </span>
                        }
                      />
                    </div>
                  );
                })}
                {/* Акт приема-передачи подписан/не подписан */}
                {contract?.is_sign_acceptance_act && contract?.act !== null && (
                  <div className="w-full mb-[8px]">
                    <Info
                      variant="success"
                      content={
                        <span>
                          <a
                            className="text-primary_400 hover:opacity-80 opacity-100"
                            href={contract.act.file_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Акт приема-передачи
                          </a>{' '}
                          подписан! Услуга предоставлена
                        </span>
                      }
                    />
                  </div>
                )}
                {contract && !contract?.is_sign_acceptance_act && contract?.act !== null && (
                  <div className="w-full mb-[8px]">
                    <Info
                      variant="danger"
                      content={
                        <div className="inline">
                          <a
                            className="text-primary_400 hover:opacity-80 opacity-100"
                            href={contract.act.file_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Акт приема-передачи
                          </a>
                          <span> не подписан! Мы отправим его вам курьером на подпись</span>
                        </div>
                      }
                    />
                  </div>
                )}
              </div>
            </div>
            <Box>
              <div className="p-[16px] pb-[12px]">
                <h3 className="text-gray_600 text-paragraph_l mb-[12px] font-bold">
                  Договорные документы
                </h3>
                <ul>
                  {contractFiles.length > 0 ? (
                    contractFiles.map((f: FilesResponse) => (
                      <li
                        key={f.link}
                        className="px-[8px] py-[4px] items-center hover:bg-gray_100 inline-flex w-full cursor-pointer justify-between"
                      >
                        <div className="inline-flex text-primary_400 text-paragraph_m items-center justify-center h-full">
                          <div className="mr-[12px]">
                            <Icon name="Document" size={20} />
                          </div>
                          <a
                            href={f.link}
                            target="_blank"
                            rel="noreferrer"
                            className=" font-bold pt-[4px]"
                          >
                            {f.filename}
                          </a>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li>
                      <div className="inline-flex text-primary_400 text-paragraph_m items-center justify-center h-full">
                        <div className="flex gap-3.5 text-[#9CA3AF]">
                          <Icon name="Document" size={20} />
                          {t('view_order.contractDocuments.description')}
                        </div>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </Box>
            {offerFile && (
              <div className="mt-[20px]">
                <Box>
                  <div className="p-[16px] pb-[12px]">
                    <h3 className="text-gray_600 text-paragraph_l mb-[12px] font-bold">
                      Коммерческое предложение
                    </h3>
                    <ul>
                      <li className="hover:bg-gray_100 cursor-pointer">
                        <div className="font-bold pt-[4px]">
                          <ul>
                            <li className="px-[8px] py-[4px] items-center hover:bg-gray_100 inline-flex w-full cursor-pointer justify-between">
                              <div className="inline-flex text-primary_400 text-paragraph_m items-center justify-center h-full">
                                <div className="mr-[12px]">
                                  <Icon name="Document" size={20} />
                                </div>
                                <a
                                  href={offerFile.link}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="font-bold pt-[4px]"
                                >
                                  {offerFile.filename}
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Box>
              </div>
            )}
            <div className="mt-[20px]">
              <Box>
                <div className="p-[16px] pb-[12px]">
                  <h3 className="text-gray_600 text-paragraph_l mb-[12px] font-bold">
                    Загружаемые документы
                  </h3>
                  <ul>
                    {uploadedFiles?.length > 0 &&
                      uploadedFiles.map((f: FilesResponse) => (
                        <li
                          key={f.link}
                          className="px-[8px] py-[4px] items-center hover:bg-gray_100 inline-flex w-full cursor-pointer justify-between "
                        >
                          <div className="inline-flex text-primary_400 text-paragraph_m items-center h-full whitespace-normal text-ellipsis overflow-hidden">
                            <div className="mr-[12px]">
                              <Icon name="Document" size={20} />
                            </div>
                            <a
                              href={f.link}
                              target="_blank"
                              rel="noreferrer"
                              className="font-bold pt-[4px]"
                              title={f.filename}
                            >
                              {f.filename}
                            </a>
                          </div>
                          <div className="text-gray_300" onClick={() => onToggleDelete(f.filename)}>
                            <Icon name="Close" size={18} />
                          </div>
                        </li>
                      ))}
                  </ul>
                  <Modal
                    isShow={openDelete}
                    label={t('create_order.modal.delete.label')}
                    type="dialog"
                    icon="Alert"
                    onCancel={() => toggleDelete(false)}
                    onSubmit={onDel}
                  >
                    {t('create_order.modal.delete.children')}
                  </Modal>
                  <div className="mt-[9px]">
                    <FileButton
                      variant="outlined"
                      label="Загрузить документ"
                      space={10}
                      icon="Plus"
                      isFull
                      onChange={handleOnChange}
                    />
                  </div>
                </div>
              </Box>
            </div>
          </section>
        </div>
      </Container>
    );
  } else if (!isNotFound && !proposal) return <></>;
  return <Error404Route />;
};

export default React.memo(ViewOrderPage);
