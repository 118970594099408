import React from 'react';

const TrendingUp = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 24 25">
      <path
        stroke="#60A5FA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13 7.482h8m0 0v8m0-8l-8 8-4-4-6 6"
      ></path>
    </svg>
  );
};

export default TrendingUp;
