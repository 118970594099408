import React from 'react';

const XCircle = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.32072 5.48046L7.32077 5.4804L7.31452 5.47436C7.06934 5.23756 6.74095 5.10653 6.4001 5.10949C6.05924 5.11245 5.73318 5.24917 5.49215 5.4902C5.25112 5.73123 5.1144 6.05729 5.11144 6.39814C5.10848 6.739 5.23951 7.06738 5.47632 7.31256L5.47626 7.31262L5.48241 7.31876L6.16326 7.99961L5.48512 8.67775C5.36259 8.79698 5.26477 8.93922 5.19727 9.09634L5.65668 9.29369L5.19727 9.09634C5.12914 9.25494 5.09328 9.42553 5.09178 9.59814C5.09028 9.77076 5.12317 9.94194 5.18854 10.1017C5.2539 10.2615 5.35043 10.4066 5.47249 10.5287C5.59455 10.6507 5.7397 10.7473 5.89947 10.8126C6.05923 10.878 6.23041 10.9109 6.40303 10.9094C6.57564 10.9079 6.74623 10.872 6.90483 10.8039C7.06194 10.7364 7.20417 10.6386 7.3234 10.5161L8.00156 9.83792L8.68241 10.5188L8.68236 10.5188L8.68861 10.5249C8.93379 10.7617 9.26217 10.8927 9.60303 10.8897C9.94388 10.8868 10.2699 10.75 10.511 10.509C10.752 10.268 10.8887 9.94193 10.8917 9.60107C10.8946 9.26022 10.7636 8.93184 10.5268 8.68665L10.5269 8.6866L10.5207 8.68046L9.83987 7.99961L10.5207 7.31876L10.5208 7.31882L10.5268 7.31256C10.7636 7.06738 10.8946 6.739 10.8917 6.39814C10.8887 6.05729 10.752 5.73123 10.511 5.4902C10.2699 5.24917 9.94388 5.11245 9.60303 5.10949C9.26217 5.10653 8.93379 5.23756 8.68861 5.47436L8.68855 5.47431L8.68241 5.48046L8.00156 6.1613L7.32072 5.48046ZM12.1735 12.1715C11.067 13.278 9.56634 13.8996 8.00156 13.8996C6.43678 13.8996 4.9361 13.278 3.82963 12.1715C2.72317 11.0651 2.10156 9.56439 2.10156 7.99961C2.10156 6.43483 2.72317 4.93414 3.82963 3.82768C4.9361 2.72121 6.43679 2.09961 8.00156 2.09961C9.56634 2.09961 11.067 2.72121 12.1735 3.82768C13.28 4.93414 13.9016 6.43483 13.9016 7.99961C13.9016 9.56439 13.28 11.0651 12.1735 12.1715Z"
        fill="#EF446D"
        stroke="#EF446D"
      />
    </svg>
  );
};

export default XCircle;
