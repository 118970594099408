import { configureStore } from '@reduxjs/toolkit';

import ordersSlices from '@/store/slices/orders.slices';
import commonSlice from '@/store/slices/common.slice';
import authSlice from '@/store/slices/auth.slice';
import counterpartySlice from '@/store/slices/counterparty.slice';
import filesSlice from '@/store/slices/files.slices';
import proposalSlice from '@/store/slices/proposal.slices';
import serviceSlice from '@/store/slices/service.slices';
import chatSlice from '@/store/slices/chat.slice';
import contractSlice from '@/store/slices/contract.slices';
import offerSlice from '@/store/slices/offer.slices';
import egrnSlice from '@/store/slices/egrn.slices';
import affiliateProgramSlice from '@/store/slices/affiliate-program.slice';
import { orderApi } from '@/store/api/order.api';
import { counterpartyApi } from '@/store/api/counterparty.api';
import { profileApi } from '@/store/api/profile.api';
import { authApi } from '@/store/api/auth.api';
import { filesApi } from '@/store/api/files.api';
import { filesArchieveApi } from '@/store/api/files.api';
import { offerApi } from '@/store/api/offer.api';
import { contractApi } from '@/store/api/contract.api';
import { egrnApi } from '@/store/api/egrn.api';
import { affiliateProgramApi } from '@/store/api/affiliate-program.api';
import { chatApi } from '@/store/api/chat.api';

export const store = configureStore({
  reducer: {
    commonStore: commonSlice,
    authStore: authSlice,
    counterpartyStore: counterpartySlice,
    ordersStore: ordersSlices,
    filesStore: filesSlice,
    proposalStore: proposalSlice,
    serviceStore: serviceSlice,
    chatStore: chatSlice,
    contractStore: contractSlice,
    offerStore: offerSlice,
    egrnStore: egrnSlice,
    affiliateProgramStore: affiliateProgramSlice,
    [profileApi.reducerPath]: profileApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [counterpartyApi.reducerPath]: counterpartyApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [filesArchieveApi.reducerPath]: filesArchieveApi.reducer,
    [filesApi.reducerPath]: filesApi.reducer,
    [offerApi.reducerPath]: offerApi.reducer,
    [contractApi.reducerPath]: contractApi.reducer,
    [egrnApi.reducerPath]: egrnApi.reducer,
    [affiliateProgramApi.reducerPath]: affiliateProgramApi.reducer,
    [chatApi.reducerPath]: chatApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      counterpartyApi.middleware,
      orderApi.middleware,
      authApi.middleware,
      filesApi.middleware,
      filesArchieveApi.middleware,
      offerApi.middleware,
      contractApi.middleware,
      egrnApi.middleware,
      affiliateProgramApi.middleware,
      profileApi.middleware,
      chatApi.middleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
