import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EgrnNewOrder } from '@/types/egrn.type';

const initialState = {
  ordersPage: 1,
  newOrder: {
    comment: '',
    items: [],
  },
};

type InitialStateType = { ordersPage: number; newOrder?: EgrnNewOrder };

const egrnSlice = createSlice({
  name: 'egrnSlice',
  initialState,
  reducers: {
    setOrdersPage: (state: InitialStateType, action: PayloadAction<number>) => {
      state.ordersPage = action.payload;
    },
    setNewOrder: (state: InitialStateType, action: PayloadAction<EgrnNewOrder>) => {
      state.newOrder = action.payload;
    },
    resetNewOrder: (state: InitialStateType) => {
      state.newOrder = {
        comment: '',
        items: [],
      };
    },
  },
});

export const { setOrdersPage, setNewOrder, resetNewOrder } = egrnSlice.actions;
export default egrnSlice.reducer;
