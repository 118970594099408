import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { Button, Input } from '@/components/ui';
import TitleLine from '@/components/ui/title-line';
import { useValidation } from '@/hooks/use-validation';
import { route } from '@/util/route';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { changeResendEmail } from '@/store/slices/common.slice';
import { useRecoverMutation } from '@/store/api/auth.api';

type LoginFields = {
  email: string;
};

const ForgotFormBlock = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const validation = useValidation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginFields>();

  const [recover] = useRecoverMutation();

  function handleFormSubmit(data: LoginFields) {
    recover(data);
    dispatch(
      changeResendEmail({
        email: data.email,
        pause: Date.now() + 60 * 60 * 2,
        isResendEmail: true,
      }),
    );
  }

  return (
    <div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <h1 className="text-gray-600 text-center text-heading_h2 mt-[2px]  mb-[12px] font-bold">
          {t('login.restore_access')}
        </h1>
        <p className="text-paragraph_l text-center font-regular text-gray_600">
          {t('login.forgot_description')}
        </p>
        <div className="mt-[16px]">
          <Input
            type="email"
            {...register('email', validation.email)}
            label={t('login.input.email.label')}
            error={errors.email?.message}
            placeholder="email@email.com"
          />
        </div>
        <div className="mt-[24px]">
          <Button type="submit" label={t('login.btn.get_new_password')} isFull />
        </div>
        <div className="my-[6px]">
          <TitleLine title={t('login.info')} />
        </div>
        <div>
          <Link to={route.login}>
            <Button label={t('login.btn.login')} variant="outlined" isFull />
          </Link>
        </div>
      </form>
    </div>
  );
};

export default ForgotFormBlock;
