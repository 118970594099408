import React, { useRef, useState } from 'react';

import { Icon } from '@/components/ui';
import { checkTheSameMessagesClassName } from '@/features/chat/blocks/message/message.style';
import { DocumentBase64 } from '@/components/ui/documents/documents.types';
import { useOnClickOutside } from '@/hooks/use-onclick-outside';

type MessagePropsType = {
  message: string;
  isOnlyMessage: boolean;
  isLastMessage: boolean;
  files: DocumentBase64[];
  isError: boolean;
  removeMessage: () => void;
  onResendMessage: () => void;
};
const FaKemessage = ({
  message,
  isOnlyMessage,
  files,
  isError,
  isLastMessage,
  removeMessage,
  onResendMessage,
}: MessagePropsType) => {
  const [error, setError] = useState(false);
  const userDate = new Date();
  const is_user = true;
  const formattedTime = userDate.toLocaleTimeString('ru-RU', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
  const formattedDate = userDate
    .toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: 'short',
    })
    .replace('.', ',');
  const removeErrorMessage = () => {
    setError(false);
    removeMessage();
  };
  const optionsBlockRef = useRef(null);

  useOnClickOutside(optionsBlockRef, () => {
    setError(false);
  });

  return (
    <div
      className={`flex  ${is_user ? 'flex-row-reverse' : ''} ${
        !isOnlyMessage ? 'mb-1' : 'mb-3'
      } last:mb-1 `}
    >
      <div>
        <div className={checkTheSameMessagesClassName(is_user, isOnlyMessage)}>
          {isError ? (
            <div className=" relative flex justify-end cursor-pointer">
              <Icon name="XCircle" size={16} />
              <div onClick={() => setError(true)}>
                <div className="text-paragraph_s italic font-regular text-negative_500 ">
                  Сообщение не отправлено
                </div>
              </div>
              {error && (
                <div
                  ref={optionsBlockRef}
                  className={`absolute bg-white z-10 w-[194px] py-1 rounded border border-gray_400 shadow-step ${
                    !isLastMessage ? 'top-[20px]' : 'bottom-[20px]'
                  }`}
                >
                  <div
                    onClick={onResendMessage}
                    className="py-2 px-3 text-paragraph_l text-gray_600 font-regular "
                  >
                    Повторить отправку
                  </div>
                  <div
                    onClick={removeErrorMessage}
                    className="py-2 px-3 text-paragraph_l text-gray_600 font-regular bg-gray_100"
                  >
                    Удалить
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              {message && (
                <div
                  className={`text-paragraph_m  font-regular break-all  ${
                    is_user ? 'text-gray_600' : 'text-white'
                  }`}
                >
                  {message}
                </div>
              )}

              {files.length > 0 && (
                <div>
                  {files.map((file, index) => (
                    <div key={index} className="flex px-2 py-2">
                      <div className="pr-3">
                        <Icon name="Document" size={20} className="text-primary_400" />
                      </div>
                      <div className="text-paragraph_m font-medium text-primary_400">
                        {file.file_name}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <div className={`flex ${is_user ? ' justify-end' : ''}`}>
                {is_user && <Icon name="CheckMessage" size={16} />}
                <div
                  className={`text-paragraph_s italic font-regular ${
                    is_user ? 'text-gray_400' : 'text-white'
                  }`}
                >
                  <span>{formattedDate} </span>
                  <span>{formattedTime}</span>
                </div>
              </div>
            </>
          )}

          {/*{!is_user || (is_user && status === 'ok') ? (*/}
          {/*  <div className={`flex ${is_user ? ' justify-end' : ''}`}>*/}
          {/*    {is_user && <Icon name="CheckMessage" size={16} />}*/}
          {/*    <div*/}
          {/*      className={`text-paragraph_s italic font-regular ${*/}
          {/*        is_user ? 'text-gray_400' : 'text-white'*/}
          {/*      }`}*/}
          {/*    >*/}
          {/*      {chat_date}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*) : (*/}
          {/*  <div onClick={showError} className=" relative flex justify-end cursor-pointer">*/}
          {/*    <Icon name="XCircle" size={16} />*/}
          {/*    <div onClick={() => setError(true)}>*/}
          {/*      <div className="text-paragraph_s italic font-regular text-negative_500 ">*/}
          {/*        Сообщение не отправлено*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    {isError && (*/}
          {/*      <div*/}
          {/*        className={`absolute bg-white z-10 w-[194px] py-1 rounded border border-gray_400 shadow-step ${*/}
          {/*          !isLastMessage ? 'top-[20px]' : 'bottom-[20px]'*/}
          {/*        }`}*/}
          {/*      >*/}
          {/*        <div className="py-2 px-3 text-paragraph_l text-gray_600 font-regular ">*/}
          {/*          Повторить отправку*/}
          {/*        </div>*/}
          {/*        <div className="py-2 px-3 text-paragraph_l text-gray_600 font-regular bg-gray_100">*/}
          {/*          Удалить*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    )}*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      </div>
    </div>
  );
};

export default FaKemessage;
