import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiBaseUrl } from '@/util/api';
import { Offer } from '@/types/offer.type';

// Согласование КП по id
export const fetchOffer = createAsyncThunk(
  'offerSlice/fetchOffer',
  async (proposal_id: string | number) => {
    const response = await fetch(`${apiBaseUrl}/offer/${proposal_id}`);
    const data = await response.json();

    return data;
  },
);
// Согласование КП
export const fetchOfferList = createAsyncThunk('offerSlice/fetchOfferList', async () => {
  const response = await fetch(`${apiBaseUrl}/offer/`);
  const data = await response.json();

  return data;
});

const initialState = {
  offer: null as null | Offer, // Согласование КП по id
  offerList: [], // Все согласования КП
};

type InitialStateType = typeof initialState;

const offerSlice = createSlice({
  name: 'offerSlice',
  initialState,
  reducers: {
    resetOffer: (state: InitialStateType) => {
      state.offer = null;
    },
  },
  extraReducers: {
    [fetchOffer.fulfilled as any]: (state, action) => {
      state.offer = action.payload?.data;
    },
    [fetchOfferList.fulfilled as any]: (state, action) => {
      state.offerList = action.payload?.data?.items;
    },
  },
});

export const { resetOffer } = offerSlice.actions;
export default offerSlice.reducer;
