import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ListResponse } from '@/types/responses.type';
import { apiBaseUrl } from '@/util/api';
import { Counterparty, GetCounterpartyParams } from '@/types/counterparty.type';

export const counterpartyApi = createApi({
  reducerPath: 'CounterPartyQuery',
  tagTypes: ['CounterParty'],
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
  endpoints: (build) => ({
    // Контрагенты
    getСounterparties: build.query<ListResponse<Counterparty[]>, GetCounterpartyParams>({
      query: (params) => ({
        url: '/counterparty/',
        params,
      }),
    }),
    // Добавить контрагента
    createСounterparties: build.mutation<ListResponse<Counterparty>, any>({
      query: (data) => ({
        url: '/counterparty/',
        method: 'POST',
        body: data,
      }),
    }),
    // Редактировать контрагента
    updateСounterparty: build.mutation({
      query: (data) => ({
        url: `/counterparty/${data.pk}`,
        method: 'POST',
        body: data.body,
      }),
    }),
    // Контрагент по id
    getСounterparty: build.query<ListResponse<Counterparty>, string>({
      query: (pk: string) => ({
        url: `/counterparty/${pk}`,
      }),
    }),
    getCounterpartyByInn: build.query<ListResponse<Counterparty>, string>({
      query: (inn: string) => ({
        url: `/counterparty/dadata/${inn}`,
      }),
    }),
  }),
});

export const {
  useGetСounterpartyQuery,
  useGetСounterpartiesQuery,
  useLazyGetСounterpartiesQuery,
  useCreateСounterpartiesMutation,
  useUpdateСounterpartyMutation,
  useLazyGetCounterpartyByInnQuery,
} = counterpartyApi;
