import React from 'react';
import clsx from 'clsx';

export type Props = React.HTMLProps<HTMLAnchorElement> & { active?: boolean };

export default function PageLink({ className, active, disabled, children, ...otherProps }: Props) {
  const customStyles = {
    backgroundColor: '#ffffff',
    padding: '10px 16px',
    color: '#4B5563',
    fontSize: '16px',
    fontWeight: 500,
    textDecoration: 'none',
    transition:
      'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out',
    cursor: 'pointer',
  };

  const customClassName = clsx(className, {
    'page-link': true,
    'bg-primary_400 text-white': active,
    disabled,
  });

  if (disabled) {
    return (
      <span style={customStyles} className={customClassName}>
        {children}
      </span>
    );
  }

  return (
    <a
      style={customStyles}
      className={customClassName}
      aria-current={active ? 'page' : undefined}
      {...otherProps}
    >
      {children}
    </a>
  );
}
