import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Counterparty } from '@/types/counterparty.type';
import { apiBaseUrl } from '@/util/api';

// Проверка контрагента при его создании, получение имени контрагента по ИНН со стороннего ресурса
export const fetchCounterpartyByInn = createAsyncThunk(
  'counterpartySlice/fetchCounterpartyByInn',
  async (inn: string) => {
    const response = await fetch(`${apiBaseUrl}/counterparty/dadata/${inn}`);

    const data = await response.json();

    return data;
  },
);

// Проверка контрагента при его создании, получение банковских данных контрагента по БИК со стороннего ресурса
export const fetchCounterpartyByBik = createAsyncThunk(
  'counterpartySlice/fetchCounterpartyByBik',
  async (bik: string) => {
    const response = await fetch(`${apiBaseUrl}/bank/dadata/${bik}`);

    const data = await response.json();

    return data;
  },
);

// Все контрагенты
export const fetchCounterparties = createAsyncThunk(
  'counterpartySlice/fetchCounterparties',
  async (params?: { search?: string; page?: number; limit?: number; my_referral?: boolean }) => {
    let url = `${apiBaseUrl}/counterparty/`;
    if (params) {
      url += '?';
      Object.entries(params).forEach(([key, value], index) => {
        if (value) {
          url += `${key}=${value}${index !== Object.entries(params).length - 1 ? '&' : ''}`;
        }
      });
    }
    const response = await fetch(url);
    const data = await response.json();

    return data;
  },
);

// Контрагент по id
export const fetchCounterparty = createAsyncThunk(
  'counterpartySlice/fetchCounterparty',
  async (pk: string | number) => {
    const response = await fetch(`${apiBaseUrl}/counterparty/${pk}`);
    const data = await response.json();

    return data;
  },
);

const initialState = {
  counterparties: [] as Counterparty[], // Все контрагенты
  page: 1,
  totalCounterpartiesCount: 0,
  counterparty: {} as Counterparty, // Контрагент по id
  isLoading: false,
  error: '',
};

type InitialStateType = typeof initialState;

const counterpartySlice = createSlice({
  name: 'counterpartySlice',
  initialState,
  reducers: {
    setCounterparties: (state: InitialStateType, action: PayloadAction<Counterparty[]>) => {
      state.counterparties = action.payload;
    },
    addCounterparty: (state: InitialStateType, action: PayloadAction<Counterparty>) => {
      state.counterparties.push(action.payload);
    },
    setCounterparty: (state: InitialStateType) => {
      state.counterparty = {} as Counterparty;
    },
    changeCounterpatyItem: (state: InitialStateType, action: PayloadAction<Counterparty>) => {
      const index = state.counterparties.findIndex((item) => item.id === action.payload.id);
      state.counterparties.splice(index, 1, action.payload);
    },
  },
  extraReducers: {
    [fetchCounterparty.fulfilled as any]: (state, action) => {
      state.counterparty = action.payload.data;
    },
    [fetchCounterparties.pending as any]: (state) => {
      state.isLoading = true;
    },
    [fetchCounterparties.fulfilled as any]: (state, action) => {
      state.isLoading = false;
      state.counterparties = action.payload.data?.items || [];
      state.totalCounterpartiesCount = action.payload.data?.total || 0;
      state.page = action.payload.data?.page || 0;
    },
  },
});

export const { setCounterparties, addCounterparty, setCounterparty, changeCounterpatyItem } =
  counterpartySlice.actions;
export default counterpartySlice.reducer;
