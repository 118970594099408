import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { apiBaseUrl } from '@/util/api';

export const offerApi = createApi({
  reducerPath: 'OfferQuery',
  tagTypes: ['Offers'],
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
  endpoints: (build) => ({
    getOffers: build.query({
      query: () => ({
        url: `/offer`,
      }),
    }),
    getOffer: build.query({
      query: (proposal_id) => ({
        url: `/offer/${proposal_id}`,
      }),
    }),
    // Принять/Отклонить КП
    updateOfferAcceptance: build.mutation({
      query: ({ pk, data }) => ({
        url: `/offer/${pk}/acceptance`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useGetOfferQuery, useGetOffersQuery, useUpdateOfferAcceptanceMutation } = offerApi;
