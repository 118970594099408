import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { apiBaseUrl } from '@/util/api';
import { ListResponse } from '@/types/responses.type';
import {
  ApiFields,
  EstateType,
  Service,
  ServiceType,
  getFieldsParams,
  getServiceTypeListParams,
  getServicesParams,
} from '@/types/types';
import {
  CreateProposalParams,
  Proposal,
  ProposalListData,
  getProposalsParams,
} from '@/types/proposal.type';

export const orderApi = createApi({
  reducerPath: 'OrderQuery',
  tagTypes: ['Order'],
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
  endpoints: (build) => ({
    // Типы объекта недвижимости
    getEstateTypes: build.query<ListResponse<EstateType>, null>({
      query: () => ({
        url: '/service/real_estate_type/',
      }),
    }),
    // Cписок типов услуг
    getServiceTypeList: build.query<ListResponse<ServiceType>, getServiceTypeListParams>({
      query: (params) => ({
        url: `/service/service_type/`,
        params,
      }),
    }),
    // поля
    getFields: build.query<ListResponse<ApiFields[]>, getFieldsParams>({
      query: (params) => ({
        url: `/service/fields_type/`,
        params,
      }),
    }),
    getServices: build.query<ListResponse<Service>, getServicesParams>({
      query: (params) => ({
        url: `/service/`,
        params,
      }),
    }),
    getService: build.query<ListResponse<Service>, number>({
      query: (pk) => ({
        url: `/service/${pk}`,
      }),
    }),
    getProposals: build.query<ListResponse<ProposalListData>, getProposalsParams>({
      query: (params) => ({
        url: '/proposal/',
        params,
      }),
    }),
    createProposal: build.mutation<ListResponse<Proposal>, CreateProposalParams>({
      query: ({ body, params }) => ({
        url: `/proposal/`,
        method: 'POST',
        body,
        params,
      }),
    }),
    callMe: build.mutation<ListResponse<string>, null>({
      query: () => ({
        url: '/proposal/callme',
        method: 'POST',
      }),
    }),
    getServiceProposal: build.query<ListResponse<Proposal>, number>({
      query: (pk) => ({
        url: `/proposal/${pk}`,
      }),
    }),
  }),
});

export const {
  useGetEstateTypesQuery,
  useGetServiceTypeListQuery,
  useGetServicesQuery,
  useGetServiceQuery,
  useGetProposalsQuery,
  useCreateProposalMutation,
  useGetServiceProposalQuery,
  useGetFieldsQuery,
  useCallMeMutation,
} = orderApi;
