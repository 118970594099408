import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useForm } from 'react-hook-form';

import { Button, Icon, Input } from '@/components/ui';
// import { route } from '@/util/route';
import { useResendRestoreEmail } from '@/features/feature-hooks/use-resend-restore-email';
import { ConfirmFields } from '@/types/auth.type';
import { useConfirmMutation, useRecoverMutation } from '@/store/api/auth.api';
import { useAppSelector } from '@/hooks/use-app-selector';
import { changeResendEmail } from '@/store/slices/common.slice';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { setUser } from '@/store/slices/auth.slice';

const ForgotSendBlock = () => {
  const { t } = useTranslation();
  const resendEmail = useResendRestoreEmail();
  const email: string = useAppSelector((state) => state.commonStore.resendEmail);
  const { register, handleSubmit } = useForm<ConfirmFields>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState<string>('');

  const [confirm] = useConfirmMutation();
  const [recover] = useRecoverMutation();

  const onConfirm = async (data: ConfirmFields) => {
    const returned: any = await confirm({ email, code: data.code });
    if (returned?.data?.success) {
      setError('');
      const { token, me } = returned.data.data;
      dispatch(changeResendEmail({ email: '', pause: 0, isResendEmail: false }));
      dispatch(setUser({ token, me }));
      navigate('/');
    } else {
      setError(returned?.error.data.error);
    }
  };

  const onResendEmail = async () => {
    dispatch(
      changeResendEmail({
        email: email,
        pause: Date.now() + 60 * 60 * 2,
        isResendEmail: true,
      }),
    );
    const variantNotification = resendEmail();
    if (variantNotification === 'success') {
      await recover({ email });
    }
  };
  useEffect(() => {
    return () => {
      dispatch(
        changeResendEmail({
          email: '',
          pause: 0,
          isResendEmail: false,
        }),
      );
    };
  }, [dispatch]);

  return (
    <div>
      <div className="w-full inline-flex justify-center">
        <Icon name="EmailBig" className="text-primary_400" size={100} />
      </div>
      <h1 className="text-gray-600 text-center text-heading_h2 mt-[2px]  mb-[12px] font-bold">
        {t('login.forgot_is_send')}
      </h1>
      <p className="text-paragraph_l text-center font-regular text-gray_600">
        <Trans
          i18nKey="login.forgot_is_send_description"
          values={{ minutes: 2, email: email, timeout: 15 }}
        />
      </p>
      <div className="mt-[24px]">
        <form onSubmit={handleSubmit(onConfirm)}>
          <div className="mt-[12px]">
            <Input
              type="text"
              {...register('code')}
              placeholder={t('common.input.code.placeholder')}
            />
          </div>
          <div className="m-[5px]" style={{ color: 'red', fontSize: '14px' }}>
            {error && <span>{error}</span>}
          </div>
          <div className="mt-[12px]">
            <Button type="submit" label={t('registration.btn.confirm')} isFull />
          </div>
        </form>
      </div>
      <div className="mt-[16px]">
        <Button
          label={t('login.btn.repeat_new_password')}
          onClick={onResendEmail}
          variant="outlined"
          isFull
        />
      </div>
    </div>
  );
};

export default ForgotSendBlock;
