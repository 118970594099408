import React, { FC, ReactNode } from 'react';

import { Icon } from '@/components/ui';
import {
  documentsClassName,
  documentWrapperClassName,
} from '@/components/ui/documents/documents.styles';
import { DocumentItem, DocumentState } from '@/components/ui/documents/documents.types';

const Documents: FC<DocumentItem> = (props) => {
  const { document, state = 'loaded', onRemove, onClick } = props;

  const iconStateList: Record<DocumentState, ReactNode> = {
    loading: <Icon name="Load" size={20} />,
    fail: <Icon name="Alert" size={20} className="text-negative_500" />,
    success: <Icon name="Document" size={20} className="text-primary_400" />,
  };
  return (
    <div className={documentWrapperClassName}>
      <div className="flex truncate justify-start items-center" onClick={onClick?.bind(null)}>
        <div className="mr-[12px] block">{iconStateList[state as DocumentState]}</div>
        <div className={documentsClassName({ isFail: state === 'fail' })}>{document.file_name}</div>
      </div>
      <div onClick={onRemove?.bind(null)}>
        <Icon name="Close" className="text-gray_300 hover:text-gray_400 cursor-pointer" size={20} />
      </div>
    </div>
  );
};

export default Documents;
