import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import moment from 'moment/moment';

import OrderInfo from './order-info';

import { Info, Icon } from '@/components/ui';
import { Acceptance, OfferStatus } from '@/types/offer.type';
import { StageOrder } from '@/types/order.type';
import { useAppSelector } from '@/hooks/use-app-selector';
import { offerFilesSelector } from '@/store/selectors/files.selectors';
import { File } from '@/types/file.type';
import { Proposal } from '@/types/proposal.type';
import { proposalSelector } from '@/store/selectors/proposal.selectors';
import { dateConvertorWithoutWeekends } from '@/util/helpers';

type OrderInfoProps = {
  status: OfferStatus | undefined;
  acceptance: Acceptance | undefined;
  stage?: StageOrder;
};

const OrderInfoKP: FC<OrderInfoProps> = (props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { status, acceptance, stage } = props;

  const offerFile: File | null = useAppSelector(offerFilesSelector); // Коммерческое предложение
  const proposal: Proposal | null = useAppSelector(proposalSelector); // Заявка

  const date = moment(`${proposal?.proposal_date} ${proposal?.proposal_time}`); // дата создания КП из API
  const dayWeek = moment(date).isoWeekday(); // день недели

  const dateCreated = date
    .add(dateConvertorWithoutWeekends(dayWeek), 'day')
    .format('DD.MM.YYYY до HH:mm'); // дата создания КП с учетом выходных

  return (
    <div>
      {/* если статус "Отправлено", а acceptance=null или 
      если статус "Отправлено", а acceptance="None" */}
      {((status === OfferStatus.sended && acceptance === null) ||
        (status === OfferStatus.sended && acceptance === Acceptance.none)) && (
        <>
          <OrderInfo offer_id={proposal?.offer_id} id={id} />
          <div className="ml-[16px] mr-[16px] mb-[8px] mt-[4px]">
            <div className="flex text-[#9CA3AF]">{t('view_order.descriptions.agreement')}</div>
          </div>
        </>
      )}
      {/* если стадия запрос КП*/}
      {stage === StageOrder.calculated && (
        <div>
          <Info
            variant="success"
            content={
              <span>
                Ваша заявка на КП принята. Ориентировочная дата создания КП: {dateCreated}
              </span>
            }
          />
        </div>
      )}
      {/* если статус "Предварительное согласие", а acceptance="accepted" или если
      статус "Отправлено", а acceptance="accepted" */}
      {((status === OfferStatus.sended && acceptance === Acceptance.accepted) ||
        (status === OfferStatus.priorConsent && acceptance === Acceptance.accepted)) && (
        <div className="border w-full border-positive_500 rounded bg-positive_50 inline-flex items-center py-[10px] px-[9px] justify-between">
          <div className="inline-flex items-center text-primary_400">
            <div className="mr-[8px] text-positive_500">
              <Icon name="Document" size={20} />
            </div>
            {offerFile && (
              <a
                key={offerFile?.filename}
                href={offerFile?.link}
                className="hover:underline mr-[4px]"
                target="_blank"
                rel="noreferrer"
              >
                {offerFile?.filename}
              </a>
            )}
          </div>
          <div className="inline-flex">
            <span className="text-gray_600">КП принято.</span>
          </div>
        </div>
      )}
      {/* если статус "Провал", а acceptance="rejected" или если 
      статус "Отправлено", а acceptance="rejected"  */}
      {((status === OfferStatus.failure && acceptance === Acceptance.rejected) ||
        (status === OfferStatus.sended && acceptance === Acceptance.rejected)) && (
        <div className="border w-full border-yellow_500 rounded bg-yellow_100 inline-flex items-center py-[10px] px-[9px] justify-between">
          <div className="inline-flex items-center text-primary_400">
            <div className="mr-[8px] text-yellow_500">
              <Icon name="Document" size={20} />
            </div>
            {offerFile && (
              <a
                key={offerFile?.filename}
                href={offerFile?.link}
                className="hover:underline mr-[4px]"
                target="_blank"
                rel="noreferrer"
              >
                {offerFile?.filename}
              </a>
            )}
          </div>
          <div className="inline-flex">
            <span className="text-gray_600">КП отклонено.</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(OrderInfoKP);
