import React from 'react';

const Sbp = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2663_11415)">
        <path
          d="M0.5 5.22266L3.35776 10.4177V13.5865L0.503343 18.7713L0.5 5.22266Z"
          fill="#5B57A2"
        />
        <path
          d="M11.4727 8.52987L14.1505 6.86067L19.6308 6.85547L11.4727 11.9383V8.52987Z"
          fill="#D90751"
        />
        <path
          d="M11.4575 5.1934L11.4726 12.0714L8.60815 10.2814V0L11.4577 5.1934H11.4575Z"
          fill="#FAB718"
        />
        <path
          d="M19.6307 6.8542L14.1502 6.8594L11.4575 5.1934L8.60815 0L19.6305 6.8542H19.6307Z"
          fill="#ED6F26"
        />
        <path
          d="M11.4726 18.8V15.463L8.60815 13.707L8.60973 23.9986L11.4726 18.8Z"
          fill="#63B22F"
        />
        <path
          d="M14.1438 17.1461L3.35756 10.4177L0.5 5.22266L19.6192 17.1393L14.1436 17.1461H14.1438Z"
          fill="#1487C9"
        />
        <path
          d="M8.60986 24L11.4723 18.8014L14.1435 17.1474L19.6189 17.1406L8.60986 24Z"
          fill="#017F36"
        />
        <path
          d="M0.503418 18.7731L8.63167 13.7091L5.89898 12.0039L3.35783 13.5883L0.503418 18.7731Z"
          fill="#984995"
        />
      </g>
      <defs>
        <clipPath id="clip0_2663_11415">
          <rect width="52" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Sbp;
