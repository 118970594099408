import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FieldValues, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import { useNavigate } from 'react-router-dom';

import { Button, Select } from '@/components/ui';
import { SelectSchema } from '@/components/ui/select/select.types';
import {
  estateTypesSelector,
  isDisabledServiceTypeListSelector,
  isSelectedServiceTypeListSelector,
  serviceTypeListSelector,
} from '@/store/selectors/orders.selectors';
import {
  fetchEstateTypes,
  fetchFields,
  fetchServiceTypeList,
  resetField,
  resetFields,
  setError,
  setIsSelectedServiceTypeList,
  toggleIsDisabledServiceTypeList,
} from '@/store/slices/orders.slices';
import { route } from '@/util/route';
import { useCreateProposalMutation } from '@/store/api/order.api';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { useAppSelector } from '@/hooks/use-app-selector';
import { EstateType, Service } from '@/types/types';
import { Counterparty } from '@/types/counterparty.type';
import SurveyingForm from '@/components/service-forms/surveying-form';
import { DocumentBase64 } from '@/components/ui/documents/documents.types';
import { useUploadFilesMutation } from '@/store/api/files.api';
import { useNotification } from '@/hooks/use-notifications';
import { ServiceTypeList } from '@/types/order.type';
import { CreateProposalBody } from '@/types/proposal.type';

type PropsType = {
  onSubmitKP?: () => void;
  selectedCounterParty?: Counterparty;
  toggleCancel: Dispatch<SetStateAction<boolean>>;
};

const BlankForm: React.FC<PropsType> = (props) => {
  const { selectedCounterParty, onSubmitKP, toggleCancel } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    register,
    control,
    getValues,
    setValue,
    handleSubmit,
    watch,
    reset,
    resetField: resetFieldForm,
  } = useForm<FieldValues>();
  const { append, fields, remove } = useFieldArray({
    control,
    name: 'services',
  });
  const { append: appendFile } = useFieldArray({
    control,
    name: 'files',
  });

  const { createNotification } = useNotification();

  const [createProposal, { isLoading }] = useCreateProposalMutation(); // Запросить КП
  const [uploadFiles] = useUploadFilesMutation(); // Загрузить файлы

  const [isCallMe, setIsCallMe] = useState(true);

  useEffect(() => {
    dispatch(setIsSelectedServiceTypeList({ index: 0, selected: false }));
    dispatch(toggleIsDisabledServiceTypeList(true));
    reset();
    remove();
    selectedCounterParty && append({});
    setValue(`services.0.real_estate_type_id`, '' as unknown);
    setValue(`services.0.service_type_id`, '' as string);
  }, [append, dispatch, selectedCounterParty, reset, remove, setValue]);

  const error: string = useAppSelector((state) => state.ordersStore.error); // ошибка
  const serviceTypeList: ServiceTypeList = useAppSelector(serviceTypeListSelector); // типы услуг
  const estateTypes: EstateType[] = useAppSelector(estateTypesSelector); // типы недвижимости
  const isDisabledServiceTypeList: boolean = useAppSelector(isDisabledServiceTypeListSelector);
  const isSelectedServiceTypeList = useAppSelector(isSelectedServiceTypeListSelector);

  useEffect(() => {
    dispatch(fetchEstateTypes()); // получить типы недвижимости
    return () => {
      dispatch(toggleIsDisabledServiceTypeList(true));
      dispatch(setIsSelectedServiceTypeList({ index: 0, selected: false }));
    };
  }, [dispatch]);

  const changeSelectEstateType = async (item: SelectSchema, index: number) => {
    setValue(`services.${index}.real_estate_type_id`, item.id as number);
    setValue(`services.${index}.real_estate_type_name`, item.name as string);
    setValue(`services.${index}.unit_type`, 'square meter' as string);
    setValue(`services.${index}.service_type_name`, '' as string);
    setValue(`services.${index}.service_type_id`, '' as string);
    setValue(`files.${index}.files`, []);
    dispatch(resetField({ [index]: [] }));
    await dispatch(fetchServiceTypeList({ id: item.id, index })); // типы услуги
  };

  const changeSelectServiceTypeList = async (item: SelectSchema, index: number) => {
    setValue(`services.${index}.service_type_id`, item.id as number);
    setValue(`services.${index}.service_type_name`, item.name as string);
    dispatch(setIsSelectedServiceTypeList({ index, selected: true }));
    // сбрасываем все поля:
    resetFieldForm(`services.${index}.count_point`);
    resetFieldForm(`services.${index}.output_count`);
    resetFieldForm(`services.${index}.input_count`);
    resetFieldForm(`services.${index}.until_area`);
    resetFieldForm(`services.${index}.after_area`);
    resetFieldForm(`services.${index}.entire_area`);
    resetFieldForm(`services.${index}.count_parking_spaces`);
    resetFieldForm(`services.${index}.cadastral_number`);
    resetFieldForm(`services.${index}.address`);

    await dispatch(fetchFields({ service_type_id: item.id, index })); // поля
  };

  // Добавить объект работы
  async function handlerCopyFields() {
    const newIndex = watch().services.length; // новый индекс
    const currentServices = watch().services[watch().services.length - 1]; // текущий массив
    // получаем типы услуги для следующей формы:
    await dispatch(
      fetchServiceTypeList({
        id: currentServices.real_estate_type_id,
        index: newIndex,
      }),
    );
    // получаем поля для следующей формы:
    await dispatch(
      fetchFields({
        service_type_id: currentServices.service_type_id,
        index: newIndex,
      }),
    );
    dispatch(setIsSelectedServiceTypeList({ index: newIndex, selected: true }));
    // берем данные для формы из предыдущей формы:
    append(getValues().services[watch().services.length - 1]);
    appendFile(getValues().files[watch().files.length - 1]);
  }

  if (!selectedCounterParty) return <></>;

  const renderErrorsOnFields = error && (
    <div className="m-[5px]" style={{ color: 'red', fontSize: '14px' }}>
      {error}
    </div>
  );

  //Запросить КП
  const handlerSubmitKPForm = async (formData: FieldValues) => {
    console.table({ ...formData, counterparty_id: selectedCounterParty.id });

    onSubmitKP?.();
    const body: CreateProposalBody = {
      services: [...formData.services],
      counterparty_id: +selectedCounterParty?.id,
    };
    const call_me = isCallMe;
    const params = { call_me };
    const result: any = await createProposal({ body, params }); // Создание заявки
    // если заявка создалась
    if (result?.data?.success) {
      dispatch(setError(''));
      // проверяем есть ли загруженные файлы
      result.data?.data?.services.map(async (service: Service, index: number) => {
        if (watch().files[index].files) {
          const proposal_id = service.proposal_id;
          await uploadFiles({ proposal_id, data: watch().files[index].files }); // Загрузка файлов
          dispatch(setIsSelectedServiceTypeList({ index, selected: false })); // тип услуги не выбран
        }
      });
      navigate(route.orders); // перенаправляем на главную страницу
      setValue('services', []);
      setValue('files', []);
      dispatch(resetFields());
      setTimeout(() => {
        createNotification?.({
          variant: 'success',
          title: t('create_order.notification.success.title'),
          description: t('create_order.notification.success.description'),
        });
      }, 500); // показываем уведомление
    } else if (result?.error?.data?.error) {
      dispatch(setError(result?.error?.data?.error));
    }
  };

  function handleAddFile(files: Array<DocumentBase64>, indexForm: number) {
    setValue(`files.${indexForm}.files`, files);
  }

  function onChangeCheckbox(e: CheckboxChangeEvent) {
    setIsCallMe(e.target.checked);
  }

  return (
    <form onSubmit={handleSubmit(handlerSubmitKPForm)}>
      {fields.map((field, index) => (
        <div key={field.id} className="mb-[24px]">
          <div className="bg-white rounded border border-gray_200 px-[24px] pb-[24px] pt-[18px]">
            <h1 className="text-gray_600 text-heading_h3 mb-4 font-bold">
              {t('work_object.title')} {index + 1 > 1 ? index + 1 : ''}
            </h1>
            <div className="inline-flex flex-col md:flex-row w-full gap-[16px] flex-wrap">
              <div className="basis-[49.28%] min-w-[49.28%]">
                <Select
                  schema={{ title: 'name', value: 'id' }}
                  data={estateTypes}
                  initialValue={
                    getValues()?.services[index]?.real_estate_type_name ||
                    getValues()?.services[0]?.real_estate_type_name
                  }
                  activeValue={
                    getValues()?.services[index]?.real_estate_type_name ||
                    getValues()?.services[0]?.real_estate_type_name
                  }
                  {...register(`services.${index}.real_estate_type_id`)}
                  onSelectOption={(option) => changeSelectEstateType(option, index)}
                  label={t('work_object.input.property_type.label') + '*'}
                  placeholder={t('work_object.input.property_type.placeholder')}
                  withSearch
                />
              </div>
              <div
                className="ml-0 mt-[10px] md:mt-0 basis-[49.28%] min-w-[49.28%]"
                title={getValues()?.services[index]?.service_type_name}
              >
                <Select
                  schema={{ title: 'name', value: 'id' }}
                  data={serviceTypeList[index]}
                  initialValue={getValues()?.services[index]?.service_type_name || ''}
                  activeValue={getValues()?.services[index]?.service_type_name || ''}
                  {...register(`services.${index}.service_type_id`, { required: true })}
                  onSelectOption={(option) => changeSelectServiceTypeList(option, index)}
                  label={t('work_object.input.service.label') + '*'}
                  placeholder={t('work_object.input.service.placeholder')}
                  disabled={isDisabledServiceTypeList}
                  withReset
                  reset={() => {
                    setValue(`services.${index}.service_type_id`, '');
                    setValue(`services.${index}.service_type_name`, '');
                    dispatch(setIsSelectedServiceTypeList({ index, selected: false }));
                  }}
                  withSearch
                />
              </div>
            </div>
            {isSelectedServiceTypeList[index] && (
              <SurveyingForm
                key={field.id}
                defaultValues={getValues()?.services[index === 0 ? 0 : index - 1]}
                documents={watch().files[index]?.files || []}
                setDocuments={(files) => handleAddFile(files, index)}
                register={register}
                indexForm={index}
              />
            )}
          </div>
        </div>
      ))}
      <div className="mb-8">
        <Checkbox
          className="text-gray_600 text-paragraph_l"
          checked={isCallMe}
          onChange={onChangeCheckbox}
          disabled={isLoading}
        >
          {t('create_order.checkbox.label')}
        </Checkbox>
      </div>
      <div className="w-full mb-[24px] inline-flex justify-between pr-[10px]">
        <div>
          <Button
            variant="outlined"
            label={t('create_order.btn.cancel')}
            onClick={() => toggleCancel(true)}
          />
        </div>
        <div className="inline-flex">
          <div>
            <Button
              variant="outlined"
              label={t('create_order.btn.add_new_works')}
              icon="Plus"
              space={16}
              onClick={handlerCopyFields}
            />
            {renderErrorsOnFields}
          </div>

          <div className="ml-[17px]">
            <Button
              type="submit"
              variant="filled"
              space={15}
              label={t('create_order.btn.call_kp')}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default React.memo(BlankForm);
