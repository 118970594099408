import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { apiBaseUrl } from '@/util/api';
import { ListResponse, PaginationParams, ListResponsePagination } from '@/types/responses.type';
import { Counterparty } from '@/types/counterparty.type';
import {
  ReferralCount,
  SetPartnerRequestBody,
  TransactionsItems,
} from '@/types/affiliate-program.type';

export const affiliateProgramApi = createApi({
  reducerPath: 'AffiliateProgramQuery',
  tagTypes: ['AffiliateProgram'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
  }),
  endpoints: (build) => ({
    getTransactions: build.query<ListResponsePagination<TransactionsItems[]>, PaginationParams>({
      query: (params) => ({
        url: `/referral/transactions`,
        params,
      }),
    }),
    getPartnerCounterparties: build.query<ListResponse<Counterparty>, unknown>({
      query: () => ({
        url: `/referral/counterparty`,
      }),
    }),
    setPartnerCounterparties: build.mutation<ListResponse<Counterparty>, SetPartnerRequestBody>({
      query: (body) => ({
        url: `/referral/counterparty`,
        method: 'POST',
        body,
      }),
    }),
    getReferralsCount: build.query<ListResponse<ReferralCount>, unknown>({
      query: () => ({
        url: `/referral/referrals_count`,
      }),
    }),
  }),
});

export const {
  useLazyGetTransactionsQuery,
  useGetPartnerCounterpartiesQuery,
  useGetReferralsCountQuery,
  useSetPartnerCounterpartiesMutation,
} = affiliateProgramApi;
