import React from 'react';

const Load = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      className="animate-spin"
      viewBox="0 0 20 20"
    >
      <path
        fill="#60A5FA"
        fillRule="evenodd"
        d="M10.449 16.069h-.002A6.085 6.085 0 1116.084 10H18a8.003 8.003 0 00-5.075-7.446 8 8 0 10-2.335 15.424l-.141-1.91z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Load;
