import React, { FC } from 'react';
import clsx from 'clsx';

import Icon from '@/components/ui/icon';
import { IconName } from '@/components/ui/icon/icons.type';

const labelClassName = () =>
  clsx('inline-flex', 'px-2', 'py-[2px]', 'rounded-2xl', 'text-paragraph_m', 'align-middle');

type LabelProps = {
  textColor: string;
  bgColor: string;
  text: string;
  icon?: IconName;
};

const Label: FC<LabelProps> = (props) => {
  const { textColor, bgColor, text, icon } = props;

  return (
    <div style={{ background: `${bgColor}`, color: `${textColor}` }} className={labelClassName()}>
      {text}
      {icon ? <Icon name={icon} size={14} className="ml-2 pt-[6px]" /> : null}
    </div>
  );
};

export default Label;
