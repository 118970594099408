import React from 'react';

const Calculator = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7.5 5.833h5m0 8.334v-2.5m-2.5 2.5h.008m-2.508 0h.008m-.008-2.5h.008m2.492 0h.008m2.492-2.5h.008m-2.508 0h.008m-2.508 0h.008M5.833 17.5h8.334a1.666 1.666 0 001.666-1.667V4.167A1.667 1.667 0 0014.167 2.5H5.833a1.667 1.667 0 00-1.666 1.667v11.666A1.666 1.666 0 005.833 17.5z"
      ></path>
    </svg>
  );
};

export default Calculator;
