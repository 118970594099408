import React from 'react';

const CreditCard = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg width={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 10.5H21M7 15.5H8M12 15.5H13M6 19.5H18C18.7956 19.5 19.5587 19.1839 20.1213 18.6213C20.6839 18.0587 21 17.2956 21 16.5V8.5C21 7.70435 20.6839 6.94129 20.1213 6.37868C19.5587 5.81607 18.7956 5.5 18 5.5H6C5.20435 5.5 4.44129 5.81607 3.87868 6.37868C3.31607 6.94129 3 7.70435 3 8.5V16.5C3 17.2956 3.31607 18.0587 3.87868 18.6213C4.44129 19.1839 5.20435 19.5 6 19.5Z"
        stroke="#60A5FA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CreditCard;
