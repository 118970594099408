import React from 'react';
import { useTranslation } from 'react-i18next';

import { textPkDescriptionClassName, textPkTitleClassName } from './kp.styles';

import { TableItem } from '@/components/ui/table/table.types';
import { Table } from '@/components/ui';
import { useTablesHeader } from '@/features/feature-hooks/use-tables-header';
import { Counterparty } from '@/types/counterparty.type';
import { useAppSelector } from '@/hooks/use-app-selector';
import { counterpartyByIdSelector } from '@/store/selectors/counterparty.selectors';
import { Service } from '@/types/types';
import { servicesSelector } from '@/store/selectors/service.selectors';

const ServicesTable: React.FC = () => {
  const { t } = useTranslation();
  const tableHead = useTablesHeader();

  const counterparty: Counterparty = useAppSelector(counterpartyByIdSelector); // Контрагенты
  const services: Service[] = useAppSelector(servicesSelector) || []; // Детальное отображение заказа

  const tableCallPKData: Array<TableItem> = services.map((el: Service) => ({
    id: el.id,
    real_estate_type: el.real_estate_type.name,
    document_type: el.service_type.document_type,
    service_type: el.service_type.name,
    address: el.address,
    count: el.real_estate.input_count,
  }));

  if (counterparty && tableCallPKData) {
    return (
      <>
        <div className=" mb-[12px]">
          <div className={textPkTitleClassName}>{t('view_order.counterparty')}</div>
          <div className={textPkDescriptionClassName}>
            {counterparty?.counterparty_type === 'ФЛ'
              ? counterparty.individual_data?.first_name &&
                counterparty.individual_data?.passport_series &&
                counterparty.individual_data?.passport_number &&
                `${counterparty.individual_data?.surname} ${
                  counterparty.individual_data?.first_name
                } ${counterparty.individual_data?.middle_name} ${t('create_order.passport')}: ${
                  counterparty?.individual_data?.passport_series
                } ${counterparty?.individual_data?.passport_number}`
              : counterparty.legal_data?.name &&
                counterparty.legal_data?.inn &&
                `${counterparty.legal_data?.name} ${t('create_order.inn')}: ${
                  counterparty.legal_data?.inn
                }`}
          </div>
        </div>
        {services.length ? (
          <div className={textPkDescriptionClassName}>
            <div>
              <Table
                columns={tableHead('dataCallPKTable')}
                fractions="1fr 2fr 2fr 3fr 1fr"
                dataSource={tableCallPKData}
                title={'true'}
                block={'true'}
              />
            </div>
          </div>
        ) : null}
      </>
    );
  }
  return <></>;
};

export default React.memo(ServicesTable);
