import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { apiBaseUrl } from '@/util/api';
import { newMessagesCountResponseType, NewMessageType } from '@/types/chat.type';

export const fetchNewMessagesCount = createAsyncThunk(
  'chatSlice/fetchNewMessagesCount',
  async () => {
    const response = await fetch(`${apiBaseUrl}/chat/new_messages_count`);
    const data = await response.json();
    return data;
  },
);

const initialState = {
  newMessagesCount: [] as NewMessageType[],
};

const chatSlice = createSlice({
  name: 'chatSlice',
  initialState,
  reducers: {
    removeMessage: (state, action: PayloadAction<number>) => {
      state.newMessagesCount = state.newMessagesCount.filter(
        (msg) => msg.chat_id !== action.payload,
      );
    },
  },
  extraReducers: {
    [fetchNewMessagesCount.fulfilled as any]: (
      state,
      action: PayloadAction<newMessagesCountResponseType>,
    ) => {
      state.newMessagesCount = action.payload.data;
    },
  },
});

export const { removeMessage } = chatSlice.actions;
export default chatSlice.reducer;
