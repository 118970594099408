import React from 'react';

const ArrowNarrowDown = (props: { size: number; color: string }) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height="16"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M16 17L12 21M12 21L8 17M12 21V3"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowNarrowDown;
