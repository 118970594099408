import { offset } from '@floating-ui/dom';
import { ShepherdButtonWithType, ShepherdOptionsWithType } from 'react-shepherd';
import { useTranslation } from 'react-i18next';

import { buttonClassName } from '@/components/ui/button/button.styles';

export function useTour() {
  const { t } = useTranslation();

  const commonButtonClasses = 'font-medium px-4 ml-4';

  const cancelButtonClasses = `${buttonClassName({
    variant: 'outlined',
    size: 'medium',
    customStyles: undefined,
    isFull: false,
  })} ${commonButtonClasses}`;

  const nextButtonClasses = `${buttonClassName({
    variant: 'filled',
    size: 'medium',
    customStyles: undefined,
    isFull: false,
  })} ${commonButtonClasses}`;

  const backButtonText = `<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.24658 0.351344C7.47155 0.576377 7.59793 0.881547 7.59793 1.19974C7.59793 1.51794 7.47155 1.82311 7.24658 2.04814L3.29498 5.99974L7.24658 9.95134C7.46517 10.1777 7.58612 10.4808 7.58339 10.7954C7.58066 11.1101 7.45445 11.411 7.23196 11.6335C7.00947 11.856 6.7085 11.9822 6.39386 11.985C6.07923 11.9877 5.7761 11.8667 5.54978 11.6481L0.749781 6.84814C0.524816 6.62311 0.398438 6.31794 0.398438 5.99974C0.398438 5.68155 0.524816 5.37638 0.749781 5.15134L5.54978 0.351344C5.77481 0.126379 6.07998 0 6.39818 0C6.71638 0 7.02155 0.126379 7.24658 0.351344V0.351344Z" fill="#6B7280"/></svg> <span class="ml-4">${t(
    'onboarding.buttons.back',
  )}</span>`;

  const cancelButton: ShepherdButtonWithType = {
    classes: cancelButtonClasses,
    text: t('onboarding.buttons.cancel'),
    type: 'cancel',
  };

  const introCancelButton: ShepherdButtonWithType = {
    classes: cancelButtonClasses,
    text: t('onboarding.buttons.intro_cancel'),
    action() {
      return this.show('onboarding_later');
    },
  };

  const introNextButton: ShepherdButtonWithType = {
    classes: nextButtonClasses,
    text: t('onboarding.buttons.intro_next'),
    action() {
      return this.show('orders');
    },
  };

  const backButton: ShepherdButtonWithType = {
    classes: cancelButtonClasses,
    text: backButtonText,
    type: 'back',
  };

  const nextButton: ShepherdButtonWithType = {
    classes: nextButtonClasses,
    text: t('onboarding.buttons.next'),
    type: 'next',
  };

  const completeButton: ShepherdButtonWithType = {
    classes: nextButtonClasses,
    text: t('onboarding.buttons.complete'),
    action() {
      return this.complete();
    },
  };

  const backLaterButton: ShepherdButtonWithType = {
    classes: cancelButtonClasses,
    text: backButtonText,
    action() {
      return this.show('intro');
    },
  };

  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
      },
      classes: 'bg-white p-6 rounded-lg shadow-step z-[150] text-gray_600 focus:outline-0',
    },
    useModalOverlay: true,
  };

  const steps: ShepherdOptionsWithType[] = [
    {
      id: 'intro',
      buttons: [introCancelButton, introNextButton],
      classes: 'w-[535px]',
      title: t('onboarding.steps.intro.title'),
      text: t('onboarding.steps.intro.text'),
      modalOverlayOpeningPadding: 10,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 28, crossAxis: 0 })],
      },
    },
    {
      id: 'onboarding_later',
      attachTo: { element: '.onboarding', on: 'bottom' },
      buttons: [backLaterButton, completeButton],
      classes: 'w-[440px]',
      title: t('onboarding.steps.onboarding_later.title'),
      text: t('onboarding.steps.onboarding_later.text'),
      modalOverlayOpeningPadding: 10,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 28, crossAxis: 0 })],
      },
    },
    {
      id: 'orders',
      attachTo: { element: '.orders', on: 'bottom' },
      buttons: [cancelButton, nextButton],
      classes: 'w-[535px]',
      title: t('onboarding.steps.orders.title'),
      text: t('onboarding.steps.orders.text'),
      modalOverlayOpeningPadding: 2,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
    },
    {
      id: 'orders_filters',
      attachTo: { element: '.orders_filters', on: 'bottom' },
      buttons: [cancelButton, backButton, nextButton],
      classes: 'w-[440px]',
      title: t('onboarding.steps.orders_filters.title'),
      text: t('onboarding.steps.orders_filters.text'),
      modalOverlayOpeningPadding: 7,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 25, crossAxis: 0 })],
      },
    },
    {
      id: 'counterparties',
      attachTo: { element: '.counterparties', on: 'bottom' },
      buttons: [cancelButton, backButton, nextButton],
      classes: 'w-[440px]',
      title: t('onboarding.steps.counterparties.title'),
      text: t('onboarding.steps.counterparties.text'),
      modalOverlayOpeningPadding: 2,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
    },
    {
      id: 'affiliate-program',
      attachTo: { element: '.affiliate-program', on: 'bottom' },
      buttons: [cancelButton, backButton, nextButton],
      classes: 'w-[440px]',
      title: t('onboarding.steps.affiliate-program.title'),
      text: t('onboarding.steps.affiliate-program.text'),
      modalOverlayOpeningPadding: 2,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
    },
    {
      id: 'egrn',
      attachTo: { element: '.egrn', on: 'bottom' },
      buttons: [cancelButton, backButton, nextButton],
      classes: 'w-[440px]',
      title: t('onboarding.steps.egrn.title'),
      text: t('onboarding.steps.egrn.text'),
      modalOverlayOpeningPadding: 2,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
    },
    {
      id: 'instructions',
      attachTo: { element: '.instructions', on: 'bottom' },
      buttons: [cancelButton, backButton, nextButton],
      classes: 'w-[440px]',
      title: t('onboarding.steps.instructions.title'),
      text: t('onboarding.steps.instructions.text'),
      modalOverlayOpeningPadding: 2,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
    },
    {
      id: 'onboarding',
      attachTo: { element: '.onboarding', on: 'bottom' },
      buttons: [backButton, completeButton],
      classes: 'w-[440px]',
      title: t('onboarding.steps.onboarding.title'),
      text: t('onboarding.steps.onboarding.text'),
      modalOverlayOpeningPadding: 10,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 28, crossAxis: 0 })],
      },
    },
  ];

  return { steps, tourOptions };
}
