import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues, UseFormRegister } from 'react-hook-form';

import { DocList, Input, Modal, Button } from '@/components/ui';
import Textarea from '@/components/ui/textarea';
import { useAppSelector } from '@/hooks/use-app-selector';
import { fieldsSelector } from '@/store/selectors/orders.selectors';
import { useCallMeMutation } from '@/store/api/order.api';
import { DocumentBase64 } from '@/components/ui/documents/documents.types';
import { ApiFields } from '@/types/types';
import { useNotification } from '@/hooks/use-notifications';

type SurveyingFormProps = {
  defaultValues: FieldValues;
  register: UseFormRegister<FieldValues>;
  indexForm: number;
  documents: Array<DocumentBase64>;
  setDocuments: (files: Array<DocumentBase64>) => void;
};

const SurveyingForm: FC<SurveyingFormProps> = (props) => {
  const { register, indexForm, defaultValues, setDocuments, documents = [] } = props;
  const { t } = useTranslation();

  const [openDelete, toggleDelete] = useState<boolean>(false);
  const [indexOfDelete, setIndexOfDelete] = useState<number | null>(null);

  const fields = useAppSelector(fieldsSelector);

  const [callMe, { isLoading: callMeLoading }] = useCallMeMutation(); // Запросить звонок

  const { createNotification } = useNotification();

  const renderFields = fields[indexForm]?.map((field: ApiFields) => (
    <div
      key={`key${field}`}
      className="w-full inline-flex flex-col md:flex-row items-center basis-[49.28%] mb-[4px]"
    >
      <Input
        label={t(`work_object.input.${field}.label`) + '*'}
        placeholder={t(`work_object.input.${field}.placeholder`)}
        defaultValue={defaultValues[field]}
        {...register(`services.${indexForm}.${field}` as const)}
        unit={field === ('entire_area' || 'until_area' || 'after_area') ? 'm2' : ''}
      />
    </div>
  ));
  const onDel = () => {
    const filteredDocs = documents.filter((el: DocumentBase64, i: number) => i !== indexOfDelete);
    setDocuments(filteredDocs);
    toggleDelete(false);
  };

  const onDeleteFile = (index: number) => {
    toggleDelete(true);
    setIndexOfDelete(index);
  };

  const onCallMeClick = () => {
    callMe(null)
      .unwrap()
      .then(() => {
        createNotification?.({
          variant: 'success',
          title: t('work_object.notification.success'),
        });
      })
      .catch(() => {
        createNotification?.({
          variant: 'fail',
          title: t('work_object.notification.fail'),
        });
      });
  };

  return (
    <div>
      <div className="inline-flex w-full flex-col md:flex-row mt-[20px] flex-wrap gap-[16px]">
        {renderFields}
      </div>
      <div className="w-full inline-flex flex-col md:flex-row justify-between mt-[20px]">
        <div className="w-full mr-[36px]">
          <Textarea
            label={t('work_object.input.comment.label')}
            height={146}
            defaultValue={defaultValues['comment']}
            {...register(`services.${indexForm}.comment` as const)}
          />
        </div>
        <div className="w-full ml-[0px] flex flex-col justify-between">
          <DocList
            index={indexForm}
            label={t('work_object.input.doc_list.label')}
            onAddNew={setDocuments}
            onRemove={onDeleteFile}
            documents={documents}
          />
          <div className="md:mb-[7px]">
            <Button
              label={t('work_object.callme_button')}
              isFull
              onClick={onCallMeClick}
              space={15}
              disabled={callMeLoading}
            />
          </div>
        </div>
      </div>

      <Modal
        isShow={openDelete}
        label={t('create_order.modal.delete.label')}
        type="dialog"
        icon="Alert"
        onCancel={() => toggleDelete(false)}
        onSubmit={onDel}
      >
        {t('create_order.modal.delete.children')}
      </Modal>
    </div>
  );
};

export default SurveyingForm;
