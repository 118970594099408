import React from 'react';

const ChatCommenQuestions = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H11C11.5304 3 12.0391 3.21071 12.4142 3.58579C12.7893 3.96086 13 4.46957 13 5V9C13 9.53043 12.7893 10.0391 12.4142 10.4142C12.0391 10.7893 11.5304 11 11 11H9L6 14V11H4C3.46957 11 2.96086 10.7893 2.58579 10.4142C2.21071 10.0391 2 9.53043 2 9V5Z"
        fill="#60A5FA"
      />
      <path
        d="M15.0005 7V9C15.0005 10.0609 14.5791 11.0783 13.8289 11.8284C13.0788 12.5786 12.0614 13 11.0005 13H9.8285L8.0625 14.767C8.3425 14.916 8.6615 15 9.0005 15H11.0005L14.0005 18V15H16.0005C16.5309 15 17.0396 14.7893 17.4147 14.4142C17.7898 14.0391 18.0005 13.5304 18.0005 13V9C18.0005 8.46957 17.7898 7.96086 17.4147 7.58579C17.0396 7.21071 16.5309 7 16.0005 7H15.0005Z"
        fill="#60A5FA"
      />
    </svg>
  );
};

export default ChatCommenQuestions;
