import ReactDOM from 'react-dom';
import React, { FC, useEffect } from 'react';

import { Notification } from '@/components/ui';
import { useNotification } from '@/hooks/use-notifications';
import { NotificationProvideItem } from '@/components/ui/notification/notification.types';

function createContainer() {
  const portalId = 'notifyContainer';
  let element = document.getElementById(portalId);
  if (element) {
    return element;
  }
  element = document.createElement('div');
  element.setAttribute('id', portalId);
  element.className = 'notification_container';
  document.body.appendChild(element);
  return element;
}

const Notifications: FC = () => {
  const { notifications, removeNotification } = useNotification();
  const container = createContainer();

  useEffect(() => {
    if (notifications?.length) {
      setTimeout(() => removeNotification?.(notifications[0].id), 3000);
    }
  }, [notifications, removeNotification]);
  const renderNotificationsList = notifications?.map((notification, index) => (
    <Notification
      key={index}
      onRemove={removeNotification}
      notification={notification as NotificationProvideItem}
    />
  ));

  return ReactDOM.createPortal(renderNotificationsList, container);
};

export default Notifications;
