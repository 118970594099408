import React, { FC, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { route } from '@/util/route';
import OrderCardSkeleton from '@/components/ui/order-card/order-card-skeleton';
import OrderInfo from '@/components/ui/order-card/order-info';
import OrderCardHeader from '@/components/ui/order-card/order-card-header';
import { cellClassName } from '@/components/ui/order-card/order-card.styles';
import { StageOrder } from '@/types/order.type';
import { Proposal } from '@/types/proposal.type';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { fetchOfferList } from '@/store/slices/offer.slices';
import { useAppSelector } from '@/hooks/use-app-selector';
import { offerListSelector } from '@/store/selectors/offer.selectors';
import { Offer } from '@/types/offer.type';
import OrderStage from '@/components/ui/order-card/order-stage';

type OrderCardProps = {
  dataSource: Proposal[];
  isLoading?: boolean;
  setOrdering: React.Dispatch<React.SetStateAction<string | undefined>>;
  ordering?: string;
};

const OrderCard: FC<OrderCardProps> = (props) => {
  const { dataSource, isLoading, setOrdering, ordering } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchOfferList());
  }, [dispatch]);

  const offerList = useAppSelector(offerListSelector);

  const renderRowOrder = isLoading ? (
    <>
      <OrderCardHeader ordering={ordering} />
      <OrderCardSkeleton count={4} />
    </>
  ) : (
    dataSource &&
    dataSource.map(
      (
        {
          id,
          services,
          proposal_date,
          stage,
          // offer_id,
          number,
          price,
          offer_number,
          contract_number,
        },
        index,
      ) => (
        <div key={`card${id}`} className="px-[24px] hover:bg-gray_50 transition" role="listitem">
          <Link to={`${route.orders}/${id}`} className="hover:text-primary_400">
            <div
              className={clsx(++index !== dataSource.length && 'border-b-[1px] border-b-gray_200')}
            >
              <div className="grid grid-cols-[1.5fr_1fr_1fr_1.5fr_1fr_1fr] py-[16px]">
                <div className="max-w-[230px] font-bold text-paragraph_m pr-2">
                  <p>[{number}]</p>
                  <p className="text-gray_400 text-paragraph_s">
                    {moment(proposal_date).format('DD.MM.YYYY')}
                  </p>
                  <p className="truncate">{services !== null ? services[0]?.address : ''}</p>
                </div>
                <div className={cellClassName}>{offer_number}</div>
                <div className={cellClassName}>{contract_number}</div>
                <div className={cellClassName}>
                  {services !== null ? services[0]?.service_type.name : ''}
                </div>
                <div className={cellClassName}>
                  <OrderStage stage={stage} />
                </div>
                <div className={clsx(cellClassName, 'flex', 'justify-end')}>
                  {price
                    ? `${new Intl.NumberFormat('ru-RU', {
                        minimumFractionDigits: 2,
                      }).format(price)} ${t('order_card.currency')}`
                    : t('order_card.calculated')}
                </div>
              </div>
              <div className=" mb-[16px]">
                {stage === StageOrder.approval &&
                  (offerList as Offer[])?.find((el: Offer) => el.proposal_id === id)?.acceptance ===
                    null && (
                    <OrderInfo
                      id={id}
                      offer_id={
                        (offerList as Offer[]).find((el: Offer) => el.proposal_id === id)?.id
                      }
                    />
                  )}
              </div>
            </div>
          </Link>
        </div>
      ),
    )
  );

  return (
    <div className="">
      <OrderCardHeader setOrdering={setOrdering} ordering={ordering} />
      {renderRowOrder}
    </div>
  );
};

export default React.memo(OrderCard);
