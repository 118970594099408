import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { userSelector } from '@/store/selectors/auth.selectors';
import { useAppSelector } from '@/hooks/use-app-selector';

export const PublicRoute = () => {
  const isAuth = useAppSelector(userSelector);

  return !isAuth ? <Outlet /> : <Navigate to="/" replace />;
};
