import React, { FC } from 'react';

import { Icon } from '@/components/ui';

type AvatarProps = {
  image?: string;
};

const Avatar: FC<AvatarProps> = (props) => {
  const { image } = props;
  if (!image)
    return (
      <div className="inline-block h-[28px] w-[28px] rounded-full ring-2 ring-white bg-primary_400 flex items-center justify-center">
        <Icon name="UserV2" size={28} className="text-white" />
      </div>
    );
  return (
    <img
      className="inline-block h-[28px] w-[28px] rounded-full ring-2 ring-white"
      src={image}
      alt=""
    />
  );
};

export default Avatar;
