import React from 'react';

import { Box } from '@/components/ui';
import LoginFormBlock from '@/features/login/blocks/login-form.block';
import { CompanyPromo } from '@/components';

const LoginPage = () => {
  return (
    <div className="w-full h-[calc(100vh-90px)] flex items-center justify-center">
      <Box>
        <div className="inline-flex">
          <CompanyPromo />
          <div className="border-l-[1px] border-l-light w-[1px] my-[25px]" />
          <div className="w-[383px] p-[24px] pt-[20px]">
            <LoginFormBlock />
          </div>
        </div>
      </Box>
    </div>
  );
};

export default LoginPage;
