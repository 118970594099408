import React from 'react';

const LogoV1 = (props: { size: number }) => {
  const { size = 152 } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 152 61">
      <path
        fill="#0AAFDE"
        d="M3.132 54.283h9.48c1.973 0 2.396-1.141 2.396-2.096V8.107S7.133 9.18 3.816 9.774C.498 10.367 0 5.975 0 5.975S13.83.827 16.493.827H22.2v51.17c0 1.4.529 2.286 2.564 2.286h3.16c2.11 0 2.11 5.504 2.11 5.504H.832s-.573-5.504 2.3-5.504z"
      ></path>
      <path
        fill="#0AAFDE"
        d="M49.37 53.342c.8 1.138 2.388 1.472 4.8 1.236 3.347-.328 3.671 4.572 3.671 4.572-1.153.468-2.25.743-3.634.979-1.382.233-2.564.35-3.543.35-1.442.057-2.695-.158-3.759-.649-1.067-.49-2.031-1.338-2.895-2.548 0 0-16.865-26.228-17.388-26.822-.74-.843-.497-2.334.085-3.11L44.36 3.786c.979-1.44 1.958-2.434 2.939-2.981.978-.548 2.102-.792 3.37-.736 2.477 0 4.868.585 7.173 1.753 0 0-.355 4.69-2.957 4.599-3.06-.104-4.628.064-5.514 1.21-.884 1.145-14.746 19.017-15.3 19.762-.555.747-.49 1.673-.02 2.38.467.705 14.522 22.435 15.32 23.57z"
      ></path>
      <path
        fill="#0AAFDE"
        d="M68.881 53.342c.8 1.138 2.387 1.472 4.8 1.236 3.347-.328 3.67 4.572 3.67 4.572-1.153.468-2.25.743-3.633.979-1.383.233-2.564.35-3.543.35-1.443.057-2.695-.158-3.76-.649-1.068-.49-2.03-1.338-2.896-2.548 0 0-16.865-26.228-17.386-26.822-.74-.843-.497-2.334.085-3.11L63.869 3.786c.98-1.44 1.959-2.434 2.938-2.981.98-.548 2.102-.792 3.372-.736 2.476 0 4.867.585 7.172 1.753 0 0-.356 4.69-2.956 4.599-3.06-.104-4.63.064-5.514 1.21-.884 1.145-14.745 19.017-15.3 19.762-.553.747-.49 1.673-.022 2.38.469.705 14.523 22.435 15.322 23.57"
      ></path>
    </svg>
  );
};

export default LogoV1;
