import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { saveAs } from 'file-saver';

import { Modal, Icon, Button } from '@/components/ui';
import { usePayEgrnOrderMutation } from '@/store/api/egrn.api';
import { EgrnOrderPayType } from '@/types/egrn.type';
import { useNotification } from '@/hooks/use-notifications';

type OnlinePayProps = {
  isShow: boolean;
  paySum: number;
  orderId?: number;
  onCancel: () => void;
};

const OnlinePayModalBlock: FC<OnlinePayProps> = (props) => {
  const { isShow, paySum, orderId, onCancel } = props;
  const { t } = useTranslation();
  const [payEgrnOrder, { isLoading: isPayOrderLoading }] = usePayEgrnOrderMutation();

  const { createNotification } = useNotification();

  const [invoice, setInvoice] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleOnCancel() {
    onCancel?.();
    setInvoice(false);
  }

  async function handleClick(type: EgrnOrderPayType) {
    if (type === 'online') {
      await payEgrnOrder({ id: orderId, pay_type: type })
        .unwrap()
        .then((payload) => {
          document.location.assign(payload.url);
          handleOnCancel();
        })
        .catch(() => {
          handleOnCancel();
          createNotification?.({
            variant: 'fail',
            title: t('create_egrn.notification.payment.fail.title'),
            description: t('create_egrn.notification.payment.fail.description'),
          });
        });
    } else if (type === 'invoice') {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_SERVER}/payments/egrn`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify({ id: orderId, pay_type: 'invoice' }),
      });
      if (response.ok) {
        const filename = response.headers.get('filename');
        const result = await response.blob();
        const a = document.createElement('a'),
          url = URL.createObjectURL(result);
        a.href = url;
        a.download = `${filename}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      } else {
        createNotification?.({
          variant: 'fail',
          title: t('create_egrn.notification.download.fail.title'),
          description: t('create_egrn.notification.download.fail.description'),
        });
      }
      setLoading(false);
      handleOnCancel();
    }
  }

  return (
    <Modal
      label={
        invoice
          ? t('create_egrn.modal.payment.label.invoice')
          : t('create_egrn.modal.payment.label.pay_type')
      }
      // description={invoice ? t('create_egrn.modal.payment.description') : ''}
      isShow={isShow}
      showClose
      onCancel={handleOnCancel}
      type="payment"
    >
      <div className="mt-8 mb-8 text-paragraph_m text-gray_600">
        {invoice ? (
          <>
            <p>{t('create_egrn.modal.payment.children.invoice.top')}</p>
            {/* <p className="mt-4">{t('create_egrn.modal.payment.children.invoice.bottom')}</p> */}
          </>
        ) : (
          <div>
            {t('create_egrn.modal.payment.children.total_sum')}{' '}
            <span className="text-heading_h1 font-bold">{paySum} руб.</span>
          </div>
        )}
      </div>
      <div className="mt-[24px]">
        {invoice ? null : (
          <button
            disabled={isPayOrderLoading || loading}
            className="py-[13px] bg-[#FFCC00] hover:bg-[#FFD83D] disabled:bg-gray_100 text-paragraph_l text-black disabled:text-gray_300 font-medium transition no-underline rounded inline-flex items-center w-full justify-center"
            onClick={() => handleClick('online')}
          >
            {isPayOrderLoading ? (
              <span className="mt-[3px]"> {t('create_egrn.modal.payment.btn.process')}</span>
            ) : (
              <div className="inline-flex items-center">
                <span className="mt-[3px]"> {t('create_egrn.modal.payment.btn.pay')}</span>
                <div className="mt-[2px] ml-6">
                  <Icon name="YandexPay" size={18} className="text-black disabled:text-gray_300" />
                </div>
                <span className="mt-[3px] ml-1 font-bold"> Pay</span>
                <div className="ml-6">
                  <Icon name="Sbp" size={24} className="text-black disabled:text-gray_300" />
                </div>
                <span className="mt-[3px] font-bold"> сбп</span>
              </div>
            )}
          </button>
        )}
        <div className="mt-4">
          {invoice ? null : (
            <Button
              isFull
              icon="Download"
              disabled={isPayOrderLoading || loading}
              size="large"
              label={
                loading
                  ? t('create_egrn.modal.payment.btn.process')
                  : t('create_egrn.modal.payment.btn.invoice_download')
              }
              onClick={() => handleClick('invoice')}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default OnlinePayModalBlock;
