import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { translateRequisitesPath } from '../constants';

import RequisitesAndContractWrapperBlock from './requisites-and-contract-wrapper.block';

import { getContractLinkStyle } from './contract-formed.style';

import { tabQuery, affiliateProgramQuery, route } from '@/util/route';
import { Info } from '@/components/ui';
import { useAppSelector } from '@/hooks/use-app-selector';
import { userSelector } from '@/store/selectors/auth.selectors';

const ContractFormedBlock = () => {
  const { t } = useTranslation();
  const user = useAppSelector(userSelector);

  return (
    <RequisitesAndContractWrapperBlock
      desctiption={
        <Trans
          defaults={t(translateRequisitesPath + '.contract-formed.paragraph')}
          values={{
            'link-to-how-to-earn-with-1kk': t(
              translateRequisitesPath + '.contract-formed.link-to-how-to-earn-with-1kk',
            ),
          }}
          components={[
            <b key={'link-to-how-to-earn-with-1kk'} />,
            <Link
              to={`${route.affiliateProgram}${tabQuery}${affiliateProgramQuery.howToEarnWith1kk}`}
              key="link-to-how-to-earn-with-1kk"
              className="text-primary_400"
            />,
          ]}
        />
      }
    >
      <Info
        variant="success"
        content={
          <span>
            {t(translateRequisitesPath + '.contract-formed.contract')}
            <a
              className={getContractLinkStyle(!!user?.document_link)}
              href={user?.document_link || ''}
              target="_blank"
              rel="noreferrer"
            >
              {t(translateRequisitesPath + '.download-contract')}
            </a>{' '}
          </span>
        }
      />
      <div className="mt-[16px]">
        <Info
          variant="danger"
          content={
            <span>
              <Trans
                defaults={t(translateRequisitesPath + '.contract-formed.action')}
                values={{
                  phone: '+7 (495) 970-00-01',
                }}
                components={{ div: <div /> }}
              />
            </span>
          }
        />
      </div>
    </RequisitesAndContractWrapperBlock>
  );
};

export default ContractFormedBlock;
