import clsx from 'clsx';

export const stepperItemClassName = ({ active, success }: { active: boolean; success: boolean }) =>
  clsx(
    active ? 'text-primary_400' : success ? 'text-gray_600 font-bold' : 'text-gray_400 font-medium',
    'select-none',
    'transition',
    'relative',
    'cursor-pointer',
    'inline-flex',
    'justify-between',
    'w-full',
    'h-[46px]',
    'pl-[13px]',
    'truncate',
    'text-paragraph_s',
  );

export const numberOfStepClassName = ({ active = false }: { active: boolean }) =>
  clsx(
    active
      ? 'ring-[2px] ring-primary_400 text-primary_400'
      : 'ring-[1px] ring-gray_400 text-gray_400',
    'w-[20px]',
    'h-[20px]',
    'rounded-full',
    'inline-flex',
    'items-center',
    'justify-center',
    'transition',
    'mr-[8px]',
  );
