import clsx from 'clsx';

export const selectClassName = ({ disabled, error }: { disabled: boolean; error: boolean }) =>
  clsx(
    'border',
    'bg-white',
    'placeholder-green-700',
    'text-paragraph_l',
    'font-regular',
    'rounded',
    'block',
    'w-full',
    'inline-flex',
    'items-center',
    'px-[12px]',
    'py-[7px]',
    'transition',
    disabled
      ? 'border-gray_300 bg-gray_100 text-gray_300 cursor-not-allowed'
      : clsx(error ? 'border-negative_500' : 'border-gray_300', 'text-gray_600', 'cursor-pointer'),
  );

export const selectBoxClassName = clsx(
  'bg-white',
  'absolute',
  'w-full',
  'border',
  'border-gray_300',
  'rounded',
  'shadow-md',
  'mt-1',
  'pt-1',
  'max-h-[420px]',
  'z-30',
  'overflow-y-auto',
  'scrollbar-small',
);

export const optionClassName = ({ active }: { active: boolean }) =>
  clsx(
    'px-[12px]',
    'w-full',
    'inline-flex',
    'items-center',
    'py-[8px]',
    'hover:bg-gray_100',
    'cursor-pointer',
    'select-none',
    'text-paragraph_l',
    'font-medium',
    'truncate',
    'rounded',
    'z-30',
    active ? 'text-primary_400' : 'text-gray_600',
    'bg-white',
  );

export const selectLabelClassName = ({ disabled }: { disabled: boolean }) =>
  clsx(
    'block',
    'mb-1',
    'font-bold',
    'text-paragraph_m',
    disabled ? 'text-gray_400' : 'text-gray_600',
  );

export const errorClassName = clsx(
  'mt-1',
  'text-negative_500',
  'text-paragraph_s',
  'font-regular italic',
);
