import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ProfileSettingsFormBlock from '@/features/profile/blocks/profile-settings-form.block';
import { Box, Button, Container } from '@/components/ui';
import { route } from '@/util/route';

const ProfileSettingsPage = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <div className="mb-[24px]">
        <div className="mt-[20px] mb-[24px]">
          <Link to={route.orders}>
            <Button label={t('profile.btn.back')} variant="outlined" icon="ChevronLeft" />
          </Link>
        </div>
        <div className="text-heading_h2 text-gray_600 mb-[16px]">{t('profile.settings_title')}</div>
        <div className="w-[376px] mb-[153px]">
          <Box>
            <div className="px-[16px] py-[24px]">
              <ProfileSettingsFormBlock />
            </div>
          </Box>
        </div>
      </div>
    </Container>
  );
};

export default ProfileSettingsPage;
