import React, { FC } from 'react';

import clsx from 'clsx';

import { TableProps } from '@/components/ui/table/table.types';
import { tableColumnClassName } from '@/components/ui/table/table.styles';

const Table: FC<TableProps> = (props) => {
  const {
    dataSource = [],
    columns,
    fractions,
    ownClassNames,
    block,
    title,
    rowHover = false,
  } = props;
  const cells = fractions
    ? { gridTemplateColumns: fractions }
    : { gridTemplateColumns: `repeat(${dataSource.length}, 1fr)` };

  const renderColumns = (
    <div className={tableColumnClassName} style={cells}>
      {columns.map(({ title, key, right = false }) => (
        <div key={key} className={clsx('flex', right && 'justify-end', ' p-[8px]')}>
          {title}
        </div>
      ))}
    </div>
  );

  const renderCells = (
    <div>
      {dataSource.map((row) => {
        return (
          <div key={row.id}>
            <div
              className={clsx(
                'grid text-paragraph_l color-gray_600 text-gray_600',
                rowHover === true && 'hover:bg-gray_50',
              )}
              style={cells}
            >
              {columns.map((column, index) => {
                return (
                  <div
                    title={title && (row[column.dataIndex] as string)}
                    key={index}
                    className={clsx(
                      'p-[8px]',
                      'flex truncate',
                      'flex-col',
                      'gap-[16px]',
                      column.right && 'justify-end' && 'items-end' && 'text-end',
                      // rowHover && 'hover:bg-primary_100',
                    )}
                    style={block && { display: 'block' }}
                  >
                    {row[column.dataIndex]}
                  </div>
                );
              })}
            </div>
            <div className={`truncate ${ownClassNames}`}>{row?.content}</div>
          </div>
        );
      })}
    </div>
  );

  return (
    <div>
      {renderColumns}
      {renderCells}
    </div>
  );
};

export default Table;
