import React, { FC } from 'react';

import { Icon } from '@/components/ui';
import { IconName } from '@/components/ui/icon/icons.type';

type HeadingProps = {
  title: string;
  icon?: IconName;
  iconClassname?: string;
};

const Heading: FC<HeadingProps> = (props) => {
  const { title, icon = 'Dashboard', iconClassname = '' } = props;
  return (
    <div className="bg-primary_50 inline-flex items-center px-[16px] py-[10px] w-full text-heading_h3 text-gray_600 font-bold border-y-[1px] border-y-gray_200">
      <div className=" inline-flex items-center truncate w-full">
        <div className="mr-[12px]">
          <Icon name={icon} size={22} className={`text-primary_400 ${iconClassname}`} />
        </div>
        <div className="truncate flex items-center mt-[2px]">{title}</div>
      </div>
    </div>
  );
};

export default Heading;
