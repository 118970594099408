import { RootState } from '@/store';

export const counterpartySelector = (state: RootState) => {
  return state.counterpartyStore.counterparties;
};
export const pageCounterpartiesSelector = (state: RootState) => {
  return state.counterpartyStore.page;
};
export const totalCounterpartiesCountSelector = (state: RootState) => {
  return state.counterpartyStore.totalCounterpartiesCount;
};
export const counterpartyByIdSelector = (state: RootState) => {
  return state.counterpartyStore.counterparty;
};
export const isLoadingSelector = (state: RootState) => {
  return state.counterpartyStore.isLoading;
};
