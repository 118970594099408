import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiBaseUrl } from '@/util/api';
import { Service } from '@/types/types';

// Детальное отображение заказа
export const fetchServices = createAsyncThunk(
  'serviceSlice/fetchServices',
  async (id: string | number) => {
    const response = await fetch(`${apiBaseUrl}/service/?proposal_id=${id}`);
    const data = await response.json();

    return data;
  },
);

const initialState = {
  services: [] as Service[], // Детальное отображение заказа
};

const serviceSlice = createSlice({
  name: 'serviceSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchServices.fulfilled as any]: (state, action) => {
      state.services = action.payload?.data?.items;
    },
  },
});

export default serviceSlice.reducer;
