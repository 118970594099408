import React from 'react';

const Edit = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.168 3.784H4.762A1.762 1.762 0 003 5.546v9.692A1.762 1.762 0 004.762 17h9.692a1.763 1.763 0 001.762-1.762v-4.406M14.97 2.538a1.763 1.763 0 112.492 2.492l-7.565 7.565H7.405v-2.492l7.565-7.565z"
      ></path>
    </svg>
  );
};

export default Edit;
