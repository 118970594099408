import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { apiBaseUrl } from '@/util/api';
import { File, ResultFile } from '@/types/file.type';

// Загруженные документы
export const fetchFiles = createAsyncThunk(
  'filesSlice/fetchFiles',
  async (proposal_id: string | number) => {
    const response = await fetch(`${apiBaseUrl}/files/get_files/${proposal_id}`);
    const data = await response.json();

    return data;
  },
);
// Договорные документы
export const fetchContractFiles = createAsyncThunk(
  'filesSlice/fetchContractFiles',
  async (contract_id: string | number) => {
    const response = await fetch(`${apiBaseUrl}/files/get_contract_files/${contract_id}`);
    const data = await response.json();

    return data;
  },
);
// Коммерческое предложение
export const fetchOffertFiles = createAsyncThunk(
  'filesSlice/fetchOffertFiles',
  async (proposal_id: string | number) => {
    const response = await fetch(`${apiBaseUrl}/files/get_offer_file/${proposal_id}`);
    const data = await response.json();

    return data;
  },
);
// Результаты работ
export const fetchResultFiles = createAsyncThunk(
  'filesSlice/fetchResultFiles',
  async (contract_id: string | number) => {
    const response = await fetch(`${apiBaseUrl}/files/get_result_files/${contract_id}`);
    const data = await response.json();

    return data;
  },
);

const initialState = {
  uploadedFiles: [] as File[], // Загруженные документы
  contractFiles: [] as File[], // Договорные документы
  offerFiles: null as File | null, // Коммерческое предложение
  resultFiles: [] as ResultFile[], // Результаты работ
};

type InitialStateType = typeof initialState;

const filesSlice = createSlice({
  name: 'filesSlice',
  initialState,
  reducers: {
    setUploadedFiles: (state: InitialStateType, action: PayloadAction<File[]>) => {
      state.uploadedFiles = action.payload;
    },
    resetFiles: (state: InitialStateType) => {
      state.resultFiles = []; // Результаты работ
      state.contractFiles = []; // Договорные документы
      state.offerFiles = null; // Коммерческое предложение
      state.uploadedFiles = []; // Загруженные документы
    },
  },
  extraReducers: {
    [fetchFiles.fulfilled as any]: (state, action) => {
      state.uploadedFiles = action.payload.data.files;
    },
    [fetchContractFiles.fulfilled as any]: (state, action) => {
      state.contractFiles = action.payload.data.files;
    },
    [fetchOffertFiles.fulfilled as any]: (state, action) => {
      state.offerFiles = action.payload?.data;
    },
    [fetchResultFiles.fulfilled as any]: (state, action) => {
      state.resultFiles = action.payload.data.items;
    },
  },
});

export const { setUploadedFiles, resetFiles } = filesSlice.actions;
export default filesSlice.reducer;
