import React, { FC, useEffect } from 'react';

import { Button, Icon } from '@/components/ui';
import { modalContainerClassName, modalWrapperClassName } from '@/components/ui/modal/modal.styles';
import { ModalProps } from '@/components/ui/modal/modal.types';

const Modal: FC<ModalProps> = (props) => {
  const {
    type = 'content',
    isShow,
    children,
    icon,
    description,
    label,
    onSubmit,
    disabledSuccess = false,
    submitType = 'button',
    submitText,
    submitStyle,
    buttonText,
    onCancel,
    showClose,
    isFileLoading,
    isFileError,
    withoutFooter,
    setShow,
  } = props;
  //данный юз эффект убирает скролл на странице при открытом модальном окне
  useEffect(() => {
    if (isShow) {
      const scrollTop = window.scrollY;
      const bodyNode = document.querySelector('body');
      if (bodyNode) {
        bodyNode.style.position = 'fixed';
        bodyNode.style.top = -scrollTop + 'px';
        bodyNode.style.left = '0px';
        bodyNode.style.right = '0px';
      }
      return () => {
        if (bodyNode) {
          bodyNode.style.removeProperty('top');
          bodyNode.style.removeProperty('left');
          bodyNode.style.removeProperty('right');
          bodyNode.style.removeProperty('position');
          window.scrollTo(0, scrollTop);
        }
      };
    }
  }, [isShow]);

  function handleOnSubmit() {
    onSubmit?.();
  }

  function handleOnCancel() {
    onCancel?.() || setShow?.(false);
  }

  const renderIcon = icon && (
    <div className="mr-[26px] ml-[6px] mt-[6px]">
      <Icon
        name="Alert"
        size={20}
        className=" ring-[8px] ring-negative_100 rounded-full text-negative_500 bg-negative_100"
      />
    </div>
  );

  const renderDescription = description && (
    <div className="text-paragraph_m text-gray_500">{description}</div>
  );

  const renderDialogFooter = (
    <div className="w-full flex justify-end mt-[24px]">
      <div className="mr-[14px]">
        {!isFileError && (
          <Button
            label={buttonText || 'Отмена'}
            onClick={handleOnCancel}
            variant="outlined"
            space={15}
          />
        )}
      </div>
      <div>
        {type === 'dialog' && (
          <Button
            label={submitText || 'Удалить'}
            onClick={handleOnSubmit}
            variant="filled"
            space={19}
            disabled={disabledSuccess}
            customStyles={submitStyle || 'bg-negative_500 text-white'}
          />
        )}
        {type === 'content' && !isFileLoading && (
          <Button
            type={submitType}
            label={submitText || 'Добавить'}
            onClick={handleOnSubmit}
            variant="filled"
            disabled={disabledSuccess}
            space={17}
          />
        )}
      </div>
    </div>
  );

  if (!isShow) return <></>;
  return (
    <div aria-hidden="true" className={modalWrapperClassName({ type })}>
      <div
        className="fixed top-0 left-0 inset-0 opacity-40 bg-gray_800"
        onClick={handleOnCancel}
      ></div>
      <div className={modalContainerClassName({ type })}>
        <div className="inline-flex w-full">
          {renderIcon}
          <div className="w-full">
            {type != 'chat' && (
              <div className="inline-flex justify-between w-full">
                <div className="text-heading_h2 text-gray_600 mb-[12px] font-bold">{label}</div>
                {showClose ? (
                  <div onClick={handleOnCancel}>
                    <Icon
                      name="Close"
                      size={30}
                      className="text-gray_300 hover:text-primary_400 m-0 cursor-pointer"
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            )}

            <div className="w-full mr-2.5">
              {renderDescription}
              {children}
            </div>
          </div>
        </div>
        <div>{type !== 'payment' && !withoutFooter ? renderDialogFooter : null}</div>
      </div>
    </div>
  );
};

export default Modal;
