import React from 'react';

const Globe = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 10C19.5 12.3869 18.5518 14.6761 16.864 16.364C15.1761 18.0518 12.8869 19 10.5 19M19.5 10C19.5 7.61305 18.5518 5.32387 16.864 3.63604C15.1761 1.94821 12.8869 1 10.5 1M19.5 10H1.5M10.5 19C8.11305 19 5.82387 18.0518 4.13604 16.364C2.44821 14.6761 1.5 12.3869 1.5 10M10.5 19C12.157 19 13.5 14.97 13.5 10C13.5 5.03 12.157 1 10.5 1M10.5 19C8.843 19 7.5 14.97 7.5 10C7.5 5.03 8.843 1 10.5 1M10.5 1C8.11305 1 5.82387 1.94821 4.13604 3.63604C2.44821 5.32387 1.5 7.61305 1.5 10"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Globe;
