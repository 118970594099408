import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CreateLegalForm from '@/components/counterparty-forms/create-legal-form';
import IndividualForm from '@/components/counterparty-forms/individual-form';
import CheckInnBikForm from '@/components/counterparty-forms/check-inn-bik-form';
import { Icon, Modal } from '@/components/ui';
import { Counterparty } from '@/types/counterparty.type';

type AddCounterpartyProps = {
  openAddCounterparty: boolean;
  toggleAddCounterparty: (state: boolean) => void;
};

const AddCounterparty: FC<AddCounterpartyProps> = (props) => {
  const { openAddCounterparty, toggleAddCounterparty } = props;
  const { t } = useTranslation();
  const [counterpartyType, setCounterpartyType] = useState('');
  const [isShownForm, setIsShownForm] = useState(false);
  const [counterparty, setCounterparty] = useState<Counterparty | undefined>();

  const radioLabelClassName =
    'flex w-full p-4 bg-gray_100 cursor-pointer rounded border-transparent border-2 peer-checked:bg-transparent peer-checked:border-primary_400';

  function handleOnCancelCreateNewCounterparty() {
    setCounterpartyType('');
    setIsShownForm(false);
    toggleAddCounterparty(false);
  }

  const handleOnCancelCounterpartyForm = () => {
    setCounterpartyType('');
    setIsShownForm(false);
    toggleAddCounterparty(false);
  };

  const handleOnBackCounterpartyForm = () => {
    setIsShownForm(false);
    toggleAddCounterparty(true);
  };

  const handleOnBackLegalForm = () => {
    setCounterparty(undefined);
  };

  return (
    <>
      {isShownForm ? (
        <Modal
          type="dialog"
          withoutFooter={true}
          label={t('create_order.modal_title')}
          onCancel={handleOnCancelCounterpartyForm}
          isShow={isShownForm}
        >
          {counterpartyType === 'ИП' || counterpartyType === 'ЮЛ' ? (
            counterparty ? (
              <CreateLegalForm
                onBack={handleOnBackLegalForm}
                OnCancel={handleOnCancelCounterpartyForm}
                counterparty={counterparty}
              />
            ) : (
              <CheckInnBikForm
                onBack={handleOnBackCounterpartyForm}
                OnCancel={handleOnCancelCounterpartyForm}
                counterpartyType={counterpartyType}
                setCounterparty={setCounterparty}
              />
            )
          ) : (
            <IndividualForm
              onBack={handleOnBackCounterpartyForm}
              OnCancel={handleOnCancelCounterpartyForm}
            />
          )}
        </Modal>
      ) : (
        <Modal
          type="dialog"
          label={t('create_order.modal_title')}
          onCancel={handleOnCancelCreateNewCounterparty}
          onSubmit={() => setIsShownForm(true)}
          submitType="submit"
          submitText={t('create_counterparty.more_button')}
          submitStyle="bg-primary_400 hover:bg-primary_500 disabled:bg-gray_100 text-white"
          disabledSuccess={!counterpartyType}
          isShow={openAddCounterparty}
        >
          <div>
            <div className="mb-2">
              <input
                id="legal"
                type="radio"
                value="ЮЛ"
                className="radio hidden peer"
                checked={counterpartyType === 'ЮЛ'}
                onChange={(e) => setCounterpartyType(e.target.value)}
              />
              <label htmlFor="legal" className={radioLabelClassName}>
                <Icon name="BriefCase" className="text-primary_500 mr-4" />
                {t('create_counterparty.radio.legal')}
              </label>
            </div>
            <div className="mb-2">
              <input
                id="private_enterprise"
                type="radio"
                value="ИП"
                className="radio hidden peer"
                checked={counterpartyType === 'ИП'}
                onChange={(e) => setCounterpartyType(e.target.value)}
              />
              <label htmlFor="private_enterprise" className={radioLabelClassName}>
                <Icon name="BriefCase" className="text-primary_500 mr-4" />
                {t('create_counterparty.radio.private_enterprise')}
              </label>
            </div>
            <div>
              <input
                id="individual"
                type="radio"
                value="ФЛ"
                className="radio hidden peer"
                checked={counterpartyType === 'ФЛ'}
                onChange={(e) => setCounterpartyType(e.target.value)}
              />
              <label htmlFor="individual" className={radioLabelClassName}>
                <Icon name="User" className="text-primary_500 mr-4" />
                {t('create_counterparty.radio.individual')}
              </label>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default AddCounterparty;
