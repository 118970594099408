import { RootState } from '@/store';

export const contractIdSelector = (state: RootState) => {
  return state.contractStore.contract_id;
};
export const contractSelector = (state: RootState) => {
  return state.contractStore.contract;
};
export const contractListSelector = (state: RootState) => {
  return state.contractStore.contractList;
};
export const listStatementsSelector = (state: RootState) => {
  return state.contractStore.listStatements;
};
export const listProgressSelector = (state: RootState) => {
  return state.contractStore.listProgress;
};
