import React from 'react';

const Box = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16.667 10.834V5.001A1.667 1.667 0 0015 3.334H5a1.667 1.667 0 00-1.667 1.667v5.833m13.334 0v4.167A1.667 1.667 0 0115 16.667H5a1.667 1.667 0 01-1.667-1.666v-4.167m13.334 0h-2.155a.833.833 0 00-.59.244l-2.011 2.012a.834.834 0 01-.59.244H8.679a.834.834 0 01-.589-.244l-2.011-2.012a.833.833 0 00-.59-.244H3.333"
      ></path>
    </svg>
  );
};

export default Box;
