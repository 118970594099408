import React, { useState, ChangeEvent, forwardRef, ForwardedRef } from 'react';
import clsx from 'clsx';
import InputMask from 'react-input-mask';

import { Icon } from '@/components/ui';
import { inputClassName, inputWrapperClassName } from '@/components/ui/input/input.styles';
import { InputProps } from '@/components/ui/input/input.types';

const Input = forwardRef((props: InputProps, forwardedRef: ForwardedRef<HTMLInputElement>) => {
  const {
    defaultValue,
    value,
    label,
    error,
    onChange,
    type = 'text',
    placeholder,
    disabled = false,
    icon,
    unit,
    mask,
    onIconClick,
    onBeforeIconClick,
    beforeIcon,
    autoComplete = 'on',
    ...remindedProps
  } = props;

  const [isFocus, setIsFocus] = useState(false);

  const inputProps = {
    className: inputClassName,
    type,
    placeholder,
    disabled,
    autoComplete,
    onChange: handleOnChange,
    ...remindedProps,
    onFocus: setIsFocus.bind(null, true),
    onBlur: setIsFocus.bind(null, false),
  };

  const renderLabel = label && (
    <label
      className={clsx(
        'block',
        'mb-1',
        'text-paragraph_m',
        'font-bold',
        'truncate',
        disabled ? 'text-gray_400' : 'text-gray_600',
      )}
    >
      {label}
    </label>
  );

  const renderError = !!error && (
    <p className="mt-1 text-negative_500 text-paragraph_s font-regular italic absolute">{error}.</p>
  );

  const handlerIconClick = () => {
    onIconClick?.();
  };

  const renderIcon = icon && (
    <div className="mx-[4px] cursor-pointer" onClick={handlerIconClick}>
      <Icon name={icon} size={24} className="text-gray_400 opacity-80 hover:opacity-100" />
    </div>
  );

  const renderUnit = unit && (
    <div className="mx-[4px] text-gray_400 text-paragraph_l cursor-pointer">{unit}</div>
  );

  const renderBeforeIcon = beforeIcon && (
    <div className="mx-[4px] mr-[12px]" onClick={onBeforeIconClick?.bind(null)}>
      <Icon name={beforeIcon} size={24} className="text-primary_400 opacity-80 hover:opacity-100" />
    </div>
  );

  const renderInput = mask ? (
    <InputMask mask={mask} maskChar={null} defaultValue={defaultValue} {...inputProps} />
  ) : (
    <input ref={forwardedRef} value={value} defaultValue={defaultValue} {...inputProps} />
  );

  function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
    onChange?.(event);
  }

  return (
    <div className="w-full">
      {renderLabel}
      <div className={inputWrapperClassName({ isFocus, disabled, error: !!error })}>
        {renderBeforeIcon}
        {renderInput}
        {renderIcon}
        {renderUnit}
      </div>
      {renderError}
    </div>
  );
});

Input.displayName = 'Input';

export default Input;
