import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';

import { Input, Spin } from 'antd';

import Message from '@/features/chat/blocks/message/message';
import Icon from '@/components/ui/icon';
import { useCreateNewMessageMutation } from '@/store/api/chat.api';
import { ChatType, MessageType, messagesResponseType } from '@/types/chat.type';
import { useAppSelector } from '@/hooks/use-app-selector';
import { userSelector } from '@/store/selectors/auth.selectors';
import FaKemessage from '@/features/chat/blocks/fakeMessage/faKemessage';
import FileButton from '@/components/ui/button/file-button';
import { readAsDataURL } from '@/util/helpers';
import { DocumentBase64 } from '@/components/ui/documents/documents.types';
import Documents from '@/components/ui/documents';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { removeMessage } from '@/store/slices/chat.slice';
import { apiBaseUrl } from '@/util/api';

const { TextArea } = Input;

type DialogPropsType = {
  currentChat: ChatType | null;
};

const Dialog = ({ currentChat }: DialogPropsType) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(userSelector)?.id ?? null;

  const [value, setValue] = useState('');
  const [messagesFetching, setMessagesFetching] = useState<boolean>(true);
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [newMessages, setNewMessages] = useState<MessageType[]>([]);
  // const [docs, setDocs] = useState<{ file_name: string; file: File }[]>([]);
  const [sendMessage, { isError }] = useCreateNewMessageMutation();
  const [docs, setDocs] = useState<DocumentBase64[]>([]);
  const [fakeMessage, setMessage] = useState('');
  const [fakeFiles, setFakeFiles] = useState<DocumentBase64[]>([]);

  useEffect(() => {
    if (currentChat && messagesFetching === false) {
      dispatch(removeMessage(currentChat?.id));
    }
  }, [currentChat, dispatch, messagesFetching]);

  useEffect(() => {
    if (newMessages && newMessages.length > 0) {
      const copyOfMessagesData = structuredClone(messages);
      copyOfMessagesData.push(...newMessages);
      setMessages(copyOfMessagesData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessages]);

  useEffect(() => {
    setDocs([]);
    const interval = setInterval(() => {
      fetchNewMessages();
    }, 15000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChat]);

  useEffect(() => {
    if (messages) {
      setMessage('');
      setFakeFiles([]);
    }
  }, [messages]);

  useEffect(() => {
    fetchMessages(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMessages = async (setLoading: boolean) => {
    if (currentChat && currentChat.id) {
      setLoading && setMessagesFetching(true);
      const response = await fetch(`${apiBaseUrl}/chat/${currentChat.id}`);
      const data: messagesResponseType = await response.json();
      setMessages(data.data);
      setLoading && setMessagesFetching(false);
    }
  };

  const fetchNewMessages = async () => {
    if (currentChat && currentChat.id) {
      const response = await fetch(`${apiBaseUrl}/chat/${currentChat.id}/view_new_messages`);
      const data: messagesResponseType = await response.json();
      if (data.data.length > 0) {
        dispatch(removeMessage(currentChat?.id));
        setNewMessages(data.data);
      }
    }
  };

  if (!currentChat)
    return (
      <div className=" flex  h-full items-center justify-center text-paragraph_m  font-medium text-gray_400">
        Выберите чат
      </div>
    );

  const removeMessageThatWasNotSent = () => {
    setFakeFiles([]);
    setMessage('');
  };

  const onChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.currentTarget.value);
  };

  const onKeyDownHandler = (e: KeyboardEvent<HTMLElement>) => {
    if (e.code === 'Enter') {
      e.preventDefault();
      onSendMessage();
    }
  };
  //
  const onSendMessage = () => {
    if ((value && value.trim() !== '') || docs.length) {
      if (userId && currentChat.type === 'general_questions') {
        setMessage(value);
        sendMessage({ user_id: userId, text: value })
          .unwrap()
          .then(() => {
            fetchMessages(false);
          });
      }
      if (userId && currentChat.type !== 'general_questions') {
        setMessage(value);
        setFakeFiles(docs);
        sendMessage({ user_id: userId, text: value, chat_id: currentChat.id, files: docs })
          .unwrap()
          .then(() => {
            fetchMessages(false);
          });
      }

      setValue('');
      setDocs([]);
    }
  };

  const onSendMessageIfPreviousMessagewasNotSent = () => {
    if (fakeMessage || fakeFiles) {
      if (userId && currentChat.type === 'general_questions') {
        setMessage(fakeMessage);
        sendMessage({ user_id: userId, text: fakeMessage });
      }
      if (userId && currentChat.type !== 'general_questions') {
        setMessage(fakeMessage);
        setFakeFiles(fakeFiles);
        sendMessage({ user_id: userId, text: value, chat_id: currentChat.id, files: fakeFiles });
      }
    }
  };

  async function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
    // 'jpg' | 'png' | 'pdf' | 'doc' | 'docx' | 'xls' | 'xlsx';
    if (event.target.files) {
      const files = [...event.target.files];
      const currentFileListNames = await Promise.all(
        files.map((f) => {
          return readAsDataURL(f);
        }),
      );
      setDocs(currentFileListNames as DocumentBase64[]);
    }
  }

  const onRemoveUploadingFile = (currentIndex: number) => {
    const filteredFiles = docs.filter((file, index) => index !== currentIndex);
    setDocs(filteredFiles);
  };

  return (
    <div className="border-l border-gray_200 h-full relative flex flex-col ">
      <div className="py-1 px-3 text-paragraph_m text-primary_400 font-medium border-b border-gray_200 sticky top-0">
        {currentChat.title}
        {currentChat.issue_number && `, ${currentChat.issue_date}`}
      </div>
      {messagesFetching ? (
        <div className="flex items-center h-full justify-center">
          <Spin />
        </div>
      ) : (
        <>
          <div className=" px-3 pb-3 overflow-y-auto flex flex-col-reverse  flex-1 border-b border-gray_200">
            <div className="  flex    flex-col justify-end ">
              {messages?.map((message, index, array) => {
                const isCurrentAdminMessage = !message?.is_user;
                const isOnlyMessage = message?.is_user !== messages[index + 1]?.is_user;
                const isLastMessage = index === array.length - 1 || index === array.length - 2;

                const isPreviousAdminMessage = index > 0 && !messages[index - 1].is_user;

                if (isCurrentAdminMessage && !isPreviousAdminMessage) {
                  return (
                    <div key={message.id}>
                      <div className="text-paragraph_s italic text-gray_500 font-regular">
                        {message.sender_name}
                      </div>
                      <div>
                        <Message
                          message={message}
                          isOnlyMessage={isOnlyMessage}
                          isLastMessage={isLastMessage}
                        />
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div key={message.id}>
                      <Message
                        message={message}
                        isOnlyMessage={isOnlyMessage}
                        isLastMessage={isLastMessage}
                      />
                    </div>
                  );
                }
              })}
              {(fakeMessage || fakeFiles.length > 0) && (
                <div>
                  <FaKemessage
                    isError={isError}
                    message={fakeMessage}
                    files={fakeFiles}
                    isOnlyMessage={true}
                    isLastMessage={true}
                    removeMessage={removeMessageThatWasNotSent}
                    onResendMessage={onSendMessageIfPreviousMessagewasNotSent}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col max-h-[261px]">
            <div className="p-3 flex items-start">
              {currentChat && currentChat.type !== 'general_questions' && (
                <div className="pr-3">
                  <FileButton
                    label={' '}
                    icon={'Assets'}
                    customStyles="py-0 px-0"
                    isFull={false}
                    onChange={handleOnChange}
                  />
                </div>
              )}

              <div className=" w-full text-paragraph_m mr-3 text-gray_700 font-regular">
                <TextArea
                  onChange={(e) => {
                    onChangeHandler(e);
                  }}
                  onKeyDown={onKeyDownHandler}
                  value={value}
                  autoSize={{ minRows: 1, maxRows: 3.5 }}
                  bordered={false}
                  className="no-scrollbar text-paragraph_m  text-gray_700 font-regular p-0"
                />
              </div>
              <div onClick={onSendMessage} className="rotate-90 cursor-pointer">
                <Icon name="Send" size={24} color="primary_400" />
              </div>
            </div>
            {docs.length > 0 && (
              <div className="grid grid-cols-3 overflow-y-auto max-h-[160px] px-3 pb-3">
                {docs.map((doc, index) => (
                  <Documents
                    onRemove={() => onRemoveUploadingFile?.(index)}
                    key={index}
                    document={doc}
                    state={'success'}
                  />
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default Dialog;
