import { ApiFields, EstateType } from '@/types/types';

export enum StageOrder {
  calculated = 'request_offer',
  approval = 'approval_offer',
  in_progress = 'in_progress',
  completed = 'completed',
  allCompleted = 'allCompleted',
}

export type ServiceTypeList = {
  [x: number]: EstateType[];
};

export type Fields = {
  [x: number]: ApiFields[];
};

export type IsSelectedServiceTypeList = {
  [x: number]: boolean;
};

export type OrderFilters = {
  real_estate_type_id?: number;
  service_type_id?: number;
  counterparty_id?: number;
  search?: string;
  date_from?: string;
  date_to?: string;
  stage?: StageOrder;
  page?: number;
  limit?: number;
  ordering?: string;
};

export type OrderFilesDataType = {
  total: number;
  total_files: number;
  limit: number;
  page: number;
  items: OrderFilesFoldersType[];
};

export type OrderFilesSendQueryType = {
  contract_id: number | null;
  folder_name?: string;
  search_folder?: string;
  search_date?: string;
};

export type OrderFilesGetQueryType = {
  contract_id: number | null;
  search_folder?: string;
  date?: string;
  page?: number;
  ordering?: SortOrderType;
};

export type OrderFilesFoldersType = {
  folder_name: string;
  files: OrderResultsFilesType[];
  date: string;
};

export type OrderResultsFilesType = {
  filename: string;
  link: string;
};

export type PropsOrderTableType = {
  setOrderDirection: (arg: SortOrderType) => void;
  orderDirection: SortOrderType;
};

export type SortOrderType = 'name' | '-name' | 'date' | '-date' | undefined;
