export type StepperItem = {
  tag: string;
  title: string;
  complete_title?: string;
};

export type StepperProps = {
  data: Array<StepperItem>;
  step: number;
  onActiveItem?: (activeItem: StepperItem) => void;
};

export enum Step {
  calculated = 0,
  approval = 1,
  in_progress = 2,
  completed = 3,
  allCompleted = 4,
}
