import { File } from './file.type';
import { OfferEmployees } from './offer.type';

export type Contract = {
  id: number;
  offer_id: number;
  number: string;
  date: string;
  counterparty_id: number;
  is_sign: boolean;
  price: number;
  prepayment: number;
  payment: number;
  left_to_pay: number;
  is_payment: boolean;
  is_sign_acceptance_act: boolean;
  status: string;
  slack_id: string;
  docs_path: string;
  project_manager: OfferEmployees;
  engineer: OfferEmployees;
  bills: Bill[];
  act: Act;
  contract_file_link: string;
  doc_type?: 'Договор' | 'Заказ';
  docs_manager?: OfferEmployees;
};

export enum ContractStatus {
  RefinementCompleted = 'Доработка завершена',
  Refinement = 'Доработка',
  InWork = 'В работе',
  JobCompleted = 'Работа завершена',
  WorkResumed = 'Работа возобновлена',
  WorkOnRequests = 'Работа по заявкам',
  WorkSuspended = 'Работа приостановлена',
  WorkDidNotStart = 'Работы не начинались',
}

type Act = { file_link: string; filename: string; number: string };

export type Bill = {
  bill_price: number | string; // сумма по счету
  bill_paid: number | string; // сумма оплаты
  file_link: string;
  filename: string;
  number: string;
};

export type Progress = {
  contract_id: number | string;
  date: string;
  name: string;
};

export type Statements = {
  answer_date: string;
  answer_type: string;
  contract_id: number;
  date_send: string;
  declarer: string;
  directed: string;
  id: number;
  number: string;
  services: string;
  statement_type: string;
  request_files: File[];
  respond_files: File[];
};

export type ContractFile = {
  files: File[];
};

export type getContractParams = Partial<{
  search: string;
  date_from: string;
  date_to: string;
  real_estate_type_id: number;
  counterparty_id: number;
  service_type_id: number;
  limit: number;
  page: number;
  active: boolean;
}>;
