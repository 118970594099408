import React, { FC, useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input, Icon, Button } from '@/components/ui';
import { useValidation } from '@/hooks/use-validation';
import { fetchCounterpartyByInn, fetchCounterpartyByBik } from '@/store/slices/counterparty.slice';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { useNotification } from '@/hooks/use-notifications';
import { Counterparty } from '@/types/counterparty.type';

type CheckInnBikFields = {
  inn: string;
  bik: string;
};

type CheckInnBikFormProps = {
  counterpartyType: 'ИП' | 'ЮЛ' | 'ФЛ';
  setCounterparty: React.Dispatch<React.SetStateAction<Counterparty | undefined>>;
  onBack: () => void;
  OnCancel: () => void;
};

const CheckInnBikForm: FC<CheckInnBikFormProps> = (props) => {
  const { onBack, OnCancel, counterpartyType, setCounterparty } = props;
  const validationFields = useValidation();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { createNotification } = useNotification();

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const {
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm<CheckInnBikFields>({
    defaultValues: {
      inn: '',
      bik: '',
    },
  });

  useEffect(() => {
    if (error) {
      createNotification?.({
        variant: 'fail',
        title: error,
      });
      setError('');
    }
  }, [error, createNotification]);

  // Проверка контрагента
  const handleCounterpartyCheck = async (formData: CheckInnBikFields) => {
    setIsLoading(true);
    const innData = await dispatch(fetchCounterpartyByInn(formData.inn));
    const bikData = formData.bik ? await dispatch(fetchCounterpartyByBik(formData.bik)) : undefined;
    if (innData.payload.success) {
      setError('');
      const counterparty = structuredClone(innData.payload.data);
      if (bikData && bikData.payload.success) {
        counterparty.account_data.bik = bikData.payload.data.bic;
        counterparty.account_data.correspondent_account =
          bikData.payload.data.correspondent_account;
        counterparty.account_data.bank_name = bikData.payload.data.value;
      } else {
        counterparty.account_data = null;
      }

      setCounterparty(counterparty);
      reset();
    } else {
      setError('Данные не найдены');
      setCounterparty(undefined);
    }
    setIsLoading(false);
  };

  const handleOnCancel = () => {
    reset();
    OnCancel?.();
  };

  const handleOnBack = () => {
    reset();
    onBack?.();
  };

  return (
    <form onSubmit={handleSubmit(handleCounterpartyCheck)}>
      <div className="flex items-center font-medium bg-primary_50 py-2 px-3 rounded-lg">
        <Icon name="Info" className="text-primary_400 mr-4" />
        {t('create_counterparty.automatic')}
      </div>
      <div className="flex mt-4 mb-8">
        <div className="w-full mr-1.5">
          <Controller
            name="inn"
            control={control}
            rules={
              counterpartyType === 'ЮЛ'
                ? validationFields.legal_inn
                : validationFields.individual_inn
            }
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  label={t('common.input.inn.label') + '*'}
                  error={errors.inn?.message}
                  placeholder={t('common.input.inn.placeholder')}
                  mask={counterpartyType === 'ЮЛ' ? '9999999999' : '999999999999'}
                  disabled={isLoading}
                />
              );
            }}
          />
        </div>
        <div className="w-full mr-1.5">
          <Controller
            name="bik"
            control={control}
            rules={{ ...validationFields.bik, required: false }}
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  label={t('common.input.bik.label')}
                  error={errors.bik?.message}
                  placeholder={t('common.input.bik.placeholder')}
                  mask="999999999"
                  disabled={isLoading}
                />
              );
            }}
          />
        </div>
      </div>
      <div className="w-full flex justify-end mt-[24px]">
        <div className="mr-[14px]">
          <Button
            label={t('create_counterparty.cancel_button')}
            onClick={handleOnCancel}
            variant="outlined"
            space={15}
            disabled={isLoading}
          />
        </div>
        <div className="mr-[14px]">
          <Button
            label={t('create_counterparty.back_button')}
            icon="ChevronLeft"
            onClick={handleOnBack}
            variant="outlined"
            space={15}
            disabled={isLoading}
          />
        </div>
        <div>
          <Button
            type="submit"
            label={t('create_counterparty.more_button')}
            variant="filled"
            disabled={isLoading}
            space={17}
          />
        </div>
      </div>
    </form>
  );
};

export default CheckInnBikForm;
