import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FieldValues, useForm, useWatch } from 'react-hook-form';
import moment from 'moment';
import { Pagination } from 'antd';

import { Box, Button, Container, Input, OrderCard, Select, Selector } from '@/components/ui';
import { route } from '@/util/route';
import { useQuery } from '@/hooks/use-query';
import { SelectSchema } from '@/components/ui/select/select.types';
import { counterpartySelector } from '@/store/selectors/counterparty.selectors';
import { Counterparty } from '@/types/counterparty.type';
import { fetchCounterparties } from '@/store/slices/counterparty.slice';
import {
  fetchEstateTypes,
  fetchServiceTypeList,
  toggleIsDisabledServiceTypeList,
} from '@/store/slices/orders.slices';
import {
  estateTypesSelector,
  isDisabledServiceTypeListSelector,
  serviceTypeListSelector,
} from '@/store/selectors/orders.selectors';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { useAppSelector } from '@/hooks/use-app-selector';
import OrderCardSkeleton from '@/components/ui/order-card/order-card-skeleton';
import { ServiceTypeList, StageOrder, OrderFilters } from '@/types/order.type';
import { EstateType } from '@/types/types';
import { fetchProposals } from '@/store/slices/proposal.slices';
import {
  isLoadingSelector,
  proposalsSelector,
  totalProposalsCountSelector,
  totalProposalsPriceSelector,
  pageProposalsSelector,
} from '@/store/selectors/proposal.selectors';
import { Proposal } from '@/types/proposal.type';
import Datepicker from '@/components/ui/input/datepicker';
import ChatOpen from '@/features/chat/blocks/chatOpen/chatOpen';
import { fetchNewMessagesCount } from '@/store/slices/chat.slice';

type SearchData = {
  searchText: string;
  estateType: SelectSchema | null;
  serviceType: SelectSchema | null;
  counterparty: Counterparty | null;
  date_from: Date | undefined;
  date_to: Date | undefined;
  selectorTag: string;
  stage: string;
};

const OrdersPage = () => {
  const { t } = useTranslation();
  const query = useQuery();
  const active = query.get('tab') || 'active';
  const dispatch = useAppDispatch();

  const pageLimit = 10;

  const { register, control, setValue } = useForm<FieldValues>();
  const searchText = useWatch({
    control,
    name: 'searchText',
  });

  const initFormData = {
    searchText: '',
    estateType: null,
    serviceType: null,
    counterparty: null,
    date_from: undefined,
    date_to: undefined,
    selectorTag: '',
    stage: '',
  };

  const [formData, setFormData] = useState<SearchData>(initFormData);
  const [startDate, setStartDate] = useState<Date | undefined | null>();
  const [endDate, setEndDate] = useState<Date | undefined | null>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [ordering, setOrdering] = useState<string | undefined>();
  const [ordersFetching, setOrdersFetching] = useState<boolean>(true);

  const counterparties: Counterparty[] | null = useAppSelector(counterpartySelector);
  const serviceTypeList: ServiceTypeList = useAppSelector(serviceTypeListSelector);
  const estateTypes: EstateType[] = useAppSelector(estateTypesSelector);
  const orders: Proposal[] = useAppSelector(proposalsSelector);
  const totalProposalsCount: number = useAppSelector(totalProposalsCountSelector);
  const totalProposalsPrice: number = useAppSelector(totalProposalsPriceSelector);
  const page: number = useAppSelector(pageProposalsSelector);
  const ordersIsLoading: boolean = useAppSelector(isLoadingSelector);
  const isDisabledServiceTypeList: boolean = useAppSelector(isDisabledServiceTypeListSelector);

  useEffect(() => {
    // dispatch(fetchProposals({} as any)); // Все заявки
    dispatch(fetchCounterparties({})); // Все контрагенты
    dispatch(fetchEstateTypes()); // Типы объекта недвижимости
    return () => {
      dispatch(toggleIsDisabledServiceTypeList(true));
    };
  }, [dispatch]);

  useEffect(() => {
    if (active === 'completed') {
      formDataUpdate('selectorTag', active);
    } else {
      formDataUpdate('selectorTag', '');
    }
  }, [active]);

  useEffect(() => {
    setOrdering('-number');
  }, []);

  useEffect(() => {
    // для получения общего количества новых сообщений
    dispatch(fetchNewMessagesCount());
    const interval = setInterval(() => {
      dispatch(fetchNewMessagesCount());
    }, 60000);
    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    // let searchTimeout: ReturnType<typeof setTimeout>;
    const searchTimeout = setTimeout(() => {
      formDataUpdate('searchText', searchText);
      setCurrentPage(1);
    }, 500);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchText]);

  useEffect(() => {
    const getStageValue = () => {
      if (formData.selectorTag === 'completed') return StageOrder.completed;
      if (formData.selectorTag === 'request') return StageOrder.calculated;
      if (formData.selectorTag === 'kp') return StageOrder.approval;
      if (formData.selectorTag === 'contract') return StageOrder.in_progress;
    };
    const params: OrderFilters = {
      real_estate_type_id: (formData.estateType?.id as number) || 0,
      service_type_id: (formData.serviceType?.id as number) || 0,
      counterparty_id: (formData.counterparty?.id as unknown as number) || 0,
      search: formData.searchText,
      date_from: formData.date_from ? moment(formData.date_from).format('YYYY-MM-DD') : undefined,
      date_to: formData.date_to ? moment(formData.date_to).format('YYYY-MM-DD') : undefined,
      page: currentPage,
      limit: pageLimit,
      ordering: ordering,
    };
    if (getStageValue()) params.stage = getStageValue();
    if (currentPage !== 0) {
      setOrdersFetching(false);
      dispatch(fetchProposals(params));
    }
  }, [formData, dispatch, currentPage, ordering]);

  const selectorData = [
    { tag: '', title: t('orders.selector.all') },
    { tag: 'request', title: t('orders.selector.request') },
    { tag: 'kp', title: t('orders.selector.kp') },
    { tag: 'contract', title: t('orders.selector.contract') },
    { tag: 'completed', title: t('orders.selector.completed') },
  ];

  const renderEmptyOrder = (
    <div className="w-full mt-[182px] inline-flex flex-col items-center justify-center">
      <span className="text-paragraph_l text-light_2">{t('orders.empty.no_requests')}</span>
      <div className="mt-[24px]">
        <Link to={route.createOrder}>
          <Button label={t('orders.btn.request_kp')} icon="Plus" />
        </Link>
      </div>
    </div>
  );

  const changeSelectEstateType = (type: SelectSchema) => {
    dispatch(fetchServiceTypeList({ id: type.id }));
    dispatch(toggleIsDisabledServiceTypeList(false));
    formDataUpdate('estateType', type);
    formDataUpdate('serviceType', {});
    setCurrentPage(1);
  };

  const changeSelectServiceType = (type: SelectSchema) => {
    formDataUpdate('serviceType', type);
    setCurrentPage(1);
  };

  const changeSelectCounterpaty = (option: SelectSchema) => {
    const currentCounterParty = counterparties?.find(
      (counterparty) => counterparty.id === option.value,
    );
    formDataUpdate('counterparty', currentCounterParty as Counterparty);
    setCurrentPage(1);
  };

  const changeDateRange = (update: [Date | null, Date | null]) => {
    const [startDate, endDate] = update;
    setStartDate(startDate);
    setEndDate(endDate);
    if (startDate && endDate) {
      formDataUpdate('date_from', startDate);
      formDataUpdate('date_to', endDate);
      setCurrentPage(1);
    }
  };

  const resetDateRange = () => {
    setStartDate(null);
    setEndDate(null);
    formDataUpdate('date_from', null);
    formDataUpdate('date_to', null);
    setCurrentPage(1);
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderOrders =
    ordersFetching || ordersIsLoading ? (
      <Box>
        <OrderCardSkeleton count={5} ordering={ordering} />
      </Box>
    ) : orders?.length ? (
      <Box>
        <OrderCard
          dataSource={orders}
          isLoading={ordersIsLoading}
          setOrdering={setOrdering}
          ordering={ordering}
        />
        <div className="flex justify-end w-full p-4">
          <Pagination
            current={page}
            defaultPageSize={pageLimit}
            onChange={onPageChange}
            total={totalProposalsCount}
            showSizeChanger={false}
          />
        </div>
      </Box>
    ) : (
      renderEmptyOrder
    );

  function formDataUpdate(
    fieldName: string,
    value: string | number | Date | { [p: string]: string | number } | null | Counterparty,
  ) {
    setFormData((prevState) => ({ ...prevState, [fieldName]: value }));
  }

  function formDataReset() {
    setFormData(initFormData);
    setValue('searchText', '');
    dispatch(toggleIsDisabledServiceTypeList(true));
    setCurrentPage(1);
  }

  return (
    <Container>
      <div className="flex flex-col w-full pb-4">
        <div className="orders_filters">
          <div className="my-[20px]">
            <p className="text-heading_h2 font-bold text-gray_600">{t('orders.filter_title')}</p>
          </div>
          <div className="flex flex-col md:flex-row items-center gap-[20px] mb-[16px]">
            <div className="w-full md:min-w-[572px] lg:w-[572px]">
              <Input
                {...register('searchText')}
                beforeIcon="Search"
                icon={searchText && 'Close'}
                onIconClick={() => {
                  setValue('searchText', '');
                  setCurrentPage(1);
                }}
                defaultValue={searchText}
                placeholder={t('orders.input.cadaster.placeholder')}
              />
            </div>
            <div
              title={formData.estateType?.name as string}
              className="w-full mt-[10px] md:mt-[0px] min-w-[277px] lg:w-[277px]"
            >
              <Select
                initialValue={formData.estateType ? formData.estateType?.name : ''}
                activeValue={formData.estateType ? formData.estateType?.name : ''}
                onSelectOption={changeSelectEstateType}
                schema={{ title: 'name', value: 'id' }}
                data={estateTypes}
                placeholder={t('orders.input.object_type.placeholder')}
                withReset
                reset={() => {
                  formDataUpdate('estateType', null);
                  formDataUpdate('serviceType', null);
                  dispatch(toggleIsDisabledServiceTypeList(true));
                  setCurrentPage(1);
                }}
              />
            </div>
            {formData.estateType ? (
              <div
                title={formData?.serviceType?.name as string}
                className="w-full mt-[10px] md:mt-[0px] md:px-0 min-w-[277px]"
              >
                <Select
                  onSelectOption={changeSelectServiceType}
                  initialValue={formData.serviceType ? formData.serviceType?.name : ''}
                  activeValue={formData.serviceType ? formData.serviceType?.name : ''}
                  schema={{ title: 'name', value: 'id' }}
                  data={serviceTypeList[0]}
                  placeholder={t('orders.input.service.placeholder')}
                  disabled={isDisabledServiceTypeList}
                  withReset
                  reset={() => {
                    formDataUpdate('serviceType', null);
                    setCurrentPage(1);
                  }}
                />
              </div>
            ) : null}
          </div>
          <div className="flex flex-col md:flex-row items-center justify-between gap-[20px]">
            <div className="w-full pr-0 mt-[10px] lg:mt-[0px] md:min-w-[275px] md:w-[275px]">
              <Select
                initialValue={
                  formData.counterparty
                    ? ((formData.counterparty.counterparty_type === 'ФЛ'
                        ? `${formData.counterparty.individual_data?.surname} ${formData.counterparty.individual_data?.first_name} ${formData.counterparty.individual_data?.middle_name}`
                        : formData.counterparty.legal_data?.name) as string)
                    : ''
                }
                activeValue={
                  formData.counterparty
                    ? ((formData.counterparty.counterparty_type === 'ФЛ'
                        ? `${formData.counterparty.individual_data?.surname} ${formData.counterparty.individual_data?.first_name} ${formData.counterparty.individual_data?.middle_name}`
                        : formData.counterparty.legal_data?.name) as string)
                    : ''
                }
                onSelectOption={changeSelectCounterpaty}
                data={
                  counterparties
                    ? counterparties.map((item) => {
                        return {
                          title:
                            (item.counterparty_type === 'ФЛ'
                              ? `${item.individual_data?.surname} ${item.individual_data?.first_name} ${item.individual_data?.middle_name}`
                              : item.legal_data?.name) || '',
                          value: item.id,
                        };
                      })
                    : []
                }
                placeholder={t('orders.input.counterparties.placeholder')}
                withReset
                reset={() => {
                  formDataUpdate('counterparty', null);
                  setCurrentPage(1);
                }}
              />
            </div>
            <div className="w-full mt-[10px] md:mt-0 pr-0 md:min-w-[275px] md:w-[275px] ">
              <Datepicker
                isRange={true}
                startDate={startDate}
                endDate={endDate}
                icon="Calendar"
                resetIcon={formData?.date_from && 'Close'}
                onResetIconClick={resetDateRange}
                placeholder={t('orders.input.period.placeholder')}
                onRangeChange={changeDateRange}
              />
            </div>
            <div className="w-full mt-[10px] md:mt-0 md:max-w-[577px]">
              <Selector
                defaultValue={formData.selectorTag}
                data={selectorData}
                onSelect={(option) => {
                  formDataUpdate('selectorTag', option.tag);
                  setCurrentPage(1);
                }}
                disabled={active === 'completed'}
              />
            </div>
          </div>
          <div className="flex justify-between flex-col md:flex-row my-[24px] items-center">
            <div className="flex items-center ml-auto gap-[24px]">
              {!Object.values(formData).filter((value) => !!value).length &&
              active === 'active' &&
              orders?.length &&
              !ordersIsLoading ? (
                <div className="text-paragraph_l font-medium text-gray_400">
                  {t('orders.totalActiveOrders')}
                  <span className="text-gray-600"> {totalProposalsCount}</span>
                </div>
              ) : null}
              {!!Object.values(formData).filter((value) => !!value).length && !ordersIsLoading && (
                <div className="mt-[10px] md:mt-0">
                  <Link to={route.orders}>
                    <Button
                      icon="Close"
                      label={t('orders.btn.reset_filters')}
                      variant="outlined"
                      onClick={formDataReset}
                    />
                  </Link>
                </div>
              )}
              <Link to={route.createOrder}>
                <div className="mt-[10px] md:mt-0">
                  <Button icon="Plus" label={t('orders.btn.request_kp')} />
                </div>
              </Link>
            </div>
          </div>
          {!!Object.values(formData).filter((value) => !!value).length && !ordersIsLoading && (
            <div className="flex md:justify-end text-paragraph_l font-medium text-gray_400 mb-4">
              <p className="md:w-[157px]">
                {t('orders.find')}
                <span className="text-gray-600">{(totalProposalsCount || 0).toLocaleString()}</span>
              </p>
              <p className="ml-8 md:w-[300px]">
                {t('orders.sum')}
                <span className="text-gray-600">{(totalProposalsPrice || 0).toLocaleString()}</span>
                {t('orders.rub')}
              </p>
            </div>
          )}
        </div>
        {renderOrders}
      </div>
      <ChatOpen />
    </Container>
  );
};

export default OrdersPage;
