// файл в base64 формат
import { ResultFile } from '@/types/file.type';

export function readAsDataURL(item: File) {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = function () {
      return resolve({
        file: fileReader.result,
        file_name: item.name,
      });
    };
    fileReader.readAsDataURL(item);
  });
}

// преобразователь цены
export function priceConvertor(price: string | number) {
  return new Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: 2,
  }).format(Number(price));
}

// преобразователь даты с учетом выходных
export function dateConvertorWithoutWeekends(dayWeek: number) {
  // если день недели пятница
  if (dayWeek == 5) {
    return 3;
  }
  // если суббота
  if (dayWeek == 6) {
    return 2;
  }
  return 1;
}

export const allFilesCounter = (arr: ResultFile[]) => {
  return arr.reduce((accumulator, currentValue) => accumulator + currentValue.files.length, 0);
};

export function getPaginationItems(currentPage: number, lastPage: number, maxLength: number) {
  const res: Array<number> = [];

  // handle lastPage less than maxLength
  if (lastPage <= maxLength) {
    for (let i = 1; i <= lastPage; i++) {
      res.push(i);
    }
  }

  // handle ellipsis logics
  else {
    const firstPage = 1;
    const confirmedPagesCount = 3;
    const deductedMaxLength = maxLength - confirmedPagesCount;
    const sideLength = deductedMaxLength / 2;

    // handle ellipsis in the middle
    if (currentPage - firstPage < sideLength || lastPage - currentPage < sideLength) {
      for (let j = 1; j <= sideLength + firstPage; j++) {
        res.push(j);
      }

      res.push(NaN);

      for (let k = lastPage - sideLength; k <= lastPage; k++) {
        res.push(k);
      }
    }

    // handle two ellipsis
    else if (
      currentPage - firstPage >= deductedMaxLength &&
      lastPage - currentPage >= deductedMaxLength
    ) {
      const deductedSideLength = sideLength - 1;

      res.push(1);
      res.push(NaN);

      for (let l = currentPage - deductedSideLength; l <= currentPage + deductedSideLength; l++) {
        res.push(l);
      }

      res.push(NaN);
      res.push(lastPage);
    }

    // handle ellipsis not in the middle
    else {
      const isNearFirstPage = currentPage - firstPage < lastPage - currentPage;
      let remainingLength = maxLength;

      if (isNearFirstPage) {
        for (let m = 1; m <= currentPage + 1; m++) {
          res.push(m);
          remainingLength -= 1;
        }

        res.push(NaN);
        remainingLength -= 1;

        for (let n = lastPage - (remainingLength - 1); n <= lastPage; n++) {
          res.push(n);
        }
      } else {
        for (let o = lastPage; o >= currentPage - 1; o--) {
          res.unshift(o);
          remainingLength -= 1;
        }

        res.unshift(NaN);
        remainingLength -= 1;

        for (let p = remainingLength; p >= 1; p--) {
          res.unshift(p);
        }
      }
    }
  }

  return res;
}

// export const getPaginationItems = (currentPage: number, total: number, limit: number) => {
//   const pageCount = Math.ceil(total / limit);
//
//   if (currentPage < 1) {
//     currentPage = 1;
//   } else if (currentPage > pageCount) {
//     currentPage = pageCount;
//   }
//
//   const res: Array<number> = [];
//
//   for (let i = 1; i <= pageCount; i++) {
//     res.push(i);
//   }
//
//   return res;
// };

//throttle
export const throttle = <T extends (...args: any[]) => void>(
  func: T,
  wait: number,
): ((this: ThisParameterType<T>, ...args: Parameters<T>) => void) => {
  let timeout: number | null = null;

  return function (this: ThisParameterType<T>, ...args: Parameters<T>): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;

    const later = function () {
      timeout = null;
      func.apply(context, args);
    };

    if (!timeout) {
      timeout = window.setTimeout(later, wait);
    }
  };
};
