import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { fetchCounterparties } from '@/store/slices/counterparty.slice';
import { Modal } from '@/components/ui';
import CounterpartyListBlock from '@/features/counterparties/blocks/counterparty-list.block';
import LegalForm from '@/components/counterparty-forms/legal-form';
import IndividualForm from '@/components/counterparty-forms/individual-form';
import { useAppSelector } from '@/hooks/use-app-selector';
import { counterpartySelector } from '@/store/selectors/counterparty.selectors';
import { Counterparty } from '@/types/counterparty.type';

const MyClientsBlock: FC = () => {
  const pageLimit = 10;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const counterparties: Counterparty[] = useAppSelector(counterpartySelector); // Все контрагенты

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [counterparty, setCounterparty] = useState<Counterparty | null>(null);
  const [isShownForm, setIsShownForm] = useState(false);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      limit: pageLimit,
      my_referral: true,
    };
    dispatch(fetchCounterparties(params));
  }, [currentPage, dispatch]);

  const handleOnCancelCounterpartyForm = () => {
    setCounterparty(null);
    setIsShownForm(false);
  };

  const openCounterpartyForEdit = (item: Counterparty) => {
    setCounterparty(item);
    setIsShownForm(true);
  };

  return (
    <div>
      <CounterpartyListBlock
        counterparties={counterparties}
        onPageChange={onPageChange}
        pageLimit={pageLimit}
        openCounterpartyForEdit={openCounterpartyForEdit}
      />
      {isShownForm && counterparty ? (
        <Modal
          type="dialog"
          withoutFooter={true}
          label={t('create_counterparty.eidt.title')}
          onCancel={handleOnCancelCounterpartyForm}
          isShow={isShownForm}
        >
          {counterparty?.counterparty_type === 'ИП' || counterparty?.counterparty_type === 'ЮЛ' ? (
            <LegalForm counterparty={counterparty} OnCancel={handleOnCancelCounterpartyForm} />
          ) : (
            <IndividualForm counterparty={counterparty} OnCancel={handleOnCancelCounterpartyForm} />
          )}
        </Modal>
      ) : null}
    </div>
  );
};

export default MyClientsBlock;
