import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { Box, Button, Icon, Input } from '@/components/ui';
import { useResendRestoreEmail } from '@/features/feature-hooks/use-resend-restore-email';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useConfirmMutation, useRegisterMutation } from '@/store/api/auth.api';
import { changeResendEmail } from '@/store/slices/common.slice';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { ConfirmFields } from '@/types/auth.type';
import { registerDataSelector } from '@/store/selectors/auth.selectors';
import { setUser } from '@/store/slices/auth.slice';

const RegistrationSuccessBlock = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const resendRestoreEmail = useResendRestoreEmail();
  const navigate = useNavigate();

  const { register, handleSubmit } = useForm<ConfirmFields>();

  const email: string = useAppSelector((state) => state.commonStore.resendEmail);

  const [error, setError] = useState<string>('');

  const [confirm] = useConfirmMutation();
  const [registration] = useRegisterMutation();

  const registerData = useAppSelector(registerDataSelector);

  const onResendEmail = async () => {
    resendRestoreEmail();
    await registration(registerData);
  };

  const onConfirm = async (data: ConfirmFields) => {
    const returned: any = await confirm({ email, code: data.code });
    if (returned?.data?.success) {
      setError('');
      dispatch(changeResendEmail({ email: '', pause: 0, isResendEmail: false }));
      const { token, me } = returned.data.data;
      dispatch(setUser({ token, me }));
      navigate('/');
    } else {
      setError(returned.error.data.error);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(
        changeResendEmail({
          email: '',
          pause: 0,
          isResendEmail: false,
        }),
      );
    };
  }, [dispatch]);

  return (
    <div className="w-[508px]">
      <Box>
        <div className="w-full py-[40px] px-[80px]">
          <div className="w-full inline-flex justify-center mb-[24px]">
            <Icon name="EmailBig" size={80} className="text-primary_500" />
          </div>
          <h1 className="text-heading_h2 text-center font-bold text-gray_600 mb-[12px]">
            {t('registration.success_title')}
          </h1>
          <p className="text-paragraph_l text-center text-gray_600 mb-[24px]">
            <Trans
              i18nKey="registration.success_description"
              values={{ email: email, timeout: 15 }}
            />
          </p>
          <div>
            <Button
              label={t('registration.btn.resend_mail_again')}
              onClick={onResendEmail}
              variant="outlined"
              isFull
            />
            <form onSubmit={handleSubmit(onConfirm)}>
              <div className="mt-[12px]">
                <Input
                  type="text"
                  {...register('code')}
                  placeholder={t('common.input.code.placeholder')}
                />
              </div>
              <div className="m-[5px]" style={{ color: 'red', fontSize: '14px' }}>
                {error && <span>{error}</span>}
              </div>
              <div className="mt-[12px]">
                <Button type="submit" label={t('registration.btn.confirm')} isFull />
              </div>
            </form>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default RegistrationSuccessBlock;
