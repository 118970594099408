import React, { FC } from 'react';

import NoContractBlock from './no-contract.block';
import ContractFormedBlock from './contract-formed.block';
import ContractIsSignedBlock from './contract-is-signed.block';
import { GenerateReportFields, RequisitesAndContractBlockProps } from './types';

import { useAppSelector } from '@/hooks/use-app-selector';
import { userSelector } from '@/store/selectors/auth.selectors';
import { User } from '@/types/user.type';
import { useNotification } from '@/hooks/use-notifications';
import { useSetPartnerCounterpartiesMutation } from '@/store/api/affiliate-program.api';
import { Counterparty } from '@/types/counterparty.type';
import { counterpartySelector } from '@/store/selectors/counterparty.selectors';
import { useLazyMeQuery } from '@/store/api/auth.api';
import { setUser } from '@/store/slices/auth.slice';
import { useAppDispatch } from '@/hooks/use-app-dispatch';

const RequisitesAndContractBlock: FC<RequisitesAndContractBlockProps> = ({ form }) => {
  const user: User | null = useAppSelector(userSelector);
  const { createNotification } = useNotification();
  const dispatch = useAppDispatch();

  const counterparties: Counterparty[] = useAppSelector(counterpartySelector); // Все контрагенты

  const [setPartner] = useSetPartnerCounterpartiesMutation();
  const [refetchMe] = useLazyMeQuery();

  const handleGenerateReport = (data: GenerateReportFields) => {
    const counterparty_id = counterparties?.find(
      (counterparty) => counterparty.legal_data?.inn === data.inn,
    )?.id;

    if (counterparty_id) {
      setPartner({
        counterparty_id: +counterparty_id,
        updated_account_data: {
          bank_name: data.bank_name,
          bik: data.bik,
          billing_account: data.billing_account,
          correspondent_account: data.correspondent_account,
        },
      }).then((result: any) => {
        if (result?.error) {
          createNotification?.({
            variant: 'fail',
            title: 'Ошибка',
            description: result.error.data.error,
          });
        } else {
          createNotification?.({
            variant: 'success',
            title: 'Отчет сформирован',
            // description: 'Отчет сформирован',
          });
          refetchMe({}).then(({ data }) => {
            const { token, me } = data.data;
            dispatch(setUser({ token, me }));
          });
        }
      });
    } else {
      setPartner({
        counterparty_new: {
          counterparty_type: data.counterparty_type,
          account_data: {
            bank_name: data.bank_name,
            bik: data.bik,
            billing_account: data.billing_account,
            correspondent_account: data.correspondent_account,
          },
          legal_data: {
            address: data.address,
            inn: data.inn,
            kpp: data.kpp,
            manager_name: data.manager_name,
            manager_position: data.manager_position,
            name: data.name,
            ogrn: data.ogrn,
          },
          referral: true,
        },
      }).then((result: any) => {
        if (result?.error) {
          createNotification?.({
            variant: 'fail',
            title: 'Ошибка',
            description: result.error.data.error,
          });
        } else {
          createNotification?.({
            variant: 'success',
            title: 'Отчет сформирован',
            // description: 'Отчет сформирован',
          });
          refetchMe({}).then(({ data }) => {
            const { token, me } = data.data;
            dispatch(setUser({ token, me }));
          });
        }
      });
    }
  };

  return (
    <>
      {!user?.referral_contract_url && !user?.counterparty_link ? ( // договора нет, форма не заполнена
        <NoContractBlock form={form} submit={form.handleSubmit(handleGenerateReport)} />
      ) : !user?.referral_contract_url && user?.counterparty_link ? ( // договор сформирован
        <ContractFormedBlock />
      ) : user?.referral_contract_url && user?.counterparty_link ? ( //договор подписан
        <ContractIsSignedBlock />
      ) : (
        <></>
      )}
    </>
  );
};

export default RequisitesAndContractBlock;
