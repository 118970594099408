import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { RequisitesAndContractWrapperBlockProps } from './types';

const RequisitesAndContractWrapperBlock: FC<RequisitesAndContractWrapperBlockProps> = ({
  desctiption,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <div className="w-[674px] bg-white rounded-lg border border-gray_200 p-[16px] text-gray_600 mb-[75px]">
      <h3 className="text-heading_h3 text-gray_600 font-bold">
        {t('affiliate-program.menu.requisites-and-contract')}
      </h3>
      <p className="text-paragraph_l whitespace-pre-wrap mt-[12px] mb-[16px] text-gray_500">
        {desctiption}
      </p>
      {children}
    </div>
  );
};

export default RequisitesAndContractWrapperBlock;
