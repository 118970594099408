import {
  ChatCreateMessage,
  ChatType,
  GettingMessageParams,
  messagesResponseType,
  newMessagesCountResponseType,
  PaginationChatType,
} from '@/types/chat.type';
import { ListResponsePagination } from '@/types/responses.type';
import { authApi } from '@/store/api/auth.api';

export const chatApi = authApi.injectEndpoints({
  endpoints: (build) => ({
    // запрос чатов
    chats: build.query<ListResponsePagination<ChatType[]>, PaginationChatType>({
      query: (params) => ({
        url: '/chat/',
        params: params,
      }),

      providesTags: ['Auth'],
    }),
    messages: build.query<messagesResponseType, GettingMessageParams>({
      query: ({ chat_id, paginationParams }) => ({
        url: `/chat/${chat_id}`,
        params: paginationParams,
      }),
      providesTags: ['Messages'],
    }),
    viewNewMessages: build.query<messagesResponseType, number>({
      query: (chat_id) => ({
        url: `/chat/${chat_id}/view_new_messages`,
      }),
      providesTags: ['Auth'],
    }),
    newMessagesCount: build.query<newMessagesCountResponseType, null>({
      query: () => ({
        url: '/chat/new_messages_count',
      }),
    }),
    createNewMessage: build.mutation<any, ChatCreateMessage>({
      query: (data) => ({
        url: '/chat/send_message',
        method: 'POST',
        body: data,
      }),
      // invalidatesTags: ['Messages'],
    }),
    searchChat: build.mutation<any, any>({
      query: (value) => ({
        url: `/chat/search?search_string=${value}`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useChatsQuery,
  useLazyViewNewMessagesQuery,
  useLazyMessagesQuery,
  useMessagesQuery,
  useCreateNewMessageMutation,
  useSearchChatMutation,
  useViewNewMessagesQuery,
  useNewMessagesCountQuery,
} = chatApi;
