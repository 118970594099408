import React from 'react';

const Logout = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14.167 13.334l3.333-3.333m0 0l-3.333-3.334m3.333 3.334H5.833m5 3.333v.833a2.5 2.5 0 01-2.5 2.5H5a2.5 2.5 0 01-2.5-2.5V5.834a2.5 2.5 0 012.5-2.5h3.333a2.5 2.5 0 012.5 2.5v.833"
      ></path>
    </svg>
  );
};

export default Logout;
