import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { SerializedError } from '@reduxjs/toolkit';

import Conversations from '@/features/chat/blocks/conversations/conversations';

import { ChatType } from '@/types/chat.type';
import { throttle } from '@/util/helpers';
import { useNotification } from '@/hooks/use-notifications';
import Dialog from '@/features/chat/blocks/dialog/dialog';
import { ListResponsePagination } from '@/types/responses.type';
import { useDebounce } from '@/hooks/use-debounce';
import { useSearchChatMutation } from '@/store/api/chat.api';

type ChatBodyProps = {
  data?: ListResponsePagination<ChatType[]>;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  currentPage: number;
  changePage: (page: number) => void;
  value: string;
  isFetching: boolean;
};
const ChatBody = ({
  data,
  isSuccess,
  isError,
  error,
  isLoading,
  currentPage,
  changePage,
  value,
  isFetching,
}: ChatBodyProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const loadingIndicatorRef = useRef<HTMLDivElement>(null);
  // const userId = useAppSelector(userSelector)?.id ?? null;
  const [chats, setChats] = useState<ChatType[]>([]);
  const [currentChat, setCurrentChat] = useState<ChatType | null>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [errorNotificationShown, setErrorNotificationShown] = useState(false);
  const { createNotification } = useNotification();
  const debouncedValue = useDebounce<string>(value, 500);
  const [searchChat, result] = useSearchChatMutation();

  const onSetCurrentChat = (chat: ChatType) => {
    setCurrentChat(chat);
  };

  useEffect(() => {
    if (result?.data?.data?.items) {
      setChats(result.data.data.items);
    }
  }, [result]);

  useEffect(() => {
    // Проверяем, есть ли элемент с индикатором загрузки и isFetching
    if (isFetching && loadingIndicatorRef.current) {
      // Прокручиваем к элементу с индикатором загрузки
      loadingIndicatorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [isFetching]);

  useEffect(() => {
    if (debouncedValue) {
      searchChat(value);
    }
    if (!debouncedValue && data) {
      setChats(data.data.items);
      setCurrentChat(null);
    }
    changePage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    if (isError && createNotification && !errorNotificationShown) {
      createNotification({
        variant: 'fail',
        title: 'Произошла ошибка, попробуйте позже',
      });
      setErrorNotificationShown(true);
    }
  }, [isError, error, createNotification, errorNotificationShown]);
  useEffect(() => {
    if (isSuccess && currentPage === 1 && data) {
      setChats(data.data.items);
      setTotalCount(data.data.total);
    }
    // if (userId && data && data.data.items.length === 0 && currentPage === 1) {
    //   setChats([defaultChatForNewUser(userId)]);
    // }

    if (isSuccess && currentPage !== 1 && data) {
      const newChats = data?.data?.items;
      setChats((prevChats) => [...prevChats, ...newChats]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const scrollHandler = useCallback(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      const scrollPosition = modalElement.scrollTop;
      const scrollHeight = modalElement.scrollHeight;
      const clientHeight = modalElement.clientHeight;
      if (
        scrollPosition + clientHeight >= scrollHeight &&
        totalCount > chats.length &&
        !debouncedValue
      ) {
        changePage(currentPage + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount, chats.length]);

  const throttledRequest = throttle(scrollHandler, 500);

  useEffect(() => {
    const modalElement = modalRef.current;

    if (modalElement) {
      modalElement.addEventListener('scroll', throttledRequest);
    }

    return function () {
      if (modalElement) {
        modalElement.removeEventListener('scroll', throttledRequest);
      }
    };
  }, [throttledRequest]);

  return (
    <div className="h-[500px] flex">
      {isLoading ? (
        <div className="flex items-center mx-auto">
          <Spin />
        </div>
      ) : (
        <>
          <div className="w-[250px] overflow-y-auto  " ref={modalRef}>
            <Conversations
              chats={chats}
              onSetCurrentChat={onSetCurrentChat}
              currentChat={currentChat}
            />
            {isFetching && (
              <div className="flex justify-center mt-2 mb-2" ref={loadingIndicatorRef}>
                <Spin />
              </div>
            )}
          </div>
          <div className="flex-1 min-w-[0]">
            <Dialog currentChat={currentChat} key={currentChat?.id} />
          </div>
        </>
      )}
    </div>
  );
};

export default ChatBody;
