import React from 'react';

const Profile = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12.357 8.19a3.333 3.333 0 10-4.714-4.714 3.333 3.333 0 004.714 4.714zM5.875 13.375a5.833 5.833 0 019.958 4.125H4.167c0-1.547.614-3.03 1.708-4.125z"
      ></path>
    </svg>
  );
};

export default Profile;
