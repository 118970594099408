import React from 'react';

import PageLink from './page-link';

import { getPaginationItems } from '@/util/helpers';
import { Icon } from '@/components/ui';

export type Props = {
  currentPage: number;
  maxLength: number;
  lastPage: number;
  setCurrentPage: (page: number) => void;
};

export default function Pagination({ currentPage, lastPage, setCurrentPage, maxLength }: Props) {
  const pageNums = getPaginationItems(currentPage, lastPage, maxLength);

  return (
    <nav className="flex-wrap flex items-center" aria-label="Pagination">
      <PageLink
        className="border border-gray_200 rounded-l-lg p-[10px]"
        disabled={currentPage === 1}
        onClick={() => setCurrentPage(currentPage - 1)}
      >
        <Icon name="ChevronLeft" />
      </PageLink>
      {pageNums.map((pageNum, idx) => (
        <PageLink
          key={idx}
          active={currentPage === pageNum}
          disabled={isNaN(pageNum)}
          onClick={() => setCurrentPage(pageNum)}
          className="border border-gray_200 -ml-[1px]"
        >
          {!isNaN(pageNum) ? pageNum : '...'}
        </PageLink>
      ))}
      <PageLink
        className="border border-gray_200 -ml-[1px] rounded-r-lg p-[10px]"
        disabled={currentPage === lastPage}
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        <Icon name="ChevronRight" />
      </PageLink>
    </nav>
  );
}
