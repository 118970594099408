import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { textPkDescriptionClassName, textPkTitleClassName } from './kp.styles';

import { Offer } from '@/types/offer.type';
import { priceConvertor } from '@/util/helpers';
import { Contract } from '@/types/contract.type';

type PropsType = {
  offer?: Offer | null;
  contract?: Contract | null;
};

const ServicesOrderDetail: React.FC<PropsType> = ({ offer, contract }) => {
  const { t } = useTranslation();
  return (
    <div className="ml-[16px] mr-[16px] mt-[16px] mb-[24px]">
      <div className=" mb-[12px]">
        {offer?.price && (
          <div className="flex flex-row w-full gap-10">
            <div>
              <div className={textPkTitleClassName}>{t('view_order.service_price')}</div>
              <div className={clsx(textPkDescriptionClassName, 'font-bold')}>
                {priceConvertor(offer?.price || 0)} {t('order_card.currency')}
              </div>
            </div>
            <div>
              <div className={textPkTitleClassName}>{t('view_order.prepayment')}</div>
              <div className={clsx(textPkDescriptionClassName, 'font-bold')}>
                {priceConvertor(contract?.prepayment || 0)} {t('order_card.currency')}
              </div>
            </div>
            <div>
              <div className={textPkTitleClassName}>{t('view_order.payment')}</div>
              <div className={clsx(textPkDescriptionClassName, 'font-bold')}>
                {priceConvertor(contract?.payment || 0)} {t('order_card.currency')}
              </div>
            </div>
            <div>
              <div className={textPkTitleClassName}>{t('view_order.left_to_pay')}</div>
              <div className={clsx(textPkDescriptionClassName, 'font-bold')}>
                {priceConvertor(contract?.left_to_pay || 0)} {t('order_card.currency')}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className=" mb-[12px]">
        {offer?.subject && (
          <>
            <div className={textPkTitleClassName}>{t('view_order.object_of_thread')}</div>
            <div className={textPkDescriptionClassName}>{offer?.subject}</div>
          </>
        )}
      </div>
      <div className=" mb-[12px]">
        {offer?.needed_documents && (
          <>
            <div className={textPkTitleClassName}>{t('view_order.documents_needed')}</div>
            <div className={textPkDescriptionClassName}>{offer?.needed_documents}</div>
          </>
        )}
      </div>
      <div className=" mb-[12px]">
        {offer?.results && (
          <>
            <div className={textPkTitleClassName}>{t('view_order.work_result')}</div>
            <div className={textPkDescriptionClassName}>{offer?.results}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(ServicesOrderDetail);
