import React, { ChangeEvent } from 'react';

import ChatInput from '@/features/chat/blocks/chatInput';
import { Icon } from '@/components/ui';

type ChatHeaderPropsType = {
  closeChat: () => void;
  value: string;
  setValue: (value: string) => void;
};

const ChatHeader = ({ closeChat, value, setValue }: ChatHeaderPropsType) => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };
  const iconCloseSearch = value ? 'CloseSearchInChat' : undefined;
  const clearValue = () => {
    setValue('');
  };

  return (
    <div className={'bg-primary_400 py-2 px-3 rounded-tl-lg rounded-tr-lg flex items-center'}>
      <ChatInput
        beforeIcon="SearchInChat"
        icon={iconCloseSearch}
        placeholder="Поиск"
        onChange={onChangeHandler}
        defaultValue={value}
        onIconClick={clearValue}
        // disabled={true}
      />
      <div onClick={closeChat} className="cursor-pointer">
        <Icon name="CloseChat" size={24} />
      </div>
    </div>
  );
};

export default ChatHeader;
