import React, { FC, useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox, Tooltip } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import { Input, Icon, Button } from '@/components/ui';
import { useValidation } from '@/hooks/use-validation';
import {
  useCreateСounterpartiesMutation,
  useUpdateСounterpartyMutation,
} from '@/store/api/counterparty.api';
import { addCounterparty, changeCounterpatyItem } from '@/store/slices/counterparty.slice';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { useNotification } from '@/hooks/use-notifications';
import { Counterparty } from '@/types/counterparty.type';
import { useAppSelector } from '@/hooks/use-app-selector';
import { userSelector } from '@/store/selectors/auth.selectors';

type CreateIndividualCounterpartyFields = {
  surname: string;
  middle_name: string;
  first_name: string;
  passport_ser_num: string;
  passport_issued_by: string;
  passport_date: string;
  passport_snils: string;
};

type IndividualFormProps = {
  counterparty?: Counterparty;
  counterpartyType?: 'ИП' | 'ЮЛ' | 'ФЛ';
  onBack?: () => void;
  OnCancel?: () => void;
};

const IndividualForm: FC<IndividualFormProps> = (props) => {
  const { counterparty, onBack, OnCancel } = props;
  const validationFields = useValidation();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { createNotification } = useNotification();
  const user = useAppSelector(userSelector);

  const [createCounterparty, { isLoading: createLoading }] = useCreateСounterpartiesMutation();
  const [updateCounterparty, { isLoading: updateLoading }] = useUpdateСounterpartyMutation();
  const [error, setError] = useState('');
  const [isReferral, setIsReferral] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm<CreateIndividualCounterpartyFields>(
    counterparty?.id
      ? {
          defaultValues: {
            ...counterparty?.individual_data,
            passport_date: counterparty?.individual_data?.passport_date
              .split('-')
              .reverse()
              .join('.'),
          },
        }
      : {
          defaultValues: {
            surname: user?.surname,
            middle_name: user?.middle_name,
            first_name: user?.name,
            passport_ser_num: '',
            passport_issued_by: '',
            passport_snils: '',
            passport_date: '',
          },
        },
  );

  useEffect(() => {
    setIsReferral(counterparty?.referral || false);
  }, [counterparty]);

  useEffect(() => {
    if (error) {
      createNotification?.({
        variant: 'fail',
        title: error,
      });
      setError('');
    }
  }, [error, createNotification]);

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    setIsReferral(e.target.checked);
  };

  // Создание или редактирование контрагента
  async function submitForm(data: CreateIndividualCounterpartyFields) {
    if (!error) {
      const params = {
        counterparty_type: 'ФЛ',
        referral: isReferral,
        individual_data: {
          ...data,
          passport_date: data.passport_date.split('.').reverse().join('-'),
          passport_snils: data.passport_snils.split(' ').join('-'),
        },
      };
      const result: any = counterparty?.id
        ? await updateCounterparty({ pk: counterparty?.id, body: params })
        : await createCounterparty(params);
      if (result?.data?.success) {
        if (counterparty?.id) {
          dispatch(changeCounterpatyItem(result.data.data));
        } else {
          dispatch(addCounterparty(result.data.data));
        }
        reset();
        OnCancel?.();
      } else if (result?.error?.data?.error) {
        setError(result?.error?.data?.error);
      }
    }
  }

  const handleOnCancel = () => {
    reset();
    OnCancel?.();
  };

  const handleOnBack = () => {
    reset();
    onBack?.();
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <div>
        <Input
          label={t('common.input.surname.label') + '*'}
          {...register('surname', validationFields.surname)}
          defaultValue={counterparty?.individual_data?.surname}
          error={errors.surname?.message}
          placeholder={t('common.input.surname.placeholder')}
          disabled={createLoading || updateLoading}
        />
      </div>
      <div className="flex mt-[16px]">
        <div className="w-full mr-1.5">
          <Input
            {...register('first_name', validationFields.name)}
            label={t('common.input.name.label') + '*'}
            defaultValue={counterparty?.individual_data?.first_name}
            error={errors.first_name?.message}
            placeholder={t('common.input.name.placeholder')}
            disabled={createLoading || updateLoading}
          />
        </div>
        <div className="w-full ml-1.5">
          <Input
            label={t('common.input.middle_name.label') + '*'}
            {...register('middle_name', validationFields.middle_name)}
            defaultValue={counterparty?.individual_data?.middle_name}
            error={errors.middle_name?.message}
            placeholder={t('common.input.middle_name.placeholder')}
            disabled={createLoading || updateLoading}
          />
        </div>
      </div>
      <div className="mt-[16px]">
        <Controller
          name="passport_ser_num"
          control={control}
          rules={validationFields.passport_ser_num}
          render={({ field }) => {
            return (
              <Input
                {...field}
                label={t('common.input.passport_ser_num.label') + '*'}
                error={errors.passport_ser_num?.message}
                placeholder={t('common.input.passport_ser_num.placeholder')}
                mask="9999 999999"
                disabled={createLoading || updateLoading}
              />
            );
          }}
        />
      </div>
      <div className="mt-[16px]">
        <Input
          label={t('common.input.passport_issued_by.label') + '*'}
          {...register('passport_issued_by', validationFields.passport_issued_by)}
          defaultValue={counterparty?.individual_data?.passport_issued_by}
          error={errors.passport_issued_by?.message}
          placeholder={t('common.input.passport_issued_by.placeholder')}
          disabled={createLoading || updateLoading}
        />
      </div>
      <div className="flex mt-[16px]">
        <div className="w-full mr-1.5">
          <Controller
            name="passport_date"
            control={control}
            rules={validationFields.passport_date}
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  label={t('common.input.passport_date.label') + '*'}
                  error={errors.passport_date?.message}
                  placeholder={t('common.input.passport_date.placeholder')}
                  mask="99.99.9999"
                  disabled={createLoading || updateLoading}
                />
              );
            }}
          />
        </div>
        <div className="w-full ml-1.5">
          <Controller
            name="passport_snils"
            control={control}
            rules={validationFields.passport_snils}
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  label={t('common.input.passport_snils.label') + '*'}
                  error={errors.passport_snils?.message}
                  placeholder={t('common.input.passport_snils.placeholder')}
                  mask="999-999-999 99"
                  disabled={createLoading || updateLoading}
                />
              );
            }}
          />
        </div>
      </div>
      <div className="flex items-center mt-[16px]">
        <Checkbox
          className="text-gray_600 text-paragraph_l"
          checked={isReferral}
          onChange={onChangeCheckbox}
          disabled={createLoading || updateLoading}
        >
          {t('create_counterparty.checkbox.label')}
        </Checkbox>
        <Tooltip
          title={t('create_counterparty.checkbox.info')}
          placement="topRight"
          color="#fff"
          overlayInnerStyle={{ color: '#4B5563' }}
        >
          <Icon name="Info" className="text-gray_300 ml-2 cursor-pointer" />
        </Tooltip>
      </div>
      <div className="w-full flex justify-end mt-[24px]">
        <div className="mr-[14px]">
          <Button
            label={t('create_counterparty.cancel_button')}
            onClick={handleOnCancel}
            variant="outlined"
            space={15}
            disabled={createLoading || updateLoading}
          />
        </div>
        {!counterparty?.id && (
          <div className="mr-[14px]">
            <Button
              label={t('create_counterparty.back_button')}
              icon="ChevronLeft"
              onClick={handleOnBack}
              variant="outlined"
              space={15}
              disabled={createLoading || updateLoading}
            />
          </div>
        )}
        <div>
          <Button
            type="submit"
            label={
              counterparty?.id
                ? t('create_counterparty.eidt.submit_button')
                : t('create_counterparty.submit_button')
            }
            variant="filled"
            disabled={createLoading || updateLoading}
            space={17}
          />
        </div>
      </div>
    </form>
  );
};

export default IndividualForm;
