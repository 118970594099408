import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ShepherdTour } from 'react-shepherd';

import { useAppSelector } from '@/hooks/use-app-selector';
import { initializeSelector } from '@/store/selectors/auth.selectors';
import { PrivateRoute } from '@/util/PrivateRoute';
import { PublicRoute } from '@/util/PublicRoute';
import { useMeQuery } from '@/store/api/auth.api';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { setInitialize, setUser } from '@/store/slices/auth.slice';
import { Header, Notifications } from '@/components/ui';
import { NotificationProvider } from '@/hooks/use-notifications';
import { NotificationProvideItem } from '@/components/ui/notification/notification.types';
import { route } from '@/util/route';
import LoginRoute from '@/pages/login.route';
import RegisterRoute from '@/pages/register.route';
import ForgotRoute from '@/pages/forgot.route';
import OrdersRoute from '@/pages/orders/orders.route';
import CreateOrderRoute from '@/pages/orders/create-order.route';
import ViewOrderRoute from '@/pages/orders/view-order.route';
import Error404Route from '@/pages/error-404.route';
import ProfileSettingsRoute from '@/pages/profile/profile-settings.route';
import CounterpartiesRoute from '@/pages/contrpaties';
import EgrnRoute from '@/pages/egrn/egrn.route';
import CreateEgrnRoute from '@/pages/egrn/create-egrn.route';
import AffiliateProgramRoute from '@/pages/affiliate-program/affiliate-program.route';
import { useTour } from '@/util/tour';

function App() {
  const [notifications, setNotifications] = useState<Array<NotificationProvideItem>>([]);
  const { data } = useMeQuery('');
  const dispatch = useAppDispatch();

  const initialize = useAppSelector(initializeSelector);

  const { steps, tourOptions } = useTour();

  // инициализация приложения (не показываем ничего, пока не придёт ответ auth/me)
  useEffect(() => {
    if (data && data.data.me.id !== 0) {
      const { token, me } = data.data;
      dispatch(setUser({ token, me }));
      dispatch(setInitialize());
    } else if (data && data.data.me.id === 0) {
      dispatch(setUser({ token: null, me: null }));
      dispatch(setInitialize());
    }
  }, [data, dispatch]);

  if (!initialize) {
    return <div></div>;
  } else {
    return (
      <>
        <NotificationProvider notifications={notifications} setNotifications={setNotifications}>
          <ShepherdTour steps={steps} tourOptions={tourOptions}>
            <Router>
              <Header />
              <div className="mt-[52px]">
                <Routes>
                  <Route path={route.main} element={<PrivateRoute />}>
                    <Route path={route.main} element={<OrdersRoute />} />
                  </Route>
                  <Route path={route.main} element={<PublicRoute />}>
                    <Route path={route.login} element={<LoginRoute />} />
                  </Route>
                  <Route path={route.main} element={<PublicRoute />}>
                    <Route path={route.forgotPassword} element={<ForgotRoute />} />
                  </Route>
                  <Route path={route.main} element={<PrivateRoute />}>
                    <Route path={route.counterparties} element={<CounterpartiesRoute />} />
                  </Route>
                  <Route path={route.main} element={<PrivateRoute />}>
                    <Route path={`${route.orders}/:id`} element={<ViewOrderRoute />} />
                  </Route>
                  <Route path={route.main} element={<PublicRoute />}>
                    <Route path={route.register} element={<RegisterRoute />} />
                  </Route>
                  <Route path={route.main} element={<PrivateRoute />}>
                    <Route path={route.orders} element={<OrdersRoute />} />
                  </Route>
                  <Route path={route.main} element={<PrivateRoute />}>
                    <Route path={route.createOrder} element={<CreateOrderRoute />} />
                  </Route>
                  <Route path={route.main} element={<PrivateRoute />}>
                    <Route path={route.profile} element={<ProfileSettingsRoute />} />
                  </Route>
                  <Route path={route.main} element={<PrivateRoute />}>
                    <Route path={route.egrn} element={<EgrnRoute />} />
                  </Route>
                  <Route path={route.main} element={<PrivateRoute />}>
                    <Route path={route.createEgrn} element={<CreateEgrnRoute />} />
                  </Route>
                  <Route path={route.main} element={<PrivateRoute />}>
                    <Route path={route.affiliateProgram} element={<AffiliateProgramRoute />} />
                  </Route>
                  <Route path={route.main} element={<PrivateRoute />}>
                    <Route path={route.instructions} element={<></>} />
                  </Route>
                  <Route path={'*'} element={<Error404Route />} />
                </Routes>
              </div>
            </Router>
          </ShepherdTour>
          <Notifications />
        </NotificationProvider>
      </>
    );
  }
}

export default App;
