import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SelectSchema } from '@/components/ui/select/select.types';
import { Button, Container, Modal, Select, Skeleton } from '@/components/ui';
import { route } from '@/util/route';
import AddCounterparty from '@/components/add-counterparty';
import { Counterparty } from '@/types/counterparty.type';
import { counterpartySelector, isLoadingSelector } from '@/store/selectors/counterparty.selectors';
import { fetchCounterparties } from '@/store/slices/counterparty.slice';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { useAppSelector } from '@/hooks/use-app-selector';
import { setIsSelectedServiceTypeList } from '@/store/slices/orders.slices';
import BlankForm from '@/components/service-forms/blank-form';

const CreateOrderPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const counterparties: Counterparty[] | null = useAppSelector(counterpartySelector); // Контрагенты
  const isLoading: boolean = useAppSelector(isLoadingSelector);

  const [selectedCounterParty, changeSelectedCounterParty] = useState<Counterparty>();
  const [openAddCounterparty, toggleAddCounterparty] = useState<boolean>(false);
  const [openCancel, toggleCancel] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchCounterparties({})); // Все контрагенты
  }, [dispatch]);

  useEffect(() => {
    if (!selectedCounterParty) changeSelectedCounterParty(counterparties?.[0]);
  }, [selectedCounterParty, counterparties]);

  const onChangeCounterparty = (item: SelectSchema) => {
    const currentCounterPartySelected = counterparties?.find(
      (counterparty) => counterparty.id === item.value,
    );
    changeSelectedCounterParty(currentCounterPartySelected);
  };

  function handleAddCounterparty() {
    toggleAddCounterparty(true); // открываем модальное окно
  }

  const renderSelectCounterPartiesData: Array<SelectSchema> = counterparties
    ? counterparties?.map(({ id, legal_data, individual_data, counterparty_type }) => ({
        title:
          counterparty_type === 'ФЛ'
            ? `${individual_data?.surname} ${individual_data?.first_name} ${
                individual_data?.middle_name
              } ${t('create_order.passport')}: ${individual_data?.passport_series} ${
                individual_data?.passport_number
              }`
            : `${legal_data?.name} ${t('create_order.inn')}: ${legal_data?.inn}`,
        value: id,
      }))
    : [];

  const renderCounterpartySelect = isLoading ? (
    <>
      <div className="mb-[10px]">
        <Skeleton size={100} height={18} />
      </div>
      <Skeleton size={572} height={40} />
    </>
  ) : (
    <div className="w-full md:max-w-[49.5%] md:mr-[16px]">
      <Modal
        isShow={openCancel}
        label={t('create_order.modal.back.label')}
        type="dialog"
        icon="Alert"
        submitText={t('create_order.modal.back.cancel')}
        buttonText={t('create_order.modal.back.stay')}
        onCancel={() => {
          toggleCancel(false); // закрываем модальное окно
        }}
        onSubmit={() => {
          toggleCancel(false); // закрываем модальное окно
          navigate(route.orders); // перенаправляем на главную страницу
          dispatch(setIsSelectedServiceTypeList({ index: 0, selected: false })); // тип услуги не выбран;
        }}
      >
        {t('create_order.modal.back.children')}
      </Modal>
      <Select
        label={t('create_order.input.counterparty.label') + '*'}
        initialValue={
          selectedCounterParty?.counterparty_type === 'ФЛ'
            ? selectedCounterParty?.individual_data?.first_name
              ? `${selectedCounterParty?.individual_data?.surname} ${
                  selectedCounterParty?.individual_data?.first_name
                } ${selectedCounterParty?.individual_data?.middle_name} ${t(
                  'create_order.passport',
                )}: ${selectedCounterParty?.individual_data?.passport_series} ${
                  selectedCounterParty?.individual_data?.passport_number
                }`
              : ''
            : selectedCounterParty?.legal_data?.name
            ? `${selectedCounterParty?.legal_data?.name} ${t('create_order.inn')}: ${
                selectedCounterParty?.legal_data?.inn
              }`
            : ''
        }
        activeValue={
          selectedCounterParty?.counterparty_type === 'ФЛ'
            ? selectedCounterParty?.individual_data?.first_name
              ? `${selectedCounterParty?.individual_data?.surname} ${
                  selectedCounterParty?.individual_data?.first_name
                } ${selectedCounterParty?.individual_data?.middle_name} ${t(
                  'create_order.passport',
                )}: ${selectedCounterParty?.individual_data?.passport_series} ${
                  selectedCounterParty?.individual_data?.passport_number
                }`
              : ''
            : selectedCounterParty?.legal_data?.name
            ? `${selectedCounterParty?.legal_data?.name} ${t('create_order.inn')}: ${
                selectedCounterParty?.legal_data?.inn
              }`
            : ''
        }
        data={renderSelectCounterPartiesData}
        placeholder={t('create_order.input.counterparty.placeholder')}
        bottomItemIcon="Plus"
        onSelectOption={onChangeCounterparty}
        bottomItemText={t('create_order.btn.add_counterparty')}
        onClickBottomItem={handleAddCounterparty}
        withBottomItem
        withSearch
      />
    </div>
  );

  function handlerKPIsSubmit() {
    //TODO: do something if u need it;
  }

  return (
    <Container>
      <div className="mt-4 w-full mb-[24px]">
        <Button
          space={12}
          label={t('create_order.btn.back_to_orders')}
          variant="outlined"
          icon="ChevronLeft"
          onClick={() => {
            toggleCancel(true);
          }}
        />
      </div>
      <div className="mb-[20px]">
        <h1 className="text-heading_h2 font-bold text-gray_600">{t('create_order.new_request')}</h1>
        <p className="mb-[20px] text-[13px] font-bold text-gray_600/70">
          {t('create_order.new_request_description')}
        </p>
        <div>{renderCounterpartySelect}</div>
      </div>
      <BlankForm
        onSubmitKP={handlerKPIsSubmit}
        selectedCounterParty={selectedCounterParty}
        toggleCancel={toggleCancel}
      />
      <AddCounterparty
        openAddCounterparty={openAddCounterparty}
        toggleAddCounterparty={toggleAddCounterparty}
      />
    </Container>
  );
};

export default CreateOrderPage;
