import React, { useEffect, useMemo, useState } from 'react';

import { Icon, Modal } from '@/components/ui';
import Chat from '@/features/chat/pages/chat';
import { useAppSelector } from '@/hooks/use-app-selector';
import { newMessagesCountSelector } from '@/store/selectors/chat.selector';
import { fetchNewMessagesCount } from '@/store/slices/chat.slice';
import { useAppDispatch } from '@/hooks/use-app-dispatch';

const ChatOpen = () => {
  const newMessagesCount = useAppSelector(newMessagesCountSelector);

  const dispatch = useAppDispatch();

  const totalNewMessagesCount = useMemo(() => {
    return newMessagesCount.reduce((acc, prev) => acc + prev.total, 0);
  }, [newMessagesCount]);

  useEffect(() => {
    // для получения общего количества новых сообщений
    dispatch(fetchNewMessagesCount());
    const interval = setInterval(() => {
      dispatch(fetchNewMessagesCount());
    }, 60000);
    return () => clearInterval(interval);
  }, [dispatch]);

  const [isChat, setIsChat] = useState(false);
  const openChat = () => {
    setIsChat(true);
  };

  const closeChat = () => {
    setIsChat(false);
  };
  return (
    <>
      <div onClick={openChat} className=" cursor-pointer fixed top-[708px] right-[30px]">
        <span className="relative">
          <Icon name={'ChatAltTwo'} size={76} />
          {totalNewMessagesCount
            ? totalNewMessagesCount > 0 && (
                <span className="absolute w-6 h-6 flex items-center justify-center text-white text-paragraph_m -top-2 right-0 bg-primary_400 rounded-full">
                  <span>{totalNewMessagesCount}</span>
                </span>
              )
            : ''}
        </span>
      </div>
      <Modal label={''} type="chat" isShow={isChat} onCancel={closeChat} withoutFooter={true}>
        {<Chat closeChat={closeChat} />}
      </Modal>
    </>
  );
};

export default ChatOpen;
