import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { apiBaseUrl } from '@/util/api';
import { ListResponse } from '@/types/responses.type';
import {
  DeleteFileParams,
  DeleteFileResponse,
  SendStatementsFileParams,
  StatementResponse,
  File,
  ResultFile,
  UploadFileData,
  UploadFileResponse,
} from '@/types/file.type';
import { ContractFile } from '@/types/contract.type';
import {
  OrderFilesDataType,
  OrderFilesGetQueryType,
  OrderFilesSendQueryType,
} from '@/types/order.type';

export const filesApi = createApi({
  reducerPath: 'FilesQuery',
  tagTypes: ['Files'],
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
  endpoints: (build) => ({
    uploadFiles: build.mutation<ListResponse<UploadFileResponse>, UploadFileData>({
      query: ({ proposal_id, data }) => ({
        url: `/files/upload_files/${proposal_id}`,
        method: 'POST',
        body: data,
      }),
    }),
    getFiles: build.query<ListResponse<File[]>, number>({
      query: (proposal_id) => ({
        url: `/files/get_files/${proposal_id}`,
      }),
    }),
    deleteFile: build.mutation<ListResponse<DeleteFileResponse>, DeleteFileParams>({
      query: ({ proposal_id, data }) => ({
        url: `/files/delete_file/${proposal_id}`,
        method: 'DELETE',
        body: data,
      }),
    }),
    sendStatementsFile: build.mutation<StatementResponse, SendStatementsFileParams>({
      query: ({ contract_id, statement_id }) => ({
        url: `/files/send_to_email/statement/${contract_id}/${statement_id}`,
        method: 'POST',
      }),
    }),
    getContractFiles: build.query<ListResponse<ContractFile>, number>({
      query: (contract_id) => ({
        url: `/files/get_contract_files/${contract_id}`,
      }),
    }),
    getOfferFile: build.query<ListResponse<File>, number | string>({
      query: (proposal_id) => ({
        url: `/files/get_offer_file/${proposal_id}`,
      }),
    }),
    getResultFiles: build.query<ListResponse<ResultFile>, number>({
      query: (contract_id) => ({
        url: `/files/get_result_files/${contract_id}`,
      }),
    }),
    getOrderFiles: build.query<ListResponse<OrderFilesDataType>, OrderFilesGetQueryType>({
      query: (args) => {
        const { contract_id, search_folder, date, page, ordering } = args;
        return {
          url: `/files/get_result_files/${contract_id}`,
          params: { search_folder, date, page, ordering },
        };
      },
    }),
  }),
});

export const {
  useUploadFilesMutation,
  useGetFilesQuery,
  useDeleteFileMutation,
  useSendStatementsFileMutation,
  useGetContractFilesQuery,
  useGetOfferFileQuery,
  useGetResultFilesQuery,
  useGetOrderFilesQuery,
} = filesApi;

export const filesArchieveApi = createApi({
  reducerPath: 'FilesArchieveQuery',
  tagTypes: ['FilesArchieve'],
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
  endpoints: (build) => ({
    getArchieveDocs: build.mutation({
      query: (contract_id) => ({
        url: `/create_task_archiving_docs/${contract_id}`,
        method: 'POST',
      }),
    }),
    sendOrderFiles: build.mutation({
      query: (args: OrderFilesSendQueryType) => {
        const { contract_id, folder_name, search_folder, search_date } = args;
        return {
          url: `files/send_to_email/result_folder/${contract_id}`,
          params: { folder_name, search_folder, search_date },
          method: 'POST',
        };
      },
    }),
  }),
});
export const { useGetArchieveDocsMutation, useSendOrderFilesMutation } = filesArchieveApi;
