import React, { FC, ReactElement } from 'react';
import clsx from 'clsx';

import { NavLink } from 'react-router-dom';

export type MenuItem = {
  tag: string | string[];
  title: string;
  link: string;
  icon?: ReactElement;
};

const menuItemClassName = ({ active }: { active: boolean }) =>
  clsx(
    'mx-[10px]',
    'font-medium',
    'block',
    'text-paragraph_l',
    'cursor-pointer',
    'h-full',
    'items-center',
    'flex',
    'border-b-[2px]',
    active ? 'border-b-primary_400 text-primary_400' : 'text-gray_400 border-b-transparent',
  );

type MenuProps = {
  menuData: Array<MenuItem>;
  active?: string;
};

const defaultActive = 'orders';

const Menu: FC<MenuProps> = (props) => {
  const { menuData, active = defaultActive } = props;

  const renderMenuItem =
    menuData &&
    menuData.map(({ link, title, tag, icon }) => {
      const currentTag = Array.isArray(tag) ? tag[0] : tag;
      const isActive = Array.isArray(tag) ? tag.includes(active.toString()) : tag === active;
      return (
        <NavLink
          key={currentTag}
          to={link}
          className={`block h-full ${Array.isArray(tag) ? `${tag[0]}` : `${tag}`}`}
        >
          <div className={menuItemClassName({ active: isActive })}>
            {title}
            {icon && <span className="ml-[8px] mt-[-1px]">{icon}</span>}
          </div>
        </NavLink>
      );
    });
  return <div className="inline-flex items-center h-full">{renderMenuItem}</div>;
};

export default Menu;
