import clsx from 'clsx';

export const textareaClassName = ({
  disabled,
  error,
  isFocus,
}: {
  disabled: boolean;
  error: boolean;
  isFocus: boolean;
}) =>
  clsx(
    'border',
    'bg-white',
    'text-gray_600',
    'placeholder-green-700',
    'text-paragraph_l',
    'font-regular',
    'rounded',
    'block',
    'w-full',
    'inline-flex',
    'items-center',
    'p-[5px]',
    'transition',
    'scrollbar-small',
    'overflow-y-auto',
    disabled
      ? 'border-gray_300 bg-gray_100'
      : error
      ? 'border-negative_500'
      : isFocus
      ? 'border-primary_400'
      : 'border-gray_300 text-gray_400 hover:border-gray_400',
  );
