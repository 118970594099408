import React from 'react';

const UserV2 = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg width={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="14" fill="#60A5FA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 13C14.7956 13 15.5587 12.6839 16.1213 12.1213C16.6839 11.5587 17 10.7956 17 10C17 9.20435 16.6839 8.44129 16.1213 7.87868C15.5587 7.31607 14.7956 7 14 7C13.2044 7 12.4413 7.31607 11.8787 7.87868C11.3161 8.44129 11 9.20435 11 10C11 10.7956 11.3161 11.5587 11.8787 12.1213C12.4413 12.6839 13.2044 13 14 13ZM7 22C7 21.0807 7.18106 20.1705 7.53284 19.3212C7.88463 18.4719 8.40024 17.7003 9.05025 17.0503C9.70026 16.4002 10.4719 15.8846 11.3212 15.5328C12.1705 15.1811 13.0807 15 14 15C14.9193 15 15.8295 15.1811 16.6788 15.5328C17.5281 15.8846 18.2997 16.4002 18.9497 17.0503C19.5998 17.7003 20.1154 18.4719 20.4672 19.3212C20.8189 20.1705 21 21.0807 21 22H7Z"
        fill="white"
      />
    </svg>
  );
};

export default UserV2;
