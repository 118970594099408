import React from 'react';

const Calendar = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6.667 5.833V2.5m6.666 3.333V2.5m-7.5 6.667h8.334m-10 8.333h11.666a1.666 1.666 0 001.667-1.667v-10a1.667 1.667 0 00-1.667-1.666H4.167A1.667 1.667 0 002.5 5.833v10A1.666 1.666 0 004.167 17.5z"
      ></path>
    </svg>
  );
};

export default Calendar;
