import clsx from 'clsx';

export const tabListClassName = clsx(
  'flex',
  'border',
  'border-gray_300',
  'rounded',
  'w-full',
  'h-[40px]',
  'bg-white',
  'items-center',
  'justify-between',
  'overflow-hidden',
);

export const tabItemClassName = ({ selected }: { selected: boolean }) =>
  clsx(
    'first:border-l-[0px]',
    'border-l-[1px]',
    'border-l-gray_300',
    'hover:text-primary_400',
    'hover:bg-primary_100',
    'transition',
    'px-[18.5px]',
    'h-full',
    'w-full',
    'text-center',
    'text-paragraph_l',
    'font-regular',
    selected
      ? 'text-primary_400 bg-primary_100'
      : 'bg-white disabled:bg-gray_100 text-gray_600 disabled:hover:text-gray_600 disabled:cursor-not-allowed',
  );
