import clsx from 'clsx';

export const documentWrapperClassName = clsx(
  'text-paragraph_m',
  'w-full',
  'inline-flex',
  'py-[7px]',
  'px-[8px]',
  'bg-white',
  'hover:bg-primary_50',
  'transition',
  'items-center',
  'justify-between',
  'group',
);

export const documentsClassName = ({ isFail }: { isFail: boolean }) =>
  clsx(
    isFail ? 'text-negative_500 hover:text-negative_500' : 'text-primary_400',
    'truncate',
    'block',
    'w-full',
    'inline-flex',
    'items-center',
    'mt-[4px]',
    'hover:underline',
    'cursor-pointer',
    'group:hover:text-primary_500',
    'truncate',
  );
