import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CommonState = {
  isLoading: boolean;
  resendEmail: string;
  isResendEmail: boolean;
  resendEmailPause: number;
};

const initialState: CommonState = {
  isLoading: false,
  resendEmail: '',
  isResendEmail: false,
  resendEmailPause: 0,
};

const commonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    changeResendEmail: (
      state,
      action: PayloadAction<{ pause: number; email: string; isResendEmail: boolean }>,
    ) => {
      const {
        payload: { pause, email, isResendEmail },
      } = action;
      state.resendEmail = email;
      state.resendEmailPause = pause;
      state.isResendEmail = isResendEmail;
    },
  },
});

export const { changeResendEmail } = commonSlice.actions;
export default commonSlice.reducer;
