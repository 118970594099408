import { useTranslation } from 'react-i18next';

import { changeResendEmail } from '@/store/slices/common.slice';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { useNotification } from '@/hooks/use-notifications';

export function useResendRestoreEmail() {
  const { resendEmailPause, resendEmail } = useAppSelector((state) => state.commonStore);
  const dispatch = useAppDispatch();
  const { createNotification } = useNotification();
  const { t } = useTranslation();
  return () => {
    if (resendEmailPause > Date.now()) {
      createNotification?.({
        variant: 'fail',
        title: t('common.notification.wrong.title'),
        description: t('common.notification.wrong.description'),
      });
      return 'fail';
    } else {
      dispatch(
        changeResendEmail({
          email: resendEmail,
          pause: Date.now() + 60 * 60 * 2,
          isResendEmail: true,
        }),
      );
      createNotification?.({
        variant: 'success',
        title: t('common.notification.success.title'),
      });
      return 'success';
    }
  };
}
