export type EgrnOrder = {
  id: number;
  order_num: string | null;
  user_id: number;
  price: number | null;
  count: number | null;
  pay_type: number;
  payed: boolean;
  date: string;
  status: number;
  comment: string;
  payment_id: number;
  items: EgrnOrderItem[];
  download_link?: string;
};

export type EgrnOrderItem = {
  id: number;
  order_id: number;
  task_id: number | null;
  cadastral_number: string;
  realty_type: string;
  report_type: number | null;
  address: string;
  comment: string;
};

export type EgrnNewOrder = {
  comment: string;
  items: EgrnNewOrderItem[];
};

export type EgrnNewOrderItem = {
  cadastral_number: string;
  realty_type: string;
  report_type: number;
  address: string;
  comment: string;
  area?: number;
  uuid?: string;
};

export enum EgrnOrderStatus {
  ready = 'Готово',
  inprogress = 'В работе',
  notpayed = 'Ожидает оплату',
}

export type EgrnOrderPayType = 'invoice' | 'online';
