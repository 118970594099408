import React, { useEffect, useState, useContext } from 'react';

import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ShepherdTourContext } from 'react-shepherd';

import { setIdRef } from '@/store/slices/auth.slice';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { idRefSelector, userSelector } from '@/store/selectors/auth.selectors';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useNotification } from '@/hooks/use-notifications';

export const PrivateRoute = () => {
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(userSelector);
  const idRef = useAppSelector(idRefSelector);
  const [searchParams] = useSearchParams();
  const { createNotification } = useNotification();
  const { t } = useTranslation();
  const [isShowedPaymentSpecification, setIsShowedPaymentSpecification] = useState(false);
  const tour = useContext(ShepherdTourContext);

  useEffect(() => {
    const referrerIdFromParams = searchParams.get('ref');
    if (idRef || !referrerIdFromParams) return;
    dispatch(setIdRef(referrerIdFromParams));
  });

  useEffect(() => {
    if (!isShowedPaymentSpecification) {
      const paymentFromParams = searchParams.get('payment');
      if (paymentFromParams === 'success') {
        createNotification?.({
          variant: 'success',
          title: t('common.query_payment.success'),
        });
      } else if (paymentFromParams === 'fail') {
        createNotification?.({
          variant: 'fail',
          title: t('common.query_payment.fail'),
        });
      }
      setIsShowedPaymentSpecification(true);
    }
  }, [searchParams, createNotification, t, isShowedPaymentSpecification]);

  useEffect(() => {
    const usersWatchedTourData = localStorage.getItem('usersWatchedKadastrServiceTour');
    const usersWatchedTour = usersWatchedTourData ? JSON.parse(usersWatchedTourData) : null;
    if (
      isAuth?.id &&
      (!usersWatchedTour || (usersWatchedTour && !usersWatchedTour.includes(isAuth?.id)))
    ) {
      tour?.start();
      const newUsersWatchedTour = usersWatchedTour || [];
      newUsersWatchedTour?.push(isAuth?.id);
      localStorage.setItem('usersWatchedKadastrServiceTour', JSON.stringify(newUsersWatchedTour));
    }
  }, [tour, isAuth?.id]);

  return isAuth !== null ? <Outlet /> : <Navigate to="/login" replace />;
};
