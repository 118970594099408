import React from 'react';
import { useTranslation } from 'react-i18next';

import { translateRequisitesPath } from '../constants';

import RequisitesAndContractWrapperBlock from './requisites-and-contract-wrapper.block';

import { Info } from '@/components/ui';
import { useAppSelector } from '@/hooks/use-app-selector';
import { userSelector } from '@/store/selectors/auth.selectors';
import { User } from '@/types/user.type';

const ContractIsSignedBlock = () => {
  const { t } = useTranslation();

  const user: User | null = useAppSelector(userSelector);

  return (
    <RequisitesAndContractWrapperBlock>
      <Info
        variant="success"
        content={
          <span>
            <a
              className="text-primary_400 hover:opacity-80 opacity-100"
              href={user?.referral_contract_url || ''}
              target="_blank"
              rel="noreferrer"
            >
              {t(translateRequisitesPath + '.contract')}
            </a>{' '}
            {t(translateRequisitesPath + '.contract-is-sign.contract')}
          </span>
        }
      />
    </RequisitesAndContractWrapperBlock>
  );
};

export default ContractIsSignedBlock;
