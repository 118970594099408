import React from 'react';

const Group = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 24 25">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 20.5h5v-2a3 3 0 00-5.356-1.857M17 20.5H7m10 0v-2c0-.656-.126-1.283-.356-1.857m0 0a5.003 5.003 0 00-9.288 0M7 20.5H2v-2a3 3 0 015.356-1.857M7 20.5v-2c0-.656.126-1.283.356-1.857M15 7.5a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zm-14 0a2 2 0 11-4 0 2 2 0 014 0z"
      ></path>
    </svg>
  );
};

export default Group;
