import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Heading from '@/components/ui/heading';
import { Icon } from '@/components/ui';
import { route } from '@/util/route';

const translatePath = 'affiliate-program.blocks.how-to-earn-with-1kk';

const HowToEarnBlock: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col w-full bg-white rounded-lg border border-gray_200 py-[24px] text-gray_600">
      <div className="pl-[52px] mb-[32px]">
        <Icon name={'Coins'} size={65} className="mb-[16px]" />
        <h1 className="text-heading_h2 font-bold text-gray_600">{t(translatePath + '.heading')}</h1>
      </div>
      <div className="mb-[32px]">
        <Heading
          icon={'Link'}
          title={t(translatePath + '.discount-referral.heading')}
          iconClassname="mt-[-2px]"
        />
        <p className="pl-[52px] max-w-[67%] mt-[12px]">
          <Trans
            defaults={t(translatePath + '.discount-referral.content.paragraph')}
            values={{
              'referral-link': t(translatePath + '.discount-referral.content.referral-link'),
            }}
            components={[<b key={'referral-link'} />]}
          />
        </p>
      </div>
      <div className="mb-[32px]">
        <Heading
          icon={'BriefCase'}
          title={t(translatePath + '.discount-client.heading')}
          iconClassname="mt-[-2px]"
        />
        <p className="pl-[52px] max-w-[67%] whitespace-pre-wrap mt-[12px] mb-[16px]">
          <Trans
            defaults={t(translatePath + '.discount-client.content.paragraph')}
            values={{
              'link-to-counterparties': t(
                translatePath + '.discount-client.content.link-to-counterparties',
              ),
              'from-clients': t(translatePath + '.discount-client.content.from-clients'),
            }}
            components={[
              <b key={'from-clients'} />,
              <Link
                to={route.counterparties}
                key={'link-to-counterparties'}
                className={'text-primary_400'}
              />,
            ]}
          />
        </p>
        <div className="ml-[52px] max-w-[60%] bg-add-new-counterparties bg-cover min-h-[408px]" />
      </div>
      <div>
        <Heading
          icon={'CreditCard'}
          title={t(translatePath + '.withdrawal.heading')}
          iconClassname="mt-[-2px]"
        />
        <p className="pl-[52px] max-w-[67%] mt-[12px]">
          {t(translatePath + '.withdrawal.content.paragraph')}
        </p>
        <ol className="list-decimal pl-[75px]  max-w-[68%] mb-[16px]">
          <li>
            <Trans
              defaults={t(translatePath + '.withdrawal.content.list-item-1')}
              values={{
                'details-and-contract': t(
                  translatePath + '.withdrawal.content.details-and-contract',
                ),
              }}
              components={[<b key={'details-and-contract'} />]}
            />
          </li>
          <li>
            <Trans
              defaults={t(translatePath + '.withdrawal.content.list-item-2')}
              values={{
                'form-an-agreement': t(translatePath + '.withdrawal.content.form-an-agreement'),
              }}
              components={[<b key={'form-an-agreement'} />]}
            />
          </li>
          <li>{t(translatePath + '.withdrawal.content.list-item-3')}</li>
          <li>
            <Trans
              defaults={t(translatePath + '.withdrawal.content.list-item-4')}
              values={{
                'signing-an-agreement': t(
                  translatePath + '.withdrawal.content.signing-an-agreement',
                ),
              }}
              components={[<b key={'signing-an-agreement'} />]}
            />
          </li>
          <li>{t(translatePath + '.withdrawal.content.list-item-5')}</li>
          <li>{t(translatePath + '.withdrawal.content.list-item-6')}</li>
        </ol>
        <div className="mr-[41px] ml-[52px] mb-[24px] bg-details-of-affiliate-payments bg-cover min-h-[215px]"></div>
      </div>
    </div>
  );
};

export default HowToEarnBlock;
