import { useTranslation } from 'react-i18next';

import { TableColumn } from '@/components/ui/table/table.types';

export function useTablesHeader() {
  const { t } = useTranslation();
  const egrnTableClassName = 'bg-transparent before:w-0 border-0 p-2';
  const tableHeaders: Record<string, Array<TableColumn>> = {
    middleResultTableHeader: [
      {
        key: 'title',
        title: t('view_order.table.name'),
        dataIndex: 'name',
      },
      {
        key: 'file',
        title: t('view_order.table.file'),
        dataIndex: 'file',
      },
      {
        key: 'date',
        title: t('view_order.table.date'),
        dataIndex: 'date',
      },
      {
        key: 'sendFiles',
        title: '',
        dataIndex: 'sendFiles',
      },
    ],
    serviceProcessTableHeader: [
      {
        key: 'title',
        title: t('view_order.table.name'),
        dataIndex: 'name',
      },
      {
        key: 'date',
        title: t('view_order.table.date'),
        dataIndex: 'date',
        right: true,
      },
    ],
    serviceOrdersTableHeader: [
      {
        key: 'title',
        title: t('view_order.table.name'),
        dataIndex: 'number',
      },
      {
        key: 'date',
        title: t('view_order.table.date'),
        dataIndex: 'date_send',
      },
      {
        key: 'file_request',
        title: t('view_order.table.request_file'),
        dataIndex: 'request_files',
      },
      {
        key: 'file-respond',
        title: t('view_order.table.respond_file'),
        dataIndex: 'respond_files',
      },
      {
        key: 'files',
        title: ' ',
        dataIndex: 'send_files',
      },
      {
        key: 'number',
        title: t('view_order.table.KN'),
        dataIndex: 'kadastr_number',
        ellipsis: true,
      },
      {
        key: 'object',
        title: t('view_order.table.object'),
        dataIndex: 'services',
        ellipsis: true,
      },
      {
        key: 'applicant',
        title: t('view_order.table.applicant'),
        dataIndex: 'declarer',
      },
      {
        key: 'kind_of_order',
        title: t('view_order.table.kind_of_order'),
        dataIndex: 'statement_type',
      },
      {
        key: 'side_filled',
        title: t('view_order.table.side_filled'),
        dataIndex: 'directed',
        right: true,
      },
      {
        key: 'answer_date',
        title: t('view_order.table.answer_date'),
        dataIndex: 'answer_date',
      },
      {
        key: 'answer_type',
        title: t('view_order.table.answer_type'),
        dataIndex: 'answer_type',
      },
    ],
    dataCallPKTable: [
      {
        key: 'real_estate_type',
        title: t('view_order.table.ON_view'),
        dataIndex: 'real_estate_type',
      },
      {
        key: 'document_type',
        title: t('view_order.table.kind_of_documents'),
        dataIndex: 'document_type',
      },
      {
        key: 'service_type',
        title: t('view_order.table.kind_of_work'),
        dataIndex: 'service_type',
      },
      {
        key: 'address',
        title: t('view_order.table.address'),
        dataIndex: 'address',
      },
      {
        key: 'count',
        title: t('view_order.table.count'),
        dataIndex: 'count',
        right: true,
      },
    ],
    counterpartiesTableHeader: [
      { key: 'name', dataIndex: 'title', title: t('counterparties.table.title') },
      { key: 'inn', dataIndex: 'inn', title: t('counterparties.table.inn') },
    ],
    orderTableHeader: [
      {
        key: 'number',
        title: t('order_card.table_head.request'),
        dataIndex: 'treaty',
      },
      {
        key: 'offer_number',
        title: t('order_card.table_head.offer'),
        dataIndex: 'treaty',
      },
      {
        key: 'contract_number',
        title: t('order_card.table_head.contract'),
        dataIndex: 'treaty',
      },
      {
        key: 'services',
        title: t('order_card.table_head.service'),
        dataIndex: 'service',
      },
      {
        key: 'stage',
        title: t('order_card.table_head.stage'),
        dataIndex: 'stage',
      },
      {
        key: 'price',
        title: t('order_card.table_head.cost'),
        dataIndex: 'cost',
        right: true,
      },
    ],
    egrnTableHeader: [
      {
        key: 'order_num',
        title: t('egrn.table_head.order_num'),
        dataIndex: '',
        width: '11%',
        className: egrnTableClassName,
      },
      {
        key: 'date',
        title: t('egrn.table_head.date'),
        dataIndex: 'date',
        width: '10%',
        className: egrnTableClassName,
      },
      {
        key: 'cadastral_number',
        title: t('egrn.table_head.cadastral_number'),
        dataIndex: 'cadastral_number',
        width: '15%',
        className: egrnTableClassName,
      },
      {
        key: 'realty_type',
        title: t('egrn.table_head.realty_type'),
        dataIndex: 'realty_type',
        width: '13%',
        ellipsis: true,
        className: egrnTableClassName,
      },
      {
        key: 'address',
        title: t('egrn.table_head.address'),
        dataIndex: 'address',
        width: '15%',
        ellipsis: true,
        className: egrnTableClassName,
      },
      {
        key: 'comment',
        title: t('egrn.table_head.comment'),
        dataIndex: 'comment',
        width: '11%',
        ellipsis: true,
        className: egrnTableClassName,
      },
      {
        key: 'pay_type',
        title: t('egrn.table_head.pay_type'),
        dataIndex: 'pay_type',
        width: '7%',
        className: egrnTableClassName,
      },
      {
        key: 'status',
        title: t('egrn.table_head.status'),
        dataIndex: 'status',
        width: '12%',
        className: egrnTableClassName,
      },
      {
        key: 'actions',
        title: t('egrn.table_head.actions'),
        dataIndex: 'actions',
        className: egrnTableClassName,
      },
    ],
  };
  return (name: keyof typeof tableHeaders) => tableHeaders[name];
}
