import { RootState } from '@/store';

export const userSelector = (state: RootState) => {
  return state.authStore.user;
};
export const referralLinkSelector = (state: RootState) => {
  return state.authStore.user?.referral_link;
};
export const tokenSelector = (state: RootState) => {
  return state.authStore.token;
};
export const registerDataSelector = (state: RootState) => {
  return state.authStore.registerData;
};
export const initializeSelector = (state: RootState) => {
  return state.authStore.initialize;
};
export const idRefSelector = (state: RootState) => {
  return state.authStore.idRef;
};
