import { RootState } from '@/store';

export const estateTypesSelector = (state: RootState) => {
  return state.ordersStore.estateTypes;
};
export const serviceTypeListSelector = (state: RootState) => {
  return state.ordersStore.serviceTypeList;
};
export const fieldsSelector = (state: RootState) => {
  return state.ordersStore.fields;
};
export const isDisabledServiceTypeListSelector = (state: RootState) => {
  return state.ordersStore.isDisabledServiceTypeList;
};
export const isSelectedServiceTypeListSelector = (state: RootState) => {
  return state.ordersStore.isSelectedServiceTypeList;
};
export const initializeSelector = (state: RootState) => {
  return state.ordersStore.initialize;
};
