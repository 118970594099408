import React, { FC } from 'react';
import clsx from 'clsx';
import { Pagination } from 'antd';
import { useTranslation } from 'react-i18next';

import { Box, Label, Skeleton } from '@/components/ui';
import { useAppSelector } from '@/hooks/use-app-selector';
import {
  isLoadingSelector,
  pageCounterpartiesSelector,
  totalCounterpartiesCountSelector,
} from '@/store/selectors/counterparty.selectors';
import { Counterparty } from '@/types/counterparty.type';

type CounterpartyListProps = {
  counterparties: Counterparty[];
  pageLimit: number;
  onPageChange: (page: number) => void;
  openCounterpartyForEdit: (item: Counterparty) => void;
};

const CounterpartyListBlock: FC<CounterpartyListProps> = ({
  counterparties,
  onPageChange,
  pageLimit,
  openCounterpartyForEdit,
}) => {
  const isLoading: boolean = useAppSelector(isLoadingSelector);
  const page: number = useAppSelector(pageCounterpartiesSelector);
  const totalCounterpartiesCount: number = useAppSelector(totalCounterpartiesCountSelector); // Всего контрагентов

  const { t } = useTranslation();

  const gridCols = 'grid-cols-[6fr_2fr_3fr]';
  const counterpartiesItemClassName = clsx(
    'grid',
    gridCols,
    'cursor-pointer',
    'py-[26px]',
    'border-b-gray_200',
    'border-b',
    'text-paragraph_l',
    'font-bold',
    'text-gray_600',
  );

  const renderSkeleton = () =>
    Array(3)
      .fill('_')
      .map((_, index) => (
        <div key={index} className="px-[24px] group hover:bg-gray_50 transition">
          <div
            className={clsx(
              counterpartiesItemClassName,
              ++index === counterparties?.length && 'border-b-0',
            )}
          >
            <Skeleton size={100} />
            <div className=" pl-[12px]">
              <Skeleton size={100} />
            </div>
          </div>
        </div>
      ));

  return (
    <div className="mb-12">
      <Box>
        <div
          className={`grid ${gridCols} text-gray_400 text-paragraph_m font-medium border-b-[1px] border-b-gray_200 w-[1168px]`}
        >
          <div className="p-[24px] pb-[12px] pr-0">{t('counterparties.table.title')}</div>
          <div className="p-[24px] pb-[12px] pl-[12px]">{t('counterparties.table.inn')}</div>
        </div>
        <>
          {isLoading ? (
            renderSkeleton()
          ) : (
            <div className="w-[1168px]">
              {counterparties?.map(
                (
                  {
                    legal_data,
                    individual_data,
                    referral,
                    is_approved,
                    counterparty_type,
                  }: Counterparty,
                  index: number,
                ) => (
                  <div
                    key={index}
                    className="px-[24px] group hover:bg-gray_50 transition"
                    onClick={() => openCounterpartyForEdit(counterparties[index])}
                  >
                    <div
                      className={clsx(
                        counterpartiesItemClassName,
                        index === counterparties?.length - 1 && 'border-b-0',
                      )}
                    >
                      <div className="group-hover:text-primary_400">
                        {counterparty_type === 'ФЛ'
                          ? `${individual_data?.surname} ${individual_data?.first_name}
                            ${individual_data?.middle_name}`
                          : legal_data?.name}
                      </div>
                      <div className=" pl-[12px]">
                        {counterparty_type === 'ФЛ'
                          ? `${individual_data?.passport_series}
                            ${individual_data?.passport_number}`
                          : legal_data?.inn}
                      </div>
                      <div className="flex justify-end">
                        {/* <Button
                          label="Запросить доступ к предыдущим договорам"
                          // onClick={() => getImportContracts(inn)}
                        /> */}
                        {referral && !is_approved ? (
                          <div className="mr-1">
                            <Label text="На проверке" bgColor="#FEFCE8" textColor="#EAB308" />
                          </div>
                        ) : null}
                        {referral ? (
                          <Label
                            text="Я агент этого клиента"
                            bgColor="#DBEAFE"
                            textColor="#60A5FA"
                            icon="Question"
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                ),
              )}
              {counterparties?.length ? (
                <div className="flex justify-end w-full p-4">
                  <Pagination
                    current={page}
                    defaultPageSize={pageLimit}
                    onChange={onPageChange}
                    total={totalCounterpartiesCount}
                    showSizeChanger={false}
                  />
                </div>
              ) : null}
            </div>
          )}
        </>
        {counterparties.length === 0 && (
          <div className="flex p-[24px] text-gray_400 justify-center">Нет данных</div>
        )}
      </Box>
    </div>
  );
};

export default CounterpartyListBlock;
