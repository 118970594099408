import React, { FC } from 'react';
import clsx from 'clsx';

import { EgrnOrderStatus } from '@/types/egrn.type';

const statusLabelClassName = (type: number) =>
  clsx(
    'inline-flex',
    'px-2',
    'py-[2px]',
    'rounded-2xl',
    'text-paragraph_m',
    type === 2 ? 'bg-positive_50 text-positive_500' : 'bg-yellow_50 text-yellow_500',
  );

type StatusLabelProps = {
  type: number;
};

const StatusLabel: FC<StatusLabelProps> = (props) => {
  const { type } = props;

  return (
    <div className={statusLabelClassName(type)}>
      {type === 2
        ? EgrnOrderStatus.ready
        : type === 1
        ? EgrnOrderStatus.inprogress
        : EgrnOrderStatus.notpayed}
    </div>
  );
};

export default StatusLabel;
