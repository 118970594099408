import React from 'react';

const DocumentText = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7.5 10h5m-5 3.333h5m1.667 4.167H5.833a1.667 1.667 0 01-1.666-1.667V4.167A1.667 1.667 0 015.833 2.5h4.655c.221 0 .433.088.59.244l4.511 4.512a.834.834 0 01.244.589v7.988a1.666 1.666 0 01-1.666 1.667z"
      ></path>
    </svg>
  );
};

export default DocumentText;
