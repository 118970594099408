import { RootState } from '@/store';

export const uploadedFilesSelector = (state: RootState) => {
  return state.filesStore.uploadedFiles;
};
export const contractFilesSelector = (state: RootState) => {
  return state.filesStore.contractFiles;
};
export const offerFilesSelector = (state: RootState) => {
  return state.filesStore.offerFiles;
};
export const resultFilesSelector = (state: RootState) => {
  return state.filesStore.resultFiles;
};
