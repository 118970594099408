import { Query, Route } from '@/types/route.type';

export const route: Record<Route, string> = {
  main: '/',
  login: '/login',
  forgotPassword: '/login/forgot',
  register: '/register',
  orders: '/orders',
  createOrder: '/create_order/',
  counterparties: '/counterparties',
  profile: '/profile',
  egrn: '/egrn',
  createEgrn: '/create_egrn',
  affiliateProgram: '/affiliate-program',
  instructions: '/instructions',
};

export const affiliateProgramQuery: Record<Query, string> = {
  myClientsList: 'my-clients-list',
  detailsOfAffiliatePayments: 'details-of-affiliate-payments',
  requisitesAndContract: 'requisites-and-contract',
  howToEarnWith1kk: 'how-to-earn-with-1kk',
};

export const tabQuery = '?tab=';
