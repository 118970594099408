import React from 'react';

import { Container } from '@/components/ui';

const Error404Route = () => {
  return (
    <Container>
      <div className="mt-[20px]">
        <h1 className="text-heading_h1 font-bold w-full text-center">404</h1>
        <h3 className="text-heading_h3 w-full text-gray_400 text-center">Страница не найдена</h3>
      </div>
    </Container>
  );
};

export default Error404Route;
