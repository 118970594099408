import clsx from 'clsx';

export const inputWrapperClassName = ({
  disabled,
  error,
  isFocus,
}: {
  disabled: boolean;
  error: boolean;
  isFocus: boolean;
}) =>
  clsx(
    'border',
    'bg-white',
    'text-gray_600',
    'placeholder-green-700',
    'text-paragraph_l',
    'font-regular',
    'rounded',
    'block',
    'w-full',
    'inline-flex',
    'items-center',
    'px-[12px]',
    'py-[7px]',
    'transition',
    disabled
      ? 'border-gray_300 bg-gray_100'
      : error
      ? 'border-negative_500'
      : isFocus
      ? 'border-primary_400'
      : 'border-gray_300 text-gray_400 hover:border-gray_400',
  );

export const inputClassName = clsx(
  'border-none',
  'focus:border-none',
  'outline-0',
  'focus:outline-0',
  'w-full',
  'text-gray_600',
  'disabled:bg-gray_100',
  'disabled:text-gray_400',
);
