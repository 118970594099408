import React, { useState } from 'react';

import ChatHeader from '@/features/chat/blocks/chatHeader/chatHeader';
import ChatBody from '@/features/chat/blocks/chatBody/сhatBody';
import { useChatsQuery } from '@/store/api/chat.api';

type ChatPropsType = {
  closeChat: () => void;
};

const Chat = ({ closeChat }: ChatPropsType) => {
  const [value, setValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isSuccess, isLoading, isError, isFetching, error } = useChatsQuery({
    limit: 8,
    page: currentPage,
  });
  const changePage = (page: number) => {
    setCurrentPage(page);
  };

  const chatBodyProps = {
    data,
    isSuccess,
    isLoading,
    isError,
    error,
    currentPage,
    changePage,
    value,
    isFetching,
  };

  return (
    <div>
      <ChatHeader closeChat={closeChat} value={value} setValue={setValue} />
      <ChatBody {...chatBodyProps} />
    </div>
  );
};

export default Chat;
