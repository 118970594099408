import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DataAffiliateProgram, DataTransactions } from '@/types/affiliate-program.type';

const initialState: DataAffiliateProgram = {
  transactions: {
    items: [],
  },
};

const affiliateProgramSlice = createSlice({
  name: 'affiliateProgramSlice',
  initialState,
  reducers: {
    setTransactions: (state: DataAffiliateProgram, action: PayloadAction<DataTransactions>) => {
      state.transactions.items = action.payload.items;
    },
  },
});

export const { setTransactions } = affiliateProgramSlice.actions;
export default affiliateProgramSlice.reducer;
