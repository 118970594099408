import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { LoginFields } from '@/types/auth.type';
import { apiBaseUrl } from '@/util/api';

export const authApi = createApi({
  reducerPath: 'AuthQuery',
  tagTypes: ['Auth', 'Chat', 'Messages'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
    prepareHeaders: (headers) => {
      const token: string | null = localStorage.getItem('__token');
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    // инициализация пользователя
    me: build.query({
      query: () => ({
        url: '/auth/me',
      }),
    }),
    // регистрация
    register: build.mutation({
      query: (data: LoginFields) => ({
        url: '/auth/register',
        method: 'POST',
        body: data,
      }),
    }),
    // восстановление пароля
    recover: build.mutation({
      query: (data) => ({
        url: '/auth/recover',
        method: 'POST',
        body: data,
      }),
    }),
    // отправить код
    confirm: build.mutation({
      query: (data) => ({
        url: '/auth/confirm',
        method: 'POST',
        body: data,
      }),
    }),
    login: build.mutation({
      query: (data) => ({
        url: '/auth/login',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Auth'],
    }),
    logout: build.mutation({
      query: () => ({
        url: '/auth/logout',
        method: 'POST',
      }),
      invalidatesTags: ['Auth'],
    }),
  }),
});

export const {
  useMeQuery,
  useLazyMeQuery,
  useRegisterMutation,
  useRecoverMutation,
  useConfirmMutation,
  useLoginMutation,
  useLogoutMutation,
} = authApi;
