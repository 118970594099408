import React, { useState, useContext } from 'react';
import { Link, useLocation, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ShepherdTourContext } from 'react-shepherd';

import { Avatar, Button, Icon, Menu } from '@/components/ui';
import { route } from '@/util/route';
import { /*notificationClassName,*/ userProfileName } from '@/components/ui/header/header.styles';
import { MenuItem } from '@/components/ui/menu/menu';
import HeadersUserMenu from '@/components/ui/header/headers-user-menu';
import VideoInstruction from '@/components/video-instruction';
import { useAppSelector } from '@/hooks/use-app-selector';
import { initializeSelector, userSelector } from '@/store/selectors/auth.selectors';
import { User } from '@/types/user.type';

const Header = () => {
  const user: User | null = useAppSelector(userSelector);
  const initialize = useAppSelector(initializeSelector);
  const [isVisibleProfileMenu, changeProfileMenuVisible] = useState<boolean>(false);
  const [isVisibleVideoInstruction, changeVideoInstructionVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const userAuthStyle = { height: user ? '45px' : '55px' };
  // const notificationCount = 1;
  // const truncatedNotification = notificationCount > 99 ? '99+' : notificationCount;

  const tour = useContext(ShepherdTourContext);

  const headerMenuItems: Array<MenuItem> = [
    {
      tag: ['orders', ''],
      title: t('header_menu.orders'),
      link: route.orders,
    },
    {
      tag: 'counterparties',
      title: t('header_menu.counterparties'),
      link: route.counterparties,
    },
    {
      tag: 'egrn',
      title: t('header_menu.egrn'),
      link: route.egrn,
    },
    {
      tag: 'affiliate-program',
      title: t('header_menu.affiliate-program'),
      link: route.affiliateProgram,
    },
    // {
    //   tag: 'instructions',
    //   title: t('header_menu.instructions'),
    //   link: route.instructions,
    //   icon: <Icon name="Video" />,
    // },
  ];

  const handleClickOnboarding = () => {
    tour?.start();
    tour?.show('orders');
  };

  const renderMenu = !!user && (
    <Menu menuData={headerMenuItems} active={pathname.replace('/', '')} />
  );

  const renderUserHeaderBlock = () => {
    if (user) {
      const shortUserName = `${user.surname ? user.surname : ''} ${
        user.name ? user?.name?.at(0) : ''
      }. ${user.middle_name ? user.middle_name?.at(0) : ''}.`;
      return (
        <div className="inline-flex relative justify-end items-center w-full max-w-[165px] mt-[-3px] ">
          <div className="onboarding cursor-pointer mr-3" onClick={handleClickOnboarding}>
            <Icon name="QuestionOutline" size={20} className="text-gray_400" />
          </div>

          {/*<div className="relative h-full cursor-pointer">*/}
          {/*  <Icon name="Bell" size={24} className="text-gray_400" />*/}
          {/*  {truncatedNotification > 0 && (*/}
          {/*    <div className={notificationClassName}>{truncatedNotification}</div>*/}
          {/*  )}*/}
          {/*</div>*/}

          <div
            className="inline-flex items-center cursor-pointer"
            onClick={changeProfileMenuVisible.bind(null, true)}
          >
            <div className={userProfileName}>{shortUserName}</div>
            <div>
              <Avatar />
            </div>
          </div>
          <HeadersUserMenu isShow={isVisibleProfileMenu} changeShow={changeProfileMenuVisible} />
        </div>
      );
    } else {
      return (
        <div className="inline-flex">
          <div className="mr-[14px]">
            <Link to={route.register}>
              <Button label={t('header.btn.registration')} variant="outlined" space={16} />
            </Link>
          </div>
          <div>
            <Link to={route.login}>
              <Button label={t('header.btn.login')} space={34} />
            </Link>
          </div>
        </div>
      );
    }
  };
  if (initialize) {
    return (
      <div
        className="border-b-[1px] fixed top-0 left-0 border-b-gray_200 bg-white w-full flex justify-center z-50"
        style={userAuthStyle}
      >
        <div className="w-full max-w-[1168px] bg-white flex items-center justify-between">
          <div className="h-full">
            <NavLink to={'/'}>
              <Icon name="LogoV1" size={68} className="h-full" />
            </NavLink>
          </div>
          <div className="h-full flex">
            {renderMenu}
            {!!user && (
              <div
                className="instructions flex items-center h-full mx-[10px] border-b-[2px] border-b-transparent text-paragraph_l text-gray_400 font-medium cursor-pointer"
                onClick={changeVideoInstructionVisible.bind(null, true)}
              >
                {t('header_menu.instructions')}
                <Icon name="Video" className="ml-[8px] mt-[-1px]" />
              </div>
            )}
          </div>
          {renderUserHeaderBlock()}
        </div>
        <VideoInstruction
          isVisibleVideoInstruction={isVisibleVideoInstruction}
          changeVideoInstructionVisible={changeVideoInstructionVisible}
        />
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default Header;
