import React from 'react';

const Trash = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.333 9.167v5m3.334-5v5M3.333 5.833h13.334m-.834 0l-.722 10.119a1.667 1.667 0 01-1.663 1.548H6.552a1.667 1.667 0 01-1.663-1.548L4.167 5.833h11.666zm-3.333 0v-2.5a.834.834 0 00-.833-.833H8.333a.833.833 0 00-.833.833v2.5h5z"
      ></path>
    </svg>
  );
};

export default Trash;
