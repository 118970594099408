/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { translateRequisitesPath } from '../constants';

import { BankDetailsFormProps } from '../requisites-and-contract/types';

import { Button, Icon, Input, Select } from '@/components/ui';
import { useValidation } from '@/hooks/use-validation';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { Counterparty } from '@/types/counterparty.type';
import { useAppSelector } from '@/hooks/use-app-selector';
import { counterpartySelector } from '@/store/selectors/counterparty.selectors';
import { fetchCounterparties } from '@/store/slices/counterparty.slice';
import { useLazyGetCounterpartyByInnQuery } from '@/store/api/counterparty.api';

const BankDetailsFormBlock: FC<BankDetailsFormProps> = ({ form, submit }) => {
  const {
    register,
    setValue,
    formState: { errors },
    getValues,
    watch,
  } = form;

  const validationFields = useValidation();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [currentCounterPartySelected, setCurrentCounterPartySelected] =
    useState<Counterparty | null>(null);

  const [getCounterpartyByInn] = useLazyGetCounterpartyByInnQuery();

  const counterparties: Counterparty[] = useAppSelector(counterpartySelector); // Все контрагенты

  function setForm(findedCounterparty: Counterparty) {
    !currentCounterPartySelected && setCurrentCounterPartySelected(findedCounterparty);
    setValue(
      'kpp',
      (findedCounterparty?.counterparty_type !== 'ИП' && findedCounterparty?.legal_data?.kpp) || '',
    );
    setValue('name', findedCounterparty?.legal_data?.name || '');
    setValue('ogrn', findedCounterparty?.legal_data?.ogrn || '');
    setValue('address', findedCounterparty?.legal_data?.address || '');
    setValue('address', findedCounterparty?.legal_data?.address || '');
    setValue('manager_name', findedCounterparty?.legal_data?.manager_name || '');
    setValue('manager_position', findedCounterparty?.legal_data?.manager_position || '');
    setValue('bank_name', findedCounterparty?.account_data?.bank_name || '');
    setValue('billing_account', findedCounterparty?.account_data?.billing_account || '');
    setValue('bik', findedCounterparty?.account_data?.bik || '');
    setValue(
      'correspondent_account',
      findedCounterparty?.account_data?.correspondent_account || '',
    );
    setValue('counterparty_type', findedCounterparty.counterparty_type);
  }
  function resetForm() {
    setValue('kpp', '');
    setValue('name', '');
    setValue('ogrn', '');
    setValue('address', '');
    setValue('address', '');
    setValue('manager_name', '');
    setValue('manager_position', '');
    setValue('bank_name', '');
    setValue('billing_account', '');
    setValue('bik', '');
    setValue('correspondent_account', '');
  }

  useEffect(() => {
    dispatch(fetchCounterparties({ my_referral: false }));
  }, [dispatch]);

  useEffect(() => {
    if (!watch().inn) {
      resetForm();
    }
    const findedCounterparty = counterparties?.find(
      (counterparty) => counterparty.legal_data?.inn === watch().inn,
    );
    if (findedCounterparty) {
      setForm(findedCounterparty);
    } else if (watch()?.inn?.length >= 10) {
      getCounterpartyByInn(watch().inn).then((res) => res.data?.success && setForm(res.data.data));
    }
  }, [counterparties, watch().inn]);

  return (
    <form onSubmit={submit}>
      <div className="flex bg-primary_50 py-2 px-3 rounded-lg">
        <Icon name="Info" className="text-primary_400 mr-4" />
        {t('create_counterparty.automatic')}
      </div>
      <div className="flex mt-[16px]">
        <div className="w-full mr-1.5">
          <Select
            schema={{
              title: 'title',
              value: 'value',
            }}
            data={counterparties
              .filter((el) => el.counterparty_type !== 'ФЛ')
              .map((el) => {
                return {
                  title: `${el.legal_data?.name} ${el.legal_data?.inn}` || '',
                  value: el.legal_data?.inn || '',
                };
              })}
            initialValue={watch()?.inn || ''}
            activeValue={
              currentCounterPartySelected
                ? `${currentCounterPartySelected?.legal_data?.name} ${currentCounterPartySelected?.legal_data?.inn}`
                : ''
            }
            {...(currentCounterPartySelected?.counterparty_type === 'ИП'
              ? {
                  ...register('inn', validationFields.individual_inn),
                }
              : {
                  ...register('inn', validationFields.legal_inn),
                })}
            onSelectOption={(option) => {
              setValue('inn', String(option.value));

              const currentCounterPartySelected = counterparties?.find(
                (counterparty) => counterparty.legal_data?.inn === option.value,
              );
              currentCounterPartySelected?.counterparty_type === 'ИП' && setValue('kpp', '');
              setCurrentCounterPartySelected(currentCounterPartySelected || null);
            }}
            label={t('common.input.inn.label') + '*'}
            placeholder={t('common.input.inn.placeholder')}
            error={errors.inn?.message}
            withReset
            reset={() => {
              setValue('inn', '');
              setCurrentCounterPartySelected(null);
            }}
            withSearch
            independentSearch
          />
        </div>
        <div className="w-full ml-1.5">
          <Input
            label={t('common.input.kpp.label') + '*'}
            {...(currentCounterPartySelected?.counterparty_type !== 'ИП' && {
              ...register('kpp', validationFields.kpp),
            })}
            // disabled={currentCounterPartySelected?.counterparty_type === 'ИП'}
            defaultValue={getValues()?.kpp}
            error={errors.kpp?.message}
            placeholder={t('common.input.kpp.placeholder')}
          />
        </div>
      </div>
      <div className="flex mt-[16px]">
        <div className="w-full mr-1.5">
          <Input
            label={t('common.input.company_name.label') + '*'}
            {...register('name', validationFields.company_name)}
            defaultValue={getValues()?.name}
            error={errors.name?.message}
            placeholder={t('common.input.company_name.placeholder')}
          />
        </div>
        <div className="w-full ml-1.5">
          <Input
            {...register('ogrn', validationFields.ogrn)}
            label={t('common.input.ogrn.label') + '*'}
            defaultValue={getValues()?.ogrn}
            error={errors.ogrn?.message}
            placeholder={t('common.input.ogrn.placeholder')}
          />
        </div>
      </div>
      <div className="mt-[16px]">
        <Input
          label={t('common.input.address.label') + '*'}
          {...register('address', validationFields.address)}
          defaultValue={getValues()?.address}
          error={errors.address?.message}
          placeholder={t('common.input.address.placeholder')}
        />
      </div>
      <div className="flex mt-[16px]">
        <div className="w-full mr-1.5">
          <Input
            label={t('common.input.manager_name.label') + '*'}
            {...register('manager_name', validationFields.manager_name)}
            defaultValue={getValues()?.manager_name}
            error={errors.manager_name?.message}
            placeholder={t('common.input.manager_name.placeholder')}
          />
        </div>
        <div className="w-full ml-1.5">
          <Input
            {...register('manager_position', validationFields.manager_position)}
            label={t('common.input.manager_position.label') + '*'}
            defaultValue={getValues()?.manager_position}
            error={errors.manager_position?.message}
            placeholder={t('common.input.manager_position.placeholder')}
          />
        </div>
      </div>
      <div className="flex mt-[16px]">
        <div className="w-full mr-1.5">
          <Input
            label={t('common.input.bank_name.label') + '*'}
            {...register('bank_name', validationFields.bank_name)}
            defaultValue={getValues()?.bank_name}
            error={errors.bank_name?.message}
            placeholder={t('common.input.bank_name.placeholder')}
          />
        </div>
        <div className="w-full ml-1.5">
          <Input
            {...register('billing_account', validationFields.billing_account)}
            label={t('common.input.billing_account.label') + '*'}
            defaultValue={getValues()?.billing_account}
            error={errors.billing_account?.message}
            placeholder={t('common.input.billing_account.placeholder')}
          />
        </div>
      </div>
      <div className="flex mt-[16px]">
        <div className="w-full mr-1.5">
          <Input
            label={t('common.input.bik.label') + '*'}
            {...register('bik', validationFields.bik)}
            defaultValue={getValues()?.bik}
            error={errors.bik?.message}
            placeholder={t('common.input.bik.placeholder')}
          />
        </div>
        <div className="w-full ml-1.5">
          <Input
            {...register('correspondent_account', validationFields.correspondent_account)}
            label={t('common.input.correspondent_account.label') + '*'}
            defaultValue={getValues()?.correspondent_account}
            error={errors.correspondent_account?.message}
            placeholder={t('common.input.correspondent_account.placeholder')}
          />
        </div>
      </div>
      <div className="mt-[22px]">
        <Button type="submit" label={t(`${translateRequisitesPath}.form-an-agreement`)} isFull />
      </div>
    </form>
  );
};

export default BankDetailsFormBlock;
