import React from 'react';

import Conversation from '@/features/chat/blocks/conversation/conversation';
import { ChatType } from '@/types/chat.type';

type ConversationsPropsType = {
  chats: ChatType[];
  onSetCurrentChat: (chat: ChatType) => void;
  currentChat: ChatType | null;
};
const Conversations = ({ chats, onSetCurrentChat, currentChat }: ConversationsPropsType) => {
  const onHandlerSetCurrentChat = async (chat: ChatType) => {
    onSetCurrentChat(chat);
  };
  return (
    <div>
      {chats &&
        chats.map((chat) => {
          const conversationProps = {
            title: chat.title,
            offer: chat.issue_number,
            date: chat.issue_date,
            isReady: chat.status,
            unRead: chat.count_new_messages,
            id: chat.id,
            currentChat: currentChat,
            type: chat.type,
          };
          return (
            // <div key={chat.id} onClick={() => onSetCurrentChat(chat)}>
            <div className="relative" key={chat.id} onClick={() => onHandlerSetCurrentChat(chat)}>
              <Conversation {...conversationProps} />
            </div>
          );
        })}
    </div>
  );
};
export default Conversations;
