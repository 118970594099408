import React, { FC /*, useEffect*/ } from 'react';
import { useTranslation } from 'react-i18next';

import { StageOrder } from '@/types/order.type';
// import { useAppDispatch } from '@/hooks/use-app-dispatch';
// import { useAppSelector } from '@/hooks/use-app-selector';
// import { Contract, ContractStatus } from '@/types/contract.type';
// import { contractSelector } from '@/store/selectors/contract.selectors';

// import { fetchContractByOfferId } from '@/store/slices/contract.slices';

type OrderStageProps = {
  stage: string;
  // offer_id: number | string | null;
};

const OrderStage: FC<OrderStageProps> = (props) => {
  const { stage /*, offer_id*/ } = props;
  const { t } = useTranslation();
  // const dispatch = useAppDispatch();

  // const contract: Contract | null = useAppSelector(contractSelector); // Договор

  // useEffect(() => {
  //   if (offer_id) {
  //     dispatch(fetchContractByOfferId(offer_id)); // Договор
  //   }
  // }, [offer_id, dispatch]);

  return (
    <>
      {
        stage === StageOrder.calculated
          ? t('order_card.stage.request_KP') // Запрос КП
          : stage === StageOrder.approval
          ? t('order_card.stage.approval') // Согласование КП
          : stage === StageOrder.completed
          ? t('order_card.stage.completed') // Работа завершена
          : t('order_card.stage.in_progress') // В работе
      }
    </>
  );
};

export default React.memo(OrderStage);
