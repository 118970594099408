import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { useForm } from 'react-hook-form';

import { route } from '@/util/route';
import { Button, Input } from '@/components/ui';
import TitleLine from '@/components/ui/title-line';
import { useValidation } from '@/hooks/use-validation';
import { useLoginMutation } from '@/store/api/auth.api';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { setUser } from '@/store/slices/auth.slice';

type LoginFields = {
  email: string;
  password: string;
};

const LoginFormBlock = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const validation = useValidation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginFields>();

  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();
  const [error, setError] = useState('');

  async function handleFormSubmit(data: LoginFields) {
    const returned: any = await login(data);
    if (returned && returned.data?.success) {
      setError('');
      const { token, me } = returned.data.data;
      localStorage.setItem('__token', token);

      dispatch(setUser({ token, me }));
      navigate('/');
    } else {
      setError(returned.error.data.error);
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <h1 className="text-gray-600 text-center text-heading_h2 mt-[2px] font-bold">
          {t('login.title')}
        </h1>
        <div className="mt-[16px]">
          <Input
            type="email"
            {...register('email', validation.email)}
            label={t('login.input.email.label')}
            error={errors.email?.message}
            placeholder="email@email.com"
          />
        </div>
        <div className="mt-[12px]">
          <Input
            type="password"
            {...register('password', validation.password)}
            error={errors.password?.message}
            label={t('login.input.password.label')}
          />
        </div>
        <div className="mt-[4px] mb-[24px] w-full inline-flex justify-end text-paragraph_m font-bold text-primary_400 opacity-80 hover:opacity-100">
          <Link to={route.forgotPassword}>{t('login.link.forgot_password')}</Link>
        </div>
        <div className="m-[5px]" style={{ color: 'red', fontSize: '14px' }}>
          {error && <span>{error}</span>}
        </div>
        <div>
          <Button type="submit" label={t('login.btn.login')} isFull disabled={isLoading} />
        </div>
        <div className="my-[6px]">
          <TitleLine title={t('login.info')} />
        </div>
        <div>
          <Link to={route.register}>
            <Button label={t('login.btn.register')} variant="outlined" isFull />
          </Link>
        </div>
      </form>
    </div>
  );
};

export default LoginFormBlock;
