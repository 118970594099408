import React, { FC, useRef } from 'react';

import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Box, Icon } from '@/components/ui';
import { route } from '@/util/route';
import { useOnClickOutside } from '@/hooks/use-onclick-outside';
import { useLogoutMutation } from '@/store/api/auth.api';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { setUser } from '@/store/slices/auth.slice';
import { resetProposals } from '@/store/slices/proposal.slices';

type HeadersUserMenuProps = {
  isShow: boolean;
  changeShow: (status: boolean) => void;
};

const HeadersUserMenu: FC<HeadersUserMenuProps> = (props) => {
  const { isShow, changeShow } = props;
  const userMenuRef = useRef(null);
  const { t } = useTranslation();
  const [logout] = useLogoutMutation();
  const dispatch = useAppDispatch();
  const onExit = async () => {
    try {
      changeShow?.(false);
      await logout({}).unwrap();
      dispatch(setUser({ token: null, me: null }));
      dispatch(resetProposals());
      localStorage.removeItem('__token');
    } catch (error) {
      console.error('rejected', error);
    }
  };
  useOnClickOutside(userMenuRef, () => changeShow?.(false));

  if (!isShow) return <></>;
  return (
    <div ref={userMenuRef} className="absolute top-full mt-[5px] w-[137px] h-[200px]">
      <Box>
        <ul>
          <Link to={route.profile}>
            <li
              className="inline-flex items-center py-[8px] my-[4px] px-[12px] w-full hover:bg-gray_100"
              onClick={() => changeShow?.(false)}
            >
              <div className="text-primary_400 mr-[10px]">
                <Icon name="User" className="text-primary_400" size={20} />
              </div>
              <span className="text-paragraph_l text-gray_600">{t('header.btn.profile')}</span>
            </li>
          </Link>
          <li
            className="inline-flex items-center cursor-pointer py-[8px] my-[4px] px-[12px] w-full hover:bg-gray_100"
            onClick={onExit}
          >
            <div className="text-primary_400 mr-[10px]">
              <Icon name="Logout" className="text-primary_400" size={20} />
            </div>
            <span className="text-paragraph_l text-gray_600">{t('header.btn.exit')}</span>
          </li>
        </ul>
      </Box>
    </div>
  );
};

export default HeadersUserMenu;
