import React from 'react';

import { Box } from '@/components/ui';
import { CompanyPromo } from '@/components';
import ForgotFormBlock from '@/features/login/blocks/forgot-form.block';
import { useAppSelector } from '@/hooks/use-app-selector';
import ForgotSendBlock from '@/features/login/blocks/forgot-send.block';

const ForgotPage = () => {
  const { isResendEmail } = useAppSelector((state) => state.commonStore);
  const renderCorrectForm = isResendEmail ? <ForgotSendBlock /> : <ForgotFormBlock />;
  return (
    <div className="w-full h-[calc(100vh-90px)] flex items-center justify-center">
      <Box>
        <div className="inline-flex">
          <CompanyPromo />
          <div className="border-l-[1px] border-l-light w-[1px] my-[25px]" />
          <div className="w-[383px] p-[24px] pt-[20px]">{renderCorrectForm}</div>
        </div>
      </Box>
    </div>
  );
};

export default ForgotPage;
