import React from 'react';

type PropsType = {
  unreadMessagesCount: number;
};
const UnredMessage = ({ unreadMessagesCount }: PropsType) => {
  return (
    <>
      {unreadMessagesCount && unreadMessagesCount > 0 ? (
        <div className="absolute bottom-2 right-2 bg-primary_400 px-2 text-paragraph_s flex justify-center items-center rounded-full text-white">
          {unreadMessagesCount}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default UnredMessage;
