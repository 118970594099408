import React from 'react';

const Send = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 15.833l7.5 1.667-7.5-15-7.5 15 7.5-1.667zm0 0V9.167"
      ></path>
    </svg>
  );
};

export default Send;
