import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { FieldValues, useForm, useWatch } from 'react-hook-form';
import moment from 'moment';
import { Table as AntdTable } from 'antd';
// import { saveAs } from 'file-saver';
import { v4 } from 'uuid';

import OnlinePayModalBlock from '@/features/egrn/blocks/online-pay/online-pay-modal.block';
import {
  Box,
  Button,
  Container,
  Input,
  Menu,
  Select,
  Icon,
  StatusLabel,
  Skeleton,
} from '@/components/ui';
import { route } from '@/util/route';
import { MenuItem } from '@/components/ui/menu/menu';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useQuery } from '@/hooks/use-query';
import { useNotification } from '@/hooks/use-notifications';
import { SelectSchema } from '@/components/ui/select/select.types';
import {
  useGetEgrnRealtyTypesQuery,
  useLazyGetEgrnOrdersQuery,
  // useLazyDownloadEgrnOrderQuery,
  // useLazyDownloadEgrnOrderItemQuery,
  useCopyEgrnOrderMutation,
  usePayEgrnOrderMutation,
} from '@/store/api/egrn.api';
import { setNewOrder, setOrdersPage } from '@/store/slices/egrn.slices';
import { pageOrderSelector } from '@/store/selectors/egrn.selectors';
import { EgrnOrder, EgrnNewOrderItem } from '@/types/egrn.type';
import Datepicker from '@/components/ui/input/datepicker';
import { useTablesHeader } from '@/features/feature-hooks/use-tables-header';
import ChatOpen from '@/features/chat/blocks/chatOpen/chatOpen';

type SearchData = {
  searchText?: string;
  realty_type?: string;
  period_from?: Date;
  period_to?: Date;
  status?: number;
};

interface DataType {
  key: number | string;
  id?: number;
  order_num?: string | null | React.ReactNode;
  date: string | null | React.ReactNode;
  cadastral_number: string | null | React.ReactNode;
  realty_type: string | null | React.ReactNode;
  address: string | null | React.ReactNode;
  comment: string | null | React.ReactNode;
  pay_type: string | null | React.ReactNode;
  status: React.ReactNode;
  actions: React.ReactNode;
  children?: DataType[];
}

const EgrnPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const query = useQuery();
  const navigate = useNavigate();

  const pageLimit = 10;

  const page: number = useAppSelector(pageOrderSelector);

  const { data: realtyTypes = [] } = useGetEgrnRealtyTypesQuery(null);
  const dataRealtyTypes = realtyTypes.data || [];
  const tableHead = useTablesHeader();

  const estateTypesSelectData = dataRealtyTypes.map((item: string) => {
    return { value: item };
  });

  const { register, control, setValue } = useForm<FieldValues>();
  const searchText = useWatch({
    control,
    name: 'searchText',
  });

  const initFormData = {
    searchText: undefined,
    realty_type: undefined,
    period_from: undefined,
    period_to: undefined,
    status: undefined,
  };

  const menuList: Array<MenuItem> = [
    { tag: 'all', title: t('egrn.menu.all'), link: route.egrn + '?tab=all' },
    { tag: 'ready', title: t('egrn.menu.ready'), link: route.egrn + '?tab=ready' },
    {
      tag: 'inprogress',
      title: t('egrn.menu.inprogress'),
      link: route.egrn + '?tab=inprogress',
    },
    {
      tag: 'notpayed',
      title: t('egrn.menu.notpayed'),
      link: route.egrn + '?tab=notpayed',
    },
  ];

  const [formData, setFormData] = useState<SearchData>(initFormData);
  const [startDate, setStartDate] = useState<Date | undefined | null>();
  const [endDate, setEndDate] = useState<Date | undefined | null>();
  const [dataOrders, setDataOrders] = useState<EgrnOrder[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const [openPayModal, togglePayModal] = useState<boolean>(false);
  const [orderForPayId, setOrderForPayId] = useState<number>(0);
  const [orderForPayPrice, setOrderForPayPrice] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const active = query.get('tab') || 'all';

  const { createNotification } = useNotification();

  const [getOrders, { isFetching: ordersIsLoading }] = useLazyGetEgrnOrdersQuery();
  // const [getDownloadOrderLink] = useLazyDownloadEgrnOrderQuery();
  // const [getDownloadOrderItemLink] = useLazyDownloadEgrnOrderItemQuery();
  const [getCopy] = useCopyEgrnOrderMutation();
  const [payEgrnOrder, { isLoading: isPayOrderLoading }] = usePayEgrnOrderMutation();

  const fetchOrders = useCallback(() => {
    const params = {
      realty_type: formData.realty_type,
      query: formData.searchText,
      period_from: formData.period_from
        ? moment(formData.period_from).format('YYYY-MM-DD')
        : undefined,
      period_to: formData.period_to ? moment(formData.period_to).format('YYYY-MM-DD') : undefined,
      status: formData.status,
      page: currentPage,
      limit: pageLimit,
    };
    getOrders(params)
      .unwrap()
      .then((payload) => {
        if (payload.success) {
          setDataOrders(payload.data.items);
          setTotalOrdersCount(payload.data.total);
          dispatch(setOrdersPage(payload.data.page));
        } else {
          setDataOrders([]);
          setTotalOrdersCount(0);
        }
      });
  }, [formData, currentPage, getOrders, dispatch]);

  useEffect(() => {
    fetchOrders();
  }, [formData, currentPage, fetchOrders]);

  useEffect(() => {
    const searchTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {
      formDataUpdate('searchText', searchText);
      setCurrentPage(1);
    }, 500);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchText]);

  useEffect(() => {
    formDataUpdate(
      'status',
      active === 'all' ? undefined : active === 'ready' ? 2 : active === 'inprogress' ? 1 : 0,
    );
    setCurrentPage(1);
  }, [active]);

  const copyElement = (orderId: number, itemId?: number) => {
    const params = {
      orderId,
      params: itemId ? { item_id: itemId } : null,
    };
    getCopy(params)
      .unwrap()
      .then((payload) => {
        const cloneData = structuredClone(payload.data);
        cloneData.items.forEach((item: EgrnNewOrderItem) => {
          item.uuid = v4();
        });
        dispatch(setNewOrder(cloneData));
        navigate(route.createEgrn);
      })
      .catch(() => {
        createNotification?.({
          variant: 'fail',
          title: t('egrn.notification.copy.fail.title'),
          description: t('egrn.notification.copy.fail.description'),
        });
      });
  };

  const renderEmptyOrder = (
    <div className="w-full mt-[182px] inline-flex flex-col items-center justify-center text-center">
      <Icon name="Inbox" size={40} className="text-primary_400 mb-2" />
      <span className="w-[340px] text-paragraph_l text-light_2">{t('egrn.empty.no_orders')}</span>
    </div>
  );

  const renderInvoiceDownload = (type: number, orderId: number, price: number, status: number) => {
    const onClickInvoice = async () => {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_SERVER}/payments/egrn`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify({ id: orderId, pay_type: 'invoice' }),
      });
      if (response.ok) {
        const filename = response.headers.get('filename');
        const result = await response.blob();
        const a = document.createElement('a'),
          url = URL.createObjectURL(result);
        a.href = url;
        a.download = `${filename}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      } else {
        createNotification?.({
          variant: 'fail',
          title: t('create_egrn.notification.download.fail.title'),
          description: t('create_egrn.notification.download.fail.description'),
        });
      }
      setLoading(false);
    };

    const onClickOnline = () => {
      setLoading(true);
      payEgrnOrder({ id: orderId, pay_type: 'online' })
        .unwrap()
        .then((payload) => {
          document.location.assign(payload.url);
        })
        .catch(() => {
          createNotification?.({
            variant: 'fail',
            title: t('create_egrn.notification.payment.fail.title'),
            description: t('create_egrn.notification.payment.fail.description'),
          });
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const onClickNotPayed = () => {
      setOrderForPayId(orderId);
      setOrderForPayPrice(price);
      togglePayModal(true);
    };

    if (status === 0) {
      if (type === 2) {
        return (
          <div
            className={loading ? 'flex text-gray_300' : 'flex text-primary_400 cursor-pointer'}
            onClick={onClickInvoice}
          >
            <Icon name="Download" />
            <span className="ml-1">{t('egrn.pay_type.invoice')}</span>
          </div>
        );
      } else if (type === 1) {
        return (
          <div
            className={
              isPayOrderLoading ? 'flex text-gray_300' : 'flex text-primary_400 cursor-pointer'
            }
            onClick={onClickOnline}
          >
            <span>{t('egrn.pay_type.online_pay')}</span>
          </div>
        );
      } else if (!type) {
        return (
          <div className="flex text-primary_400 cursor-pointer" onClick={onClickNotPayed}>
            <span>{t('egrn.pay_type.not_payed')}</span>
          </div>
        );
      }
    } else {
      if (type === 2) {
        return <>{t('egrn.pay_type.invoice')}</>;
      } else if (type === 1) {
        return <>{t('egrn.pay_type.online')}</>;
      } else {
        return <></>;
      }
    }
  };

  const cancelPayModal = () => {
    togglePayModal(false);
    fetchOrders();
  };

  const renderOrderActions = (orderStatus: number, orderId: number, itemId?: number) => {
    return (
      <div className="flex justify-end text-primary_400">
        {orderStatus === 2 ? (
          // <div
          //   className="cursor-pointer"
          //   onClick={itemId ? () => downloadOrderItem(itemId) : () => downloadOrder(orderId)}
          // >
          <a
            href={
              itemId
                ? `${process.env.REACT_APP_SERVER}/egrn/item/${itemId}/download`
                : `${process.env.REACT_APP_SERVER}/egrn/${orderId}/download`
            }
            download
          >
            <Icon name="Download" />
          </a>
        ) : // </div>
        null}
        <div className="ml-3 cursor-pointer" onClick={() => copyElement(orderId, itemId)}>
          <Icon name="Refresh" />
        </div>
      </div>
    );
  };

  const renderStatus = (status: number) => <StatusLabel type={status} />;

  const tableEgrnData: DataType[] = dataOrders.map((el: EgrnOrder) => {
    if (el.items.length && el.items.length > 1) {
      return {
        key: el.id,
        id: el.id,
        order_num: el.order_num,
        date: el.date,
        cadastral_number: '-',
        realty_type: '-',
        address: '-',
        comment: '-',
        pay_type: renderInvoiceDownload(el.pay_type, el.id, el.price || 0, el.status),
        status: renderStatus(el.status),
        actions: renderOrderActions(el.status, el.id),
        children: el.items.map((item) => {
          return {
            key: `${el.id}${item.id}`,
            id: item.id,
            order_num: null,
            date: null,
            cadastral_number: item.cadastral_number,
            realty_type: item.realty_type,
            address: item.address,
            comment: item.comment,
            pay_type: null,
            status: null,
            actions: renderOrderActions(el.status, el.id, item.id),
          };
        }),
      };
    } else {
      const item = el.items[0];
      return {
        key: el.id,
        id: el.id,
        order_num: el.order_num,
        date: el.date,
        cadastral_number: item?.cadastral_number,
        realty_type: item.realty_type,
        address: item.address,
        comment: item.comment,
        pay_type: renderInvoiceDownload(el.pay_type, el.id, el.price || 0, el.status),
        status: renderStatus(el.status),
        actions: renderOrderActions(el.status, el.id),
      };
    }
  });

  const changeSelectEstateType = (type: SelectSchema) => {
    formDataUpdate('realty_type', type.value as string);
    setCurrentPage(1);
  };

  const changeExpandedRowClassName = (expanded: boolean, record: DataType) => {
    const row = document.querySelector(`.ant-table-row-level-0[data-row-key="${record.key}"]`);
    if (row) {
      if (expanded) {
        row.classList.add('bg-primary_100');
      } else {
        row.classList.remove('bg-primary_100');
      }
    }
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderOrderNumber = (
    expanded: boolean,
    onExpand: {
      (record: DataType, event: React.MouseEvent<HTMLElement, MouseEvent>): void;
      (arg0: DataType, arg1: any): void;
    },
    record: DataType,
  ) => {
    if (record.children) {
      return (
        <div
          className="flex text-primary_400 cursor-pointer"
          onClick={(e: any) => onExpand(record, e)}
        >
          <Icon name={expanded ? 'ChevronTop' : 'ChevronBottom'} />
          {record.order_num} ({record.children.length})
        </div>
      );
    } else return <span>{record.order_num}</span>;
  };

  const renderSceleton = Array(3)
    .fill('')
    .map((_, index) => {
      return {
        key: index,
        order_num: <Skeleton size={100} height={21} />,
        date: <Skeleton size={100} height={21} />,
        cadastral_number: <Skeleton size={100} height={21} />,
        realty_type: <Skeleton size={100} height={21} />,
        address: <Skeleton size={100} height={21} />,
        comment: <Skeleton size={100} height={21} />,
        pay_type: <Skeleton size={100} height={21} />,
        status: <Skeleton size={100} height={21} />,
        actions: <Skeleton size={100} height={21} />,
      };
    });

  const renderOrders = dataOrders?.length ? (
    <Box>
      <div className="p-4">
        <AntdTable
          columns={tableHead('egrnTableHeader')}
          dataSource={ordersIsLoading ? renderSceleton : tableEgrnData}
          pagination={{
            current: page,
            defaultPageSize: pageLimit,
            onChange: onPageChange,
            total: totalOrdersCount,
            showSizeChanger: false,
          }}
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) => {
              return renderOrderNumber(expanded, onExpand, record);
            },
            onExpand: (expanded, record) => {
              changeExpandedRowClassName(expanded, record);
            },
          }}
          rowClassName={() => 'text-paragraph_l text-gray_600'}
          scroll={{ x: 1136 }}
        />
      </div>
    </Box>
  ) : ordersIsLoading ? null : (
    renderEmptyOrder
  );

  const changeDateRange = (update: [Date | null, Date | null]) => {
    const [startDate, endDate] = update;
    setStartDate(startDate);
    setEndDate(endDate);
    if (startDate && endDate) {
      formDataUpdate('period_from', startDate);
      formDataUpdate('period_to', endDate);
      setCurrentPage(1);
    }
  };

  const resetDateRange = () => {
    setStartDate(null);
    setEndDate(null);
    formDataUpdate('period_from', null);
    formDataUpdate('period_to', null);
    setCurrentPage(1);
  };

  function formDataUpdate(
    fieldName: string,
    value: string | number | Date | { [p: string]: string | number } | null | undefined,
  ) {
    setFormData((prevState) => ({ ...prevState, [fieldName]: value }));
  }

  function formDataReset() {
    setFormData(initFormData);
    setValue('searchText', '');
    setStartDate(null);
    setEndDate(null);
    setCurrentPage(1);
  }

  return (
    <Container>
      <div className="my-[20px]">
        <p className="text-heading_h2 font-bold text-gray_600">{t('egrn.filter_title')}</p>
      </div>
      <div className="flex flex-col w-full pb-4">
        <div className="flex flex-col md:flex-row items-center justify-between gap-[20px] mb-[16px]">
          <div className="w-full md:min-w-[572px]">
            <Input
              {...register('searchText')}
              beforeIcon="Search"
              icon={searchText && 'Close'}
              onIconClick={() => {
                setValue('searchText', '');
                setCurrentPage(1);
              }}
              defaultValue={searchText}
              placeholder={t('egrn.input.cadaster.placeholder')}
            />
          </div>
          <div title={formData.realty_type} className="w-full mt-[10px] md:mt-[0px] min-w-[277px]">
            <Select
              initialValue={formData.realty_type || ''}
              activeValue={formData.realty_type || ''}
              onSelectOption={changeSelectEstateType}
              schema={{ title: 'value', value: 'value' }}
              data={estateTypesSelectData}
              placeholder={t('egrn.input.object_type.placeholder')}
              withReset
              reset={() => {
                formDataUpdate('realty_type', '');
                setCurrentPage(1);
              }}
            />
          </div>
          <div className="w-full mt-[10px] md:mt-0 pr-0 md:min-w-[275px] md:w-[275px] ">
            <Datepicker
              isRange={true}
              startDate={startDate}
              endDate={endDate}
              icon="Calendar"
              resetIcon={formData?.period_from && 'Close'}
              onResetIconClick={resetDateRange}
              placeholder={t('egrn.input.period.placeholder')}
              onRangeChange={changeDateRange}
            />
          </div>
        </div>
        <div className="inline-flex justify-between flex-col md:flex-row my-[24px] items-center">
          <Menu menuData={menuList} active={active} />
          {(!!Object.values(formData).filter((value) => !!value).length || active !== 'all') &&
            !ordersIsLoading && (
              <>
                <div className="text-paragraph_l font-medium text-gray_400">
                  {t('orders.find')}
                  <span className="text-gray-600">{totalOrdersCount}</span>
                </div>
                <div className="mt-[10px] md:mt-0">
                  <Link to={route.egrn}>
                    <Button
                      icon="Close"
                      label={t('orders.btn.reset_filters')}
                      variant="outlined"
                      onClick={formDataReset}
                    />
                  </Link>
                </div>
              </>
            )}
          <div className="flex items-center gap-[24px]">
            <Link to={route.createEgrn}>
              <div className="mt-[10px] md:mt-0">
                <Button icon="Document" label={t('egrn.btn.new_order')} />
              </div>
            </Link>
          </div>
        </div>
        {renderOrders}
        <OnlinePayModalBlock
          isShow={openPayModal}
          paySum={(orderForPayPrice || 0) / 100}
          orderId={orderForPayId}
          onCancel={cancelPayModal}
        />
      </div>
      <ChatOpen />
    </Container>
  );
};

export default EgrnPage;
