import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';

import { Icon } from '@/components/ui';
import { IconName } from '@/components/ui/icon/icons.type';

type InfoVariant = 'success' | 'danger';
type InfoVariantKeys = { container: string; icon: { name: IconName; color: string } };

type InfoProps = {
  variant: InfoVariant;
  content: string | ReactNode;
};

const Info: FC<InfoProps> = (props) => {
  const { variant = 'success', content } = props;
  const variantList: Record<InfoVariant, InfoVariantKeys> = {
    success: {
      container: 'bg-positive_50 border border-positive_500',
      icon: { name: 'Check', color: 'text-positive_500' },
    },
    danger: {
      container: 'bg-yellow_100 border border-yellow_500',
      icon: { name: 'Info', color: 'text-yellow_500' },
    },
  };
  return (
    <div
      className={clsx(
        variantList[variant].container,
        'rounded',
        'w-full',
        'inline-flex',
        'items-center',
        'p-[12px]',
      )}
    >
      <div>
        <Icon
          name={variantList[variant].icon.name}
          className={variantList[variant].icon.color}
          size={20}
        />
      </div>
      <div className="text-gray_600 flex items-center font-medium ml-[8px] mt-[4px] text-paragraph_m">
        {content}
      </div>
    </div>
  );
};

export default Info;
