import React from 'react';

import { Icon } from '@/components/ui';
import { checkTheSameMessagesClassName } from '@/features/chat/blocks/message/message.style';
import { MessageType } from '@/types/chat.type';

type MessagePropsType = {
  message: MessageType;
  isOnlyMessage: boolean;
  isLastMessage: boolean;
};
const Message = ({ message, isOnlyMessage }: MessagePropsType) => {
  const { text, chat_date, chat_time, is_user, files, path_files } = message;
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userDate = new Date(`${chat_date}T${chat_time}Z`);

  const formattedTime = userDate.toLocaleTimeString('ru-RU', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: userTimeZone,
  });
  const formattedDate = userDate
    .toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: 'short',
    })
    .replace('.', ',');

  return (
    <div
      className={`flex  ${is_user ? 'flex-row-reverse' : ''} ${
        !isOnlyMessage ? 'mb-1' : 'mb-3'
      } last:mb-1`}
    >
      <div>
        <div className={checkTheSameMessagesClassName(is_user, isOnlyMessage)}>
          {text && (
            <div
              className={`text-paragraph_m  font-regular break-all  ${
                is_user ? 'text-gray_600' : 'text-white'
              }`}
            >
              {text}
            </div>
          )}

          {files.length > 0 && (
            <div>
              {files.map((file, index) => (
                <div key={index} className="flex px-2 py-2">
                  <div className="pr-3">
                    <Icon
                      name="Document"
                      size={20}
                      className={`${is_user ? 'text-primary_400' : 'text-white'}`}
                    />
                  </div>
                  <div
                    className={`text-paragraph_m font-medium ${
                      is_user ? 'text-primary_400' : 'text-white'
                    } 0`}
                  >
                    <a
                      target="_blank"
                      rel="noreferrer"
                      download
                      href={`/files${path_files}/${file}`}
                    >
                      {file}
                    </a>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className={`flex ${is_user ? ' justify-end' : ''}`}>
            {is_user && <Icon name="CheckMessage" size={16} />}
            <div
              className={`text-paragraph_s italic font-regular ${
                is_user ? 'text-gray_400' : 'text-white'
              }`}
            >
              <span>{formattedDate} </span>
              <span>{formattedTime}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
