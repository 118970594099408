import React from 'react';

const ClipboardCheck = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7.5 4.167H5.833a1.667 1.667 0 00-1.666 1.666v10A1.666 1.666 0 005.833 17.5h8.334a1.666 1.666 0 001.666-1.667v-10a1.667 1.667 0 00-1.666-1.666H12.5m-5 0a1.667 1.667 0 001.667 1.666h1.666A1.667 1.667 0 0012.5 4.167m-5 0A1.667 1.667 0 019.167 2.5h1.666A1.667 1.667 0 0112.5 4.167m-5 7.5l1.667 1.666L12.5 10"
      ></path>
    </svg>
  );
};

export default ClipboardCheck;
