import { RootState } from '@/store';

export const proposalSelector = (state: RootState) => {
  return state.proposalStore.proposal;
};
export const proposalsSelector = (state: RootState) => {
  return state.proposalStore.proposals;
};
export const totalProposalsCountSelector = (state: RootState) => {
  return state.proposalStore.totalProposalsCount;
};
export const totalProposalsPriceSelector = (state: RootState) => {
  return state.proposalStore.totalProposalsPrice;
};
export const pageProposalsSelector = (state: RootState) => {
  return state.proposalStore.page;
};
export const isLoadingSelector = (state: RootState) => {
  return state.proposalStore.isLoading;
};
