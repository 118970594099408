import React from 'react';

const Dashboard = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3.822 3.822a1.667 1.667 0 00-.489 1.179v1.666A1.667 1.667 0 005 8.334h1.667a1.667 1.667 0 001.666-1.667V5.001a1.667 1.667 0 00-1.666-1.667H5c-.442 0-.866.176-1.178.488zM12.155 3.822a1.667 1.667 0 00-.488 1.179v1.666a1.667 1.667 0 001.666 1.667H15a1.667 1.667 0 001.667-1.667V5.001A1.667 1.667 0 0015 3.334h-1.667c-.442 0-.866.176-1.178.488zM3.822 12.155a1.667 1.667 0 00-.489 1.179v1.667A1.667 1.667 0 005 16.667h1.667a1.667 1.667 0 001.666-1.666v-1.667a1.667 1.667 0 00-1.666-1.667H5c-.442 0-.866.176-1.178.489zM12.155 12.155a1.666 1.666 0 00-.488 1.179v1.667a1.667 1.667 0 001.666 1.666H15a1.667 1.667 0 001.667-1.666v-1.667A1.667 1.667 0 0015 11.667h-1.667c-.442 0-.866.176-1.178.489z"
      ></path>
    </svg>
  );
};

export default Dashboard;
