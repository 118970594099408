import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@/components/ui';

type AddCounterpartyProps = {
  openArchieveResult: boolean;
  toggleArchieveResult: (state: boolean) => void;
  email: string;
};

const ArchieveResult: FC<AddCounterpartyProps> = (props) => {
  const { email, openArchieveResult, toggleArchieveResult } = props;
  const { t } = useTranslation();
  const handleOnCancel = () => {
    toggleArchieveResult(false);
  };
  return (
    <Modal
      type="dialog"
      withoutFooter={true}
      label={t('view_order.modal_title')}
      isShow={openArchieveResult}
      onCancel={handleOnCancel}
    >
      <div>
        {t('view_order.archieveResult')}
        {` `}
        {email}
      </div>
    </Modal>
  );
};

export default ArchieveResult;
