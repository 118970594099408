import React, {
  FC,
  useRef,
  useState,
  MouseEvent,
  forwardRef,
  ForwardedRef,
  ChangeEvent,
  useEffect,
} from 'react';
import clsx from 'clsx';

import { useOnClickOutside } from '@/hooks/use-onclick-outside';
import { Icon } from '@/components/ui';
import {
  errorClassName,
  optionClassName,
  selectBoxClassName,
  selectClassName,
  selectLabelClassName,
} from '@/components/ui/select/select.styles';
import { SelectProps, SelectSchema } from '@/components/ui/select/select.types';

const Select: FC<SelectProps> = forwardRef(
  (props, forwardedRef: ForwardedRef<HTMLInputElement>) => {
    const {
      schema = { title: 'title', value: 'value' },
      data,
      withReset = false,
      withBottomItem = false,
      bottomItemIcon,
      bottomItemText,
      onClickBottomItem,
      disabled = false,
      label,
      onSelectOption,
      initialValue,
      activeValue,
      placeholder,
      error,
      ownClassName = '',
      reset,
      withSearch = false,
      independentSearch = false,
      ...remainingProps
    } = props;

    const [toggleSelect, changeToggleSelect] = useState<boolean>(false);
    const [currentSearchText, setCurrentSearchText] = useState<string | null>('');
    const [currentSuccessSearch, setCurrentSuccessSearch] = useState<string | number | null>(
      initialValue ? String(initialValue) : null,
    );
    const [currentData, changeCurrentData] = useState<Array<SelectSchema>>(data);
    const selectRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      data && changeCurrentData(data);
    }, [data]);

    useEffect(() => {
      if (initialValue && withSearch) {
        setCurrentSearchText(initialValue as string);
        setCurrentSuccessSearch(initialValue as string);
      }
    }, [initialValue, withSearch]);

    function handleCloseDropList() {
      changeToggleSelect(false);
      setCurrentSearchText(null);
      setCurrentSuccessSearch(activeValue);
    }

    useOnClickOutside(selectRef, handleCloseDropList);

    function handleToggleSelect() {
      !disabled && changeToggleSelect(true);
    }

    function findValueInData(value: string | number | undefined) {
      const result = currentData?.find((item) => item[schema.value] == value);
      if (!result) return {};
      else return result;
    }

    function handleSelectValue(option: string | number) {
      onSelectOption?.(findValueInData(option));
      changeToggleSelect(false);
      setCurrentSuccessSearch(String(findValueInData(option)[schema['title']]));
      setCurrentSearchText('');
    }

    function handleBottomClick(event: MouseEvent<HTMLLIElement>) {
      event.preventDefault();
      onClickBottomItem?.();
    }

    function resetSelectValue() {
      reset?.();
      setCurrentSuccessSearch(null);
      setCurrentSearchText(null);
      changeCurrentData(data);
    }

    function handleSearchInputAndFilterData(event: ChangeEvent<HTMLInputElement>) {
      if (withSearch && independentSearch) {
        setCurrentSearchText(event.target.value);
        setCurrentSuccessSearch(null);
        changeCurrentData([]);
        onSelectOption?.({ title: '', value: String(event.target.value) });
        return;
      }
      if (data) {
        setCurrentSuccessSearch(null);
        setCurrentSearchText(event.target.value);
        changeCurrentData(
          data?.filter(
            (item) =>
              item &&
              String(item[schema.title])
                .toLowerCase()
                .replace(/[-.'"]/gm, '')
                .startsWith(String(event.target.value).toLowerCase()),
          ),
        );
      }
    }

    function handleChangeVariantOfSearchThenFocus() {
      changeToggleSelect.bind(true);
      setCurrentSearchText(null);
      changeCurrentData(data);
      //setCurrentSuccessSearch(String(activeValue) || String(initialValue) || '');
    }

    const resetButton = withReset
      ? [
          <li key={0} onClick={resetSelectValue} className={optionClassName({ active: true })}>
            <span className="mr-[10px] mt-[-2px]">
              <Icon name="Close" size={18} />
            </span>
            Сброс
          </li>,
        ]
      : [];

    const bottomButton = withBottomItem
      ? [
          <li
            key={currentData.length + 1}
            onClick={handleBottomClick}
            className={optionClassName({ active: true })}
          >
            <span className="mr-[10px] mt-[-2px]">
              {bottomItemIcon && <Icon name={bottomItemIcon} size={18} />}
            </span>
            {bottomItemText}
          </li>,
        ]
      : [];

    const renderSelectOptionsList = currentData && [
      ...resetButton,
      ...currentData.map((option, index) => (
        <li
          onClick={handleSelectValue.bind(null, option[schema.value])}
          className={optionClassName({
            active: option[schema.title] === activeValue,
          })}
          key={++index}
        >
          {option[schema.title]}
        </li>
      )),
      ...bottomButton,
    ];

    const renderValueOrSearchInput = withSearch ? (
      <input
        className={clsx(disabled && 'cursor-not-allowed', 'bg-transparent w-full h-full')}
        placeholder={placeholder}
        defaultValue={currentSuccessSearch || currentSearchText || ''}
        onChange={handleSearchInputAndFilterData}
        onFocus={handleChangeVariantOfSearchThenFocus}
        disabled={disabled}
      />
    ) : (
      <div className="truncate">
        {initialValue ? initialValue : <span className="text-gray_400 ">{placeholder}</span>}
      </div>
    );

    return (
      <div>
        {label && <label className={selectLabelClassName({ disabled })}>{label}</label>}
        <div className={`relative ${ownClassName}`} ref={selectRef}>
          <input
            className="h-0 appearance-none hidden"
            defaultValue={initialValue}
            ref={forwardedRef}
            {...remainingProps}
          />
          <div
            className={selectClassName({ disabled, error: !!error })}
            onClick={handleToggleSelect}
          >
            <div className="w-full items-center justify-between flex">
              <div className="truncate w-full">{renderValueOrSearchInput}</div>
              <Icon name={toggleSelect ? 'ChevronTop' : 'ChevronBottom'} size={20} />
            </div>
          </div>
          <div>
            {toggleSelect && <ul className={selectBoxClassName}>{renderSelectOptionsList}</ul>}
          </div>
        </div>
        {!!error && <p className={errorClassName}>{error}.</p>}
      </div>
    );
  },
);

Select.displayName = 'Select';

export default Select;
