import React, { ChangeEvent, FC, ForwardedRef, forwardRef, useEffect, useState } from 'react';

import clsx from 'clsx';

import { textareaClassName } from '@/components/ui/textarea/textarea.styles';

type TextareaProps = {
  label?: string;
  error?: boolean;
  disabled?: boolean;
  height?: number;
  defaultValue?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
};

const Textarea: FC<TextareaProps> = forwardRef(
  (props, forwardedRef: ForwardedRef<HTMLTextAreaElement>) => {
    const {
      label,
      error = false,
      disabled = false,
      defaultValue,
      onChange,
      height = 200,
      ...remainingProps
    } = props;
    const [value, setValue] = useState('');
    const [isFocus, setIsFocus] = useState(false);

    useEffect(() => {
      defaultValue && setValue(defaultValue);
    }, [defaultValue]);

    function handleOnChange(event: ChangeEvent<HTMLTextAreaElement>) {
      setValue(event.target.value);
      onChange?.(event);
    }

    const renderLabel = label && (
      <label
        className={clsx(
          'block',
          'mb-1',
          'text-paragraph_m',
          'font-bold',
          'truncate',
          disabled ? 'text-gray_400' : 'text-gray_600',
        )}
      >
        {label}
      </label>
    );

    return (
      <div className="w-full">
        {renderLabel}
        <textarea
          value={value}
          ref={forwardedRef}
          className={textareaClassName({ isFocus, disabled, error })}
          style={{ height: `${height}px` }}
          onFocus={setIsFocus.bind(null, true)}
          onBlur={setIsFocus.bind(null, false)}
          onChange={handleOnChange}
          {...remainingProps}
        >
          {defaultValue}
        </textarea>
      </div>
    );
  },
);

Textarea.displayName = 'Textarea';

export default Textarea;
