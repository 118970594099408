import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useValidation } from '@/hooks/use-validation';
import { Button, Input } from '@/components/ui';
import { User } from '@/types/user.type';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useUpdateUserMutation } from '@/store/api/profile.api';
import { setUser } from '@/store/slices/auth.slice';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { tokenSelector, userSelector } from '@/store/selectors/auth.selectors';
import { useNotification } from '@/hooks/use-notifications';
import { UpdateProfileBody } from '@/types/profile.type';

const ProfileSettingsFormBlock = () => {
  const user: User | null = useAppSelector(userSelector);
  const token: string | null = useAppSelector(tokenSelector);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<UpdateProfileBody>({
    defaultValues: user
      ? {
          surname: user?.surname,
          name: user?.name,
          middle_name: user?.middle_name,
          phone1: user?.phone1,
          phone2: user?.phone2,
          email: user?.email,
        }
      : {},
  });

  const { t } = useTranslation();
  const validation = useValidation();
  const dispatch = useAppDispatch();
  const { createNotification } = useNotification();

  const [updateUser] = useUpdateUserMutation();

  async function handleProfileSubmit(data: UpdateProfileBody) {
    if (!user?.id) return;
    const result = await updateUser({ userId: user.id, body: data }).unwrap();
    if (result.success) {
      dispatch(setUser({ token, me: result.data }));
      createNotification?.({
        variant: 'success',
        title: t('profile.notification.success'),
      });
    } else {
      createNotification?.({
        variant: 'fail',
        title: t('profile.notification.fail'),
      });
    }
  }

  const renderFormButton =
    user && Object.keys(user).filter((item) => item !== '').length > 1 ? (
      <div className="mt-[24px]">
        <Button type="submit" label={t('profile.btn.edit')} icon="Edit" variant="outlined" isFull />
      </div>
    ) : (
      <div className="mt-[24px] inline-flex items-center gap-1">
        <Button
          type="submit"
          label={t('profile.btn.cancel')}
          icon="Edit"
          variant="outlined"
          isFull
        />
        <Button type="submit" label={t('profile.btn.save')} icon="Edit" variant="outlined" isFull />
      </div>
    );

  if (user) {
    return (
      <div>
        <form onSubmit={handleSubmit(handleProfileSubmit)}>
          <div className="mt-[16px]">
            <Input
              type="text"
              {...register('surname', validation.surname)}
              label={t('profile.input.surname.label')}
              error={errors.surname?.message}
              placeholder={t('profile.input.surname.placeholder')}
              defaultValue={user?.surname}
            />
          </div>
          <div className="mt-[16px]">
            <Input
              type="text"
              {...register('name', validation.name)}
              label={t('profile.input.name.label')}
              error={errors.name?.message}
              defaultValue={user?.name}
              placeholder={t('profile.input.name.placeholder')}
            />
          </div>
          <div className="mt-[16px]">
            <Input
              type="text"
              {...register('middle_name', validation.middle_name)}
              label={t('profile.input.patronymic.label')}
              error={errors.middle_name?.message}
              placeholder={t('profile.input.patronymic.placeholder')}
              defaultValue={user?.middle_name}
            />
          </div>
          <div className="mt-[16px]">
            <Input
              type="phone"
              autoComplete="off"
              {...register('phone1', validation.phone1)}
              mask={'+7 (999) 999-99-99'}
              onChange={(event) => setValue('phone1', event.target.value.replace(/[_]/gm, ''))}
              label={t('profile.input.phone1.label')}
              error={errors.phone1?.message}
              placeholder={t('profile.input.phone1.placeholder')}
              defaultValue={user?.phone1}
            />
          </div>
          <div className="mt-[16px]">
            <Input
              type="phone"
              autoComplete="off"
              {...register('phone2', validation.phone2)}
              mask={'+7 (999) 999-99-99'}
              onChange={(event) => {
                if (event.target.value.replace(/[_]/gm, '') === '') {
                  setValue('phone2', null);
                } else {
                  setValue('phone2', event.target.value.replace(/[_]/gm, ''));
                }
              }}
              label={t('profile.input.phone2.label')}
              error={errors.phone2?.message}
              placeholder={t('profile.input.phone2.placeholder')}
              defaultValue={user?.phone2}
            />
          </div>
          <div className="mt-[16px]">
            <Input
              type="text"
              {...register('email', validation.email)}
              label={t('profile.input.email.label')}
              error={errors.email?.message}
              placeholder={t('profile.input.email.placeholder')}
              defaultValue={user?.email}
            />
          </div>
          {renderFormButton}
        </form>
        {/* {isError && isError.error} */}
      </div>
    );
  } else {
    return null;
  }
};

export default ProfileSettingsFormBlock;
