import React, { FC, useCallback, useEffect } from 'react';

import { Table } from 'antd';

import { Link } from 'react-router-dom';

import moment from 'moment';

import { TFunction, useTranslation } from 'react-i18next';

import { useLazyGetTransactionsQuery } from '@/store/api/affiliate-program.api';
import { setTransactions } from '@/store/slices/affiliate-program.slice';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { useAppSelector } from '@/hooks/use-app-selector';
import { transactionsSelector } from '@/store/selectors/affiliate-program.selectors';

import { TransactionsItems } from '@/types/affiliate-program.type';

type AlignType = 'left' | 'center' | 'right';

const getActRecord = (actUrl: TransactionsItems['act_url']) => {
  return actUrl ? (
    <Link className={'text-primary_400'} to={actUrl}>
      Акт 12387674
    </Link>
  ) : (
    '-'
  );
};

const getAmountWithStatus = (amount: TransactionsItems['amount'], type: TransactionsItems['t']) => {
  return type === 'income' ? (
    <span className="bg-positive_100 text-positive_500 px-[8px] py-[4px] rounded-[100px]">
      +{amount} р
    </span>
  ) : (
    <span className="bg-negative_100 text-negative_500 px-[8px] py-[4px] rounded-[100px]">
      -{amount} р
    </span>
  );
};

const columnClassname = 'before:hidden bg-white text-gray_600';
const translatePath = 'affiliate-program.blocks.details-of-affiliate-payments';
const getColumns = (t: TFunction<'translation'>) => {
  return [
    {
      title: `${t(translatePath + '.table.columns.name')}`,
      dataIndex: 'comment',
      key: 'comment',
      className: columnClassname,
    },
    {
      title: `${t(translatePath + '.table.columns.date')}`,
      dataIndex: 'date',
      key: 'date',
      width: 173,
      className: columnClassname,
      render: (date: moment.MomentInput) => moment(date).format('DD.MM.YYYY'),
    },
    {
      title: `${t(translatePath + '.table.columns.act')}`,
      dataIndex: 'act_url',
      key: 'act_url',
      width: 173,
      className: columnClassname,
      render: (actUrl: TransactionsItems['act_url']) => getActRecord(actUrl),
    },
    {
      title: `${t(translatePath + '.table.columns.status')}`,
      dataIndex: 'amount',
      key: 'amount',
      align: 'right' as AlignType,
      width: 111,
      className: columnClassname,
      render: (amount: TransactionsItems['amount'], record: TransactionsItems) =>
        amount === 0 ? 0 : getAmountWithStatus(amount, record.t),
    },
  ];
};

const DetailsOfAffiliatePaymentsBlock: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const transactions = useAppSelector(transactionsSelector);
  const [getTransactions] = useLazyGetTransactionsQuery();
  const fetchTransactions = useCallback(
    async ({ limit = 20, page = 1 }) => {
      const response = await getTransactions({ limit, page });
      if (response.status === 'fulfilled') {
        dispatch(setTransactions(await response.data.data));
      }
    },
    [dispatch, getTransactions],
  );

  useEffect(() => {
    if (transactions.items?.length) return;
    fetchTransactions({ limit: 20, page: 1 });
  }, [fetchTransactions, transactions.items?.length]);

  return (
    <div className="bg-white rounded-lg border border-gray_200 p-[16px]">
      <Table
        dataSource={transactions.items}
        columns={getColumns(t)}
        pagination={false}
        rowKey={(record) => record.id + '_transactions'}
        size={'small'}
        rowClassName={'table-row__transactions'}
      />
    </div>
  );
};

export default DetailsOfAffiliatePaymentsBlock;
