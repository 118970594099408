import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Input } from '@/components/ui';
import { EgrnNewOrderItem } from '@/types/egrn.type';

type RealEstateCardProps = {
  item: EgrnNewOrderItem;
  index: number;
  deleteItem: (id: string | undefined) => void;
  onChangeItem: (
    field: keyof Pick<EgrnNewOrderItem, 'comment' | 'report_type'>,
    value: string | number,
    uuid?: string,
  ) => void;
};

const RealEstateCard: FC<RealEstateCardProps> = (props) => {
  const { item, index, deleteItem, onChangeItem } = props;
  const { t } = useTranslation();
  const itemTitleClassName = 'inline-block min-w-[160px] text-gray_400 mr-4';
  const itemClassName = 'mt-3 flex';
  const radioStyledClassName =
    'radio-styled inline-block w-4 h-4 border border-gray_400 rounded-full';

  return (
    <Box>
      <div className="py-5 px-6 text-paragraph_l text-gray_600">
        <div className="flex justify-between">
          <span className="font-bold text-heading_h3">Объект {index + 1}</span>
          <span
            className="text-gray_400 hover:text-primary_400 cursor-pointer"
            onClick={() => deleteItem(item.uuid)}
          >
            Удалить из заказа
          </span>
        </div>
        <ul className="flex flex-col">
          <li className={itemClassName}>
            <span className={itemTitleClassName}>{t('create_egrn.real_estate_card.cadastr')}</span>
            <span>{item.cadastral_number}</span>
          </li>
          <li className={itemClassName}>
            <span className={itemTitleClassName}>
              {t('create_egrn.real_estate_card.realty_type')}
            </span>
            <span>{item.realty_type}</span>
          </li>
          <li className={itemClassName}>
            <span className={itemTitleClassName}>{t('create_egrn.real_estate_card.area')}</span>
            <span>{item.area}</span>
          </li>
          <li className={itemClassName}>
            <span className={itemTitleClassName}>{t('create_egrn.real_estate_card.address')}</span>
            <span>{item.address}</span>
          </li>
          <li className={itemClassName}>
            <span className={itemTitleClassName}>
              {t('create_egrn.real_estate_card.report_type')}
            </span>
            <div>
              <label className="block cursor-pointer mb-1">
                <input
                  type="radio"
                  value={1}
                  className="radio hidden"
                  checked={item.report_type === 1}
                  onChange={(e) => onChangeItem('report_type', +e.target.value, item.uuid)}
                />
                <span className={radioStyledClassName}></span>
                {t('create_egrn.radio.report_type.1')}
              </label>
              <label className="block cursor-pointer">
                <input
                  type="radio"
                  value={2}
                  className="radio hidden"
                  checked={item.report_type === 2}
                  onChange={(e) => onChangeItem('report_type', +e.target.value, item.uuid)}
                />
                <span className={radioStyledClassName}></span>
                {t('create_egrn.radio.report_type.2')}
              </label>
            </div>
          </li>
          <li className={itemClassName}>
            <span className={itemTitleClassName}>{t('create_egrn.real_estate_card.comment')}</span>
            <Input
              placeholder={t('create_egrn.input.comment.placeholder')}
              onChange={(e) => onChangeItem('comment', e.target.value, item.uuid)}
            />
          </li>
        </ul>
      </div>
    </Box>
  );
};

export default RealEstateCard;
