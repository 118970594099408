import React from 'react';

const ChatAltTwo = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_5499_17036)">
        <rect x="6" y="2" width="64" height="64" rx="12" fill="#E1FAF2" />
        <path
          d="M46.3333 27.3327H49.6667C50.5507 27.3327 51.3986 27.6839 52.0237 28.309C52.6488 28.9341 53 29.782 53 30.666V40.666C53 41.5501 52.6488 42.3979 52.0237 43.023C51.3986 43.6482 50.5507 43.9994 49.6667 43.9994H46.3333V50.666L39.6667 43.9994H33C32.5622 43.9998 32.1287 43.9138 31.7242 43.7461C31.3198 43.5785 30.9525 43.3327 30.6433 43.0227M30.6433 43.0227L36.3333 37.3327H43C43.8841 37.3327 44.7319 36.9815 45.357 36.3564C45.9821 35.7313 46.3333 34.8834 46.3333 33.9993V23.9993C46.3333 23.1153 45.9821 22.2674 45.357 21.6423C44.7319 21.0172 43.8841 20.666 43 20.666H26.3333C25.4493 20.666 24.6014 21.0172 23.9763 21.6423C23.3512 22.2674 23 23.1153 23 23.9993V33.9993C23 34.8834 23.3512 35.7313 23.9763 36.3564C24.6014 36.9815 25.4493 37.3327 26.3333 37.3327H29.6667V43.9994L30.6433 43.0227Z"
          stroke="#22C594"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </g>
    </svg>
  );
};

export default ChatAltTwo;
