import React from 'react';

import { tabItemClassName, tabListClassName } from '@/components/ui/selector/selector.styles';

type SelectorItem = {
  tag: Readonly<string>;
  title: string;
};

type SelectorProps = {
  data: Array<SelectorItem>;
  initialSelect?: SelectorItem;
  defaultValue: string | number;
  onSelect?: (tab: SelectorItem) => void;
  disabled?: boolean;
};

const Selector: React.FC<SelectorProps> = (props) => {
  const { data, onSelect, defaultValue, disabled } = props;

  function handleOnSelect(selectedTab: SelectorItem) {
    onSelect?.(selectedTab);
  }

  const renderTabItem =
    data &&
    data.map((tab) => (
      <button
        onClick={handleOnSelect.bind(null, tab)}
        key={tab.tag}
        type="button"
        className={tabItemClassName({ selected: defaultValue === tab.tag })}
        disabled={disabled}
      >
        {tab.title}
      </button>
    ));

  return (
    <div className={tabListClassName} role="group">
      {renderTabItem}
    </div>
  );
};

export default Selector;
