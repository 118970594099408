export type Offer = {
  id: number;
  number: string | number;
  proposal_id: number;
  price: number;
  deadline: number;
  subject: string;
  docs_manager?: OfferEmployees;
  needed_documents: string;
  results: string;
  employees: OfferEmployees;
  status: OfferStatus;
  acceptance: Acceptance;
};

export type OfferEmployees = {
  id: number;
  fio: string;
  phone: string;
  email: string;
  tg_link: string;
  wa_link: string;
};

export enum OfferStatus {
  sended = 'Отправлено',
  priorConsent = 'Предварительное согласие',
  failure = 'Провал',
}
export enum Acceptance {
  accepted = 'accepted',
  rejected = 'rejected',
  none = 'None',
}

export type OfferFile = {
  files: File[];
};
