import React from 'react';

const Clip = (props: { size: number }) => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12.643 5.833l-5.488 5.489a1.668 1.668 0 102.357 2.356l5.345-5.488a3.333 3.333 0 00-4.714-4.713L4.797 8.964a5 5 0 007.072 7.072l5.214-5.203"
      ></path>
    </svg>
  );
};

export default Clip;
